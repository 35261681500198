import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { numberFormat, onlyNumber } from '../../Utils/Common';
import {
    handleInputChange,
    isSalePrice,
    DoNotTrackSwitch,
} from './../../redux/actions/product/product.actions';
import ReactTooltip from 'react-tooltip';
import Switch from 'react-switch';

function PricingStocks({
    product: {
        single_product: {
            cost,
            srp,
            delivery_fee,
            customization_fee,
            initial_stock,
            low_threshold,
            sale_price,
            is_sale_price,
            mute_threshold,
        },
    },
    handleInputChange,
    isSalePrice,
    DoNotTrackSwitch,
}) {
    return (
        <Fragment>
            <ReactTooltip effect="solid" event="click" />
            <h4 className="form-section">
                <i className="ft-clipboard"></i> Pricing & Stock{' '}
            </h4>
            <section className="row f-regular">
                <div className="col-sm-6">
                    <div className="form-group row">
                        <label className="col-md-4 col-12 label-control">
                            SRP <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-7 col-10">
                            <input
                                onKeyPress={(e) => onlyNumber(e)}
                                type="number"
                                id="srp"
                                name="srp"
                                step="any"
                                value={srp}
                                className="form-control"
                                placeholder="SRP"
                                onChange={handleInputChange}
                                min={0}
                                required
                            />
                        </div>
                        <div className="float-right pl-0 col-2 col-md-1">
                            <div className="input-group-prepend">
                                <span
                                    href="#"
                                    className="tool-tip text-primary"
                                    data-tip="this is a tool tip"
                                >
                                    <i className="la la-question-circle"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-md-4 col-12 label-control">
                            Delivery Fee
                        </label>
                        <div className="col-md-7 col-10">
                            <input
                                onKeyPress={(e) => onlyNumber(e)}
                                type="number"
                                id="delivery_fee"
                                step="any"
                                value={delivery_fee}
                                min={0}
                                name="delivery_fee"
                                className="form-control"
                                placeholder="Delivery Fee"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="float-right pl-0 col-2 col-md-1">
                            <div className="input-group-prepend">
                                <span
                                    href="#"
                                    className="tool-tip text-primary"
                                    data-tip="this is a tool tip"
                                >
                                    <i className="la la-question-circle"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-md-4 col-12 label-control">
                            Custom. Fee
                        </label>
                        <div className="col-md-7 col-10">
                            <input
                                onKeyPress={(e) => onlyNumber(e)}
                                type="number"
                                id="customization_fee"
                                step="any"
                                value={customization_fee}
                                name="customization_fee"
                                min={0}
                                className="form-control"
                                placeholder="Customization Fee"
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="float-right pl-0 col-2 col-md-1">
                            <div className="input-group-prepend">
                                <span
                                    href="#"
                                    className="tool-tip text-primary"
                                    data-tip="this is a tool tip"
                                >
                                    <i className="la la-question-circle"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6">
                    <div className="form-group row">
                        <label className="col-md-4 col-12 label-control">
                            Low Threshold
                        </label>
                        <div className="col-md-7 col-10">
                            <input
                                onKeyPress={(e) => onlyNumber(e)}
                                type="number"
                                id="low_threshold"
                                value={low_threshold}
                                name="low_threshold"
                                className="form-control"
                                placeholder="Low Threshold"
                                onChange={handleInputChange}
                                min={0}
                                disabled={mute_threshold}
                            />
                        </div>
                        <div className="float-right pl-0 col-2 col-md-1">
                            <div className="input-group-prepend">
                                <span
                                    href="#"
                                    className="tool-tip text-primary"
                                    data-tip="this is a tool tip"
                                >
                                    <i className="la la-question-circle"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-md-4 col-12 label-control">
                            Mute threshold alarm:
                        </label>
                        <div className="col-md-7 col-10">
                            <Switch
                                onChange={(event) =>
                                    DoNotTrackSwitch(event, 'mute_threshold')
                                }
                                checked={mute_threshold}
                                onColor="#6967CE"
                                width={62}
                                checkedIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            paddingLeft: 4,
                                            fontSize: 16,
                                            color: '#fff',
                                        }}
                                    >
                                        {' '}
                                        Yes{' '}
                                    </div>
                                }
                                uncheckedIcon={
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100%',
                                            padding: 2,
                                            fontSize: 17,
                                            color: '#fff',
                                        }}
                                    >
                                        {' '}
                                        No{' '}
                                    </div>
                                }
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <label className="col-md-4 col-12 label-control">
                            Sale Price{' '}
                        </label>
                        <div className="col-md-7 col-10">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <input
                                            type="checkbox"
                                            onChange={isSalePrice}
                                            checked={is_sale_price}
                                        />
                                    </div>
                                </div>
                                {is_sale_price ? (
                                    <input
                                        onKeyPress={(e) => onlyNumber(e)}
                                        type="number"
                                        id="sale_price"
                                        value={sale_price}
                                        step="any"
                                        name="sale_price"
                                        className="form-control"
                                        placeholder="Sale Price"
                                        min={0}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    <div className="form-control bg-disabled"></div>
                                )}
                            </div>
                        </div>
                        <div className="float-right pl-0 col-2 col-md-1">
                            <div className="input-group-prepend">
                                <span
                                    href="#"
                                    className="tool-tip text-primary"
                                    data-tip="this is a tool tip"
                                >
                                    <i className="la la-question-circle"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    product: state.product,
});

export default connect(mapStateToProps, {
    handleInputChange,
    isSalePrice,
    DoNotTrackSwitch,
})(PricingStocks);
