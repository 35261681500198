import React, { Fragment, useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import {
    getInventoryList,
    handleSearchInventory,
    sortTableByColumn,
    addStock,
    removeRestrock,
    processConfirm,
    hideModal,
    multipleDelete,
    setSelectedRows,
    filterItemByBrand,
} from '../../redux/actions/inventory/inventory.actions';
import { useHistory, Link } from 'react-router-dom';
import { Show, useAccess } from 'react-redux-permission';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { formattedDate, limitString, numberFormat } from '../../Utils/Common';
import { useDebounce } from 'use-debounce';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    handleHover,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import InventorySuppler from './modals/suppliers';
import InventoryMultiSuppler from './modals/poprocess';

function InventoryList({
    inventory: {
        inventory_list,
        search,
        pagination,
        loading_inv,
        stock_view,
        sort_order_name,
        sort_order_direction,
        set_row_checked,
        loading,
        po_process,
        show_modal,
        delete_general,
        selected_rows,
        inv_brand,
    },
    getInventoryList,
    handleSearchInventory,
    sortTableByColumn,
    handleHover,
    hoverIndex,
    updateMUISetting,
    create_url,
    addStock,
    removeRestrock,
    processConfirm,
    hideModal,
    multipleDelete,
    setSelectedRows,
    filterItemByBrand,
}) {
    let history = useHistory();
    const { hasPermission } = useAccess();
    const canEdit = hasPermission('inv.inventory.all.edit');
    const canview = hasPermission('inv.inventory.all.view');

    useEffect(() => {
        getInventoryList(1);
    }, []);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    const [searchInputValue, setSearchInputValue] = useState('');

    function handleSearchOnKeyUp(event) {
        setSearchInputValue(event.target.value);
    }

    useEffect(() => {
        if (debouncedSearch) {
            if (oldDebounced !== debouncedSearch[0]) {
                if (debouncedSearch[0].length !== 1) {
                    setOldDebounced(debouncedSearch[0]);
                    getInventoryList(1, true);
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    let inv_list = inventory_list[stock_view];

    function handleChangeLink(link) {
        if (canEdit) {
            history.push(link);
        }
    }

    // THIS WILL SET THE HEADER ALIGN STYLE
    const useStyles = makeStyles((theme) => ({
        centeredHeader: {
            '& span': {
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
            },
        },
    }));
    const classes = useStyles();

    // THIS SET THE ROW BACKGROUND COLOR
    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(2)': {
                            // height: '61px'   // does not need for height coz image will do the height for every row
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    root: {
                        '&:nth-child(1)': {
                            width: '100px',
                        },
                        '&:nth-child(5)': {
                            width: '150px',
                        },
                        '&:nth-child(12)': {
                            width: '100px',
                        },
                    },
                },
            },
        });
    let column_id = stock_view == 're-stock' ? 'request_id' : '_id';
    const columns = [
        {
            name: column_id,
            label: '',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: '',
            label: 'Image',
            options: {
                display: true,
                filter: false,
                print: false,
                download: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let product_img =
                        inv_list[tableMeta['rowIndex']].product_img;
                    return loading_inv ? (
                        <div>
                            <Skeleton height={40} />
                        </div>
                    ) : (
                        <img src={product_img} className="img img-thumbnail" />
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'sku',
            label: 'SKU',
            options: {
                display: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    let id = inv_list[tableMeta['rowIndex']]._id;
                    // handleChangeLink('/inventory/view/' + rowData[0])
                    return loading_inv ? (
                        <div>
                            <Skeleton height={40} />
                        </div>
                    ) : (
                        <Link to={`/inventory/view/${id}`}>{value}</Link>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'product_name',
            label: 'Name',
            options: {
                display: true,
                sort: true,
                customBodyRender: (value) => {
                    return loading_inv ? (
                        <div>
                            <Skeleton height={40} />
                        </div>
                    ) : (
                        value
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'description',
            label: 'Description',
            options: {
                display: true,
                sort: true,
                customBodyRender: (value) => {
                    return loading_inv ? (
                        <div className="cursor-pointer">
                            <Skeleton height={40} />
                        </div>
                    ) : (
                        value
                    );
                },
            },
            setCellProps: (value) => {
                return { className: 'cursor-pointer' };
            },
        },
        {
            name: 'attributes',
            label: 'Attributes',
            options: {
                display: true,
                sort: false,
                customBodyRender: (value) => {
                    return loading_inv ? (
                        <div className="cursor-pointer">
                            <Skeleton height={40} />
                        </div>
                    ) : (
                        limitString(value.str, 50)
                    );
                },
            },
            setCellProps: (value) => {
                return { className: 'cursor-pointer' };
            },
        },
        {
            name: 'brands',
            label: 'Brand',
            options: {
                display: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const brand_obj = {
                        brand_id: inv_list[tableMeta['rowIndex']].brand_id,
                        brand_name: value,
                    };

                    return loading_inv ? (
                        <div>
                            <Skeleton height={40} />
                        </div>
                    ) : (
                        <Link
                            onClick={() => filterItemByBrand(brand_obj, true)}
                        >
                            {value}
                        </Link>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'category',
            label: 'Category',
            options: {
                display: true,
                sort: false,
                customBodyRender: (value) => {
                    return loading_inv ? (
                        <div>
                            <Skeleton height={40} />
                        </div>
                    ) : (
                        value
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'stock_status',
            label: 'Status',
            options: {
                display: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let stock_status =
                        inv_list[tableMeta['rowIndex']].stock_status;
                    let stock_class =
                        inv_list[tableMeta['rowIndex']].stock_class;

                    return loading_inv ? (
                        <div>
                            <Skeleton height={40} />
                        </div>
                    ) : (
                        <span className={stock_class}>{stock_status}</span>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-center' };
                },
            },
        },
        {
            name: 'quantity',
            label: 'Quantity',
            options: {
                display: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let product_unit =
                        inv_list[tableMeta['rowIndex']].product_unit;
                    let quantity = inv_list[tableMeta['rowIndex']].quantity;

                    return loading_inv ? (
                        <div>
                            <Skeleton height={40} />
                        </div>
                    ) : (
                        <dumx>
                            {quantity ? numberFormat(quantity, 0) : 0}{' '}
                            {product_unit}
                        </dumx>
                    );
                },
            },
            setCellProps: (value) => {
                return { className: 'cursor-pointer text-right' };
            },
        },
        // {
        //   name: "",
        //   label: "Incoming",
        //   options: {
        //     display: true,
        //     filter: false,
        //     sort: false,
        //     print: false,
        //     download: false,
        //     customBodyRender: (value) => {
        //       return loading_inv ? (
        //         <div>
        //           <Skeleton height={40} />
        //         </div>
        //       ) : (
        //         <span>0</span>
        //       );
        //     },
        //     setCellProps: (value) => {
        //       return { className: "cursor-pointer text-center" };
        //     },
        //   },
        // },
        {
            name: 'srp_str',
            label: 'Price',
            options: {
                display: true,
                sort: true,
                customBodyRender: (value) => {
                    return loading_inv ? (
                        <div className="money-cell">
                            <Skeleton height={40} />
                        </div>
                    ) : (
                        <div className="money-cell">₱ {value}</div>
                    );
                },
            },
            setCellProps: (value) => {
                return { className: 'cursor-pointer money-cell' };
            },
        },
        {
            name: '',
            label: 'Actions',
            options: {
                display: true,
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = inv_list[tableMeta['rowIndex']]._id;

                    return loading_inv ? (
                        <div>
                            <Skeleton height={40} />
                        </div>
                    ) : (
                        <div className=" w-150p">
                            {hoverIndex == tableMeta.rowIndex ||
                            inv_list[tableMeta['rowIndex']].restock_click ? (
                                stock_view == 're-stock' ? (
                                    <section className="btn-group">
                                        <button
                                            className="btn btn-danger btn-sm"
                                            disabled={delete_general}
                                            onClick={(e) =>
                                                removeRestrock(
                                                    e,
                                                    tableMeta.rowIndex
                                                )
                                            }
                                        >
                                            <i
                                                className={
                                                    inv_list[
                                                        tableMeta['rowIndex']
                                                    ].restock_click
                                                        ? 'icon-loading-custom icon-15'
                                                        : 'ft ft-trash'
                                                }
                                            ></i>
                                        </button>
                                    </section>
                                ) : (
                                    <section className="btn-group">
                                        <Show when="inv.inventory.all.edit">
                                            <button
                                                onClick={() =>
                                                    handleChangeLink(
                                                        '/inventory/view/' + id
                                                    )
                                                }
                                                className="btn btn-grey btn-sm"
                                            >
                                                <i className="ft-edit"></i>
                                            </button>
                                        </Show>
                                        <button
                                            className="btn btn-restock btn-sm"
                                            onClick={(e) =>
                                                addStock(e, tableMeta.rowIndex)
                                            }
                                        >
                                            <i
                                                className={
                                                    inv_list[
                                                        tableMeta['rowIndex']
                                                    ].restock_click
                                                        ? 'icon-loading-custom icon-15'
                                                        : 'ft-rotate-cw'
                                                }
                                            ></i>
                                        </button>
                                    </section>
                                )
                            ) : (
                                <section className="btn-group"></section>
                            )}
                        </div>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-center' };
                },
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage
            ? pagination.itemsCountPerPage
            : 1,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        searchOpen: true,
        selectableRows: stock_view == 're-stock' ? true : false,
        elevation: 2,
        filter: true,
        // print: stock_view != 'all' ? false : true, // Only header toolbar in All Items Tab only
        // download: stock_view != 'all' ? false : true, // Only header toolbar in All Items Tab only
        // filter: stock_view != 'all' ? false : true, // Only header toolbar in All Items Tab only
        // viewColumns: stock_view != 'all' ? false : true, // Only header toolbar in All Items Tab only
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `pos_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading_inv ? (
                    <div>
                        <Skeleton height={40} />
                    </div>
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        rowsSelected: selected_rows,
        onChangeRowsPerPage: (numberOfRows) => {
            getInventoryList(null, null, numberOfRows);
            updateMUISetting('rows', 'inventory', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getInventoryList(
                        tableState.page + 1,
                        null,
                        pagination.itemsCountPerPage
                    );
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    // getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'inventory',
                        null,
                        tableState.columns
                    );
                    break;
                default:
                // console.log('action not handled.');
            }
        },
        // onRowsDelete: (rowsDeleted, dataRows) => {
        //     console.log("rowsDeleted", rowsDeleted);
        //     console.log("dataRows", dataRows);
        // },
        onRowClick: (rowData, rowMeta) => {
            // Go to edit page when row is clicked
            if (canEdit) {
                // handleChangeLink('/inventory/view/' + rowData[0])
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={handleSearchInventory}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
        customToolbarSelect: (selectedRows) => {
            return (
                <div className="mr-24">
                    {stock_view == 're-stock' ? (
                        delete_general ? (
                            <span
                                className="MuiButtonBase-root MuiIconButton-root MUIDataTableToolbarSelect-iconButton-1009"
                                tabIndex="0"
                                type="button"
                                aria-label="Delete Selected Rows"
                            >
                                <span className="MuiIconButton-label">
                                    <i className="icon-loading-custom icon-loading-grey icon-15"></i>
                                </span>
                                <span className="MuiTouchRipple-root"></span>
                            </span>
                        ) : (
                            <span
                                className="MuiButtonBase-root MuiIconButton-root MUIDataTableToolbarSelect-iconButton-1009"
                                onClick={() => multipleDelete(selectedRows)}
                                tabIndex="0"
                                type="button"
                                aria-label="Delete Selected Rows"
                                title="Delete"
                            >
                                <span className="MuiIconButton-label">
                                    <svg
                                        className="MuiSvgIcon-root MUIDataTableToolbarSelect-deleteIcon-1010"
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                    >
                                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                                    </svg>
                                </span>
                                <span className="MuiTouchRipple-root"></span>
                            </span>
                        )
                    ) : (
                        <></>
                    )}
                </div>
            );
        },
        onRowsSelect: (rowsSelected, allRows) => {
            setSelectedRows(allRows);
        },
        onFilterChange: (
            changedColumn,
            filterList,
            type,
            changedColumnIndex,
            displayData
        ) => {
            console.log(
                changedColumn,
                filterList,
                type,
                changedColumnIndex,
                displayData
            );
        },
    };

    const _columns = updateMUISetting(
        'view-columns',
        'inventory',
        null,
        columns
    );

    return (
        <Fragment>
            <h4 className="card-title card-header-tab">
                {stock_view == 're-stock'
                    ? 'List Of Draft PO'
                    : 'List Of Inventory'}{' '}
                <a href="#" className="tool-tip" data-tip="this is a tool tip">
                    <i className="la la-question-circle"></i>
                </a>
                {stock_view == 're-stock' ? (
                    <section className="pull-right">
                        <button
                            className="btn btn-primary btn-sm "
                            onClick={() => processConfirm()}
                        >
                            <i className="ft-rotate-cw"></i>{' '}
                            {po_process ? 'Processing...' : 'Process'}{' '}
                        </button>
                    </section>
                ) : (
                    <Show
                        when={[
                            'inv.products.items.create',
                            'inv.receiving.itemReceipts.cost',
                        ]}
                        operator="and"
                    >
                        <Link
                            to={create_url}
                            className="btn btn-primary btn-sm pull-right"
                        >
                            <i className="ft ft-plus"></i> Create New{' '}
                        </Link>
                    </Show>
                )}
            </h4>
            {/* <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3"></i></a> */}

            <div className="row">
                <div className="col-12">
                    {inv_brand && (
                        <button className="btn btn-light btn-sm text-white ftr-btn my-1">
                            {inv_brand}
                            <span>
                                <i
                                    onClick={() =>
                                        filterItemByBrand(null, false)
                                    }
                                    className="ft ft-x"
                                ></i>
                            </span>
                        </button>
                    )}
                </div>
            </div>

            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    data={inv_list}
                    columns={_columns}
                    options={options}
                />
            </MuiThemeProvider>
            <InventorySuppler
                show={show_modal.supplier}
                onHide={(e) => hideModal(e, 'supplier')}
            />
            <InventoryMultiSuppler
                show={show_modal.po}
                onHide={(e) => hideModal(e, 'po')}
            />
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    inventory: state.inventory,
    hoverIndex: state.helper.hoverIndex,
    create_url: state.product.create_url,
});

export default connect(mapStateToProps, {
    getInventoryList,
    handleSearchInventory,
    sortTableByColumn,
    handleHover,
    updateMUISetting,
    addStock,
    removeRestrock,
    processConfirm,
    multipleDelete,
    setSelectedRows,
    filterItemByBrand,
})(InventoryList);
