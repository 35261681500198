import React, { Fragment, useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import {
    exportProduct,
    getProducts,
    handleSearchInput,
    setPage,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    importModal,
    setModal,
    setRowChecked,
} from '../../redux/actions/product/product.actions';
import ReactTooltip from 'react-tooltip';
import { Redirect, useHistory } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { formattedDate, limitString } from '../../Utils/Common';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import ImportModal from './modals/Product.import.modal';

function ProductList({
    product: {
        products,
        loading,
        pagination,
        search,
        create_url,
        edit_page,
        edit_url,
        edit_upload_url,
        delete_modal,
        export_url,
        sort_order_direction,
        sort_order_name,
        import_modal,
        set_row_checked,
    },
    getProducts,
    handleSearchInput,
    setPage,
    exportProduct,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    handleHover,
    hoverIndex,
    updateMUISetting,
    pageOrigin,
    setMUIPage,
    muiPage,
    importModal,
    setModal,
    setRowChecked,
}) {
    const { hasPermission } = useAccess();
    const canCreate = hasPermission('inv.products.items.create');
    const canDelete = hasPermission('inv.products.items.delete');
    const canEdit = hasPermission('inv.products.items.edit');
    const canview = hasPermission('inv.products.items.view');
    const canViewCost = hasPermission('inv.receiving.itemReceipts.cost');

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        if (debouncedSearch) {
            if (oldDebounced !== debouncedSearch[0]) {
                if (debouncedSearch[0].length !== 1) {
                    if (canview) {
                        setOldDebounced(debouncedSearch[0]);
                        getProducts(1);
                    }
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    const history = useHistory();
    useEffect(() => {
        if (canview) {
            getProducts(muiPage, null, pageOrigin);
        }
    }, []);

    if (edit_page)
        return <Redirect to={canCreate ? edit_url : edit_upload_url} />;

    const changePage = (link) => {
        history.push(link);
    };

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(5)': {
                            height: '61px',
                        },
                    },
                },
                MUIDataTableHeadCell: {
                    root: {
                        '&:nth-child(7)': {
                            width: '150px',
                        },
                        '&:nth-child(6)': {
                            width: '120px',
                        },
                    },
                },
            },
        });

    // console.log(products);

    const columns = [
        {
            name: '_id',
            label: '',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: 'item_upload',
            label: 'Image',
            options: {
                display: true,
                filter: false,
                print: false,
                download: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let product_image =
                        products[tableMeta['rowIndex']].product_image;
                    return (
                        <div>
                            {
                                <img
                                    src={product_image}
                                    className="img img-thumbnail"
                                />
                            }
                        </div>
                    );
                },
            },
        },
        {
            name: 'sku',
            label: 'SKU',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let _id = products[tableMeta['rowIndex']]._id;
                    return (
                        <div>
                            <Link to={`/inventory/view/${_id}`}>{value}</Link>
                        </div>
                    );
                },
            },
        },
        // {name: 'barcode', label: 'Barcode', options : { display: true,
        //         customBodyRender: (value, tableMeta, updateValue) => {
        //           let barcode = products[tableMeta['rowIndex']].barcode;
        //           return (
        //               <div>{barcode}</div>
        //           )
        //       }
        //   },
        // },
        { name: 'product_name', label: 'Name', options: { display: true } },
        {
            name: 'description',
            label: 'Description',
            options: { display: true },
        },
        {
            name: 'attributes',
            label: 'Attributes',
            options: {
                display: true,
                sort: true,
                customBodyRender: (value) => {
                    return <div>{value ? limitString(value, 50) : ''}</div>;
                },
            },
        },
        {
            name: 'suppliers',
            label: 'Supplier(s)',
            options: {
                display: true,
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const suppliers =
                        value.length > 0
                            ? value.map((sup, i) =>
                                  sup ? (
                                      <>
                                          <Link
                                              to={`/purchases/suppliers/history/${sup._id}`}
                                              target="_blank"
                                          >
                                              {sup.name}
                                          </Link>{' '}
                                          {value.length != i + 1 && ' | '}
                                      </>
                                  ) : (
                                      'N/A'
                                  )
                              )
                            : 'N/A';

                    return <span>{suppliers}</span>;
                },
                setCellProps: (value) => {
                    return { className: 'text-center' };
                },
            },
        },
        {
            name: 'brand',
            label: 'Brand',
            options: { display: true, sort: false },
        },
        {
            name: 'category',
            label: 'Category',
            options: { display: true, sort: false },
        },
        {
            name: 'uom',
            label: 'UOM',
            options: {
                display: true,
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{value}</span>;
                },
                setCellProps: (value) => {
                    return { className: 'text-center' };
                },
            },
        },
        // {name: 'cost', label: 'Cost', options : { display: true, },},
        {
            name: 'srp',
            label: 'Price',
            options: {
                display: true,
                customBodyRender: (value) => {
                    return <div className="money-cell">₱ {value}</div>;
                },
            },
        },
        {
            name: 'Actions',
            label: '',
            options: {
                display: true,
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = products[tableMeta.rowIndex]._id;

                    if (!canEdit) {
                        return false;
                    }

                    return (
                        <div className="btn-group">
                            {hoverIndex === tableMeta.rowIndex &&
                                (canCreate ? (
                                    <Link
                                        to={`${edit_url}/${id}`}
                                        className="btn btn-sm btn-grey"
                                    >
                                        <i className="ft ft-edit"></i>
                                    </Link>
                                ) : (
                                    <Link
                                        to={`${edit_upload_url}/${id}`}
                                        className="btn btn-sm btn-grey"
                                    >
                                        <i className="ft ft-edit"></i>
                                    </Link>
                                ))}
                        </div>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'text-center' };
                },
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        search: false,
        searchOpen: true,
        filter: false,
        rowsPerPageOptions: [10, 20, 50, 100, 200],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `products_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getProducts(null, numberOfRows, pageOrigin);
            updateMUISetting('rows', 'product', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    getProducts(
                        tableState.page + 1,
                        pagination.itemsCountPerPage,
                        pageOrigin
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction, pageOrigin);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'product',
                        null,
                        tableState.columns
                    );
                    break;
                default:
                // console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if (canDelete) {
                confirmDeleteDialogBox();
            }
            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // redirect to edit page
            if (canEdit) {
                // changePage(`${edit_url}/${rowData[0]}`)
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        onKeyUp={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };

    if (!canDelete) {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting('view-columns', 'product', null, columns);

    return (
        <Fragment>
            <div className="col-sm-12">
                <div id="what-is" className="card">
                    <div className="card-header">
                        <h4 className="card-title">
                            List Of Items{' '}
                            <a
                                href="#"
                                className="tool-tip"
                                data-tip="this is a tool tip"
                            >
                                <i className="la la-question-circle"></i>
                            </a>
                            {!pageOrigin && (
                                <div className="pull-right">
                                    {/* <a href={export_url} className="btn btn-sm btn-success"><i className="ft ft-download"></i> Export</a> */}{' '}
                                    {canCreate && canViewCost ? (
                                        <a
                                            onClick={importModal}
                                            className="btn btn-sm btn-blue text-white ml-10"
                                        >
                                            <i className="ft ft-upload"></i>{' '}
                                            Import
                                        </a>
                                    ) : (
                                        <>{''}</>
                                    )}
                                    <Show when="inv.products.items.export">
                                        <a
                                            onClick={exportProduct}
                                            className="btn btn-sm btn-success text-white ml-10"
                                        >
                                            <i className="ft ft-download"></i>{' '}
                                            Export
                                        </a>
                                    </Show>
                                    <Show
                                        when={[
                                            'inv.products.items.create',
                                            'inv.receiving.itemReceipts.cost',
                                        ]}
                                        operator="and"
                                    >
                                        <Link
                                            to={create_url}
                                            className="btn btn-sm btn-primary text-white ml-10"
                                        >
                                            <i className="ft ft-plus"></i>{' '}
                                            Create New
                                        </Link>
                                    </Show>
                                </div>
                            )}
                        </h4>
                        <a className="heading-elements-toggle">
                            <i className="la la-ellipsis-v font-medium-3"></i>
                        </a>
                    </div>
                    <div className="card-content collapse show">
                        <div className="card-body">
                            <Show when="inv.products.items.view">
                                <MuiThemeProvider theme={getMuiTheme()}>
                                    <MUIDataTable
                                        data={products}
                                        columns={_columns}
                                        options={options}
                                    />
                                </MuiThemeProvider>
                            </Show>
                        </div>
                    </div>
                </div>
            </div>
            <ImportModal
                show={import_modal}
                onHide={() => setModal('import_modal', false)}
            />
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    product: state.product,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    getProducts,
    handleSearchInput,
    setPage,
    exportProduct,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
    handleHover,
    updateMUISetting,
    setMUIPage,
    importModal,
    setModal,
    setRowChecked,
})(ProductList);
