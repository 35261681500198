import React, { useEffect, useState, useRef, Fragment } from 'react';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect, useDispatch } from 'react-redux';
import { MiniSpinner } from '../layouts/Spinner';
import { numberFormat, dateForm, stockClass } from '../../Utils/Common';
import { customAlert } from '../../Utils/Confirm';
import {
    viewReceiving,
    handleInputChange,
    updateReceiving,
    getUnreceive,
    loadUnReceived,
    updateUnReceiving,
    setModal,
    editCost,
    editSrp,
    handleSelectTemplate,
    getReceivingTemplates,
    cancelReceiving,
    getSingleProduct,
} from '../../redux/actions/receiving/receiving.actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { SwalWarning } from '../../Services/_swal.service';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import ReceivingSupplierModal from './modals/Receiving.supplier.modal';
import { Show, useAccess } from 'react-redux-permission';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { blockRoute } from '../../redux/actions/helper/helper.actions';
import moment from 'moment';

function ReceivingEdit({
    receiving: {
        search,
        loading,
        ir_default_template,
        item_receipt_templates,
        po_items,
        single_receiving,
        receivingData,
        deliveryDate,
        get_unreceive,
        unreceive_items,
        supplier_modal,
        editable_cost,
        editable_srp,
        redirect,
        btn_form,
    },
    viewReceiving,
    handleInputChange,
    updateReceiving,
    match,
    getUnreceive,
    loadUnReceived,
    updateUnReceiving,
    setModal,
    editCost,
    editSrp,
    blockRoute,
    block_route,
    getReceivingTemplates,
    handleSelectTemplate,
    cancelReceiving,
}) {
    const history = useHistory();

    const { hasPermission } = useAccess();
    const canReceive = hasPermission(
        'inv.receiving.itemReceipts.receive-items'
    );
    const canPrintBarcode = hasPermission(
        'inv.receiving.itemReceipts.print-barcode'
    );
    const canCreateAdjusment = hasPermission('inv.receiving.adjustment.create');
    const canViewCost = hasPermission('inv.receiving.itemReceipts.cost');

    let [delivery_date, setdatePick] = useState(deliveryDate);
    let [purchase_order_items, setInputList] = useState(po_items);
    let [unreceiveItems, setUnrecItems] = useState(unreceive_items);
    let date_string = '';

    const [status, setStatus] = useState('draft');
    const [purchase_order_id, setOrderId] = useState();

    const [showArrowBox, setShowArrowBox] = useState({
        show: false,
        key: null,
        position: { x: 0, y: 0 },
    });

    // console.log(showArrowBox)

    const dispatch = useDispatch();
    // Add the handleHoverProduct function
    const handleHoverProduct = (productId, key, e) => {
        dispatch(getSingleProduct(productId));

        // Update the position of the hover box based on mouse coordinates
        setShowArrowBox({
            show: true,
            key,
            position: { x: e.clientX, y: e.clientY },
        });
    };

    const handleMouseMove = (e) => {
        // Update the position of the hover box based on mouse coordinates
        setShowArrowBox((prev) => ({
            ...prev,
            position: { x: e.clientX, y: e.clientY },
        }));
    };

    const handleScroll = () => {
        // Update the position of the hover box based on scroll
        setShowArrowBox((prev) => ({
            ...prev,
            position: { ...prev.position, y: prev.position.y + window.scrollY },
        }));
    };

    useEffect(() => {
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
        return () => {
            // Remove scroll event listener on component unmount
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        blockRoute(false);
        getReceivingTemplates();
    }, []);

    useEffect(() => {
        viewReceiving(match.params.id, function (res, item_data) {
            setdatePick(res);
            setInputList(item_data);
            //console.log(res)
            // console.log("item_data", item_data, res);
        });
        loadUnReceived(match.params.id, function (res) {
            // console.log(res)
            setUnrecItems(res);
        });
    }, [search]);

    useEffect(() => {
        // When onClick navbar prevent another dialog box
        window.addEventListener('beforeunload', function (e) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
            blockRoute(false);
            console.log('01');
        });

        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
            console.log('02');
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () => {
        window.history.pushState(null, null, window.location.pathname);
        console.log('03');
    };

    const dynamicInputChange = (e, index) => {
        if (e.target.value) {
            blockRoute(true);
        } else {
            blockRoute(false);
        }

        let { name, value } = e.target;
        const list = [...purchase_order_items];

        if (name == 'srp' || name == 'cost') {
            if (value == '') {
                value = list[index]['org_' + name];
            }
        }
        list[index][name] = value;
        setInputList(list);
    };

    // const unreceiveChange = (e, index) => {

    //     if(e.target.value){
    //         blockRoute(true);
    //     }else{
    //         blockRoute(false);
    //     }

    //     const { name, value } = e.target;
    //     const list = [...unreceiveItems];
    //     list[index][name] = value;
    //     setUnrecItems(list);
    // }

    const unreceiveChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...unreceiveItems];

        // Convert to number if the field is 'cost' or any other numeric field
        list[index][name] =
            name === 'cost' || name === 'received_qty'
                ? parseFloat(value)
                : value;

        // Update blockRoute to indicate unsaved changes
        blockRoute(value ? true : false);

        // Update state
        setUnrecItems(list);
    };

    const onSubmit = (e) => {
        e.preventDefault();
    };
    const submitClick = (stat) => {
        setStatus(stat);
        if (stat == 'saved' || stat == 'saved-print') {
            SwalWarning(
                'Are you sure?',
                'You are about to save Item Receipt, saving this will stop you from editing any other changes. Continue?',
                function () {
                    formsubmit(stat);
                }
            );
        } else if (stat == 'saved-email') {
            SwalWarning(
                'Are you sure?',
                'You are about to save and email Item Receipt, saving this will stop you from editing any other changes. Continue?',
                function () {
                    formsubmit(stat);
                }
            );
        } else if (stat == 'draft-email') {
            SwalWarning(
                'Are you sure?',
                'You are about to draft and email this Item Receipt. Continue?',
                function () {
                    formsubmit(stat);
                }
            );
        } else {
            formsubmit(stat);
        }
    };

    const submitUnReceive = (e) => {
        e.preventDefault();
        let formdata = {
            delivery_id: match.params.id,
            items: JSON.stringify(unreceiveItems),
        };
        updateUnReceiving(formdata);
    };
    const handleChange = (date, key) => {
        const list = [...purchase_order_items];
        list[key]['expiry_date'] = date;
        setInputList(list);
    };

    const formsubmit = (stat) => {
        let formdata = single_receiving;
        let stat_arr = stat.split('-');
        let stats = stat_arr[0];
        let actions = stat_arr[1] !== undefined ? stat_arr[1] : null;
        if (actions == 'email') {
            formdata.send_to_email = true;
        }
        let purchase_order_items = po_items.map((item) => ({
            ...item,
            lot_no:
                typeof item.lot_no === 'string' && item.lot_no
                    ? item.lot_no
                    : receivingData.lot_no,
        }));
        formdata.purchase_order_items = JSON.stringify(purchase_order_items);
        formdata.purchase_order_id = receivingData.purchase_order_id;
        formdata.delivery_date = delivery_date;
        formdata.supplier_id = receivingData.supplier_id;
        formdata.status = stats;
        formdata.actions = actions;
        updateReceiving(match.params.id, formdata);
    };

    const scrollTo = (ref) => {
        if (ref && get_unreceive) {
            ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const popmod = [
        {
            flip: {
                behavior: ['bottom'], // don't allow it to flip to be above
            },
            preventOverflow: {
                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            },
            hide: {
                enabled: false, // turn off since needs preventOverflow to be enabled
            },
        },
    ];

    const status_lbl = (
        <span className={`lbl-status mr-1 lbl-${receivingData.status}`}>
            {receivingData.status}
        </span>
    );
    const delivery_status =
        receivingData.delivery_status != '' ? (
            <span
                className={`lbl-status mr-1 lbl-${receivingData.delivery_status}`}
            >
                {receivingData.delivery_status}
            </span>
        ) : (
            ''
        );
    // console.log("po_items", po_items);
    if (redirect) {
        return <Redirect to={'/receivings'} />;
    }

    // console.log("loading", loading)

    return (
        <BeforeUnloadComponent
            ignoreBeforeunloadDocument={true}
            blockRoute={block_route}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Receiving{' '}
                            </h3>

                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb f-regular">
                                        <li className="breadcrumb-item ">
                                            <a href="#" className="text-white">
                                                Item Receipt No.{' '}
                                                {receivingData.item_receipt_no}
                                            </a>
                                        </li>
                                        {/* <li className="breadcrumb-item"><a href="#" className="text-white">{receivingData.lot_no}</a></li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        <section className="row f-regular">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title"></h4>
                                        <Link to="/receivings">
                                            <i className="ft ft-arrow-left"></i>{' '}
                                            Back
                                        </Link>
                                        <div className="heading-elements">
                                            {' '}
                                            <ul className="list-inline mb-0"></ul>
                                        </div>
                                    </div>
                                    {loading ? (
                                        <MiniSpinner />
                                    ) : (
                                        <form
                                            className="card-content"
                                            onSubmit={onSubmit}
                                        >
                                            <div className="card-body f-regular">
                                                <div className="row">
                                                    <div className="col-12 mb-2">
                                                        {status_lbl}
                                                        {delivery_status}
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-8"></div>
                                                            <div className="col-md-4">
                                                                <div className="form-group pb-0 mb-0">
                                                                    {receivingData.manual && (
                                                                        <div className="form-group">
                                                                            <label className="">
                                                                                <i
                                                                                    className={
                                                                                        receivingData.manual
                                                                                            ? 'ft-check-square'
                                                                                            : 'ft-square'
                                                                                    }
                                                                                ></i>
                                                                                &nbsp;Manually
                                                                                Input
                                                                                Reference
                                                                                No.
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <label>
                                                                    Template{' '}
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-10 col-10 pr-0">
                                                                        <Select
                                                                            name="receiving_template"
                                                                            value={
                                                                                ir_default_template
                                                                            }
                                                                            onChange={
                                                                                handleSelectTemplate
                                                                            }
                                                                            options={
                                                                                item_receipt_templates
                                                                            }
                                                                            isDisabled={
                                                                                receivingData.status !=
                                                                                    'draft' &&
                                                                                true
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4"></div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    {receivingData.manual ? (
                                                                        <Fragment>
                                                                            <label>
                                                                                Reference
                                                                                No.
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    {receivingData.status ==
                                                                                    'draft' ? (
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control f-regular"
                                                                                            name="reference_no"
                                                                                            onChange={
                                                                                                handleInputChange
                                                                                            }
                                                                                            value={
                                                                                                single_receiving.reference_no
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        <div className="form-control f-regular">
                                                                                            {
                                                                                                single_receiving.reference_no
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Fragment>
                                                                    ) : (
                                                                        <Fragment>
                                                                            <label>
                                                                                P.O.
                                                                                Number
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <div className="form-control f-regular">
                                                                                        {
                                                                                            single_receiving.reference_no
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Fragment>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <label>
                                                                    Supplier
                                                                    <span className="red">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-10 col-10 pr-0">
                                                                        <div className="form-control f-regular">
                                                                            {
                                                                                receivingData.supplier_name
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                            {receivingData.status ==
                                                                                'draft' && (
                                                                                <div
                                                                                    className="a-tagicon icon-s"
                                                                                    onClick={() =>
                                                                                        setModal(
                                                                                            'supplier_modal',
                                                                                            null,
                                                                                            true
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <i className="ft ft-plus-circle text-primary"></i>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                {' '}
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <label>
                                                                    Delivery
                                                                    Date:{' '}
                                                                    <span className="red">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                {receivingData.status ==
                                                                'draft' ? (
                                                                    <div className="form-group row">
                                                                        <div className="col-md-10 col-10 pr-0">
                                                                            <div className="date-picker-100">
                                                                                <DatePicker
                                                                                    name="delivery_date"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        setdatePick(
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                    selected={
                                                                                        delivery_date
                                                                                    }
                                                                                    className="form-control f-regular"
                                                                                    peekNextMonth
                                                                                    showMonthDropdown
                                                                                    showYearDropdown
                                                                                    dropdownMode="select"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="float-right pl-0 col-md-2 col-2">
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="form-group row">
                                                                        <div className="col-md-10 col-10 pr-0">
                                                                            <div className="date-picker-100">
                                                                                <div className="form-control f-regular">
                                                                                    {
                                                                                        receivingData.date
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="float-right pl-0 col-md-2 col-2">
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ReactTooltip
                                                    effect="solid"
                                                    event="click"
                                                />
                                                <div className="row">
                                                    <div className="col-md-12 table-responsive">
                                                        <table className="table table-condensed table-bordered f-regular">
                                                            <thead>
                                                                <tr>
                                                                    <th
                                                                        className="min-wdth-300 max-wdth-300"
                                                                        rowSpan="2"
                                                                        width="150px"
                                                                    >
                                                                        LOT #{' '}
                                                                        <a
                                                                            href="#"
                                                                            className="tool-tip"
                                                                            data-place="right"
                                                                            data-tip="leaving this column blank will auto generate LOT #"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </a>
                                                                    </th>
                                                                    <th
                                                                        rowSpan="2"
                                                                        width="30%"
                                                                        className="mw-200"
                                                                    >
                                                                        Product
                                                                    </th>
                                                                    <th
                                                                        className="text-center"
                                                                        colSpan="2"
                                                                    >
                                                                        Ordered
                                                                    </th>
                                                                    <th
                                                                        className="text-center"
                                                                        colSpan="2"
                                                                    >
                                                                        Received
                                                                    </th>
                                                                    <th
                                                                        rowSpan="2"
                                                                        className="text-center"
                                                                        width="120px"
                                                                    >
                                                                        SRP
                                                                        <Show when="inv.receiving.itemReceipts.srp">
                                                                            {receivingData.status ==
                                                                            'draft' ? (
                                                                                <a
                                                                                    className="ml-10"
                                                                                    onClick={() =>
                                                                                        editSrp(
                                                                                            editable_srp
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {editable_srp ? (
                                                                                        <i className="icon-unlock text-success"></i>
                                                                                    ) : (
                                                                                        <i className="icon-lock"></i>
                                                                                    )}
                                                                                </a>
                                                                            ) : (
                                                                                <span></span>
                                                                            )}
                                                                        </Show>
                                                                    </th>
                                                                    <th
                                                                        rowSpan="2"
                                                                        className="text-center"
                                                                    >
                                                                        Expiry
                                                                        Date{' '}
                                                                        <a
                                                                            href="#"
                                                                            className="tool-tip"
                                                                            data-place="left"
                                                                            data-tip="leaving this column blank means product has no expiration date"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </a>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th
                                                                        className="text-center min-wdth-120 max-wdth-100"
                                                                        width="80px"
                                                                    >
                                                                        Qty
                                                                    </th>
                                                                    <th
                                                                        className="text-center min-wdth-120 max-wdth-100"
                                                                        width="80px"
                                                                    >
                                                                        Unit
                                                                    </th>
                                                                    <th
                                                                        className="text-center min-wdth-120 max-wdth-100"
                                                                        width="80px"
                                                                    >
                                                                        Cost
                                                                        <Show when="inv.receiving.itemReceipts.cost">
                                                                            {receivingData.status ==
                                                                            'draft' ? (
                                                                                <a
                                                                                    className="ml-10"
                                                                                    onClick={() =>
                                                                                        editCost(
                                                                                            editable_cost
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {editable_cost ? (
                                                                                        <i className="icon-unlock text-success"></i>
                                                                                    ) : (
                                                                                        <i className="icon-lock"></i>
                                                                                    )}
                                                                                </a>
                                                                            ) : (
                                                                                <span></span>
                                                                            )}
                                                                        </Show>
                                                                    </th>
                                                                    <th
                                                                        className="text-center"
                                                                        width="80px"
                                                                    >
                                                                        Qty
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Object.keys(
                                                                    po_items
                                                                ).length ==
                                                                0 ? (
                                                                    <tr>
                                                                        <td
                                                                            colSpan="8"
                                                                            className="text-center"
                                                                        >
                                                                            --
                                                                            No
                                                                            data
                                                                            found
                                                                            --
                                                                        </td>
                                                                    </tr>
                                                                ) : // populating data
                                                                receivingData.status ==
                                                                  'draft' ? (
                                                                    po_items.map(
                                                                        (
                                                                            items,
                                                                            key
                                                                        ) => {
                                                                            return (
                                                                                <tr
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    className={stockClass(
                                                                                        items.status_code
                                                                                    )}
                                                                                >
                                                                                    <td>
                                                                                        <input
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                dynamicInputChange(
                                                                                                    e,
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                            name="lot_no"
                                                                                            value={
                                                                                                items.lot_no
                                                                                            }
                                                                                            type="text"
                                                                                            className="form-control f-regular"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div>
                                                                                            <div className="mw-90">
                                                                                                <Link
                                                                                                    to={`/inventory/view/${items.item_id}`}
                                                                                                    target="_blank"
                                                                                                    onClick={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        e.stopPropagation()
                                                                                                    }
                                                                                                    onMouseEnter={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        handleHoverProduct(
                                                                                                            items.item_id,
                                                                                                            key,
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                    onMouseMove={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        handleMouseMove(
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                    onMouseLeave={() =>
                                                                                                        setShowArrowBox(
                                                                                                            {
                                                                                                                show: false,
                                                                                                                key: null,
                                                                                                                position:
                                                                                                                    {
                                                                                                                        x: 0,
                                                                                                                        y: 0,
                                                                                                                    },
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        items.product_name
                                                                                                    }
                                                                                                </Link>

                                                                                                {showArrowBox.show &&
                                                                                                    showArrowBox.key ===
                                                                                                        key && (
                                                                                                        <div
                                                                                                            className="arrow-box"
                                                                                                            style={{
                                                                                                                top: `${showArrowBox.position.y}px`,
                                                                                                                left: `${showArrowBox.position.x}px`,
                                                                                                                position:
                                                                                                                    'fixed',
                                                                                                            }}
                                                                                                        >
                                                                                                            <p>
                                                                                                                Incoming:{' '}
                                                                                                                <span className="font-weight-bold">
                                                                                                                    {
                                                                                                                        items
                                                                                                                            .productDetails
                                                                                                                            .incoming_orders
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                Current
                                                                                                                stock:{' '}
                                                                                                                <span className="font-weight-bold">
                                                                                                                    {
                                                                                                                        items
                                                                                                                            .productDetails
                                                                                                                            .total_stocks
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                Last
                                                                                                                arrival:{' '}
                                                                                                                <span className="font-weight-bold">
                                                                                                                    {moment(
                                                                                                                        items
                                                                                                                            .productDetails
                                                                                                                            .last_arrival
                                                                                                                    ).isValid()
                                                                                                                        ? moment(
                                                                                                                              items
                                                                                                                                  .productDetails
                                                                                                                                  .last_arrival
                                                                                                                          ).format(
                                                                                                                              'YYYY-MM-DD'
                                                                                                                          )
                                                                                                                        : 'N/A'}
                                                                                                                </span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    )}
                                                                                            </div>
                                                                                            {/* <div className="f-12 text-dark">{items.sku}</div> */}
                                                                                            <div className="f-12 text-dark">
                                                                                                {
                                                                                                    items.barcode
                                                                                                }
                                                                                            </div>
                                                                                            <div className="f-12 text-dark">
                                                                                                {
                                                                                                    items.description
                                                                                                }
                                                                                            </div>
                                                                                            <div className="f-12 text-dark">
                                                                                                {
                                                                                                    items.brand
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {numberFormat(
                                                                                            items.order_qty,
                                                                                            0
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        {
                                                                                            items.product_unit
                                                                                        }
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {editable_cost ? (
                                                                                            <input
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    dynamicInputChange(
                                                                                                        e,
                                                                                                        key
                                                                                                    )
                                                                                                }
                                                                                                name="cost"
                                                                                                value={numberFormat(
                                                                                                    items.cost,
                                                                                                    2,
                                                                                                    false
                                                                                                )}
                                                                                                type="number"
                                                                                                step="any"
                                                                                                min="0"
                                                                                                className="form-control text-right"
                                                                                                required
                                                                                            />
                                                                                        ) : (
                                                                                            <label
                                                                                                style={{
                                                                                                    filter: !canViewCost
                                                                                                        ? 'blur(4px)'
                                                                                                        : 'none',
                                                                                                    userSelect:
                                                                                                        !canViewCost
                                                                                                            ? 'none'
                                                                                                            : 'auto',
                                                                                                }}
                                                                                            >
                                                                                                {numberFormat(
                                                                                                    items.cost,
                                                                                                    2,
                                                                                                    true
                                                                                                )}
                                                                                            </label>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="min-wdth-120 max-wdth-100">
                                                                                        <input
                                                                                            type="number"
                                                                                            step="any"
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                dynamicInputChange(
                                                                                                    e,
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                            name="received_qty"
                                                                                            value={
                                                                                                items.received_qty
                                                                                            }
                                                                                            min="0"
                                                                                            className="form-control text-right"
                                                                                        />
                                                                                    </td>
                                                                                    <td className="text-right min-wdth-120 max-wdth-100">
                                                                                        {editable_srp ? (
                                                                                            <input
                                                                                                type="number"
                                                                                                step="any"
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    dynamicInputChange(
                                                                                                        e,
                                                                                                        key
                                                                                                    )
                                                                                                }
                                                                                                name="srp"
                                                                                                min="0"
                                                                                                className="form-control text-right"
                                                                                                value={numberFormat(
                                                                                                    items.srp,
                                                                                                    2,
                                                                                                    false
                                                                                                )}
                                                                                            />
                                                                                        ) : (
                                                                                            <label>
                                                                                                {numberFormat(
                                                                                                    items.srp,
                                                                                                    2,
                                                                                                    true
                                                                                                )}
                                                                                            </label>
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        <DatePicker
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                handleChange(
                                                                                                    e,
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                            selected={
                                                                                                items.expiry_date
                                                                                            }
                                                                                            popperPlacement="bottom"
                                                                                            popperModifiers={
                                                                                                popmod
                                                                                            }
                                                                                            name="expiry_date"
                                                                                            className="form-control f-regular"
                                                                                            peekNextMonth
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            dropdownMode="select"
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )
                                                                ) : (
                                                                    po_items.map(
                                                                        (
                                                                            items,
                                                                            key
                                                                        ) => {
                                                                            return (
                                                                                <tr
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    className={stockClass(
                                                                                        items.status_code
                                                                                    )}
                                                                                >
                                                                                    <td>
                                                                                        {
                                                                                            items.lot_no
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        <div>
                                                                                            <div className="mw-90">
                                                                                                <Link
                                                                                                    to={`/inventory/view/${items.item_id}`}
                                                                                                    target="_blank"
                                                                                                    onClick={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        e.stopPropagation()
                                                                                                    }
                                                                                                    onMouseEnter={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        handleHoverProduct(
                                                                                                            items.item_id,
                                                                                                            key,
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                    onMouseMove={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        handleMouseMove(
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                    onMouseLeave={() =>
                                                                                                        setShowArrowBox(
                                                                                                            {
                                                                                                                show: false,
                                                                                                                key: null,
                                                                                                                position:
                                                                                                                    {
                                                                                                                        x: 0,
                                                                                                                        y: 0,
                                                                                                                    },
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        items.product_name
                                                                                                    }
                                                                                                </Link>

                                                                                                {showArrowBox.show &&
                                                                                                    showArrowBox.key ===
                                                                                                        key && (
                                                                                                        <div
                                                                                                            className="arrow-box"
                                                                                                            style={{
                                                                                                                top: `${showArrowBox.position.y}px`,
                                                                                                                left: `${showArrowBox.position.x}px`,
                                                                                                                position:
                                                                                                                    'fixed',
                                                                                                            }}
                                                                                                        >
                                                                                                            <p>
                                                                                                                Incoming:{' '}
                                                                                                                <span className="font-weight-bold">
                                                                                                                    {
                                                                                                                        items
                                                                                                                            .productDetails
                                                                                                                            .incoming_orders
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                Current
                                                                                                                stock:{' '}
                                                                                                                <span className="font-weight-bold">
                                                                                                                    {
                                                                                                                        items
                                                                                                                            .productDetails
                                                                                                                            .total_stocks
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                Last
                                                                                                                arrival:{' '}
                                                                                                                <span className="font-weight-bold">
                                                                                                                    {moment(
                                                                                                                        items
                                                                                                                            .productDetails
                                                                                                                            .last_arrival
                                                                                                                    ).isValid()
                                                                                                                        ? moment(
                                                                                                                              items
                                                                                                                                  .productDetails
                                                                                                                                  .last_arrival
                                                                                                                          ).format(
                                                                                                                              'YYYY-MM-DD'
                                                                                                                          )
                                                                                                                        : 'N/A'}
                                                                                                                </span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    )}
                                                                                            </div>
                                                                                            {/* <div className="f-12 text-dark">{items.sku}</div> */}
                                                                                            <div className="f-12 text-dark">
                                                                                                {
                                                                                                    items.barcode
                                                                                                }
                                                                                            </div>
                                                                                            <div className="f-12 text-dark">
                                                                                                {
                                                                                                    items.description
                                                                                                }
                                                                                            </div>
                                                                                            <div className="f-12 text-dark">
                                                                                                {
                                                                                                    items.brand
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {numberFormat(
                                                                                            items.order_qty,
                                                                                            0
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        {
                                                                                            items.product_unit
                                                                                        }
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        <span className="strike-through">
                                                                                            {
                                                                                                items.old_cost
                                                                                            }
                                                                                        </span>

                                                                                        <label
                                                                                            style={{
                                                                                                filter: !canViewCost
                                                                                                    ? 'blur(4px)'
                                                                                                    : 'none',
                                                                                                userSelect:
                                                                                                    !canViewCost
                                                                                                        ? 'none'
                                                                                                        : 'auto',
                                                                                            }}
                                                                                        >
                                                                                            {numberFormat(
                                                                                                items.cost,
                                                                                                2
                                                                                            )}
                                                                                        </label>
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {numberFormat(
                                                                                            items.received_qty,
                                                                                            0
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        <span className="strike-through">
                                                                                            {
                                                                                                items.old_srp
                                                                                            }
                                                                                        </span>
                                                                                        {numberFormat(
                                                                                            items.srp,
                                                                                            2
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            items.date_str
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )
                                                                )}
                                                            </tbody>
                                                        </table>
                                                        <div className="row mt-3">
                                                            <div className="col-md-4">
                                                                <label>
                                                                    Remarks{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-10 col-10 pr-0">
                                                                        {receivingData.status ==
                                                                        'draft' ? (
                                                                            <textarea
                                                                                name="remarks"
                                                                                onChange={
                                                                                    handleInputChange
                                                                                }
                                                                                value={
                                                                                    single_receiving.remarks
                                                                                }
                                                                                className="form-control f-regular"
                                                                                rows="2"
                                                                            ></textarea>
                                                                        ) : (
                                                                            <textarea
                                                                                value={
                                                                                    single_receiving.remarks
                                                                                }
                                                                                className="form-control f-regular"
                                                                                rows="2"
                                                                                disabled
                                                                            ></textarea>
                                                                        )}
                                                                    </div>
                                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>
                                                                    Employeee{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-10 col-10 pr-0">
                                                                        <div className="form-control f-regular">
                                                                            {
                                                                                receivingData.user
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <div className="row justify-content-end mt-3">
                                                    <div className="mr-2">
                                                        {receivingData.status ==
                                                        'draft' ? (
                                                            <div className="form-group">
                                                                <Show when="inv.receiving.itemReceipts.draft">
                                                                    <div className="btn-group btn-dpd">
                                                                        <button
                                                                            className="btn btn-default btn-outline-light text-dark btn-main"
                                                                            onClick={() =>
                                                                                submitClick(
                                                                                    'draft'
                                                                                )
                                                                            }
                                                                            type="button"
                                                                            disabled={
                                                                                loading
                                                                            }
                                                                        >
                                                                            Draft
                                                                        </button>
                                                                        <button
                                                                            data-toggle="dropdown"
                                                                            className="btn btn-default btn-outline-light text-dark dropdown-toggle btn-carret"
                                                                            type="button"
                                                                            disabled={
                                                                                loading
                                                                            }
                                                                        >
                                                                            <span className="caret"></span>
                                                                        </button>
                                                                        <ul className="dropdown-menu">
                                                                            <li>
                                                                                <a
                                                                                    className="dpd-menu"
                                                                                    onClick={() =>
                                                                                        submitClick(
                                                                                            'draft-email'
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Draft
                                                                                    &
                                                                                    Email
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    className="dpd-menu"
                                                                                    onClick={() =>
                                                                                        submitClick(
                                                                                            'draft-print'
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Draft
                                                                                    &
                                                                                    Print
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </Show>{' '}
                                                                <div className="btn-group btn-dpd">
                                                                    <button
                                                                        className="btn btn-primary  btn-main"
                                                                        onClick={() =>
                                                                            submitClick(
                                                                                'saved'
                                                                            )
                                                                        }
                                                                        type="button"
                                                                        disabled={
                                                                            loading
                                                                        }
                                                                    >
                                                                        Save
                                                                        Receiving
                                                                    </button>
                                                                    <button
                                                                        data-toggle="dropdown"
                                                                        className="btn btn-primary dropdown-toggle btn-carret"
                                                                        type="button"
                                                                        disabled={
                                                                            loading
                                                                        }
                                                                    >
                                                                        <span className="caret"></span>
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        <li>
                                                                            <a
                                                                                className="dpd-menu"
                                                                                onClick={() =>
                                                                                    submitClick(
                                                                                        'saved-email'
                                                                                    )
                                                                                }
                                                                            >
                                                                                Save
                                                                                &
                                                                                Email
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                className="dpd-menu"
                                                                                onClick={() =>
                                                                                    submitClick(
                                                                                        'saved-print'
                                                                                    )
                                                                                }
                                                                            >
                                                                                Save
                                                                                &
                                                                                Print
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        ) : receivingData.status !=
                                                          'cancelled' ? (
                                                            <>
                                                                <Fragment>
                                                                    <Show when="inv.receiving.itemReceipts.cancel">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            onClick={
                                                                                cancelReceiving
                                                                            }
                                                                            type="button"
                                                                        >
                                                                            {btn_form.cancel ? (
                                                                                <i className="icon-loading-custom"></i>
                                                                            ) : (
                                                                                <span>
                                                                                    Cancel
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </Show>
                                                                </Fragment>
                                                            </>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-12">
                                {receivingData.status == 'saved' &&
                                receivingData.status != 'cancelled' ? (
                                    <div className="card">
                                        <div className="card-body pb-10m">
                                            {
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        {receivingData.delivery_status !==
                                                        'full' ? (
                                                            <span>
                                                                <Show when="inv.receiving.itemReceipts.receive-items">
                                                                    <button
                                                                        onClick={() =>
                                                                            getUnreceive(
                                                                                !get_unreceive
                                                                            )
                                                                        }
                                                                        className="btn btn-info text-white mr-1"
                                                                    >
                                                                        Receive
                                                                        Items
                                                                    </button>
                                                                </Show>
                                                            </span>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                        <span>
                                                            <Show when="inv.receiving.adjustment.create">
                                                                <a
                                                                    className="btn  btn-info text-white"
                                                                    href="/receiving/adjustment/create"
                                                                >
                                                                    Stock
                                                                    Adjustment
                                                                </a>
                                                            </Show>
                                                        </span>
                                                        <Show when="inv.receiving.itemReceipts.print-barcode">
                                                            <button className="btn btn-outline-secondary float-right">
                                                                Print Barcode
                                                            </button>
                                                        </Show>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            <Show when="inv.receiving.itemReceipts.receive-items">
                                <div
                                    className="col-md-12"
                                    // ref={scrollTo}
                                >
                                    {get_unreceive && (
                                        <form
                                            className="card"
                                            onSubmit={submitUnReceive}
                                        >
                                            <div className="card-header">
                                                <h4 className="card-title text-center">
                                                    Receive Items
                                                </h4>
                                            </div>
                                            {loading ? (
                                                <MiniSpinner />
                                            ) : (
                                                <div className="card-body f-regular">
                                                    <div className="table-responsive-sm">
                                                        <table className="table table-bordered f-regular">
                                                            <thead>
                                                                <tr>
                                                                    <th rowSpan="2">
                                                                        SKU
                                                                    </th>
                                                                    <th rowSpan="2">
                                                                        Product
                                                                    </th>
                                                                    <th rowSpan="2">
                                                                        Unit
                                                                    </th>
                                                                    <th
                                                                        colSpan="3"
                                                                        className="text-center"
                                                                    >
                                                                        Quantity
                                                                    </th>
                                                                    <th rowSpan="2">
                                                                        New
                                                                        Receiving
                                                                    </th>
                                                                    <th rowSpan="2">
                                                                        Cost
                                                                        Price
                                                                        <Show when="inv.receiving.itemReceipts.cost">
                                                                            <a
                                                                                className="ml-10"
                                                                                onClick={() =>
                                                                                    editCost(
                                                                                        editable_cost
                                                                                    )
                                                                                }
                                                                            >
                                                                                {editable_cost ? (
                                                                                    <i className="icon-unlock text-success"></i>
                                                                                ) : (
                                                                                    <i className="icon-lock"></i>
                                                                                )}
                                                                            </a>
                                                                        </Show>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Ordered
                                                                    </th>
                                                                    <th>
                                                                        Pending
                                                                    </th>
                                                                    <th>
                                                                        Received
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {unreceiveItems.map(
                                                                    (
                                                                        items,
                                                                        key
                                                                    ) => {
                                                                        return (
                                                                            <tr
                                                                                key={
                                                                                    key
                                                                                }
                                                                            >
                                                                                <td>
                                                                                    {
                                                                                        items.sku
                                                                                    }
                                                                                </td>
                                                                                {/* <td> 
                                                                                    <Link
                                                                                        to={`/inventory/view/${items.item_id}`}
                                                                                        target="_blank"
                                                                                        onClick={(e) => e.stopPropagation()}
                                                                                        onMouseEnter={(e) => handleHoverProduct(items.item_id, key, e)}
                                                                                        onMouseMove={(e) => handleMouseMove(e)}
                                                                                        onMouseLeave={() => setShowArrowBox({ show: false, key: null, position: { x: 0, y: 0 } })}
                                                                                        >
                                                                                        {items.product_name}
                                                                                    </Link>

                                                                                    {showArrowBox.show && showArrowBox.key === key && (
                                                                                        <div className="arrow-box" style={{ top: `${showArrowBox.position.y}px`, left: `${showArrowBox.position.x}px`, position: 'fixed' }}>
                                                                                        <p>Incoming: <span className='font-weight-bold'>{items.productDetails.incoming_orders}</span></p>
                                                                                        <p>Current stock: <span className='font-weight-bold'>{items.productDetails.total_stocks}</span></p>
                                                                                        <p>Last arrival: <span className='font-weight-bold'>{moment(items.productDetails.last_arrival).isValid() ? moment(items.productDetails.last_arrival).format("YYYY-MM-DD") : "N/A"}</span></p>
                                                                                        </div>
                                                                                    )}
                                                                                </td> */}
                                                                                <td>
                                                                                    {
                                                                                        items.product_name
                                                                                    }
                                                                                    <div className="f-12 text-dark">
                                                                                        {
                                                                                            items.description
                                                                                        }
                                                                                    </div>
                                                                                    <div className="f-12 text-dark">
                                                                                        {
                                                                                            items.brand
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        items.product_unit
                                                                                    }
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {numberFormat(
                                                                                        items.order_qty,
                                                                                        0
                                                                                    )}
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {numberFormat(
                                                                                        items.order_qty -
                                                                                            items.received_qty_old,
                                                                                        0
                                                                                    )}
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {numberFormat(
                                                                                        items.received_qty_old,
                                                                                        0
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="number"
                                                                                        name="received_qty"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            unreceiveChange(
                                                                                                e,
                                                                                                key
                                                                                            )
                                                                                        }
                                                                                        className="form-control text-right"
                                                                                        min="0"
                                                                                        max={
                                                                                            items.order_qty -
                                                                                            items.received_qty_old
                                                                                        }
                                                                                        step="any"
                                                                                        value={
                                                                                            items.received_qty
                                                                                        }
                                                                                    />
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {editable_cost ? (
                                                                                        <input
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                unreceiveChange(
                                                                                                    e,
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                            name="cost"
                                                                                            value={numberFormat(
                                                                                                items.cost,
                                                                                                2,
                                                                                                false
                                                                                            )}
                                                                                            type="number"
                                                                                            step="any"
                                                                                            min="0"
                                                                                            className="form-control text-right"
                                                                                            required
                                                                                        />
                                                                                    ) : (
                                                                                        <label
                                                                                            style={{
                                                                                                filter: !canViewCost
                                                                                                    ? 'blur(4px)'
                                                                                                    : 'none',
                                                                                                userSelect:
                                                                                                    !canViewCost
                                                                                                        ? 'none'
                                                                                                        : 'auto',
                                                                                            }}
                                                                                        >
                                                                                            {numberFormat(
                                                                                                items.cost,
                                                                                                2,
                                                                                                true
                                                                                            )}
                                                                                        </label>
                                                                                    )}
                                                                                </td>
                                                                                {/* <td><input type="number" name="cost" onChange={ e => unreceiveChange(e, key)} className="form-control text-right" step="any" value={items.cost} /></td> */}
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )}
                                                                {}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="card-footer">
                                                <div className="row justify-content-end mt-3">
                                                    <div className="mr-2">
                                                        <div className="form-group">
                                                            <button
                                                                className="margin-l-5 btn btn-primary text-white"
                                                                disabled={
                                                                    loading
                                                                }
                                                            >
                                                                Save as
                                                                Receiving
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </Show>
                        </section>
                    </div>
                </div>
                <ReceivingSupplierModal
                    show={supplier_modal}
                    onHide={() => setModal('supplier_modal', null, false)}
                />
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    receiving: state.receiving,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    getReceivingTemplates,
    blockRoute,
    viewReceiving,
    handleInputChange,
    updateReceiving,
    getUnreceive,
    loadUnReceived,
    updateUnReceiving,
    setModal,
    editCost,
    editSrp,
    handleSelectTemplate,
    cancelReceiving,
    getSingleProduct,
})(ReceivingEdit);
