import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import {
    quotationValidationPost,
    clearQuotationValidatiton,
    handleInputChangeValidation,
} from '../../../redux/actions/quotation/quotation.actions';
import { toggleDrawer } from '../../../redux/actions/term_condition/term_condition.actions';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

function QuotationValidationModal({
    show,
    onHide,
    pageOrigin = 'quotation',
    quotationValidationPost,
    clearQuotationValidatiton,
    handleInputChangeValidation,
    toggleDrawer,
}) {
    useEffect(() => {
        clearQuotationValidatiton();
    }, []);

    return (
        <Modal
            centered
            dialogClassName="modal-container"
            show={show}
            onHide={onHide}
            size="md"
            animation={true}
        >
            <Modal.Header closeButton dialogClassName="p-0">
                <Modal.Title> Quotation Validation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(e) => quotationValidationPost(e, pageOrigin)}>
                    <ReactTooltip effect="solid" event="click" />
                    <section className="row f-regular">
                        <div className="col-12">
                            {/* <label className="label-control f-regular">Name: <span className='text-danger'>*</span></label> */}
                            <div className="form-group row">
                                <div className="col-md-11 col-10">
                                    <input
                                        onChange={handleInputChangeValidation}
                                        type="text"
                                        name="title"
                                        className="form-control f-regular"
                                        placeholder="Enter Title"
                                        required
                                    />
                                </div>
                                <div className="float-right pl-0 col-1 col-md-1">
                                    <div className="input-group-prepend">
                                        <span
                                            href="#"
                                            className="tool-tip text-primary"
                                            data-tip="this is a tool tip"
                                        >
                                            <i className="la la-question-circle"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group row">
                                <div className="col-md-4 col-12 label-control f-regular"></div>
                                <div className="col-md-7 col-10 text-right">
                                    {pageOrigin === 'setting_app' && (
                                        <button
                                            onClick={() =>
                                                toggleDrawer(true, pageOrigin)
                                            }
                                            type="button"
                                            className="btn btn-sm terms-toggle f-regular mr-1 text-white"
                                        >
                                            <FormatListBulletedIcon />
                                        </button>
                                    )}
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-sm btn-save f-regular"
                                    >
                                        Save
                                    </button>
                                </div>
                                <div className="float-right pl-0 col-2 col-md-1"></div>
                            </div>
                        </div>
                    </section>
                </form>
            </Modal.Body>
        </Modal>
    );
}
const mapStateToProps = (state) => ({
    quotation: state.quotation,
});

export default connect(mapStateToProps, {
    quotationValidationPost,
    clearQuotationValidatiton,
    handleInputChangeValidation,
    toggleDrawer,
})(QuotationValidationModal);
