import React, { Fragment, useEffect } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { NumberFormat } from '../../Services/_numberformat.service';
// import { NumberFormat } from '../../Services/_numberformat.service';
import {
    reactSelectStyle,
    stockClass,
    numberFormat,
} from './../../Utils/Common';
import {
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputSrp,
    handleCheckVat,
    handleInputQty,
    handleSelectItem,
    handleSelectTermsConditions,
    handleOnSubmit,
    handleEditInputRow,
    handleToggleEditRow,
    handleUpdateRow,
    handleAttrSubmit,
    getProducts,
    getTermsConditions,
    getCustomers,
    getSingleQuotation,
    setPage,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    setModal,
    handleSelectSalesInvoiceTemplate,
    getQuotationTemplates,
    QUDatePicker,
    handleSelectPaymentType,
    add_items,
    dynamicInputChange,
    searchProducst,
    handleTaxType,
    computationHandler,
    ShowComboBox,
    cancelQuote,
    getQuotationValidations,
} from '../../redux/actions/quotation/quotation.actions';
import { connect } from 'react-redux';
import { MiniSpinner } from '../layouts/Spinner';
import { Redirect } from 'react-router-dom';
import QuotationViewTable from './Quotation.view.table';
import ReactTooltip from 'react-tooltip';
import QuotationCustomerModal from './modals/Quotation.customer.modal';
import PaymentTermsModal from './modals/PaymentTerms.modal';
import DatePicker from 'react-datepicker';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import debounce from 'debounce-promise';
import DynamicFont from 'react-dynamic-font';
import { ConsoleView } from 'react-device-detect';
import FormTab from './component/FormTab';

function QuotationEdit({
    quotation: {
        single_quotation,
        mini_loading,
        customers,
        terms_conditions,
        isSearchable,
        itemSelected,
        items,
        isDiscount,
        default_product_value,
        isOther,
        edit_quotation_items,
        edit_row_index,
        edit_attr,
        main_page,
        main_url,
        default_customer_value,
        customer_modal,
        qu_templates,
        qu_default_template,
        payment_mode_options,
        payment_terms_modal,
        loading,
        tax_types,
        btn_form,
    },
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputSrp,
    handleCheckVat,
    handleInputQty,
    handleSelectItem,
    handleSelectTermsConditions,
    handleOnSubmit,
    handleEditInputRow,
    handleToggleEditRow,
    handleUpdateRow,
    handleAttrSubmit,
    getProducts,
    getTermsConditions,
    getCustomers,
    getSingleQuotation,
    setPage,
    match,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    setModal,
    handleSelectSalesInvoiceTemplate,
    getQuotationTemplates,
    QUDatePicker,
    handleSelectPaymentType,
    add_items,
    dynamicInputChange,
    searchProducst,
    ShowComboBox,
    handleTaxType,
    computationHandler,
    cancelQuote,
    getQuotationValidations,
}) {
    useEffect(() => {
        async function fetchAll() {
            Promise.all([
                getQuotationTemplates(),
                getProducts(),
                getTermsConditions(),
                getQuotationValidations(),
                getCustomers(),
                getSingleQuotation(match.params.id),
            ]);
        }

        fetchAll();
    }, []);

    const { quotation_items, discount, discount_type, tax, tax_type, other } =
        single_quotation;
    useEffect(() => {
        computationHandler();
    }, [
        JSON.stringify(quotation_items),
        discount,
        discount_type,
        tax,
        tax_type,
        other,
    ]);

    if (main_page) return <Redirect to={main_url} />;
    const customFilter = (option, searchText) => {
        if (
            option.data.label
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
            option.data.barcode.toLowerCase().includes(searchText.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    };
    // computed subtotal
    // const subTotalComputed = single_quotation.quotation_items.reduce((sub, item) => sub + parseFloat(item.amount ? item.amount : item.srp), 0);
    // const discount = single_quotation.discount_type == 1 ? ((parseFloat(subTotalComputed) * single_quotation.discount) / 100) : single_quotation.discount;
    // const subTotalDiscounted = subTotalComputed - discount;
    // const totalPriceComputed = parseFloat(subTotalDiscounted + ((parseFloat(subTotalDiscounted) * single_quotation.vat) / 100) + single_quotation.other);
    // // const totalPriceComputed = 0;
    // // formatted price
    // // console.log(totalPriceComputed);
    // const subTotal = numberFormat(subTotalComputed);
    // const total = numberFormat(totalPriceComputed);
    // const itemSelectedAmount = itemSelected.qty * itemSelected.srp;

    let percent_value = [
        { value: 'none', label: 'None' },
        { value: 'percent', label: 'Percent' },
        { value: 'amount', label: 'Amount' },
    ];

    const Option = (props) => {
        // console.log(props)
        return (
            <components.Option {...props}>
                <div>
                    {props.data.label} [{props.data.brand}]
                </div>
                <div style={{ fontSize: 11 }}>{props.data.sku}</div>
                <div style={{ fontSize: 11 }}>{props.data.description}</div>
            </components.Option>
        );
    };

    const status_lbl = (
        <span className={`lbl-status mr-1 lbl-${single_quotation.status}`}>
            {single_quotation.status}
        </span>
    );
    const payment_status =
        single_quotation.payment_mode.value != '' ? (
            <span
                className={`lbl-status mr-1 lbl-payment-type lbl-${single_quotation.payment_mode.label}`}
            >
                {single_quotation.payment_mode.label}
            </span>
        ) : (
            ''
        );

    // console.log("items new:", single_quotation.quotation_items)
    //  console.log("mini_loading", mini_loading)
    return (
        <div className="app-content content">
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">
                            Sales
                        </h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item">
                                        <Link
                                            to={main_url}
                                            className="text-white"
                                        >
                                            Quotation No.
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="#" className="text-white">
                                            {single_quotation.quotation_no}
                                        </Link>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row f-regular">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header mb-0 pb-0">
                                        <Link to={main_url}>
                                            <i className="ft ft-arrow-left"></i>{' '}
                                            Back
                                        </Link>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body pb-10m f-regular">
                                            {mini_loading ? (
                                                <MiniSpinner />
                                            ) : single_quotation.status !=
                                              'saved' ? (
                                                <form>
                                                    <ReactTooltip
                                                        effect="solid"
                                                        event="click"
                                                    />
                                                    <section className="">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="row">
                                                                    <div className="col-12 mb-2">
                                                                        {
                                                                            status_lbl
                                                                        }
                                                                        {
                                                                            payment_status
                                                                        }
                                                                    </div>
                                                                    <div className="col-sm-4">
                                                                        <label>
                                                                            Template{' '}
                                                                            <span className="text-danger">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-10 col-10 pr-0">
                                                                                <Select
                                                                                    options={
                                                                                        qu_templates
                                                                                    }
                                                                                    onChange={
                                                                                        handleSelectSalesInvoiceTemplate
                                                                                    }
                                                                                    value={
                                                                                        qu_default_template
                                                                                    }
                                                                                    isSearchable={
                                                                                        isSearchable
                                                                                    }
                                                                                    required
                                                                                />
                                                                            </div>
                                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-4">
                                                                        <label>
                                                                            Customer{' '}
                                                                            <span className="text-danger">
                                                                                *
                                                                            </span>{' '}
                                                                        </label>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-10 col-10 pr-0">
                                                                                {/* <Select
                                          options={customers}
                                          value={default_customer_value}
                                          isSearchable={isSearchable}
                                          onChange={handleSelectCustomer}
                                          placeholder='Select Customer...'
                                          required
                                        /> */}
                                                                                <div className="form-control f-regular">
                                                                                    {
                                                                                        default_customer_value.label
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                    <Show when="inv.sales.customers.create">
                                                                                        <div
                                                                                            className="a-tagicon icon-s"
                                                                                            onClick={() =>
                                                                                                setModal(
                                                                                                    'customer_modal',
                                                                                                    true
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="ft ft-plus-circle text-primary"></i>
                                                                                        </div>
                                                                                    </Show>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4"></div>
                                                                    <div className="col-sm-4">
                                                                        <label>
                                                                            Quotation
                                                                            Date
                                                                        </label>
                                                                        <div className="form-group row">
                                                                            <div className="col-md-10 col-10 pr-0">
                                                                                <div className="date-picker-100">
                                                                                    <DatePicker
                                                                                        onChange={(
                                                                                            date
                                                                                        ) =>
                                                                                            QUDatePicker(
                                                                                                date,
                                                                                                'quotation_date'
                                                                                            )
                                                                                        }
                                                                                        selected={
                                                                                            single_quotation.quotation_date
                                                                                        }
                                                                                        name="sales_invoice_date"
                                                                                        className="form-control f-regular"
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {single_quotation.customer && (
                                                                    <Fragment>
                                                                        <ReactTooltip
                                                                            effect="solid"
                                                                            event="click"
                                                                        />
                                                                        {/* <table className='table table-hover table-striped table-bordered table-responsive'> */}
                                                                        <table className="table table-hover table-striped table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className="text-left td-16m">
                                                                                        SKU
                                                                                    </th>
                                                                                    <th className="text-center">
                                                                                        Product
                                                                                    </th>
                                                                                    <th className="text-center mw-4m">
                                                                                        Qty
                                                                                    </th>
                                                                                    <th className="text-center">
                                                                                        Unit
                                                                                    </th>
                                                                                    <th className="text-center">
                                                                                        Unit/Price
                                                                                    </th>
                                                                                    <th className="text-center">
                                                                                        Amount
                                                                                    </th>
                                                                                    <th className="text-center">
                                                                                        Action
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {single_quotation.quotation_items.map(
                                                                                    (
                                                                                        item,
                                                                                        index
                                                                                    ) => (
                                                                                        <Fragment
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {' '}
                                                                                            {/* This is the key part */}
                                                                                            <tr
                                                                                                className={stockClass(
                                                                                                    item.status_code
                                                                                                )}
                                                                                            >
                                                                                                {/* <tr
                                               key={index}
                                               className={stockClass(
                                                 item.status_code
                                               )}
                                             > */}
                                                                                                <td className="table-data__sku ">
                                                                                                    {
                                                                                                        item.sku
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="min-wdth-300 max-wdth-200">
                                                                                                    {
                                                                                                        item.new ? (
                                                                                                            item.selected ? (
                                                                                                                <div>
                                                                                                                    {/* <div className='f-right' onClick={() => ShowComboBox(index)}>
                                                          <i className='ft ft-chevron-down c-pointer'></i>
                                                        </div> */}
                                                                                                                    <div className="mw-90">
                                                                                                                        <Link
                                                                                                                            to={`/inventory/view/${item._id}`}
                                                                                                                            target="_blank"
                                                                                                                            onClick={(
                                                                                                                                e
                                                                                                                            ) =>
                                                                                                                                e.stopPropagation()
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {
                                                                                                                                item.product_name
                                                                                                                            }
                                                                                                                        </Link>
                                                                                                                    </div>
                                                                                                                    <div className="f-12 text-dark">
                                                                                                                        {
                                                                                                                            item.barcode
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="f-12 text-dark">
                                                                                                                        {
                                                                                                                            item.description
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="f-12 text-dark">
                                                                                                                        {
                                                                                                                            item.brand
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    <AsyncSelect
                                                                                                                        value={{
                                                                                                                            label: item.product_name,
                                                                                                                            value: item._id,
                                                                                                                        }}
                                                                                                                        isLoading={
                                                                                                                            loading
                                                                                                                        }
                                                                                                                        filterOption={
                                                                                                                            customFilter
                                                                                                                        }
                                                                                                                        components={{
                                                                                                                            Option,
                                                                                                                        }}
                                                                                                                        classNamePrefix="lp-copy-sel"
                                                                                                                        loadingMessage={() =>
                                                                                                                            'searching...'
                                                                                                                        }
                                                                                                                        noOptionsMessage={({
                                                                                                                            inputValue,
                                                                                                                        }) =>
                                                                                                                            inputValue
                                                                                                                                ? 'No Products'
                                                                                                                                : 'Search product'
                                                                                                                        }
                                                                                                                        defaultOptions={
                                                                                                                            item.items
                                                                                                                        }
                                                                                                                        loadOptions={debounce(
                                                                                                                            (
                                                                                                                                value
                                                                                                                            ) =>
                                                                                                                                searchProducst(
                                                                                                                                    value,
                                                                                                                                    index
                                                                                                                                ),
                                                                                                                            1000
                                                                                                                        )}
                                                                                                                        isSearchable={
                                                                                                                            true
                                                                                                                        }
                                                                                                                        cacheOptions
                                                                                                                        onChange={(
                                                                                                                            val
                                                                                                                        ) =>
                                                                                                                            handleSelectItem(
                                                                                                                                val,
                                                                                                                                index
                                                                                                                            )
                                                                                                                        }
                                                                                                                        placeholder="Select Products..."
                                                                                                                        required
                                                                                                                    />
                                                                                                                </>
                                                                                                            )
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {/* <div className='f-right' onClick={() => ShowComboBox(index)}>
                                                          <i className='ft ft-chevron-down c-pointer'></i>
                                                        </div> */}
                                                                                                                <div className="mw-90">
                                                                                                                    <Link
                                                                                                                        to={`/inventory/view/${item.product_id}`}
                                                                                                                        target="_blank"
                                                                                                                        onClick={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            e.stopPropagation()
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            item.product_name
                                                                                                                        }
                                                                                                                    </Link>
                                                                                                                </div>
                                                                                                                <div className="f-12 text-dark">
                                                                                                                    {
                                                                                                                        item
                                                                                                                            .product
                                                                                                                            .barcode
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="f-12 text-dark">
                                                                                                                    {
                                                                                                                        item
                                                                                                                            .product
                                                                                                                            .description
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="f-12 text-dark">
                                                                                                                    {
                                                                                                                        item.brand
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                        // <span>{item.product_name} <br/> <small className="text-secondary f-10">{item.product != null && item.product.description}</small></span>
                                                                                                    }
                                                                                                </td>
                                                                                                <td className="mw-4m text-center">
                                                                                                    <input
                                                                                                        value={
                                                                                                            item.qty
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            dynamicInputChange(
                                                                                                                e,
                                                                                                                index
                                                                                                            )
                                                                                                        }
                                                                                                        className="form-control text-center"
                                                                                                        type="number"
                                                                                                        name="qty"
                                                                                                        step="any"
                                                                                                        min={
                                                                                                            1
                                                                                                        }
                                                                                                    />
                                                                                                </td>
                                                                                                <td className="min-wdth-100 max-wdth-100 text-center">
                                                                                                    {
                                                                                                        item.product_unit_name
                                                                                                    }
                                                                                                </td>
                                                                                                <td
                                                                                                    className="mw-8m"
                                                                                                    align="right"
                                                                                                >
                                                                                                    <input
                                                                                                        value={
                                                                                                            item.srp
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            dynamicInputChange(
                                                                                                                e,
                                                                                                                index
                                                                                                            )
                                                                                                        }
                                                                                                        className="form-control text-right"
                                                                                                        type="number"
                                                                                                        name="srp"
                                                                                                    />
                                                                                                </td>
                                                                                                <td
                                                                                                    className="min-wdth-150 max-wdth-150"
                                                                                                    align="right"
                                                                                                >
                                                                                                    {item.amount
                                                                                                        ? numberFormat(
                                                                                                              item.amount
                                                                                                          )
                                                                                                        : numberFormat(
                                                                                                              item.srp
                                                                                                          )}
                                                                                                </td>
                                                                                                <td
                                                                                                    align="center"
                                                                                                    className="mw-2m"
                                                                                                >
                                                                                                    <span className="m-1">
                                                                                                        <button
                                                                                                            className="btn btn-sm btn-primary"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleDiscountItem(
                                                                                                                    index,
                                                                                                                    false
                                                                                                                )
                                                                                                            }
                                                                                                            type="button"
                                                                                                        >
                                                                                                            <i className="ft ft-tag"></i>
                                                                                                        </button>
                                                                                                    </span>

                                                                                                    <span>
                                                                                                        <button
                                                                                                            className="btn btn-sm btn-danger"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleRemoveItem(
                                                                                                                    index
                                                                                                                )
                                                                                                            }
                                                                                                            type="button"
                                                                                                        >
                                                                                                            <i className="ft ft-trash"></i>
                                                                                                        </button>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                            {(item.discount_type &&
                                                                                                item
                                                                                                    .discount_type
                                                                                                    .value !==
                                                                                                    'none') ||
                                                                                            item.showDiscountRow ? (
                                                                                                <tr>
                                                                                                    <td
                                                                                                        colSpan={
                                                                                                            1
                                                                                                        }
                                                                                                    ></td>
                                                                                                    <td
                                                                                                        className="d-flex ali justify-content-center align-items-center"
                                                                                                        style={{
                                                                                                            gap: '5%',
                                                                                                        }}
                                                                                                    >
                                                                                                        <span>
                                                                                                            Discount:
                                                                                                        </span>

                                                                                                        <span className="w-20 table-data__discount">
                                                                                                            {(() => {
                                                                                                                switch (
                                                                                                                    item
                                                                                                                        .discount_type
                                                                                                                        .value
                                                                                                                ) {
                                                                                                                    case 'percent':
                                                                                                                        return (
                                                                                                                            <div class="input-group">
                                                                                                                                <input
                                                                                                                                    onChange={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        handleInputDiscountProduct(
                                                                                                                                            e,
                                                                                                                                            index
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        item.discount
                                                                                                                                    }
                                                                                                                                    name="discount"
                                                                                                                                    className="form-control text-right f-regular"
                                                                                                                                    type="number"
                                                                                                                                />
                                                                                                                                <div class="input-group-append">
                                                                                                                                    <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                        %
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    case 'amount':
                                                                                                                        return (
                                                                                                                            <input
                                                                                                                                onChange={(
                                                                                                                                    e
                                                                                                                                ) =>
                                                                                                                                    handleInputDiscountProduct(
                                                                                                                                        e,
                                                                                                                                        index
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                value={
                                                                                                                                    item.discount
                                                                                                                                }
                                                                                                                                name="discount"
                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                type="number"
                                                                                                                            />
                                                                                                                        );
                                                                                                                    default: // 0 disabled
                                                                                                                        return (
                                                                                                                            <input
                                                                                                                                value={
                                                                                                                                    item.discount
                                                                                                                                }
                                                                                                                                disabled
                                                                                                                                name="discount"
                                                                                                                                className="form-control text-right f-regular"
                                                                                                                                type="number"
                                                                                                                            />
                                                                                                                        );
                                                                                                                }
                                                                                                            })()}
                                                                                                        </span>

                                                                                                        <span className="table-data__discount-dropdown">
                                                                                                            <Select
                                                                                                                name="discount_type"
                                                                                                                onChange={(
                                                                                                                    selectOption
                                                                                                                ) =>
                                                                                                                    handleSelectDiscountTypeProduct(
                                                                                                                        selectOption,
                                                                                                                        index
                                                                                                                    )
                                                                                                                }
                                                                                                                menuPortalTarget={
                                                                                                                    document.body
                                                                                                                }
                                                                                                                value={
                                                                                                                    item.discount_type
                                                                                                                }
                                                                                                                defaultValue={
                                                                                                                    item.discount_type
                                                                                                                }
                                                                                                                label="Single select"
                                                                                                                options={
                                                                                                                    percent_value
                                                                                                                }
                                                                                                                isSearchable={
                                                                                                                    false
                                                                                                                }
                                                                                                            />
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td className="min-wdth-100 max-wdth-100 text-right text-danger">
                                                                                                        -{' '}
                                                                                                        {NumberFormat(
                                                                                                            Number(
                                                                                                                item.discount_amount
                                                                                                            ).toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        align="center"
                                                                                                        className="mw-2m"
                                                                                                    >
                                                                                                        <button
                                                                                                            className="btn btn-sm btn-danger"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                handleDiscountItem(
                                                                                                                    index,
                                                                                                                    true
                                                                                                                )
                                                                                                            }
                                                                                                            type="button"
                                                                                                        >
                                                                                                            <i className="ft ft-trash"></i>
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : null}
                                                                                        </Fragment>
                                                                                    )
                                                                                )}
                                                                                {/* <tr className="bg-primary bg-accent-2">
                                                                                    <td className="min-wdth-100 max-wdth-100"><div className="td-padding">{itemSelected.sku} </div></td>
                                                                                    <td className="min-wdth-300 max-wdth-200"><Select components={{Option}} filterOption={customFilter} classNamePrefix="lp-copy-sel" options={items} onChange={handleSelectItem} isSearchable={isSearchable} value={default_product_value}/></td>
                                                                                    <td className="min-wdth-120 max-wdth-100"><input onChange={handleInputQty} value={itemSelected.qty} name="qty" min={1} className="form-control" type="text" step="any"/></td>
                                                                                    <td className="min-wdth-100 max-wdth-100 text-center">{itemSelected.product_unit_name}</td>
                                                                                    <td className="min-wdth-150 max-wdth-150"><input onChange={handleInputSrp} value={itemSelected.srp} type="number" name="srp" className="form-control" min={1}/></td>
                                                                                    <td className="min-wdth-150 max-wdth-150" align="right"><div className="td-padding">{itemSelectedAmount ?  numberFormat(itemSelectedAmount) : 0}</div></td>
                                                                                    <td className="text-center" width="5%"><a className="btn btn-sm btn-primary text-white" onClick={handleAddItem}>add</a></td>
                                                                                </tr> */}
                                                                                <tr>
                                                                                    <td
                                                                                        colSpan="7"
                                                                                        className="text-left"
                                                                                    >
                                                                                        <button
                                                                                            className="btn btn-primary"
                                                                                            type="button"
                                                                                            onClick={
                                                                                                add_items
                                                                                            }
                                                                                        >
                                                                                            <i className="la la-plus"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                    <td className="p-2"></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td
                                                                                        colSpan={
                                                                                            4
                                                                                        }
                                                                                    ></td>
                                                                                    <td>
                                                                                        <div align="right">
                                                                                            Sub
                                                                                            Total
                                                                                        </div>
                                                                                    </td>
                                                                                    <td align="right">
                                                                                        {numberFormat(
                                                                                            single_quotation.sub_total.toFixed(
                                                                                                2
                                                                                            )
                                                                                        )}
                                                                                    </td>
                                                                                    <td></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td
                                                                                        colSpan={
                                                                                            4
                                                                                        }
                                                                                    ></td>
                                                                                    <td>
                                                                                        <div align="right">
                                                                                            Discount
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {(() => {
                                                                                            switch (
                                                                                                single_quotation
                                                                                                    .discount_type
                                                                                                    .value
                                                                                            ) {
                                                                                                case 'percent':
                                                                                                    return (
                                                                                                        <div class="input-group">
                                                                                                            <input
                                                                                                                onChange={
                                                                                                                    handleInputChangeDiscount
                                                                                                                }
                                                                                                                value={
                                                                                                                    single_quotation.discount
                                                                                                                }
                                                                                                                name="discount"
                                                                                                                className="form-control text-right f-regular"
                                                                                                                type="number"
                                                                                                            />
                                                                                                            <div class="input-group-append">
                                                                                                                <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                    %
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    );
                                                                                                case 'amount':
                                                                                                    return (
                                                                                                        <input
                                                                                                            onChange={
                                                                                                                handleInputChangeDiscount
                                                                                                            }
                                                                                                            value={
                                                                                                                single_quotation.discount
                                                                                                            }
                                                                                                            name="discount"
                                                                                                            className="form-control text-right f-regular"
                                                                                                            type="number"
                                                                                                        />
                                                                                                    );
                                                                                                default: // 0 disabled
                                                                                                    return (
                                                                                                        <input
                                                                                                            value={
                                                                                                                single_quotation.discount
                                                                                                            }
                                                                                                            disabled
                                                                                                            name="discount"
                                                                                                            className="form-control text-right f-regular"
                                                                                                            type="number"
                                                                                                        />
                                                                                                    );
                                                                                            }
                                                                                        })()}
                                                                                    </td>
                                                                                    <td
                                                                                        width="10%"
                                                                                        className="text-center padding-td-5"
                                                                                    >
                                                                                        <Select
                                                                                            name="discount_type"
                                                                                            onChange={
                                                                                                handleSelectDiscountType
                                                                                            }
                                                                                            menuPortalTarget={
                                                                                                document.body
                                                                                            }
                                                                                            value={
                                                                                                single_quotation.discount_type
                                                                                            }
                                                                                            defaultValue={
                                                                                                single_quotation.discount_type
                                                                                            }
                                                                                            label="Single select"
                                                                                            options={
                                                                                                percent_value
                                                                                            }
                                                                                            isSearchable={
                                                                                                false
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td
                                                                                        colSpan={
                                                                                            4
                                                                                        }
                                                                                    ></td>
                                                                                    <td>
                                                                                        <div align="right">
                                                                                            VAT
                                                                                            (
                                                                                            {
                                                                                                single_quotation.tax
                                                                                            }{' '}
                                                                                            %)
                                                                                        </div>
                                                                                    </td>
                                                                                    <td align="right">
                                                                                        {numberFormat(
                                                                                            single_quotation.vat_amount
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="padding-td-5 min-width-150">
                                                                                        <Select
                                                                                            name="tax_type"
                                                                                            onChange={
                                                                                                handleTaxType
                                                                                            }
                                                                                            value={
                                                                                                single_quotation.tax_type
                                                                                            }
                                                                                            menuPortalTarget={
                                                                                                document.body
                                                                                            }
                                                                                            options={
                                                                                                tax_types
                                                                                            }
                                                                                            isSearchable={
                                                                                                false
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td
                                                                                        colSpan={
                                                                                            4
                                                                                        }
                                                                                    ></td>
                                                                                    <td>
                                                                                        <div align="right">
                                                                                            Delivery
                                                                                            Fee
                                                                                        </div>
                                                                                    </td>
                                                                                    <td width="12%">
                                                                                        {isOther ? (
                                                                                            <input
                                                                                                onChange={
                                                                                                    handleInputAttr
                                                                                                }
                                                                                                value={
                                                                                                    edit_attr.other
                                                                                                }
                                                                                                name="other"
                                                                                                className="form-control"
                                                                                                type="number"
                                                                                            />
                                                                                        ) : (
                                                                                            <div align="right">
                                                                                                {' '}
                                                                                                {
                                                                                                    single_quotation.other
                                                                                                }{' '}
                                                                                            </div>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        {isOther ? (
                                                                                            <Fragment>
                                                                                                <div className="btn-group">
                                                                                                    <a
                                                                                                        className="btn btn-primary btn-sm"
                                                                                                        onClick={() =>
                                                                                                            handleAttrSubmit(
                                                                                                                'other'
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <i className="ft ft-check text-white"></i>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </Fragment>
                                                                                        ) : (
                                                                                            <a
                                                                                                className="btn btn-info btn-sm"
                                                                                                onClick={() =>
                                                                                                    toggleEditBtn(
                                                                                                        true,
                                                                                                        'isOther'
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i className="ft ft-edit text-white"></i>
                                                                                            </a>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colSpan="4"></td>
                                                                                    <td>
                                                                                        <div align="right">
                                                                                            <strong>
                                                                                                Total
                                                                                            </strong>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td align="right">
                                                                                        {numberFormat(
                                                                                            single_quotation.total
                                                                                        )}
                                                                                    </td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </Fragment>
                                                                )}

                                                                <FormTab />
                                                                {/* <div className='col-12 mb-2'></div>
                                <div className='row'>
                                  <div className='col-md-4'>
                                    <label>Remarks </label>
                                    <div className='form-group row'>
                                      <div className='col-md-10 col-10 pr-0'>
                                        <textarea
                                          onChange={handleInputChange}
                                          value={single_quotation.remarks}
                                          rows='2'
                                          placeholder='Remarks'
                                          name='remarks'
                                          className='form-control f-regular'
                                        ></textarea>
                                      </div>
                                      <div className='float-right pl-0 col-md-2 col-2'>
                                        <div className='input-group-prepend'>
                                          <span
                                            href='#'
                                            className='tool-tip text-primary'
                                            data-tip='this is a tool tip'
                                          >
                                            <i className='la la-question-circle'></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-sm-4'>
                                    <label>Payment Mode:</label>
                                    <div className='form-group row'>
                                      <div className='col-md-10 col-10 pr-0'>
                                        <Select
                                          options={payment_mode_options}
                                          onChange={handleSelectPaymentType}
                                          value={single_quotation.payment_mode}
                                          isSearchable={isSearchable}
                                        />
                                      </div>
                                      <div className='float-right pl-0 col-md-2 col-2'>
                                        <div className='input-group-prepend'>
                                          <span
                                            href='#'
                                            className='tool-tip text-primary'
                                            data-tip='this is a tool tip'
                                          >
                                            <i className='la la-question-circle'></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div className='col-sm-4'>
                                    <label>Payment Terms: </label>
                                    <div className='form-group row'>
                                      <div className='col-md-10 col-10 pr-0'>
                                        <Select
                                          options={terms_conditions}
                                          onChange={handleSelectTermsConditions}
                                          value={single_quotation.payment_terms}
                                          isSearchable={isSearchable}
                                        />
                                      </div>
                                      <div className='float-right pl-0 col-md-2 col-2'>
                                        <div className='input-group-prepend'>
                                          <span
                                            href='#'
                                            className='tool-tip text-primary'
                                            data-tip='this is a tool tip'
                                          >
                                            <i className='la la-question-circle'></i>
                                          </span>
                                          <div
                                            className='a-tagicon icon-s'
                                            onClick={() =>
                                              setModal(
                                                "payment_terms_modal",
                                                true
                                              )
                                            }
                                          >
                                            <i className='ft ft-plus-circle text-primary'></i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                                            </div>
                                                        </div>
                                                    </section>
                                                </form>
                                            ) : (
                                                <QuotationViewTable />
                                            )}
                                            <div className="row justify-content-end mt-3">
                                                <div className="mr-2">
                                                    <div className="form-group">
                                                        {single_quotation.status !=
                                                            'saved' &&
                                                            single_quotation.status !=
                                                                'cancelled' && (
                                                                <Fragment>
                                                                    {/* <Show when="inv.sales.quotations.draft"><button onClick={() => handleOnSubmit('update','draft')} type="button" className="btn btn-outline-light text-dark">Draft</button></Show>{" "}
                                                                {
                                                                    (edit_row_index != null || isDiscount) ? <button type="button" className="btn btn-primary btn-update" disabled>Save</button>
                                                                    : <button onClick={() => handleOnSubmit('update','saved')} className="btn btn-primary btn-update">Save</button>
                                                                } */}
                                                                    <Show when="inv.sales.quotations.draft">
                                                                        <div className="btn-group btn-dpd">
                                                                            <button
                                                                                className="btn btn-default btn-outline-light text-dark btn-main"
                                                                                onClick={() =>
                                                                                    handleOnSubmit(
                                                                                        'update',
                                                                                        'draft'
                                                                                    )
                                                                                }
                                                                                type="button"
                                                                                disabled={
                                                                                    mini_loading
                                                                                }
                                                                            >
                                                                                Save
                                                                                as
                                                                                Draft
                                                                            </button>
                                                                            <button
                                                                                data-toggle="dropdown"
                                                                                className="btn btn-default btn-outline-light text-dark dropdown-toggle btn-carret"
                                                                                type="button"
                                                                                disabled={
                                                                                    mini_loading
                                                                                }
                                                                            >
                                                                                <span className="caret"></span>
                                                                            </button>
                                                                            <ul className="dropdown-menu">
                                                                                {/* <li><a className="dpd-menu" onClick={() => handleOnSubmit('update', 'draft')}>Daft</a></li> */}
                                                                                <li>
                                                                                    <a
                                                                                        className="dpd-menu"
                                                                                        onClick={() =>
                                                                                            handleOnSubmit(
                                                                                                'update',
                                                                                                'draft-email'
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Draft
                                                                                        &
                                                                                        Email
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a
                                                                                        className="dpd-menu"
                                                                                        onClick={() =>
                                                                                            handleOnSubmit(
                                                                                                'update',
                                                                                                'draft-print'
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Draft
                                                                                        &
                                                                                        Print
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </Show>{' '}
                                                                    <div className="btn-group btn-dpd">
                                                                        <button
                                                                            className="btn btn-primary  btn-main"
                                                                            onClick={() =>
                                                                                handleOnSubmit(
                                                                                    'update',
                                                                                    'saved'
                                                                                )
                                                                            }
                                                                            type="button"
                                                                            disabled={
                                                                                mini_loading
                                                                            }
                                                                        >
                                                                            Save
                                                                            Quotation
                                                                        </button>
                                                                        <button
                                                                            data-toggle="dropdown"
                                                                            className="btn btn-primary dropdown-toggle btn-carret"
                                                                            type="button"
                                                                            disabled={
                                                                                mini_loading
                                                                            }
                                                                        >
                                                                            <span className="caret"></span>
                                                                        </button>
                                                                        <ul className="dropdown-menu">
                                                                            {/* {
                                                                            (edit_row_index != null || isDiscount) &&
                                                                            <li><a className="dpd-menu" href="#" onClick={() => handleOnSubmit('update', 'saved')}>Save</a></li>
                                                                        } */}
                                                                            <li>
                                                                                <a
                                                                                    className="dpd-menu"
                                                                                    onClick={() =>
                                                                                        handleOnSubmit(
                                                                                            'update',
                                                                                            'saved-email'
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Save
                                                                                    &
                                                                                    Email
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    className="dpd-menu"
                                                                                    onClick={() =>
                                                                                        handleOnSubmit(
                                                                                            'update',
                                                                                            'saved-print'
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Save
                                                                                    &
                                                                                    Print
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </Fragment>
                                                            )}
                                                        {single_quotation.status ==
                                                            'saved' &&
                                                            single_quotation.status !=
                                                                'cancelled' && (
                                                                <Fragment>
                                                                    <Show when="inv.sales.quotations.cancel">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            onClick={
                                                                                cancelQuote
                                                                            }
                                                                            type="button"
                                                                        >
                                                                            {btn_form.cancel ? (
                                                                                <i className="icon-loading-custom"></i>
                                                                            ) : (
                                                                                <span>
                                                                                    Cancel
                                                                                </span>
                                                                            )}
                                                                        </button>
                                                                    </Show>
                                                                </Fragment>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <QuotationCustomerModal
                show={customer_modal}
                onHide={() => setModal('customer_modal', false)}
            />
            <PaymentTermsModal
                show={payment_terms_modal}
                onHide={() => setModal('payment_terms_modal', false)}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    quotation: state.quotation,
});

export default connect(mapStateToProps, {
    handleOnSubmit,
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleDiscountItem,
    handleSelectDiscountTypeProduct,
    handleInputDiscountProduct,
    handleInputAttr,
    handleInputChange,
    handleInputSrp,
    handleCheckVat,
    handleInputQty,
    handleSelectItem,
    handleSelectTermsConditions,
    handleEditInputRow,
    setModal,
    handleToggleEditRow,
    handleUpdateRow,
    handleAttrSubmit,
    getProducts,
    getTermsConditions,
    getCustomers,
    getSingleQuotation,
    setPage,
    handleSelectDiscountType,
    handleInputChangeDiscount,
    handleSelectSalesInvoiceTemplate,
    getQuotationTemplates,
    QUDatePicker,
    handleSelectPaymentType,
    add_items,
    dynamicInputChange,
    searchProducst,
    handleTaxType,
    computationHandler,
    ShowComboBox,
    cancelQuote,
    getQuotationValidations,
})(QuotationEdit);
