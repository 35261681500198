import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    setModal,
    handleInputChange,
    handleSelectPaymentType,
    handleSelectTermsConditions,
    handleSelectQuotationValidation,
} from '../../../redux/actions/quotation/quotation.actions';
import Select from 'react-select';

function FormTab({
    quotation: {
        single_quotation,
        payment_mode_options,
        isSearchable,
        terms_conditions,
        quote_validation,
    },
    auth: { user },
    handleInputChange,
    handleSelectPaymentType,
    handleSelectTermsConditions,
    handleSelectQuotationValidation,
    setModal,
}) {
    const [activeTab, setActiveTab] = useState('terms');
    const paymentDetailsRef = useRef(null);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    //** SHIN MIKANA PAYMENT DETAILS */
    //** DEFAULT PATYMENT DETAILS VALUE FOR SHINMIKANA. */
    const defaultPaymentDetails = `BANK DETAILS:
BDO
SHIN MIKANA PHILIPPINES INC.
0035 4801 4474

METROBANK
SHIN MIKANA PHILIPPINES INC.
1957 1955 1682 7`;

    // On mount (or when values change), update payment_details in Redux if subscriber_id matches and field is empty
    useEffect(() => {
        if (
            user?.subscriber_id === '6798872018e19dd6d143f2b3' &&
            !single_quotation.payment_details
        ) {
            // Simulate an event to update the redux state for payment_details
            handleInputChange({
                target: {
                    name: 'payment_details',
                    value: defaultPaymentDetails,
                },
            });
        }
    }, [
        user,
        single_quotation.payment_details,
        defaultPaymentDetails,
        handleInputChange,
    ]);

    // Auto resize function
    const autoResize = (element) => {
        if (element) {
            element.style.height = 'auto';
            element.style.height = `${element.scrollHeight}px`;
        }
    };

    // Adjust the textarea height whenever payment_details changes
    useEffect(() => {
        autoResize(paymentDetailsRef.current);
    }, [single_quotation.payment_details]);

    return (
        <section className="d-flex justify-content-between">
            <div className="card form-tab-card ">
                <div className="card-content">
                    <div className="card-body">
                        <ul className="nav nav-pills nav-pill-with-active-bordered">
                            <li className="nav-item">
                                <button
                                    type="button"
                                    className={`nav-link form-tab-link ${
                                        activeTab === 'terms' ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick('terms')}
                                    id="base-pill21"
                                    data-toggle="pill"
                                    href="#pill21"
                                    aria-expanded="true"
                                >
                                    Terms &amp; Condition
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    type="button"
                                    className={`nav-link form-tab-link ${
                                        activeTab === 'payment' ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick('payment')}
                                    id="base-pill22"
                                    data-toggle="pill"
                                    href="#pill22"
                                    aria-expanded="false"
                                >
                                    Payment Terms
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    type="button"
                                    className={`nav-link form-tab-link ${
                                        activeTab === 'internal' ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick('internal')}
                                    id="base-pill23"
                                    data-toggle="pill"
                                    href="#pill23"
                                    aria-expanded="false"
                                >
                                    Internal Notes
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content px-1 pt-1">
                            {activeTab === 'terms' && (
                                <div
                                    role="tabpanel"
                                    className="tab-pane active"
                                    id="pill21"
                                    aria-expanded="true"
                                    aria-labelledby="base-pill21"
                                >
                                    <>
                                        <div className="row">
                                            <div className="col-sm-9">
                                                <label>
                                                    Quotation Validity:{' '}
                                                </label>
                                                <div className="form-group row">
                                                    <div className="col-md-10 col-10 pr-0">
                                                        <Select
                                                            options={
                                                                quote_validation
                                                            }
                                                            onChange={
                                                                handleSelectQuotationValidation
                                                            }
                                                            value={
                                                                single_quotation.quotation_validation
                                                            }
                                                            isSearchable={
                                                                isSearchable
                                                            }
                                                        />
                                                    </div>
                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                        <div className="input-group-prepend">
                                                            <span
                                                                href="#"
                                                                className="tool-tip text-primary"
                                                                data-tip="this is a tool tip"
                                                            >
                                                                <i className="la la-question-circle"></i>
                                                            </span>
                                                            <div
                                                                className="a-tagicon icon-s"
                                                                onClick={() =>
                                                                    setModal(
                                                                        'quote_validation_modal',
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <i className="ft ft-plus-circle text-primary"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade show active"
                                            role="tabpanel"
                                        >
                                            <div className="form-group">
                                                <label>
                                                    Terms &amp; Condition
                                                </label>
                                                <textarea
                                                    onChange={(e) => {
                                                        autoResize(e.target);
                                                        handleInputChange(e);
                                                    }}
                                                    value={
                                                        single_quotation.terms_and_condition
                                                    }
                                                    rows="2"
                                                    placeholder="Terms & Condition"
                                                    name="terms_and_condition"
                                                    className="form-control"
                                                    style={{
                                                        overflow: 'hidden',
                                                    }}
                                                ></textarea>
                                            </div>
                                        </div>{' '}
                                        <div
                                            className="tab-pane fade show active"
                                            role="tabpanel"
                                        >
                                            <div className="form-group">
                                                <label>Additonal Remarks</label>
                                                <textarea
                                                    onChange={(e) => {
                                                        autoResize(e.target);
                                                        handleInputChange(e);
                                                    }}
                                                    value={
                                                        single_quotation.remarks
                                                    }
                                                    rows="2"
                                                    placeholder="remarks"
                                                    name="remarks"
                                                    className="form-control"
                                                    style={{
                                                        overflow: 'hidden',
                                                    }}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            )}

                            {activeTab === 'payment' && (
                                <div
                                    className="tab-pane active"
                                    id="pill22"
                                    aria-labelledby="base-pill22"
                                >
                                    <>
                                        {' '}
                                        <div className="row">
                                            <div className="col-sm-9">
                                                <label>Payment Mode:</label>
                                                <div className="form-group row">
                                                    <div className="col-md-10 col-10 pr-0">
                                                        <Select
                                                            options={
                                                                payment_mode_options
                                                            }
                                                            onChange={
                                                                handleSelectPaymentType
                                                            }
                                                            value={
                                                                single_quotation.payment_mode
                                                            }
                                                            isSearchable={
                                                                isSearchable
                                                            }
                                                        />
                                                    </div>
                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                        <div className="input-group-prepend">
                                                            <span
                                                                href="#"
                                                                className="tool-tip text-primary"
                                                                data-tip="this is a tool tip"
                                                            >
                                                                <i className="la la-question-circle"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-9">
                                                <label>Payment Terms: </label>
                                                <div className="form-group row">
                                                    <div className="col-md-10 col-10 pr-0">
                                                        <Select
                                                            options={
                                                                terms_conditions
                                                            }
                                                            onChange={
                                                                handleSelectTermsConditions
                                                            }
                                                            value={
                                                                single_quotation.payment_terms
                                                            }
                                                            isSearchable={
                                                                isSearchable
                                                            }
                                                        />
                                                    </div>
                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                        <div className="input-group-prepend">
                                                            <span
                                                                href="#"
                                                                className="tool-tip text-primary"
                                                                data-tip="this is a tool tip"
                                                            >
                                                                <i className="la la-question-circle"></i>
                                                            </span>
                                                            <div
                                                                className="a-tagicon icon-s"
                                                                onClick={() =>
                                                                    setModal(
                                                                        'payment_terms_modal',
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <i className="ft ft-plus-circle text-primary"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade show active"
                                            role="tabpanel"
                                        >
                                            <div className="form-group">
                                                <label>Payment Details</label>
                                                <textarea
                                                    ref={paymentDetailsRef}
                                                    onChange={(e) => {
                                                        autoResize(e.target);
                                                        handleInputChange(e);
                                                    }}
                                                    value={
                                                        single_quotation.payment_details
                                                    }
                                                    rows="2"
                                                    placeholder="Payment Details"
                                                    name="payment_details"
                                                    className="form-control"
                                                    style={{
                                                        overflow: 'hidden',
                                                    }}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            )}

                            {activeTab === 'internal' && (
                                <div
                                    className="tab-pane active"
                                    id="pill23"
                                    aria-labelledby="base-pill23"
                                >
                                    <div
                                        className="tab-pane fade show active"
                                        role="tabpanel"
                                    >
                                        <div className="form-group">
                                            <label>Internal Notes</label>
                                            <textarea
                                                onChange={(e) => {
                                                    autoResize(e.target);
                                                    handleInputChange(e);
                                                }}
                                                value={
                                                    single_quotation.internal_notes
                                                }
                                                rows="2"
                                                placeholder="Internal Notes"
                                                name="internal_notes"
                                                className="form-control"
                                                style={{ overflow: 'hidden' }}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card  bg-light ">{''}</div>
        </section>
    );
}
const mapStateToProps = (state) => ({
    quotation: state.quotation,
    auth: state.auth,
});

export default connect(mapStateToProps, {
    setModal,
    handleInputChange,
    handleSelectPaymentType,
    handleSelectTermsConditions,
    handleSelectQuotationValidation,
})(FormTab);
