import React, { Fragment, useEffect, useState } from 'react';
import Spinner from '../layouts/Spinner';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { customAlert } from '../../Utils/Confirm';
import InventoryList from './Inventory.list';
import {
    getInvetoryItem,
    getSelectedRows,
    handleSearchInputMUI,
    stockPopUp,
} from '../../redux/actions/inventory/inventory.actions';
import { getPurchaseOrders } from '../../redux/actions/purchase_order/purchase_order.actions';
import { getReceiving } from '../../redux/actions/receiving/receiving.actions';
import { getQuotations } from '../../redux/actions/quotation/quotation.actions';
import { getSalesInvoices } from '../../redux/actions/sales_invoice/sales_invoice.actions';
import { getSalesOrders } from '../../redux/actions/sales_order/sales_order.actions';
import { useHistory, useParams } from 'react-router-dom';
import Barcode from 'react-barcode';
import PurchaseOrderTable from '../purchase_order/PurchaseOrder.table';
import ReceivingTable from '../receiving/Receiving.table';
import QuotationTable from '../quotation/Quotation.table';
import InvoiceTable from '../invoice/Invoice.table';
import SalesOrderTable from '../sales_order/SalesOrder.table';
import StockLogTable from './inventoryStockLog.table';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import Delivery from '../delivery/Delivery';
import DeliveryTable from '../delivery/Delivery.table';
import LotNoData from '../receiving/LotNoData';
import { get_dr } from '../../redux/actions/delivery/delivery.actions';
import { getLotNoData } from '../../redux/actions/receiving/lotnodata.actions';
import { formattedDate } from '../../Utils/Common';
import { makeStyles } from '@material-ui/core/styles';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import MUIDataTable from 'mui-datatables';

function InventoryView({
    inventory: {
        inventory_list,
        inventory_item,
        loading_inv,
        stock_logs,
        sort_order_direction,
        pagination,
        sortTableByColumn,
        loading,
        sort_order_name,
        set_row_checked,
    },
    getInvetoryItem,
    getPurchaseOrders,
    getReceiving,
    getQuotations,
    getSalesInvoices,
    getSalesOrders,
    get_dr,
    match,
    handleHover,
    setMUIPage,
    updateMUISetting,
    getSelectedRows,
    handleSearchInputMUI,
    stockPopUp,
}) {
    let history = useHistory();
    let { id } = useParams();

    const pageOrigin = {
        product_id: id,
        origin: 'inventory_view',
    };

    const { hasPermission } = useAccess();
    const canViewCost = hasPermission('inv.receiving.itemReceipts.cost');

    const [tab, setTab] = useState('lotno');

    useEffect(() => {
        getInvetoryItem(match.params.id).then(() => {
            // getPurchaseOrders(1, match.params.id);
        });
    }, []);

    const handleChangeLink = (link) => history.push(link);
    // console.log("inventory_item._id", inventory_item);
    return (
        <div className="app-content content">
            <ReactTooltip effect="solid" />
            <div className="content-wrapper max-1920 mx-auto">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">
                            Inventory{' '}
                        </h3>

                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item ">
                                        <a href="#" className="text-white">
                                            Item
                                        </a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="btn-group  pull-right btn-fix-header f-regular">
                            <Show when="inv.sales.quotations.create">
                                <button
                                    onClick={() =>
                                        handleChangeLink(
                                            '/sales/quotations/create?item_id=' +
                                                inventory_item._id
                                        )
                                    }
                                    className="btn btn-primary btn-sm lbl-lg"
                                >
                                    Create Quote
                                </button>
                            </Show>
                            &nbsp;
                            <Show when="inv.purchases.purchase-orders.create">
                                <button
                                    onClick={() =>
                                        handleChangeLink(
                                            '/purchases/purchase-orders/create?item_id=' +
                                                inventory_item._id
                                        )
                                    }
                                    className="btn btn-primary btn-sm lbl-lg"
                                >
                                    Create Order
                                </button>
                            </Show>
                        </div>
                    </div>
                </div>

                <div className="content-body margin-top-5">
                    <section className="row f-regular">
                        <div className="col-sm-12">
                            <div id="what-is" className="card">
                                <div className="card-header">
                                    <Link
                                        to={'/inventory'}
                                        className="text-primary"
                                    >
                                        <i className="ft ft-arrow-left"></i>{' '}
                                        Back
                                    </Link>
                                    <a className="heading-elements-toggle">
                                        <i className="la la-ellipsis-v font-medium-3"></i>
                                    </a>
                                </div>
                                <div className="card-content collapse show f-black">
                                    <div className="card-body">
                                        {loading_inv ? (
                                            <Spinner />
                                        ) : (
                                            <Fragment>
                                                <div className="row pad-bot-30">
                                                    <div className="col-md-6 col-sm-12">
                                                        <span
                                                            className={
                                                                inventory_item.stock_class +
                                                                ' lbl-lg'
                                                            }
                                                        >
                                                            {
                                                                inventory_item.stock_status
                                                            }
                                                        </span>
                                                        <a
                                                            href="#"
                                                            className="tool-tip"
                                                            data-tip="this is a tool tip"
                                                        >
                                                            <i className="la la-question-circle"></i>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <div className="btn-group pull-right margin-bottom-5">
                                                            <button
                                                                onClick={() =>
                                                                    handleChangeLink(
                                                                        '/inventory/products/items/edit/' +
                                                                            inventory_item._id
                                                                    )
                                                                }
                                                                className="btn btn-info btn-sm"
                                                            >
                                                                <i className="ft-edit"></i>
                                                            </button>{' '}
                                                            &nbsp;
                                                            <button className="btn btn-info btn-sm">
                                                                <i className="ft-download"></i>
                                                            </button>{' '}
                                                            &nbsp;
                                                            <button className="btn btn-info btn-sm">
                                                                <i className="la la-print"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row pad-bot-20">
                                                    <section className="col-md-10 row">
                                                        <div className="col-md-12 row pad-bot-20">
                                                            <div className="col-md-5">
                                                                <h2>
                                                                    {
                                                                        inventory_item.product_name
                                                                    }{' '}
                                                                    (
                                                                    {
                                                                        inventory_item.product_unit
                                                                    }
                                                                    )
                                                                </h2>
                                                            </div>
                                                            <div className="col-md-7 no-padding-left-right">
                                                                {inventory_item.barcode ? (
                                                                    <Fragment>
                                                                        <Barcode
                                                                            value={
                                                                                inventory_item.barcode
                                                                            }
                                                                            height="30"
                                                                            fontSize="10"
                                                                            margin="1"
                                                                        />{' '}
                                                                    </Fragment>
                                                                ) : (
                                                                    <span className="text-secondary"></span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 row pad-bot-20">
                                                            <div className="col-md-5">
                                                                <label className="text-secondary">
                                                                    SKU:
                                                                </label>{' '}
                                                                <span>
                                                                    {
                                                                        inventory_item.sku
                                                                    }
                                                                </span>
                                                                <a
                                                                    href="#"
                                                                    className="tool-tip"
                                                                    data-tip="this is a tool tip"
                                                                >
                                                                    <i className="la la-question-circle"></i>
                                                                </a>
                                                            </div>
                                                            <div className="col-md-7 no-padding-left-right">
                                                                <label className="text-secondary">
                                                                    Barcode:
                                                                </label>{' '}
                                                                {inventory_item.barcode
                                                                    ? inventory_item.barcode
                                                                    : 'N/A'}{' '}
                                                                <a href="javascript:">
                                                                    <i className="la la-print"></i>{' '}
                                                                    Print
                                                                </a>{' '}
                                                                <a
                                                                    href="#"
                                                                    className="tool-tip"
                                                                    data-tip="this is a tool tip"
                                                                >
                                                                    <i className="la la-question-circle"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 row pad-bot-20">
                                                            <div className="col-md-5">
                                                                <label className="text-secondary">
                                                                    Description
                                                                </label>
                                                                <a
                                                                    href="#"
                                                                    className="tool-tip"
                                                                    data-tip="this is a tool tip"
                                                                >
                                                                    <i className="la la-question-circle"></i>
                                                                </a>{' '}
                                                                <br />{' '}
                                                                <span>
                                                                    {
                                                                        inventory_item.product_description
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="col-md-7 row">
                                                                <div className="col-md-4 no-padding-left-right ">
                                                                    <label className="text-secondary">
                                                                        Stock
                                                                        AVL
                                                                    </label>
                                                                    <a
                                                                        href="#"
                                                                        className="tool-tip"
                                                                        data-tip="this is a tool tip"
                                                                    >
                                                                        <i className="la la-question-circle"></i>
                                                                    </a>
                                                                    <a
                                                                        href="#"
                                                                        onClick={() =>
                                                                            stockPopUp()
                                                                        }
                                                                    >
                                                                        <i className="ft-edit"></i>
                                                                    </a>
                                                                    <br />
                                                                    {
                                                                        inventory_item.stock
                                                                    }{' '}
                                                                    {
                                                                        inventory_item.product_unit
                                                                    }
                                                                </div>
                                                                <div className="col-md-4 no-padding-left-right ">
                                                                    <label className="text-secondary">
                                                                        Reserved
                                                                    </label>
                                                                    <br />{' '}
                                                                    <span>
                                                                        0{' '}
                                                                        {
                                                                            inventory_item.product_unit
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-4 no-padding-left-right ">
                                                                    <label className="text-secondary">
                                                                        Incoming
                                                                    </label>
                                                                    <a
                                                                        href="#"
                                                                        className="tool-tip"
                                                                        data-tip="this is a tool tip"
                                                                    >
                                                                        <i className="la la-question-circle"></i>
                                                                    </a>
                                                                    <br />{' '}
                                                                    <span>
                                                                        0{' '}
                                                                        {
                                                                            inventory_item.product_unit
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 row pad-bot-20">
                                                            <div className="col-md-5">
                                                                <label className="text-secondary">
                                                                    Attributes
                                                                </label>{' '}
                                                                <a
                                                                    href="#"
                                                                    className="tool-tip"
                                                                    data-tip="this is a tool tip"
                                                                >
                                                                    <i className="la la-question-circle"></i>
                                                                </a>
                                                                <br />
                                                                <ul className="pad-left-0">
                                                                    {inventory_item.attributes.arr.map(
                                                                        (
                                                                            item
                                                                        ) => (
                                                                            <li>
                                                                                {
                                                                                    item
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-7 no-padding-left-right">
                                                                <label className="text-secondary">
                                                                    Low
                                                                    Threshold
                                                                </label>
                                                                <a
                                                                    href="#"
                                                                    className="tool-tip pull"
                                                                    data-tip="this is a tool tip"
                                                                >
                                                                    <i className="la la-question-circle"></i>
                                                                </a>
                                                                <br />{' '}
                                                                {
                                                                    inventory_item.stock_alarm
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 row pad-bot-20">
                                                            <div className="col-md-5 ">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="text-secondary">
                                                                            Brand
                                                                        </label>
                                                                        <a
                                                                            href="#"
                                                                            className="tool-tip"
                                                                            data-tip="this is a tool tip"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </a>
                                                                        <br />{' '}
                                                                        <a
                                                                            href="javascript:"
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    '/products/brands/edit/' +
                                                                                        inventory_item.brand_id
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                inventory_item.brands
                                                                            }
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="text-secondary">
                                                                            Category
                                                                        </label>
                                                                        <a
                                                                            href="#"
                                                                            className="tool-tip"
                                                                            data-tip="this is a tool tip"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </a>
                                                                        <br />{' '}
                                                                        <a
                                                                            href="javascript:"
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    '/products/categories/edit/' +
                                                                                        inventory_item.category_id
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                inventory_item.category
                                                                            }
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-7 row">
                                                                <div className="col-md-4 no-padding-left-right ">
                                                                    <label className="text-secondary">
                                                                        Price
                                                                    </label>
                                                                    <br />{' '}
                                                                    <span>
                                                                        ₱{' '}
                                                                        {
                                                                            inventory_item.srp_str
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-4 no-padding-left-right ">
                                                                    <label className="text-secondary">
                                                                        Sale
                                                                        Price
                                                                    </label>
                                                                    <a
                                                                        href="#"
                                                                        className="tool-tip"
                                                                        data-tip="this is a tool tip"
                                                                    >
                                                                        <i className="la la-question-circle"></i>
                                                                    </a>
                                                                    <br />{' '}
                                                                    <span>
                                                                        ₱{' '}
                                                                        {
                                                                            inventory_item.sales_price_str
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 row pad-bot-20">
                                                            <div className="col-md-5">
                                                                <label className="text-secondary">
                                                                    Suppliers/Cost
                                                                </label>{' '}
                                                                <a
                                                                    href="#"
                                                                    className="tool-tip"
                                                                    data-tip="this is a tool tip"
                                                                >
                                                                    <i className="la la-question-circle"></i>
                                                                </a>
                                                                <br />
                                                                <ul className="pad-left-0">
                                                                    {inventory_item.supplier.map(
                                                                        (
                                                                            item
                                                                        ) => (
                                                                            <li>
                                                                                <a
                                                                                    href="javascript:"
                                                                                    onClick={() =>
                                                                                        history.push(
                                                                                            '/purchases/suppliers/history/' +
                                                                                                item.id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.name
                                                                                    }
                                                                                </a>{' '}
                                                                                -{' '}
                                                                                <span
                                                                                    style={{
                                                                                        filter: !canViewCost
                                                                                            ? 'blur(4px)'
                                                                                            : 'none',
                                                                                        userSelect:
                                                                                            !canViewCost
                                                                                                ? 'none'
                                                                                                : 'auto',
                                                                                    }}
                                                                                >
                                                                                    ₱{' '}
                                                                                    {item.cost.toFixed(
                                                                                        2
                                                                                    )}
                                                                                </span>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-7 row">
                                                                <div className="col-md-4 no-padding-left-right ">
                                                                    <label className="text-secondary">
                                                                        Delivery
                                                                        Fee
                                                                    </label>
                                                                    <br />₱{' '}
                                                                    {
                                                                        inventory_item.delivery_fee_str
                                                                    }
                                                                </div>
                                                                <div className="col-md-8 no-padding-left-right">
                                                                    <label className="text-secondary">
                                                                        Custom
                                                                        Fee
                                                                    </label>
                                                                    <a
                                                                        href="#"
                                                                        className="tool-tip"
                                                                        data-tip="this is a tool tip"
                                                                    >
                                                                        <i className="la la-question-circle"></i>
                                                                    </a>
                                                                    <br />{' '}
                                                                    <span>
                                                                        ₱{' '}
                                                                        {
                                                                            inventory_item.customization_fee_str
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section className="col-md-2 row ">
                                                        <center>
                                                            <img
                                                                className="img-fluid border-secondary max-200"
                                                                src={
                                                                    inventory_item.product_img
                                                                }
                                                            ></img>
                                                        </center>
                                                    </section>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12"></div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="">
                                <div className="card-content collapse show">
                                    <div className="card-body no-padding-top no-padding-left-right">
                                        <ul className="nav nav-tabs">
                                            <Show when="inv.purchases.purchase-orders.view">
                                                <li>
                                                    <button
                                                        onClick={() =>
                                                            setTab('lotno')
                                                        }
                                                        className={
                                                            tab === 'lotno'
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                    >
                                                        Lot No.
                                                    </button>
                                                </li>
                                            </Show>
                                            <Show when="inv.purchases.purchase-orders.view">
                                                <li>
                                                    <button
                                                        onClick={() =>
                                                            setTab(
                                                                'purchase_orders'
                                                            )
                                                        }
                                                        className={
                                                            tab ===
                                                            'purchase_orders'
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                    >
                                                        Purchase Orders
                                                    </button>
                                                </li>
                                            </Show>
                                            <Show when="inv.receiving.itemReceipts.view">
                                                <li>
                                                    <button
                                                        onClick={() =>
                                                            setTab('receiving')
                                                        }
                                                        className={
                                                            tab === 'receiving'
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                    >
                                                        Receiving
                                                    </button>
                                                </li>
                                            </Show>
                                            <Show when="inv.sales.quotations.view">
                                                <li>
                                                    <button
                                                        onClick={() =>
                                                            setTab('quotations')
                                                        }
                                                        className={
                                                            tab === 'quotations'
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                    >
                                                        Quotations
                                                    </button>
                                                </li>
                                            </Show>
                                            <Show when="inv.sales.sales-orders.view">
                                                <li>
                                                    <button
                                                        onClick={() =>
                                                            setTab(
                                                                'sales_orders'
                                                            )
                                                        }
                                                        className={
                                                            tab ===
                                                            'sales_orders'
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                    >
                                                        Sales Orders
                                                    </button>
                                                </li>
                                            </Show>
                                            <Show when="inv.delivery.receipts.view">
                                                <li>
                                                    <button
                                                        onClick={() =>
                                                            setTab('deliveries')
                                                        }
                                                        className={
                                                            tab === 'deliveries'
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                    >
                                                        Delivery
                                                    </button>
                                                </li>
                                            </Show>
                                            <Show when="inv.sales.invoices.view">
                                                <li>
                                                    <button
                                                        onClick={() =>
                                                            setTab('invoices')
                                                        }
                                                        className={
                                                            tab === 'invoices'
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                    >
                                                        Invoices
                                                    </button>
                                                </li>
                                            </Show>
                                            <Show when="inv.setting.inventory.stocks">
                                                <li>
                                                    <button
                                                        onClick={() =>
                                                            setTab('stock_logs')
                                                        }
                                                        className={
                                                            tab === 'stock_logs'
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                    >
                                                        Stock Logs
                                                    </button>
                                                </li>
                                            </Show>
                                        </ul>
                                        <div className="tab-content px-1 card card-box-top">
                                            <br />
                                            {tab === 'lotno' && (
                                                <div
                                                    role="tabpanel"
                                                    className="tab-pane active"
                                                    id="tab-lotno"
                                                    aria-expanded="true"
                                                >
                                                    <LotNoData
                                                        pageOrigin={pageOrigin}
                                                    />
                                                </div>
                                            )}
                                            {tab === 'purchase_orders' && (
                                                <Show when="inv.purchases.purchase-orders.view">
                                                    <div
                                                        role="tabpanel"
                                                        className={
                                                            tab ===
                                                            'purchase_orders'
                                                                ? 'tab-pane active'
                                                                : 'tab-pane'
                                                        }
                                                        id="tab-po"
                                                        aria-expanded="true"
                                                        aria-labelledby="base-tab1"
                                                    >
                                                        <PurchaseOrderTable
                                                            pageOrigin={
                                                                pageOrigin
                                                            }
                                                        />
                                                    </div>
                                                </Show>
                                            )}
                                            {tab === 'receiving' && (
                                                <Show when="inv.receiving.itemReceipts.view">
                                                    <div
                                                        role="tabpanel"
                                                        className={
                                                            tab === 'receiving'
                                                                ? 'tab-pane active'
                                                                : 'tab-pane'
                                                        }
                                                        id="tabs-receiving"
                                                        aria-expanded="true"
                                                    >
                                                        <ReceivingTable
                                                            pageOrigin={
                                                                pageOrigin
                                                            }
                                                        />
                                                    </div>
                                                </Show>
                                            )}
                                            {tab === 'quotations' && (
                                                <Show when="inv.sales.quotations.view">
                                                    <div
                                                        role="tabpanel"
                                                        className={
                                                            tab === 'quotations'
                                                                ? 'tab-pane active'
                                                                : 'tab-pane'
                                                        }
                                                        id="tabs-quotation"
                                                        aria-expanded="true"
                                                    >
                                                        <QuotationTable
                                                            pageOrigin={
                                                                pageOrigin
                                                            }
                                                        />
                                                    </div>
                                                </Show>
                                            )}
                                            {tab === 'sales_orders' && (
                                                <Show when="inv.sales.sales-orders.view">
                                                    <div
                                                        role="tabpanel"
                                                        className={
                                                            tab ===
                                                            'sales_orders'
                                                                ? 'tab-pane active'
                                                                : 'tab-pane'
                                                        }
                                                        id="tabs-so"
                                                        aria-expanded="true"
                                                    >
                                                        <SalesOrderTable
                                                            pageOrigin={
                                                                pageOrigin
                                                            }
                                                        />
                                                    </div>
                                                </Show>
                                            )}
                                            {tab === 'deliveries' && (
                                                <Show when="inv.delivery.receipts.view">
                                                    <div
                                                        role="tabpanel"
                                                        className={
                                                            tab === 'deliveries'
                                                                ? 'tab-pane active'
                                                                : 'tab-pane'
                                                        }
                                                        id="tabs-delivery"
                                                        aria-expanded="true"
                                                    >
                                                        <DeliveryTable
                                                            pageOrigin={
                                                                pageOrigin
                                                            }
                                                        />
                                                    </div>
                                                </Show>
                                            )}
                                            {tab === 'invoices' && (
                                                <Show when="inv.sales.invoices.view">
                                                    <div
                                                        role="tabpanel"
                                                        className={
                                                            tab === 'invoices'
                                                                ? 'tab-pane active'
                                                                : 'tab-pane'
                                                        }
                                                        id="tabs-invoices"
                                                        aria-expanded="true"
                                                    >
                                                        <InvoiceTable
                                                            pageOrigin={
                                                                pageOrigin
                                                            }
                                                        />
                                                    </div>
                                                </Show>
                                            )}
                                            {tab === 'stock_logs' && (
                                                <Show when="inv.setting.inventory.stocks">
                                                    <div
                                                        role="tabpanel"
                                                        className={
                                                            tab === 'stock_logs'
                                                                ? 'tab-pane active'
                                                                : 'tab-pane'
                                                        }
                                                        id="tabs-stocklogs"
                                                        aria-expanded="true"
                                                    >
                                                        <StockLogTable
                                                            pageOrigin={
                                                                pageOrigin
                                                            }
                                                        />
                                                    </div>
                                                </Show>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    inventory: state.inventory,
});

export default connect(mapStateToProps, {
    getInvetoryItem,
    getPurchaseOrders,
    getReceiving,
    getQuotations,
    getSalesInvoices,
    getSalesOrders,
    get_dr,
    handleHover,
    setMUIPage,
    updateMUISetting,
    getSelectedRows,
    handleSearchInputMUI,
    stockPopUp,
})(InventoryView);
