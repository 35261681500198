import React, { useState } from 'react';
import axios from 'axios';
import {
    setUserSession,
    setUserAccess,
    setUserPermissions,
    setUser,
} from '../../Utils/Common';
import { LoginService } from './Login.service';

import { Animated } from 'react-animated-css';
import { ToastDanger } from '../../Services/_toast.service';
import '../../assets/login.css';
import { setDefaultRedirect } from '../../redux/actions/auth/auth.actions';

function Login(props) {
    const email = useFormInput('');
    const password = useFormInput('');
    let [remember, rememberChange] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    document.body.classList.remove('vertical-layout');
    document.body.classList.add('bg-gradient-x-purple-blue');
    const setRemember = (e) => {
        remember = remember ? false : true;
        rememberChange(remember);
    };
    const handleLogin = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        LoginService.login({
            username: email.value,
            password: password.value,
            remember: remember,
        })
            .then((response) => {
                setLoading(false);
                if (response.data.status == 1) {
                    setUserSession(response.data.token, response.data.user);
                    setUserAccess(response.data.access);
                    setUserPermissions(response.data.access);
                    setUser(response.data.user);
                    setDefaultRedirect(response.data.url_redirect);
                    window.location.pathname = response.data.url_redirect;
                } else {
                    alert(response.data.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                if (error.response !== undefined) {
                    if (error.response.status === 401)
                        setError(error.response.data.message);
                    else
                        setError(
                            'Something went wrong. Please try again later.'
                        );
                }
                console.log(error);

                ToastDanger(`Incorrect username and/or password`, error);
            });
    };

    return (
        <div className="app-content content">
            <div className="bg-auth bg-gradient-x-purple-blue"></div>
            <div className="content-wrapper">
                <div className="content-body">
                    <form
                        className="flexbox-container"
                        onSubmit={(e) => handleLogin(e)}
                    >
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-10 box-shadow-2 p-0">
                                <div className="card border-grey border-lighten-3 px-1 py-1 m-0">
                                    <div className="card-header border-0">
                                        <div className="text-center mt-3">
                                            <Animated
                                                animationIn="zoomIn"
                                                animationOut="bounceOut"
                                                animationInDuration={2000}
                                                animationOutDuration={1000}
                                                isVisible={true}
                                            >
                                                <img
                                                    src="app-assets/images/logo/blitz_logo_purple.png"
                                                    style={{
                                                        'max-width': '100%',
                                                        width: '100px',
                                                    }}
                                                    alt="branding logo"
                                                ></img>
                                            </Animated>
                                        </div>
                                        <div className="text-center mt-2">
                                            <Animated
                                                animationIn="zoomIn"
                                                animationOut="bounceOut"
                                                animationInDuration={2000}
                                                animationOutDuration={1000}
                                                isVisible={true}
                                            >
                                                <img
                                                    src="app-assets/images/logo/blitz_logo_text_purple.png"
                                                    style={{
                                                        'max-width': '100%',
                                                        width: '350px',
                                                    }}
                                                    alt="branding logo"
                                                ></img>
                                            </Animated>
                                        </div>
                                        <div className="font-large-1 text-center font-weight-bold text-primary"></div>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <fieldset className="form-group position-relative has-icon-left">
                                                <input
                                                    type="text"
                                                    className=" round login-form"
                                                    id="user-name"
                                                    placeholder="Your Username"
                                                    {...email}
                                                    autoComplete="new-password"
                                                    required
                                                ></input>
                                                <div className="form-control-position">
                                                    <i className="ft-user"></i>
                                                </div>
                                            </fieldset>
                                            <fieldset className="form-group position-relative has-icon-left">
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <input
                                                        type={
                                                            showPassword
                                                                ? 'text'
                                                                : 'password'
                                                        }
                                                        className="round login-form"
                                                        id="user-password"
                                                        placeholder="Enter Password"
                                                        {...password}
                                                        autoComplete="new-password"
                                                        required
                                                    />
                                                    <div className="form-control-position">
                                                        <i className="ft-lock"></i>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="border border-0 bg-transparent"
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            right: '10px',
                                                            top: '50%',
                                                            transform:
                                                                'translateY(-50%)',
                                                        }}
                                                        onClick={() =>
                                                            setShowPassword(
                                                                !showPassword
                                                            )
                                                        }
                                                    >
                                                        {showPassword ? (
                                                            <i className="fas fa-eye"></i>
                                                        ) : (
                                                            <i className="fas fa-eye-slash"></i>
                                                        )}
                                                    </button>
                                                </div>
                                            </fieldset>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <div className="form-group pb-0 mb-0">
                                                        <input
                                                            type="checkbox"
                                                            className="switchery"
                                                            onChange={
                                                                setRemember
                                                            }
                                                            id="remember-me"
                                                        />
                                                        <label
                                                            for="remember-me"
                                                            className="ml-1"
                                                        >
                                                            Remember me
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <a href="/forgot-password">
                                                        Forgot Password?
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="form-group text-center">
                                                <button
                                                    type="submit"
                                                    className="btn-login btn-lg round btn-block btn-glow btn-bg-gradient-x-purple-blue col-12 mr-1 mb-1 text-white"
                                                    disabled={loading}
                                                >
                                                    {loading
                                                        ? 'Loading...'
                                                        : 'Login'}
                                                </button>
                                            </div>
                                            <div className="form-group text-center">
                                                Don't have an account?
                                                <a href="/sign-up">
                                                    {' '}
                                                    Sign up here.
                                                </a>
                                            </div>
                                            <div className="form-group text-center mt-2">
                                                <p>
                                                    {' '}
                                                    Powered by{' '}
                                                    <a
                                                        href="https://bluehive.com.ph/"
                                                        target="_blank"
                                                    >
                                                        Bluehive Systems
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange,
    };
};

export default Login;
