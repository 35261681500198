import React, { useEffect, Fragment } from 'react';
import Spinner from '../layouts/Spinner';
import '../profile/styles/profile.css';
import { Modal, Button } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { getUserData } from '../../Utils/Common';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import ProfileUpload from './modal/ProfileUpload.moadal';
import {
    load_data,
    profile_modal,
    setModal,
    toggle_profile,
    update_info,
    dynamicInputChangeEdit,
} from '../../redux/actions/profile/profile.actions';

function ProfileInformation({
    profile: { user, edit_user, mini_loading, profile_img, edit_profile },
    load_data,
    profile_modal,
    setModal,
    toggle_profile,
    update_info,
    dynamicInputChangeEdit,
}) {
    useEffect(() => {
        load_data();
    }, []);
    return (
        <div>
            {mini_loading ? (
                <Spinner />
            ) : (
                <section>
                    <div className="row justify-content-md-center">
                        <div className="col-md-auto">
                            <img
                                src={user.image}
                                alt="avatar"
                                className="image-profile"
                            />
                            <button
                                className="btn btn-camera-profile"
                                onClick={profile_modal}
                            >
                                <i className="la la-camera"></i>
                            </button>
                        </div>
                    </div>
                    {edit_profile ? (
                        <form
                            className="row justify-content-md-center"
                            onSubmit={(e) => update_info(e)}
                            method="POST"
                        >
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                            dynamicInputChangeEdit(e)
                                        }
                                        name="name"
                                        value={edit_user.name}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                            dynamicInputChangeEdit(e)
                                        }
                                        name="last_name"
                                        value={edit_user.last_name}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Username</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                            dynamicInputChangeEdit(e)
                                        }
                                        name="username"
                                        value={edit_user.username}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        onChange={(e) =>
                                            dynamicInputChangeEdit(e)
                                        }
                                        name="email"
                                        value={edit_user.email}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Contact</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                            dynamicInputChangeEdit(e)
                                        }
                                        name="contact"
                                        value={edit_user.contact}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn btn-primary float-right ml-10"
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                    <button
                                        className="btn btn-secondary float-right"
                                        type="button"
                                        onClick={(e) => toggle_profile(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <div className="row justify-content-md-center">
                            <div className="col-md-auto text-center">
                                <a
                                    className="profile-edit-btn"
                                    onClick={(e) => toggle_profile(true)}
                                >
                                    <i className="la la-pencil"></i>
                                </a>
                                <h3>{user.name} </h3>
                                <h5 className="text-bold-500 d-none d-sm-block">
                                    ({user.username})
                                </h5>
                                <h5 className="text-bold-500 d-none d-sm-block">
                                    {user.email}
                                </h5>
                                <h5 className="text-bold-500 d-none d-sm-block">
                                    {user.contact}
                                </h5>
                            </div>
                        </div>
                    )}

                    <ProfileUpload
                        show={profile_img}
                        onHide={() => {
                            setModal('profile_picture_modal', false);
                            load_data();
                        }}
                    />
                </section>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    profile: state.profile,
});

export default connect(mapStateToProps, {
    load_data,
    profile_modal,
    setModal,
    toggle_profile,
    update_info,
    dynamicInputChangeEdit,
})(ProfileInformation);
