import React, { Fragment, useEffect, useState } from 'react';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import { customAlert } from '../../Utils/Confirm';
import {
    get_dr,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    delDr,
    setModal,
    setModalSo,
    emailConfig,
    dynamiPdf,
    sendEmailDyanmic,
    confirmDeleteDialogBox,
    autoPrint,
} from '../../redux/actions/delivery/delivery.actions';
import { useHistory } from 'react-router-dom';
import { formattedDate, numberFormat } from '../../Utils/Common';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { Show, useAccess } from 'react-redux-permission';
import DeliveryPrintModal from './modals/print.modal';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import SalesOrderPrintModal from '../sales_order/modals/SalesOrder.modal.print';
import moment from 'moment';

function DeliveryTable({
    delivery: {
        search,
        dr_data,
        rows_to_delete,
        pagination,
        sort_order_name,
        sort_order_direction,
        dr_print_modal,
        print_modal_si,
        loading,
        edit_url,
        edit_url_so,
    },
    get_dr,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    delDr,
    setModal,
    setModalSo,
    handleHover,
    hoverIndex,
    updateMUISetting,
    pageOrigin,
    emailConfig,
    setMUIPage,
    muiPage,
    sendEmailDyanmic,
    dynamiPdf,
    confirmDeleteDialogBox,
    autoPrint,
}) {
    const { hasPermission } = useAccess();
    const canCreate = hasPermission('inv.delivery.receipts.create');
    const canDelete = hasPermission('inv.delivery.receipts.delete');
    const canEdit = hasPermission('inv.delivery.receipts.edit');
    const canview = hasPermission('inv.delivery.receipts.view');
    const canPrint = hasPermission('inv.delivery.receipts.print');

    useEffect(() => {
        get_dr(muiPage, null, pageOrigin);
        emailConfig();
        autoPrint();
    }, []);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        if (debouncedSearch) {
            if (oldDebounced !== debouncedSearch[0]) {
                if (debouncedSearch[0].length !== 1) {
                    setOldDebounced(debouncedSearch[0]);
                    get_dr(1, null, pageOrigin);
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH
    // console.log("dr_data", dr_data);
    const history = useHistory();
    const btnDelete = (id, lot_no) => {
        var message = 'You are about to remove ' + lot_no + '. Continue?';
        customAlert('Are you sure?', message, function () {
            // deleteReceving(id);
            delDr(id);
        });
    };
    const changePage = (link) => {
        history.push(link);
    };

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(4)': {
                            height: '61px',
                        },
                        '&:nth-child(5)': {
                            width: '200px',
                        },
                        '&:nth-child(6)': {
                            width: '50px',
                        },
                        '&:nth-child(7)': {
                            width: '50px',
                        },
                        '&:nth-child(10)': {
                            width: '200px',
                        },
                    },
                },
                MUIDataTableHeadCell: {},
            },
        });

    const columns = [
        {
            name: '_id',
            label: '',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: 'delivery_receipt_no',
            label: 'Delivery Receipt No.',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = dr_data[tableMeta['rowIndex']]._id;
                    // setModal('dr_print_modal', true, dr_data[cellMeta.dataIndex]._id)
                    return (
                        <Link
                            className="txt-underline"
                            onClick={() => setModal('dr_print_modal', true, id)}
                        >
                            {value}
                        </Link>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
                setCellHeaderProps: (value) => {
                    return { className: 'text-center' };
                },
            },
        },
        {
            name: 'created_at',
            label: 'Date Created',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span> {moment(value).format('YYYY-MM-DD')} </span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
            },
        },
        {
            name: 'customer',
            label: 'Customer',
            options: {
                display: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = dr_data[tableMeta['rowIndex']].customer_id;
                    if (value === null) return '';
                    return (
                        <Link
                            className="txt-underline"
                            to={`/sales/customers/history/${id}`}
                        >
                            {value}
                        </Link>
                    );
                },

                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-left' };
                },
            },
        },
        {
            // name: "address",
            name: 'customer_address',
            label: 'Address',
            options: {
                display: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{value}</span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-left' };
                },
            },
        },
        {
            name: '_total_items',
            label: 'Items',
            options: {
                display: true,
                sort: false,
                setCellProps: (value) => {
                    return { className: 'text-center' };
                },
                customBodyRender: (value) => {
                    return (
                        <div className="text-center">
                            {numberFormat(value, 0)}{' '}
                        </div>
                    );
                },
            },
        },
        {
            name: '_total_qty',
            label: 'Total Quantity',
            options: {
                display: true,
                sort: false,
                setCellProps: (value) => {
                    return { className: 'text-center' };
                },
                customBodyRender: (value) => {
                    return (
                        <div className="text-center">
                            {numberFormat(value, 0)}{' '}
                        </div>
                    );
                },
            },
        },
        // {
        //   name: "delivery_date",
        //   label: "Received Date",
        //   options: {
        //     display: true,
        //     setCellProps: (value) => {
        //       return { className: "cursor-pointer" };
        //     },
        //     setCellHeaderProps: (value) => {
        //       return { className: "text-center" };
        //     },
        //   },
        // },
        {
            name: 'sales_order_no',
            label: 'Sales Order',
            options: {
                display: true,
                customBodyRender: (value, tableMeta) => {
                    let data = dr_data[tableMeta['rowIndex']];
                    // Check if data and sales_order_id exist
                    if (!data || !data.sales_order_id) {
                        return <span>N/A</span>;
                    }
                    return (
                        // <div className="money-cell">₱ {value && numberFormat(value)}</div>
                        // <Link
                        // to={`/sales/sales-orders/edit/${data.sales_order_id}`}
                        // className="txt-underline"
                        // >
                        //     {value}
                        // </Link>

                        <Link
                            className="txt-underline"
                            onClick={() =>
                                setModalSo(
                                    'print_modal_si',
                                    data.sales_order_id,
                                    true
                                )
                            }
                        >
                            {value}
                        </Link>
                    );
                },
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                display: true,
                filter: true,
                customBodyRender: (value) => {
                    return (
                        <label
                            className={'parent-center lbl-status lbl-' + value}
                        >
                            {value}
                        </label>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
                setCellHeaderProps: (value) => {
                    return { className: 'text-center' };
                },
            },
        },
        {
            name: '',
            label: 'Actions',
            options: {
                display: true,
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = dr_data[tableMeta['rowIndex']]._id;
                    return (
                        <div style={{ width: '150px' }}>
                            {(hoverIndex == tableMeta.rowIndex ||
                                dr_data[tableMeta['rowIndex']].sending_email ||
                                dr_data[tableMeta['rowIndex']]
                                    .download_pdf) && (
                                <>
                                    <Show when="inv.delivery.receipts.edit">
                                        <Link
                                            className="btn btn-sm btn-grey btn-sm"
                                            to={'/deliveries/edit/' + id}
                                        >
                                            {' '}
                                            <i className="ft ft-edit"></i>{' '}
                                        </Link>
                                    </Show>
                                    <button
                                        className="btn btn-sm btn-blue"
                                        onClick={(e) =>
                                            sendEmailDyanmic(
                                                e,
                                                id,
                                                tableMeta.rowIndex
                                            )
                                        }
                                    >
                                        <i
                                            className={
                                                dr_data[tableMeta['rowIndex']]
                                                    .sending_email
                                                    ? 'icon-loading-custom icon-15'
                                                    : dr_data[
                                                          tableMeta['rowIndex']
                                                      ].email_sent
                                                    ? 'custom-resend'
                                                    : 'ft ft-navigation'
                                            }
                                        ></i>
                                    </button>
                                    <button
                                        className="btn btn-sm btn-danger"
                                        onClick={(e) =>
                                            dynamiPdf(e, id, tableMeta.rowIndex)
                                        }
                                    >
                                        <i
                                            className={
                                                dr_data[tableMeta['rowIndex']]
                                                    .download_pdf
                                                    ? 'icon-loading-custom icon-15'
                                                    : 'ft-custom-pdf'
                                            }
                                        ></i>
                                    </button>
                                </>
                            )}
                        </div>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'text-left' };
                },
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        search: false,
        searchOpen: true,
        filter: false,
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `deliveries_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            get_dr(null, numberOfRows, pageOrigin);
            updateMUISetting('rows', 'delivery', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    get_dr(
                        tableState.page + 1,
                        pagination.itemsCountPerPage,
                        pageOrigin
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction, pageOrigin);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'delivery',
                        null,
                        tableState.columns
                    );
                    break;
                default:
                // console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if (canDelete) {
                confirmDeleteDialogBox(pageOrigin);
            }
            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // redirect to edit page
            // changePage(`${edit_url}/${rowData[0]}`)
        },
        onCellClick: (colData, cellMeta) => {
            if (cellMeta.colIndex != 5) {
                if (!canPrint) {
                    return false;
                }
                // changePage('/deliveries/edit/' + dr_data[cellMeta.dataIndex]._id);
                // setModal('dr_print_modal', true, dr_data[cellMeta.dataIndex]._id)
            }
        },
        onFilterChange: (column, filterList, type) => {
            console.log(column, filterList, type);
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        onKeyUp={(e) => handleSearchInputMUI(e, pageOrigin)}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };

    if (!canDelete) {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting(
        'view-columns',
        'delivery',
        null,
        columns
    );

    return (
        <>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={''}
                    data={dr_data}
                    columns={_columns}
                    options={options}
                />
            </MuiThemeProvider>
            <SalesOrderPrintModal
                show={print_modal_si}
                onHide={() => setModalSo('print_modal_si', null, false)}
                edit_url={edit_url_so}
            />
            <DeliveryPrintModal
                show={dr_print_modal}
                onHide={() => setModal('dr_print_modal', false, null)}
                edit_url={edit_url}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    delivery: state.delivery,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    get_dr,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    delDr,
    setModal,
    setModalSo,
    handleHover,
    updateMUISetting,
    emailConfig,
    setMUIPage,
    dynamiPdf,
    sendEmailDyanmic,
    confirmDeleteDialogBox,
    autoPrint,
})(DeliveryTable);
