import React, { Fragment } from 'react';
import { MiniSpinner } from '../../../layouts/Spinner';
import { Modal, Button } from 'react-bootstrap';
import { NumberFormat } from '../../../../Services/_numberformat.service';
import { connect } from 'react-redux';
import { useAccess } from 'react-redux-permission';

function UserLogViewModal({
    single_user_log: {
        log_event,
        log_table,
        log_old_values,
        log_new_values,
        log_ip_address,
        created_at,
        new_items,
        old_items,
        user_details,
    },
    show,
    onHide,
    mini_loading,
}) {
    const { hasPermission } = useAccess();
    const canViewCost = hasPermission('inv.receiving.itemReceipts.cost');

    //** Apply bold to updated field value */
    const applyBold = (element, updated) =>
        updated ? <strong>{element}</strong> : element;

    //** for items */
    function renderIfNotNull(value, label, oldValue, discountType = '') {
        if (value !== 0 && value !== null && value !== undefined) {
            const updated = oldValue !== undefined && oldValue !== value;

            // Blur cost and amount fields if the user doesn't have permission
            if (label === 'Cost' || label === 'Amount') {
                return (
                    <div className="mb-1">
                        <strong>{label}:</strong>{' '}
                        <span
                            style={{
                                filter: !canViewCost ? 'blur(4px)' : 'none',
                                userSelect: !canViewCost ? 'none' : 'auto',
                            }}
                        >
                            {applyBold(NumberFormat(value), updated)}
                        </span>
                    </div>
                );
            }

            if (label === 'Discount Amount') {
                return (
                    <div className="mb-1">
                        <strong>{label}:</strong>{' '}
                        <span className="text-danger">
                            -{applyBold(NumberFormat(value), updated)}
                        </span>
                    </div>
                );
            } else if (label === 'SKU') {
                return (
                    <div className="mb-1">
                        <strong>{label}:</strong> {applyBold(value, updated)}
                    </div>
                );
            } else if (label === 'Discount') {
                const discountText =
                    discountType === 'amount'
                        ? `${discountType}`
                        : `${discountType} (${NumberFormat(value)})`;
                return (
                    <div className="mb-1">
                        <strong>{label} type:</strong>{' '}
                        {applyBold(discountText, updated)}
                    </div>
                );
            } else {
                return (
                    <div className="mb-1">
                        <strong>{label}:</strong>{' '}
                        {applyBold(NumberFormat(value), updated)}
                    </div>
                );
            }
        }
        return null;
    }

    //** helper function to check values of Array/Object */
    const deepEqual = (a, b) => {
        if (a === b) return true;

        if (
            a == null ||
            typeof a != 'object' ||
            b == null ||
            typeof b != 'object'
        )
            return false;

        let keysA = Object.keys(a),
            keysB = Object.keys(b);

        if (keysA.length != keysB.length) return false;

        for (let key of keysA) {
            if (!keysB.includes(key) || !deepEqual(a[key], b[key]))
                return false;
        }

        return true;
    };

    //** compare old and new values */
    const renderValue = (key, value, oldValue) => {
        if (value == null || value === '') return '';

        const updated = oldValue !== undefined && !deepEqual(oldValue, value);

        // Check if the key should be blurred when cost view is not allowed
        if (
            ['cost', 'amount', 'sub_total', 'total', 'total_cost'].includes(key)
        ) {
            return (
                <span
                    style={{
                        filter: !canViewCost ? 'blur(4px)' : 'none',
                        userSelect: !canViewCost ? 'none' : 'auto',
                    }}
                >
                    {applyBold(NumberFormat(value), updated)}
                </span>
            );
        }

        if (Array.isArray(value)) {
            if (typeof value[0] === 'object') {
                return applyBold(
                    value.map((item, index) => (
                        <span key={index}>
                            {item.label}
                            {index !== value.length - 1 ? ', ' : ''}
                        </span>
                    )),
                    updated
                );
            }
            return applyBold(value.join(', '), updated);
        }

        if (typeof value === 'object') {
            return applyBold(value.label, updated);
        }

        // Handle specific keys
        if (
            key === 'country' ||
            key === 'payment_terms' ||
            key === 'payment_mode' ||
            key === 'discount_type' ||
            key === 'tax_type' ||
            key === 'receipt_type' ||
            key === 'unit_option_value' ||
            key === 'unit_property_option_value'
        ) {
            return value === null || value === ''
                ? ''
                : applyBold(value.label, updated);
        }

        if (key === 'attributes' || key === 'choices') {
            return value.map((attr, index) => (
                <span key={index}>
                    {applyBold(attr.label, updated)}
                    {index !== value.length - 1 ? ', ' : ''}
                </span>
            ));
        }

        // Default rendering for other keys
        if (
            key === 'amount' ||
            key === 'srp' ||
            key === 'cost' ||
            key === 'sub_total' ||
            key === 'total' ||
            key === 'mute_stock' ||
            key === 'mute_threshold'
        ) {
            return applyBold(NumberFormat(value), updated);
        }
        return applyBold(value, updated);
    };

    return (
        <Modal
            dialogClassName={
                log_event == 'create' || log_event == 'delete'
                    ? 'log-show-modal__create '
                    : 'log-show-modal__update '
            }
            show={show}
            onHide={onHide}
            animation={true}
        >
            <Modal.Header closeButton>
                {' '}
                <Modal.Title>Logs Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {mini_loading ? (
                    <MiniSpinner />
                ) : (
                    <Fragment>
                        <section className="row mb-2">
                            <div className="col-sm-12">
                                <div className="mb-1">
                                    <span>
                                        <strong>Log Event: </strong>
                                    </span>
                                    {!log_event
                                        ? null
                                        : log_event == 'delete'
                                        ? 'DELETE'
                                        : log_event.toUpperCase()}
                                </div>
                                <div className="mb-1">
                                    <span>
                                        <strong>Log Menu Tab: </strong>
                                    </span>
                                    {log_table
                                        ? log_table.charAt(0).toUpperCase() +
                                          log_table
                                              .slice(1)
                                              .replace(/_/g, ' ')
                                              .toUpperCase()
                                        : null}
                                </div>
                                <div className="mb-1">
                                    <span>
                                        <strong>Log IP Address: </strong>
                                    </span>
                                    {log_ip_address}
                                </div>
                                <div className="mb-1">
                                    <span>
                                        <strong>Created by: </strong>
                                    </span>{' '}
                                    {!user_details
                                        ? null
                                        : user_details.admin_name}{' '}
                                </div>
                                <div className="mb-1">
                                    <span>
                                        <strong>Created at: </strong>
                                    </span>
                                    {created_at}
                                </div>
                            </div>
                        </section>
                        <section className="row">
                            {(log_event == 'create' ||
                                log_event == 'update') && (
                                <div
                                    className={
                                        log_event === 'create'
                                            ? 'col-sm-12'
                                            : 'col-sm-6'
                                    }
                                >
                                    <div className="card card-body border-top-primary border-top-5 border-primary">
                                        <h4 className="card-title">
                                            LOG NEW VALUES{' '}
                                        </h4>
                                        {log_new_values && (
                                            <div className="log-show-modal__body">
                                                {log_new_values.map((log) => {
                                                    const logKeys =
                                                        Object.keys(log);
                                                    const setsOfKeys = [];

                                                    // Determine the slice size based on the log_event
                                                    let sliceSize =
                                                        log_event === 'create'
                                                            ? 7
                                                            : 15;

                                                    // Split the keys into sets based on the sliceSize
                                                    for (
                                                        let i = 0;
                                                        i < logKeys.length;
                                                        i += sliceSize
                                                    ) {
                                                        setsOfKeys.push(
                                                            logKeys.slice(
                                                                i,
                                                                i + sliceSize
                                                            )
                                                        );
                                                    }

                                                    // Return each set as a separate column
                                                    return setsOfKeys.map(
                                                        (set) => (
                                                            <div
                                                                style={{
                                                                    flex: '1',
                                                                }}
                                                            >
                                                                {set.map(
                                                                    (key) => (
                                                                        <div className="mb-1">
                                                                            {/* label output key of object*/}
                                                                            <strong>
                                                                                {key
                                                                                    .charAt(
                                                                                        0
                                                                                    )
                                                                                    .toUpperCase() +
                                                                                    key
                                                                                        .slice(
                                                                                            1
                                                                                        )
                                                                                        .replace(
                                                                                            /_/g,
                                                                                            ' '
                                                                                        )}{' '}
                                                                                :{' '}
                                                                            </strong>

                                                                            {/* output the value */}
                                                                            {renderValue(
                                                                                key,
                                                                                log[
                                                                                    key
                                                                                ],
                                                                                log_old_values[0]
                                                                                    ? log_old_values[0][
                                                                                          key
                                                                                      ]
                                                                                    : undefined
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        )
                                                    );
                                                })}
                                            </div>
                                        )}

                                        {new_items && (
                                            <div>
                                                {' '}
                                                <hr className="border-primary" />{' '}
                                                <div className="font-medium-3 text-bold-600 mb-2">
                                                    <i className="ft ft-list"></i>{' '}
                                                    ITEMS:{' '}
                                                </div>{' '}
                                            </div>
                                        )}

                                        <div className="item-card">
                                            {new_items &&
                                                new_items.map((item, index) => {
                                                    const oldItem = old_items
                                                        ? old_items[index]
                                                        : undefined; // Get the corresponding old item
                                                    return (
                                                        <div
                                                            className={`list-group-item list-group-item-action flex-column align-items-start text-secondary ${
                                                                new_items.length >
                                                                1
                                                                    ? 'item-fields'
                                                                    : 'item-column-full'
                                                            }`}
                                                        >
                                                            {renderIfNotNull(
                                                                item.sku,
                                                                'SKU',
                                                                oldItem
                                                                    ? oldItem.sku
                                                                    : undefined
                                                            )}
                                                            {renderIfNotNull(
                                                                item.product_name,
                                                                'Product',
                                                                oldItem
                                                                    ? oldItem.product_name
                                                                    : undefined
                                                            )}
                                                            {renderIfNotNull(
                                                                item.qty,
                                                                'Quantity',
                                                                oldItem
                                                                    ? oldItem.qty
                                                                    : undefined
                                                            )}
                                                            {renderIfNotNull(
                                                                item.order_qty,
                                                                'Order Quantity',
                                                                oldItem
                                                                    ? oldItem.order_qty
                                                                    : undefined
                                                            )}
                                                            {renderIfNotNull(
                                                                item.received_qty,
                                                                'Received Quantity',
                                                                oldItem
                                                                    ? oldItem.received_qty
                                                                    : undefined
                                                            )}
                                                            {renderIfNotNull(
                                                                item.cost,
                                                                'Cost',
                                                                oldItem
                                                                    ? oldItem.cost
                                                                    : undefined
                                                            )}
                                                            {renderIfNotNull(
                                                                item.srp,
                                                                'SRP',
                                                                oldItem
                                                                    ? oldItem.srp
                                                                    : undefined
                                                            )}
                                                            {renderIfNotNull(
                                                                item.discount,
                                                                `Discount`,
                                                                oldItem
                                                                    ? oldItem.discount
                                                                    : undefined,
                                                                item.discount_type
                                                                    ? item
                                                                          .discount_type
                                                                          .value
                                                                    : ''
                                                            )}
                                                            {renderIfNotNull(
                                                                item.discount_amount,
                                                                'Discount Amount',
                                                                oldItem
                                                                    ? oldItem.discount_amount
                                                                    : undefined
                                                            )}
                                                            {renderIfNotNull(
                                                                item.amount,
                                                                'Amount',
                                                                oldItem
                                                                    ? oldItem.amount
                                                                    : undefined
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        {new_items && (
                                            <div className="mt-1">
                                                {' '}
                                                <div>
                                                    <strong>
                                                        Total Items:{' '}
                                                    </strong>
                                                    {new_items.length}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {(log_event == 'cancelled' ||
                                log_event == 'delete') && (
                                <div
                                    className={
                                        log_event === 'delete' || 'cancelled'
                                            ? 'col-sm-12'
                                            : 'col-sm-6'
                                    }
                                >
                                    <div className="card card-body border-top-danger border-top-5 border-danger">
                                        <h4 className="card-title">
                                            LOG OLD VALUES{' '}
                                        </h4>
                                        {log_old_values && (
                                            <div className="log-show-modal__body">
                                                {log_old_values.map((log) => {
                                                    const logKeys =
                                                        Object.keys(log);
                                                    const setsOfKeys = [];

                                                    // Split the keys into sets of 12
                                                    for (
                                                        let i = 0;
                                                        i < logKeys.length;
                                                        i += 15
                                                    ) {
                                                        setsOfKeys.push(
                                                            logKeys.slice(
                                                                i,
                                                                i + 15
                                                            )
                                                        );
                                                    }

                                                    // Return each set as a separate column
                                                    return setsOfKeys.map(
                                                        (set) => (
                                                            <div
                                                                style={{
                                                                    flex: '1',
                                                                }}
                                                            >
                                                                {set.map(
                                                                    (key) => (
                                                                        <div className="mb-1">
                                                                            {/* label output key of object*/}
                                                                            <strong>
                                                                                {key
                                                                                    .charAt(
                                                                                        0
                                                                                    )
                                                                                    .toUpperCase() +
                                                                                    key
                                                                                        .slice(
                                                                                            1
                                                                                        )
                                                                                        .replace(
                                                                                            /_/g,
                                                                                            ' '
                                                                                        )}{' '}
                                                                                :{' '}
                                                                            </strong>

                                                                            {/* output the value */}
                                                                            {renderValue(
                                                                                key,
                                                                                log[
                                                                                    key
                                                                                ],
                                                                                log_old_values[0]
                                                                                    ? log_old_values[0][
                                                                                          key
                                                                                      ]
                                                                                    : undefined
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        )
                                                    );
                                                })}
                                            </div>
                                        )}

                                        {old_items && (
                                            <div>
                                                {' '}
                                                <hr className="border-primary" />{' '}
                                                <div className="font-medium-3 text-bold-600 mb-2">
                                                    <i className="ft ft-list"></i>{' '}
                                                    ITEMS:{' '}
                                                </div>{' '}
                                            </div>
                                        )}

                                        <div className="item-card">
                                            {old_items &&
                                                old_items.map((item, index) => (
                                                    <div
                                                        className={`list-group-item list-group-item-action flex-column align-items-start text-secondary ${
                                                            old_items.length > 1
                                                                ? 'item-fields'
                                                                : 'item-column-full'
                                                        }`}
                                                    >
                                                        <div className="mb-1">
                                                            <strong>
                                                                SKU:
                                                            </strong>{' '}
                                                            {item.sku}
                                                        </div>
                                                        <div className="mb-1">
                                                            <strong>
                                                                Product:
                                                            </strong>{' '}
                                                            {item.product_name}
                                                        </div>
                                                        {renderIfNotNull(
                                                            item.qty,
                                                            'Quantity'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.order_qty,
                                                            'Order Quantity'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.received_qty,
                                                            'Received Quantity'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.cost,
                                                            'Cost'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.srp,
                                                            'SRP'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.discount,
                                                            'Discount',
                                                            undefined,
                                                            item.discount_type
                                                                ? item
                                                                      .discount_type
                                                                      .value
                                                                : ''
                                                        )}
                                                        {renderIfNotNull(
                                                            item.discount_amount,
                                                            'Discount Amount'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.amount,
                                                            'Amount'
                                                        )}
                                                    </div>
                                                ))}
                                        </div>

                                        {old_items && (
                                            <div className="mt-1">
                                                {' '}
                                                <div>
                                                    <strong>
                                                        Total Items:{' '}
                                                    </strong>
                                                    {old_items.length}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {log_event == 'update' && (
                                <div className="col-sm-6">
                                    <div className="card card-body border-top-danger border-top-5 border-danger">
                                        <h4 className="card-title">
                                            LOG OLD VALUES{' '}
                                        </h4>
                                        {log_old_values && (
                                            <div className="log-show-modal__body">
                                                {log_old_values.map((log) => {
                                                    const logKeys =
                                                        Object.keys(log);
                                                    const setsOfKeys = [];

                                                    // Split the keys into sets of 12
                                                    for (
                                                        let i = 0;
                                                        i < logKeys.length;
                                                        i += 15
                                                    ) {
                                                        setsOfKeys.push(
                                                            logKeys.slice(
                                                                i,
                                                                i + 15
                                                            )
                                                        );
                                                    }

                                                    // Return each set as a separate column
                                                    return setsOfKeys.map(
                                                        (set) => (
                                                            <div
                                                                style={{
                                                                    flex: '1',
                                                                }}
                                                            >
                                                                {set.map(
                                                                    (key) => (
                                                                        <div className="mb-1">
                                                                            {/* label output key of object*/}
                                                                            <strong>
                                                                                {key
                                                                                    .charAt(
                                                                                        0
                                                                                    )
                                                                                    .toUpperCase() +
                                                                                    key
                                                                                        .slice(
                                                                                            1
                                                                                        )
                                                                                        .replace(
                                                                                            /_/g,
                                                                                            ' '
                                                                                        )}{' '}
                                                                                :{' '}
                                                                            </strong>

                                                                            {/* output the value */}
                                                                            {renderValue(
                                                                                key,
                                                                                log[
                                                                                    key
                                                                                ],
                                                                                log_old_values[0]
                                                                                    ? log_old_values[0][
                                                                                          key
                                                                                      ]
                                                                                    : undefined
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        )
                                                    );
                                                })}
                                            </div>
                                        )}

                                        {old_items && (
                                            <div>
                                                {' '}
                                                <hr className="border-primary" />{' '}
                                                <div className="font-medium-3 text-bold-600 mb-2">
                                                    <i className="ft ft-list"></i>{' '}
                                                    ITEMS:{' '}
                                                </div>{' '}
                                            </div>
                                        )}

                                        <div className="item-card">
                                            {old_items &&
                                                old_items.map((item, index) => (
                                                    <div
                                                        className={`list-group-item list-group-item-action flex-column align-items-start text-secondary ${
                                                            old_items.length > 1
                                                                ? 'item-fields'
                                                                : 'item-column-full'
                                                        }`}
                                                    >
                                                        <div className="mb-1">
                                                            <strong>
                                                                SKU:
                                                            </strong>{' '}
                                                            {item.sku}
                                                        </div>
                                                        <div className="mb-1">
                                                            <strong>
                                                                Product:
                                                            </strong>{' '}
                                                            {item.product_name}
                                                        </div>
                                                        {renderIfNotNull(
                                                            item.qty,
                                                            'Quantity'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.order_qty,
                                                            'Order Quantity'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.received_qty,
                                                            'Received Quantity'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.cost,
                                                            'Cost'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.srp,
                                                            'SRP'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.discount,
                                                            'Discount',
                                                            undefined,
                                                            item.discount_type
                                                                ? item
                                                                      .discount_type
                                                                      .value
                                                                : ''
                                                        )}
                                                        {renderIfNotNull(
                                                            item.discount_amount,
                                                            'Discount Amount'
                                                        )}
                                                        {renderIfNotNull(
                                                            item.amount,
                                                            'Amount'
                                                        )}
                                                    </div>
                                                ))}
                                        </div>

                                        {old_items && (
                                            <div className="mt-1">
                                                {' '}
                                                <div>
                                                    <strong>
                                                        Total Items:{' '}
                                                    </strong>
                                                    {old_items.length}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </section>
                    </Fragment>
                )}
            </Modal.Body>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    single_user_log: state.user_log.single_user_log,
    mini_loading: state.user_log.mini_loading,
});

export default connect(mapStateToProps, {})(UserLogViewModal);
