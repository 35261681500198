import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    setModal,
    handleInputChange,
    handleSelectPaymentType,
    handleSelectTermsConditions,
} from '../../../redux/actions/delivery/delivery.actions';
import Select, { components } from 'react-select';

function FormTab({
    delivery: {
        si_data,
        payment_mode_options,
        isSearchable,
        terms_conditions,
        quote_validation,
    },
    handleInputChange,
    handleSelectPaymentType,
    handleSelectTermsConditions,
    setModal,
}) {
    const [activeTab, setActiveTab] = useState('terms');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // console.log('si_data', si_data);
    return (
        <section className="d-flex justify-content-between mt-2">
            <div className="card form-tab-card ">
                <div className="card-content">
                    <div className="card-body">
                        <ul className="nav nav-pills nav-pill-with-active-bordered">
                            <li className="nav-item">
                                <button
                                    type="button"
                                    className={`nav-link form-tab-link ${
                                        activeTab === 'terms' ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick('terms')}
                                    id="base-pill21"
                                    data-toggle="pill"
                                    href="#pill21"
                                    aria-expanded="true"
                                >
                                    Terms &amp; Condition
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    type="button"
                                    className={`nav-link form-tab-link ${
                                        activeTab === 'payment' ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick('payment')}
                                    id="base-pill22"
                                    data-toggle="pill"
                                    href="#pill22"
                                    aria-expanded="false"
                                >
                                    Payment Terms
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    type="button"
                                    className={`nav-link form-tab-link ${
                                        activeTab === 'internal' ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabClick('internal')}
                                    id="base-pill23"
                                    data-toggle="pill"
                                    href="#pill23"
                                    aria-expanded="false"
                                >
                                    Internal Notes
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content px-1 pt-1">
                            {activeTab === 'terms' && (
                                <div
                                    role="tabpanel"
                                    className="tab-pane active"
                                    id="pill21"
                                    aria-expanded="true"
                                    aria-labelledby="base-pill21"
                                >
                                    <>
                                        <div
                                            className="tab-pane fade show active"
                                            role="tabpanel"
                                        >
                                            <div className="form-group">
                                                <label>
                                                    Terms &amp; Condition
                                                </label>
                                                <textarea
                                                    onChange={handleInputChange}
                                                    value={
                                                        si_data.terms_and_condition
                                                    }
                                                    rows="2"
                                                    placeholder="Terms & Condition"
                                                    name="terms_and_condition"
                                                    className="form-control"
                                                    disabled
                                                ></textarea>
                                            </div>
                                        </div>{' '}
                                        <div
                                            className="tab-pane fade show active"
                                            role="tabpanel"
                                        >
                                            <div className="form-group">
                                                <label>Additonal Remarks</label>
                                                <textarea
                                                    onChange={handleInputChange}
                                                    value={si_data.remarks}
                                                    rows="2"
                                                    placeholder="remarks"
                                                    name="remarks"
                                                    className="form-control"
                                                    disabled
                                                ></textarea>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            )}

                            {activeTab === 'payment' && (
                                <div
                                    className="tab-pane active"
                                    id="pill22"
                                    aria-labelledby="base-pill22"
                                >
                                    <>
                                        {' '}
                                        <div className="row">
                                            <div className="col-sm-9">
                                                <label>Payment Mode:</label>
                                                <div className="form-group row">
                                                    <div className="col-md-10 col-10 pr-0">
                                                        <Select
                                                            options={
                                                                payment_mode_options
                                                            }
                                                            onChange={
                                                                handleSelectPaymentType
                                                            }
                                                            value={
                                                                si_data.payment_mode
                                                            }
                                                            isSearchable={
                                                                isSearchable
                                                            }
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                        <div className="input-group-prepend">
                                                            <span
                                                                href="#"
                                                                className="tool-tip text-primary"
                                                                data-tip="this is a tool tip"
                                                            >
                                                                <i className="la la-question-circle"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-9">
                                                <label>Payment Terms: </label>
                                                <div className="form-group row">
                                                    <div className="col-md-10 col-10 pr-0">
                                                        <Select
                                                            options={
                                                                terms_conditions
                                                            }
                                                            onChange={
                                                                handleSelectTermsConditions
                                                            }
                                                            value={
                                                                si_data.payment_terms
                                                            }
                                                            isSearchable={
                                                                isSearchable
                                                            }
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                    <div className="float-right pl-0 col-md-2 col-2">
                                                        <div className="input-group-prepend">
                                                            <span
                                                                href="#"
                                                                className="tool-tip text-primary"
                                                                data-tip="this is a tool tip"
                                                            >
                                                                <i className="la la-question-circle"></i>
                                                            </span>
                                                            <div
                                                                className="a-tagicon icon-s"
                                                                onClick={() =>
                                                                    setModal(
                                                                        'payment_terms_modal',
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <i className="ft ft-plus-circle text-primary"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade show active"
                                            role="tabpanel"
                                        >
                                            <div className="form-group">
                                                <label>Payment Details</label>
                                                <textarea
                                                    onChange={handleInputChange}
                                                    value={
                                                        si_data.payment_details
                                                    }
                                                    rows="2"
                                                    placeholder="Payment Details"
                                                    name="payment_details"
                                                    className="form-control"
                                                    disabled
                                                ></textarea>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            )}

                            {activeTab === 'internal' && (
                                <div
                                    className="tab-pane active"
                                    id="pill23"
                                    aria-labelledby="base-pill23"
                                >
                                    <div
                                        className="tab-pane fade show active"
                                        role="tabpanel"
                                    >
                                        <div className="form-group">
                                            <label>Internal Notes</label>
                                            <textarea
                                                onChange={handleInputChange}
                                                value={si_data.internal_notes}
                                                rows="2"
                                                placeholder="Internal Notes"
                                                name="internal_notes"
                                                className="form-control"
                                                disabled
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card  bg-light ">{''}</div>
        </section>
    );
}
const mapStateToProps = (state) => ({
    delivery: state.delivery,
});

export default connect(mapStateToProps, {
    setModal,
    handleInputChange,
    handleSelectPaymentType,
    handleSelectTermsConditions,
})(FormTab);
