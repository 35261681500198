// return the user data from the session storage
export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
};

// return the token from the session storage
export const getToken = () => {
    return localStorage.getItem('token') || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
    localStorage.setItem('token', token);
    // localStorage.setItem('user', JSON.stringify(user));
};

export const setUser = (user) => {
    localStorage.setItem('_user', JSON.stringify(user));
};

export const removeUser = () => {
    localStorage.removeItem('_user');
};

export const getUserData = () => {
    const userStr = localStorage.getItem('_user');
    if (userStr) return JSON.parse(userStr);
    else return null;
};

export const setUserPermissions = (access) => {
    localStorage.setItem('permssions', JSON.stringify(access));
};

export const setUserAccess = (access) => {
    localStorage.setItem('access', JSON.stringify(access));
};

export const getUserAccess = () => {
    // return JSON.parse(sessionStorage.getItem('access')) || null;
    const access = localStorage.getItem('access');
    if (access) return JSON.parse(access);
    else return null;
};

export const getUserPermissions = () => {
    // return JSON.parse(sessionStorage.getItem('access')) || null;
    const permssions = localStorage.getItem('permssions');
    if (permssions) return JSON.parse(permssions);
    else return null;
};

// react-select custom style mini select
export const reactSelectStyle = {
    control: (base, state) => ({
        ...base,
        height: 30,
        minHeight: 30,
    }),
    dropdownIndicator: (base) => ({
        ...base,
        padding: 2,
    }),
};

// truncate stirng greater than 50
export const truncateString = (longString) => {
    let string;

    try {
        if (longString.length > 50) {
            string = longString.substr(0, 30) + ' ...';
        } else {
            string = longString;
        }

        return string;
    } catch (error) {
        console.log(error);
    }
};

export const numberFormat = (number, decimal = 2, comma = true) => {
    if (comma) {
        return Number(parseFloat(number).toFixed(decimal)).toLocaleString(
            'en',
            {
                minimumFractionDigits: decimal,
            }
        );
    } else {
        return Number(parseFloat(number).toFixed(decimal));
    }
};

/**
 * Format a number to display as a currency
 * @param {number} price
 * @param {"code" | "symbol"} currencyDisplay - "code" or "symbol"; get from template setting
 * @param {"currency" | string} style - the style of formatting (default: "currency")
 * @param {"PHP" | string} currency - type of currency to display e.g. PHP, JPY, USD, etc.
 * @param {*} params - other options (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options)
 * @returns {string} number in currency format
 */
export const formatPrice = (
    price,
    currencyDisplay = 'code',
    style = 'currency',
    currency = 'PHP',
    params = {}
) => {
    let formatOptions = {
        style,
        currencyDisplay,
        notation: 'standard',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    };

    try {
        if (style === 'currency') {
            formatOptions['currency'] = currency;
        }

        if (params) {
            formatOptions = { ...formatOptions, ...params };
        }
        return Intl.NumberFormat('en-US', formatOptions).format(price);
    } catch (e) {
        return Intl.NumberFormat('en-US', formatOptions).format(price);
    }
};

export const dateForm = (date, string = false) => {
    if (string) {
        var dateObj = new Date(date);
        return dateObj.toString();
    }
    return new Date(date);
};

export const checkForDuplicates = (array, keyName) => {
    return new Set(array.map((item) => item[keyName])).size !== array.length;
};

export const formattedDate = () => {
    let current_datetime = new Date();
    let formatted_date =
        ('0' + (current_datetime.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + current_datetime.getDate()).slice(-2) +
        '-' +
        current_datetime.getFullYear();

    return formatted_date;
};

export const capitalize = (stringValue) => {
    if (typeof stringValue !== 'string') return '';
    return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
};

export const getFileExtension = (filename) => {
    return (
        filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
        filename
    );
};

// fileExtensionIcon display
export const fileExtensionIcon = (filename) => {
    let fileExtension = getFileExtension(filename);
    // console.log(fileExtension)
    switch (fileExtension) {
        case 'doc':
            return '/file-types/doc.png';
            break;
        case 'docx':
            return '/file-types/docx.png';
            break;
        case 'pdf':
            return '/file-types/pdf.png';
            break;
        case 'ppt':
            return '/file-types/ppt.png';
            break;
        case 'xls':
            return '/file-types/xls.png';
            break;
        case 'xlsx':
            return '/file-types/xls.png';
            break;
        case 'csv':
            return '/file-types/csv.png';
            break;
        case 'jpg':
            return '/file-types/jpg.png';
            break;
        case 'jpeg':
            return '/file-types/jpg.png';
            break;
        case 'png':
            return '/file-types/png.png';
            break;
        default:
            return '/file-types/fail.png';
            break;
    }
};

// this prevents from typing negative number, text, including "e"
export const onlyNumber = (evt) => {
    evt = evt ? evt : window.event;
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 45
    ) {
        evt.preventDefault();
    } else {
        return true;
    }
};

// get muidatatable settings
export const getMUIDataSetting = () => {
    const muisetting = localStorage.getItem('muisetting');
    if (muisetting) return JSON.parse(muisetting);
    else return null;
};

// get numrows from muisetting
export const getNumRows = (table) => {
    let { _id: user_id } = getUserData();
    let muisetting = getMUIDataSetting();

    if (muisetting !== null) {
        let checkMUI = muisetting.filter(
            (setting) => setting.user_id === user_id
        );

        if (checkMUI.length > 0) {
            let num_rows = checkMUI[0]['tables'].filter(
                (row) => row.table === table
            );
            return num_rows.length > 0 ? num_rows[0].num_rows : null;
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export const limitString = (str, count) => {
    return str.slice(0, count) + (str.length > count ? '...' : '');
};

export const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    let croppedImage = new File([u8arr], filename, { type: mime });
    return croppedImage;
};

export const imageURLtoBlob = (dataURI) => {
    // Obtain a blob: URL for the image data.
    var arrayBufferView = new Uint8Array(dataURI);
    var blob = new Blob([arrayBufferView], { type: 'image/jpeg' });
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(blob);

    return imageUrl;
};

// generate downlaod file from preview
function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
        return;
    }

    canvas.toBlob(
        (blob) => {
            const previewUrl = window.URL.createObjectURL(blob);

            const anchor = document.createElement('a');
            anchor.download = 'cropPreview.png';
            anchor.href = URL.createObjectURL(blob);
            anchor.click();

            window.URL.revokeObjectURL(previewUrl);

            console.log(canvas, crop);
            console.log(blob);
            console.log(anchor.href);
        },
        'image/png',
        1
    );
}

// Check if value return or set default value
export const checkValueIfEmpty = (data, default_value) => {
    switch (data) {
        case '':
            return default_value;
        case null:
            return default_value;
        case NaN:
            return default_value;
        default:
            if (typeof data === 'object' || typeof data === 'number') {
                return data;
            }

            return default_value;
    }
};

// get setting app setting from local storage
export const getSubSetting = () => {
    let subsetting = localStorage.getItem('_user');
    subsetting = JSON.parse(subsetting);
    subsetting = subsetting.subscriber_settings;
    // console.log("subsetting", subsetting)
    if (subsetting) return subsetting;
    else return null;
};

// store setting app data to local storage
export const setSubSetting = (subssetting) => {
    localStorage.setItem('subsetting', JSON.stringify(subssetting));
};

export const removeSubSetting = () => {
    localStorage.removeItem('subsetting');
};

export const makeRandomID = (length) => {
    var result = [];
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result.push(
            characters.charAt(Math.floor(Math.random() * charactersLength))
        );
    }
    return result.join('');
};

const SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'P', 'E'];

export const abbreviateNumber = (number) => {
    // what tier? (determines SI symbol)
    let tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return number;

    // get suffix and determine scale
    let suffix = SI_SYMBOL[tier];
    let scale = Math.pow(10, tier * 3);

    // scale the number
    let scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
};

export const stockClass = (code) => {
    let status = '';
    if (code == 1) {
        status = 'bg-warning';
    }
    if (code == 2) {
        status = 'bg-danger';
    }
    return status;
};

// set the token and user from the session storage
export const settoPrint = (print, id, target) => {
    let printer = {
        print: print, // true or false
        id: id,
    };
    localStorage.setItem(target, JSON.stringify(printer));
};
export const gettoPrint = (target) => {
    return localStorage.getItem(target) || null;
};

export const numOnly = (value) => {
    if (isNaN(parseFloat(value))) {
        return 0;
    }
    return parseFloat(value);
};

export const safeDivide = (num1, num2) => {
    try {
        let res = 0;
        if (num2 != 0) {
            res = parseFloat(num1) / parseFloat(num2);
            if (isNaN(res)) {
                res = 0;
            }
        }

        return res;
    } catch (err) {
        return 0;
    }
};

/**
 * Shorten ObjectID
 * @param {string} id
 */
export const truncateObjectId = (id) => {
    try {
        return id.slice(0, 4) + '....' + id.slice(id.length - 4);
    } catch (e) {
        console.log(e);

        return null;
    }
};

// export const discountTypeToString = (discountType) => {
//   switch (discountType) {
//     case 1:
//       return { "value": "percent", "label": "Percent" };
//     case 2:
//       return { "value": "amount", "label": "Amount" };
//     default:
//       return { "value": "none", "label": "None" };
//   }
// };
