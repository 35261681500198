import React, { useEffect, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../../layouts/Spinner';
import { Modal, Button } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { formattedDate } from '../../../../Utils/Common';
import {
    getRoles,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
} from '../../../../redux/actions/settings/roles.actions';
import { Show, useAccess } from 'react-redux-permission';
import { useDebounce } from 'use-debounce';

function Roles({
    rolesReducer: {
        pagination,
        sort_order_name,
        sort_order_direction,
        rows_to_delete,
        loading,
        edit_url,
        roles,
        search,
    },
    getRoles,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    confirmDeleteDialogBox,
}) {
    const history = useHistory();
    const { hasPermission } = useAccess();
    const canCreate = hasPermission('inv.setting.users.roles.view');
    const canDelete = hasPermission('inv.setting.users.roles.create');
    const canEdit = hasPermission('inv.setting.users.roles.edit');
    const canview = hasPermission('inv.setting.users.roles.delete');

    useEffect(() => {
        getRoles();
    }, []);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        if (debouncedSearch) {
            if (debouncedSearch[0]) {
                if (oldDebounced !== debouncedSearch[0]) {
                    if (debouncedSearch[0].length !== 1) {
                        if (canview) {
                            setOldDebounced(debouncedSearch[0]);
                            getRoles(1);
                        }
                    }
                }
            } else {
                if (oldDebounced !== '') {
                    if (canview) {
                        setOldDebounced('');
                        getRoles(1);
                    }
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    const changePage = (link) => {
        history.push(link);
    };

    // THIS WILL SET THE HEADER ALIGN STYLE
    const useStyles = makeStyles((theme) => ({
        centeredHeader: {
            '& span': {
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
            },
        },
    }));
    const classes = useStyles();

    // THIS SET THE ROW BACKGROUND COLOR
    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                // MUIDataTableHeadCell: {
                //     toolButton: {
                //       justifyContent: 'center'
                //     },
                // },
                MUIDataTableBodyCell: {},
            },
        });

    const columns = [
        {
            name: '_id',
            label: '',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
            },
        },
        { name: 'role_title', label: 'Role Name' },
        {
            name: 'role_desc',
            label: 'Description',
            options: {
                customBodyRender: (value) => {
                    return <span title={value ? value : "No description"}> {value ? value.substr(0, 30) + "..." : 'N/A'} </span>;
                },
            },
        },
        {
            name: 'created_at',
            label: 'Created at',
            options: {
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: ' cursor-pointer' };
                },
                customBodyRender: (value) => {
                    return <span> {value.substr(0, 10)} </span>;
                },
            },
        },
        {
            name: 'updated_at',
            label: 'Updated at',
            options: {
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
                customBodyRender: (value) => {
                    return <span> {value.substr(0, 10)} </span>;
                },
            },
        },
    ];

    console.log(roles);

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPageOptions: [10, 20, 50, 100],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        search: false,
        searchOpen: true,
        // selectableRows: "none",
        elevation: 2,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `roles_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getRoles(null, numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getRoles(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                default:
                    console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if (canDelete) {
                confirmDeleteDialogBox();
            }

            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // Go to edit page when row is clicked
            if (canEdit) {
                history.push(`${edit_url}/${rowData[0]}`);
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        onKeyUp={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
    };

    if (!canDelete) {
        options.selectableRows = false;
    }

    return (
        <div className="m-1">
            <div className="d-flex justify-content-between mb-2">
                <h3 className="card-title">
                    List of Roles{' '}
                    <a
                        href="#"
                        className="tool-tip"
                        data-tip="this is a tool tip"
                    >
                        <i className="la la-question-circle"></i>
                    </a>{' '}
                </h3>
                <div>
                    <Show when="inv.setting.users.roles.create">
                        <Link
                            to="/settings/users/role/create"
                            className="btn btn-primary btn-sm btn-fix-header"
                        >
                            <i className="ft ft-plus"></i> Create New
                        </Link>
                    </Show>
                </div>
            </div>

            <Show when="inv.setting.users.accounts.view">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={roles}
                        columns={columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </Show>
        </div>
    );
}

const mapStateToProps = (state) => ({
    rolesReducer: state.rolesReducer,
});

export default connect(mapStateToProps, {
    getRoles,
    handleSearchInputMUI,
    sortTableByColumn,
    getSelectedRows,
    confirmDeleteDialogBox,
})(Roles);
