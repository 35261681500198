import {
    SET_LOADING,
    SEARCH_INPUT,
    SET_PAGE,
    SET_MINI_LOADING,
    GET_SINGLE_QUOTATION,
    GET_QUOTATIONS,
    GET_QUOTATION_STATUS,
    QUOTATION_INPUT_CHANGE,
    GET_QU_CUSTOMERS,
    GET_QU_PRODUCTS,
    GET_QU_TERMS_CONDITIONS,
    CLEAR_QUOTATION,
    HANDLE_QU_SELECT_TERMS_CONDITIONS,
    HANDLE_QU_SELECT_ITEM,
    HANDLE_QU_REMOVE_ITEM,
    HANDLE_QU_ADD_ITEM,
    HANDLE_QU_SRP,
    HANDLE_QU_QTY,
    HANDLE_QU_INPUT_ATTR,
    HANDLE_QU_TOGGLE_EDIT_BTN_ATTR,
    HANDLE_QU_SELECT_CUSTOMER,
    HANDLE_QU_CHECK_VAT,
    GET_QU_SI_TEMPLATES,
    GET_QU_SO_TEMPLATES,
    HANDLE_SELECT_QU_SI_TEMPLATE,
    HANDLE_QU_SELECT_RECEIPT_TYPE,
    HANDLE_QU_TOGGLE_EDIT_ROW,
    HANDLE_QU_EDIT_INPUT_ROW,
    HANDLE_QU_EDIT_UPDATE_ROW,
    HANDLE_QU_SUBMIT_ATTR,
    HANDLE_QU_SI_PAYMENT,
    HANDLE_QU_SELECT_PAYMENT_TYPE,
    SET_PO_ITEM_ID,
    SET_MODAL,
    HANDLE_SELECT_QU_TEMPLATE,
    SET_DATE_PICKER,
    GET_QU_TEMPLATES,
    HANDLE_CHECK_MANUAL,
    SORT_BY_COLUMN,
    SET_QU_ITEM_ID,
    SET_ROW_CHECKED,
    GET_ROWS_TO_DELETE,
    POS_GET_CUSTOMERS,
    QU_COMPUTATION_HANDLER,
    ITEMS_TEMPLATE,
    ADD_ITEM,
    TOGGLE_DISCOUNT_ROW,
    SET_LAST_ADDED_DROPDOWN_INDEX,
    SET_ITEM,
    QUOTATION_CLEAR_UPLOAD,
    QU_SELECT_TAX_TYPE,
    SEND_EMAIL,
    DYNAMIC_SEND_EMAIL,
    RESEND_EMAIL,
    DOWNLOAD_BTN,
    EMAIL_SETTING_DATA,
    CLEAR_DATA,
    BTN_FORM,
    GET_STATUS,
    GET_QU_VALIDATION,
    HANDLE_QU_SELECT_VALIDATION,
    CLEAR_QU_VALIDATION,
    QU_VALIDATION_INPUT_CHANGE,
} from './../../types';
import {
    ToastSuccess,
    ToastDanger,
    ToastWarning,
} from '../../../Services/_toast.service';
import $ from 'jquery';
import { QuotationService } from './_service.quotation';
import {
    SwalWarning,
    SwalError,
    SwalLoading,
    SwalSuccessAutoRemove,
    SwallverfiyPass,
    SwalWarningHtml,
} from '../../../Services/_swal.service';
import { blockRoute, setMUIPage } from '../helper/helper.actions';
import {
    getNumRows,
    getSubSetting,
    getUserData,
    settoPrint,
} from '../../../Utils/Common';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { getSettingApp } from '../settings_app/setting_app.actions';
import { ConsoleView } from 'react-device-detect';
import {
    getSingleSalesOrder,
    soClearUpload,
} from './../sales_order/sales_order.actions';

// DISCOUNT
export const handleSelectDiscountType = (selectOption) => async (dispatch) => {
    dispatch({
        type: QUOTATION_INPUT_CHANGE,
        payload: { key: 'discount', value: 0 },
    });
    dispatch({
        type: QUOTATION_INPUT_CHANGE,
        payload: { key: 'discount_type', value: selectOption },
    });
};

export const handleChangeStatusView =
    (status_view) => async (dispatch, getState) => {
        dispatch({ type: GET_STATUS, payload: status_view });

        let { search, quotations } = getState().quotation;

        if (
            typeof quotations[status_view] === 'undefined' ||
            quotations[status_view] == null
        ) {
            dispatch({ type: SEARCH_INPUT, payload: '' });
            dispatch(getQuotations(1));
        } else {
            dispatch({
                type: SEARCH_INPUT,
                payload: quotations[status_view].search,
            });
            dispatch({
                type: GET_QUOTATIONS,
                payload: {
                    quotations: quotations,
                    pagination: quotations[status_view],
                },
            });
        }
    };

export const handleSelectDiscountTypeProduct =
    (selectOption, index) => async (dispatch, getState) => {
        let { single_quotation } = getState().quotation;
        let items = { ...single_quotation.quotation_items[index] };

        // Reset discount amount when changing discount type to 'amount'
        if (selectOption.value === 'amount' || 'percent' || 'none') {
            items.discount_amount = 0;
            items.amount = items.srp * items.qty;
        }

        items.discount_type = selectOption;
        items.discount = 0;

        single_quotation.quotation_items[index] = items;
        dispatch({ type: ADD_ITEM, payload: single_quotation.quotation_items });
    };

export const handleInputChangeDiscount = (e) => async (dispatch, getState) => {
    let { single_quotation } = getState().quotation;

    if (single_quotation.discount_type.value == 'percent') {
        if (e.target.value > 100) {
            ToastDanger(
                'If your discount is percentage, discount should not be over 100'
            );
            dispatch({
                type: QUOTATION_INPUT_CHANGE,
                payload: { key: e.target.name, value: 100 },
            });
        } else if (e.target.value < 0) {
            ToastDanger('Discount should not be less than zero');
            dispatch({
                type: QUOTATION_INPUT_CHANGE,
                payload: { key: e.target.name, value: 0 },
            });
        } else {
            dispatch({
                type: QUOTATION_INPUT_CHANGE,
                payload: { key: e.target.name, value: e.target.value },
            });
        }
    } else {
        if (e.target.value < 0) {
            ToastDanger('Discount should not be less than zero');
            dispatch({
                type: QUOTATION_INPUT_CHANGE,
                payload: { key: e.target.name, value: 0 },
            });
        } else {
            dispatch({
                type: QUOTATION_INPUT_CHANGE,
                payload: { key: e.target.name, value: e.target.value },
            });
        }
    }
};

export const handleDiscountItem =
    (index, remove = false) =>
    async (dispatch, getState) => {
        let { single_quotation } = getState().quotation;
        let items = { ...single_quotation.quotation_items[index] };

        if (!items.product_name) {
            ToastDanger('Please add Product item at least one.');
            return;
        }

        if (remove) {
            items['discount'] = 0;
            items['discount_type'] = { value: 'none', label: 'None' };
            items['discount_amount'] = 0;
            items['amount'] = items.srp * items.qty;
        }

        single_quotation.quotation_items[index] = items;

        dispatch({
            type: ADD_ITEM,
            payload: single_quotation.quotation_items,
        });

        dispatch({
            type: TOGGLE_DISCOUNT_ROW,
            payload: { index, remove },
        });
    };

export const handleInputDiscountProduct =
    (e, index) => async (dispatch, getState) => {
        let { name, value } = e.target;
        value = parseFloat(value);
        let { single_quotation } = getState().quotation;
        let items = { ...single_quotation.quotation_items[index] };

        // // Save original amount if not already saved
        // if (!items.hasOwnProperty('original_amount')) {
        //   items['original_amount'] = items.srp;
        // }

        let discountedAmount = 0;
        let discountAmount = 0;

        items.amount = items.srp * items.qty;

        if (value === '0') {
        } else if (items.discount_type.value === 'percent') {
            discountAmount = (value / 100) * items.amount;
            discountedAmount = items.amount - discountAmount;
            items.amount = discountedAmount;
        } else if (items.discount_type.value === 'amount') {
            discountAmount = value;
            discountedAmount = items.amount - value;
            items.amount = discountedAmount;
        }

        items[name] = parseFloat(value);
        items['discount_amount'] = discountAmount ? discountAmount : 0;

        single_quotation.quotation_items[index] = items;
        dispatch({ type: ADD_ITEM, payload: single_quotation.quotation_items });
    };

// handle inputs
export const handleInputChange = (e) => async (dispatch) => {
    if (e.target.value !== '') {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    let name = e.target.name;
    let value = e.target.value;

    dispatch({
        type: QUOTATION_INPUT_CHANGE,
        payload: { key: name, value: value },
    });
};

// // handle edit inputs
export const handleSearchInput = (e) => async (dispatch) => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value });
    dispatch(getQuotations(1));
};

// loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: SET_LOADING, payload: status });
export const setMiniLoading = (status) => async (dispatch) =>
    dispatch({ type: SET_MINI_LOADING, payload: status });

// clear data
export const clearQuotation = () => async (dispatch) =>
    dispatch({ type: CLEAR_QUOTATION });

// set modal
export const setPage =
    (page, id, status = true) =>
    async (dispatch, getState) => {
        let { edit_url } = getState().quotation;

        switch (page) {
            case 'main_page':
                dispatch({ type: SET_PAGE, payload: { page, status } });
                dispatch(clearQuotation());
                break;
            case 'edit_page':
                let edit_url_with_id = `${edit_url}/${id}`;
                dispatch({
                    type: SET_PAGE,
                    payload: { page, status, edit_url: edit_url_with_id },
                });
                break;
            case 'delete_modal':
                dispatch(getSingleQuotation(id, 'delete'));
                break;
            case 'print_modal_qu':
                if (status) {
                    dispatch(getSingleQuotation(id));
                } else {
                    dispatch(quClearUpload());
                }

                dispatch({ type: SET_PAGE, payload: { page, status } });
            default:
                dispatch({ type: SET_PAGE, payload: { page, status } });
        }
    };

export const setModalSo = (modal, id, status) => async (dispatch) => {
    dispatch(getSingleSalesOrder(id));
    dispatch({ type: SET_MODAL, payload: { modal: modal, status: status } });
    settoPrint(false, null, 'Sales Order');
    if (status === false) {
        dispatch(soClearUpload());
        // dispatch(clearDelivery());
    }
};

export const quClearUpload = () => async (dispatch) =>
    dispatch({ type: QUOTATION_CLEAR_UPLOAD });

// create selectOptions use in suppliers
const createSelectOption = (lists) => {
    let selectOptions = [];

    lists.map((val, i) => {
        let select = { value: val._id, label: val.name, i: i };

        selectOptions[i] = select;
    });

    return selectOptions;
};

export const handleSelectCustomer =
    (selectOption) => async (dispatch, getState) => {
        if (selectOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        QuotationService.getSingleCustomer(selectOption.value)
            .then((res) => {
                let default_customer_value = {
                    value: res.data.customer._id,
                    label: res.data.customer.name,
                    data: res.data.customer,
                };

                // console.log('default', default_customer_value);

                dispatch({
                    type: HANDLE_QU_SELECT_CUSTOMER,
                    payload: {
                        customer_id: selectOption.value,
                        customer: res.data.customer,
                        default_customer_value: default_customer_value,
                    },
                });
                let { item_template } = getState().quotation;
                let items_data = [item_template];
                dispatch({ type: ADD_ITEM, payload: items_data });
            })
            .catch((error) => {
                // ToastDanger("Network error, please reload the page.");
                console.log(error);
            });
    };

// handle select tems and conditions
export const handleSelectTermsConditions =
    (selectOption) => async (dispatch) => {
        dispatch({
            type: HANDLE_QU_SELECT_TERMS_CONDITIONS,
            payload: selectOption,
        });
    };

// handle Select Item
export const handleSelectItem =
    (selectOption, index) => async (dispatch, getState) => {
        let { single_quotation } = getState().quotation;
        let items = { ...single_quotation.quotation_items[index] };

        // Check if the product is already selected in other rows
        const selectedProduct = single_quotation.quotation_items.some(
            (item, i) => i !== index && item.product_id === selectOption._id
        );

        // console.log("selectOption", selectOption)
        if (selectedProduct) {
            ToastDanger('Product is already added on the list');
            // Remove the row
            dispatch(handleRemoveItem(index));
        } else if (selectOption.instock === false) {
            ToastWarning('Product is out of stock');
            // Remove the row
            dispatch(handleRemoveItem(index));
        } else {
            items['_id'] = selectOption._id;
            items['product_id'] = selectOption._id;
            items['sku'] = selectOption.sku;
            items['product_name'] = selectOption.product_name;
            items['qty'] = parseInt(selectOption.qty);
            items['product_unit_id'] = selectOption.product_unit_id;
            items['product_unit_name'] = selectOption.product_unit_name;
            items['attribute_string'] = selectOption.attribute_string;
            items['srp'] = parseFloat(selectOption.srp);
            items['amount'] = parseFloat(selectOption.amount);
            items['status_code'] = selectOption.status_code;
            items['barcode'] = selectOption.barcode;
            items['description'] = selectOption.description;
            items['selected'] = true;
            items['brand'] = selectOption.brand;
            items['brand_id'] = selectOption.brand_id;
            items['category_id'] = selectOption.category_id;
            items['category'] = selectOption.category;
            items['discount_type'] = { label: 'None', value: 'none' };
            items['discount'] = 0;
            items['discount_amount'] = 0;

            single_quotation.quotation_items[index] = items;
            dispatch({
                type: ADD_ITEM,
                payload: single_quotation.quotation_items,
            });
        }
    };

export const ShowComboBox = (index) => async (dispatch, getState) => {
    let { single_quotation } = getState().quotation;
    let items = { ...single_quotation.quotation_items[index] };
    items['selected'] = false;
    single_quotation.quotation_items[index] = items;
    dispatch({ type: ADD_ITEM, payload: single_quotation.quotation_items });
};

// handle Add Item
export const handleAddItem = (e) => async (dispatch, getState) => {
    e.preventDefault();

    let { itemSelected, single_quotation } = getState().quotation;

    const duplicate = single_quotation.quotation_items.filter(
        (item) => item._id == itemSelected._id
    );

    if (duplicate.length > 0) {
        ToastDanger(`${duplicate[0].product_name} is already on the list.`);
    } else if (itemSelected.product_name == '') {
        ToastDanger('Product Item is required, please select at least one');
    } else if (itemSelected.qty == 0 || isNaN(itemSelected.qty)) {
        ToastDanger('Quantity field is required and cannot be a string value');
    } else if (itemSelected.srp == 0 || isNaN(itemSelected.srp)) {
        ToastDanger('Srp is required and cannot be a string value');
    } else {
        let quotation_items =
            single_quotation.quotation_items.concat(itemSelected);

        dispatch({
            type: HANDLE_QU_ADD_ITEM,
            payload: { quotation_items: quotation_items },
        });
    }
};

// Handle remove Item
export const handleRemoveItem = (index) => async (dispatch, getState) => {
    let { quotation_items } = getState().quotation.single_quotation;
    // removing value in array base on index
    let _quotation_items = quotation_items.filter((_, i) => i !== index);

    dispatch({
        type: HANDLE_QU_REMOVE_ITEM,
        payload: { quotation_items: _quotation_items },
    });
};
// handle Srp
export const handleInputSrp = (e) => async (dispatch, getState) => {
    e.preventDefault();

    let { itemSelected } = getState().quotation;

    let srp = parseFloat(e.target.value);
    let amount = itemSelected.qty * srp;

    dispatch({ type: HANDLE_QU_SRP, payload: { srp: srp, amount: amount } });
};

// handle Qty
export const handleInputQty = (e) => async (dispatch, getState) => {
    e.preventDefault();
    let { itemSelected } = getState().quotation;
    let multipliedPrice = e.target.value * itemSelected.srp;

    dispatch({
        type: HANDLE_QU_QTY,
        payload: { qty: e.target.value, amount: parseFloat(multipliedPrice) },
    });
};

// handle discount, other
export const handleInputAttr = (e) => async (dispatch) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == 'other') {
        dispatch({
            type: HANDLE_QU_INPUT_ATTR,
            payload: { key: e.target.name, value: parseFloat(value) },
        });
    } else {
        //console.log(e.target.name, e.target.value);
        dispatch({
            type: HANDLE_QU_INPUT_ATTR,
            payload: { key: e.target.name, value: parseInt(value) },
        });
    }
};

// handle toggle discount, other
export const toggleEditBtn = (status, attr) => async (dispatch) => {
    dispatch({
        type: HANDLE_QU_TOGGLE_EDIT_BTN_ATTR,
        payload: { attr: attr, status: status },
    });
};

// handle discount, other submit
export const handleAttrSubmit = (attr) => async (dispatch, getState) => {
    let { edit_attr } = getState().quotation;
    let attr_val = edit_attr[attr];

    if (isNaN(attr_val)) {
        ToastDanger('Field required at least 0 value');
    } else {
        dispatch({
            type: HANDLE_QU_SUBMIT_ATTR,
            payload: { key: attr, value: attr_val },
        });
    }
};

// handle vat
export const handleCheckVat = (e) => async (dispatch) => {
    let checked;

    if (e.target.checked) {
        checked = 12;
    } else {
        checked = 0;
    }

    dispatch({ type: HANDLE_QU_CHECK_VAT, payload: checked });
};

// handle edit row
export const handleToggleEditRow = (index) => async (dispatch) => {
    dispatch({ type: HANDLE_QU_TOGGLE_EDIT_ROW, payload: index });
};

// handle select payment
export const handleSelectPayment = (selectOption) => async (dispatch) => {
    dispatch({ type: HANDLE_QU_SI_PAYMENT, payload: selectOption });
};

// handle input edit row
export const handleEditInputRow = (index, e) => async (dispatch, getState) => {
    e.preventDefault();

    let { edit_quotation_items } = getState().quotation;
    let name = e.target.name;
    edit_quotation_items[index][name] = parseFloat(e.target.value);

    dispatch({ type: HANDLE_QU_EDIT_INPUT_ROW, payload: edit_quotation_items });
};

// handle update row
export const handleUpdateRow = (index) => async (dispatch, getState) => {
    let { edit_quotation_items } = getState().quotation;

    let qty = edit_quotation_items[index].qty;
    let srp = edit_quotation_items[index].srp;

    if (qty == 0 || isNaN(qty)) {
        ToastDanger('Qty is required and cannot be a string value');
    } else if (srp == 0 || isNaN(srp)) {
        ToastDanger('Srp is required and cannot be a string value');
    } else {
        let updated_amount = srp * qty;
        edit_quotation_items[index].amount = updated_amount;

        dispatch({
            type: HANDLE_QU_EDIT_UPDATE_ROW,
            payload: edit_quotation_items,
        });
    }
};

// handle select status receipt type for create sales invoice
export const handleSelectTypeInvoice = (selectedOption) => async (dispatch) => {
    dispatch({ type: HANDLE_QU_SELECT_RECEIPT_TYPE, payload: selectedOption });
};

// handle select status receipt type for create sales invoice
export const handleSelectTypeOrder = (selectedOption) => async (dispatch) => {
    dispatch({ type: HANDLE_QU_SELECT_RECEIPT_TYPE, payload: selectedOption });
};

// handle selec payment type
export const handleSelectPaymentType = (selectOption) => async (dispatch) => {
    if (selectOption) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    dispatch({ type: HANDLE_QU_SELECT_PAYMENT_TYPE, payload: selectOption });
};

// fetch quotation templates
export const getQuotationTemplates = () => async (dispatch) => {
    QuotationService.getQuotationTemplates()
        .then((res) => {
            let qu_templates = res.data.quotation_templates;
            let qu_default_template = {
                value: res.data.selected_template._id,
                label: res.data.selected_template.template_name,
            };
            let quotation_template_id = res.data.quotation_template_id;

            let qu_template_arr = [];
            qu_templates.map((val, i) => {
                let select = {
                    value: val._id,
                    label: val.template_name,
                    name: 'quotation_template_id',
                };
                qu_template_arr[i] = select;
            });

            let payload = {
                qu_templates: qu_template_arr,
                qu_default_template: qu_default_template,
                quotation_template_id: quotation_template_id,
            };

            dispatch({ type: GET_QU_TEMPLATES, payload: payload });
        })
        .catch((error) => {
            // ToastDanger("Network error, please reload the page.");
            $('.btn-remove').removeAttr('disabled').html('Remove');
            console.log(error);
        });
};

// get all the payment_terms and conditions
export const getTermsConditions = () => async (dispatch) => {
    QuotationService.getTermsConditions()
        .then((res) => {
            let payment_terms_arr = [];
            let payment_terms = res.data.terms_conditions;

            payment_terms.map((val, i) => {
                let select = { value: val.content, label: val.title };
                payment_terms_arr[i] = select;
            });
            payment_terms_arr.unshift({ value: '', label: 'None' });

            dispatch({
                type: GET_QU_TERMS_CONDITIONS,
                payload: payment_terms_arr,
            });
        })
        .catch((error) => {
            // ToastDanger("Network error, please reload the page.");
            console.log(error);
        });
};

// get all the payment_terms and conditions
export const getQuotationValidations = () => async (dispatch) => {
    QuotationService.getQuotationValidations()
        .then((res) => {
            let validation_arr = [];
            let quote_validation = res.data.quotation_validation;

            quote_validation.map((val, i) => {
                let select = { value: val.content, label: val.title };
                validation_arr[i] = select;
            });
            validation_arr.unshift({ value: '', label: 'None' });

            dispatch({
                type: GET_QU_VALIDATION,
                payload: validation_arr,
            });
        })
        .catch((error) => {
            // ToastDanger("Network error, please reload the page.");
            console.log(error);
        });
};

// handle select tems and conditions
export const handleSelectQuotationValidation =
    (selectOption) => async (dispatch) => {
        dispatch({
            type: HANDLE_QU_SELECT_VALIDATION,
            payload: selectOption,
        });
    };

// clear data
export const clearQuotationValidatiton = () => async (dispatch) =>
    dispatch({ type: CLEAR_QU_VALIDATION });

// handle inputs
export const handleInputChangeValidation = (e) => async (dispatch) => {
    dispatch({
        type: QU_VALIDATION_INPUT_CHANGE,
        payload: { key: e.target.name, value: e.target.value },
    });
};

export const quotationValidationPost =
    (e, pageOrigin) => async (dispatch, getState) => {
        e.preventDefault();
        let { single_quote_validation } = getState().quotation;
        $('.btn-save').attr('disabled', 'disabled').html('Saving...');

        let arrOrigin = ['purchase_order', 'quotation', 'sales_invoice'];
        if (arrOrigin.includes(pageOrigin)) {
            // to get the return selected value
            single_quote_validation['select_quote_validation'] = true;
        }
        console.log('e', e);
        console.log('pageOrigin', pageOrigin);

        QuotationService.postQuotationValidations(single_quote_validation)
            .then((res) => {
                switch (res.data.status) {
                    case 0:
                        ToastDanger(res.data.errors);
                        break;
                    case 1:
                        console.log('res', res);
                        ToastSuccess(res.data.message);
                        dispatch(clearQuotationValidatiton());
                        const selectedValidation = res.data
                            .select_quote_validation
                            ? {
                                  value: res.data.select_quote_validation._id,
                                  label: res.data.select_quote_validation.title,
                              }
                            : {};

                        if (pageOrigin == 'quotation') {
                            dispatch(setModal('quote_validation_modal', false));
                            dispatch(getQuotationValidations());
                            dispatch(
                                handleSelectQuotationValidation(
                                    selectedValidation
                                )
                            );
                        }
                        break;
                    default:
                        break;
                }
                $('.btn-save').removeAttr('disabled').html('Save');
            })
            .catch((error) => {
                if (error.data?.errors.length > 0) {
                    ToastDanger(error.data.errors);
                } else {
                    ToastDanger('Network error, please reload the page.');
                }
                $('.btn-save').removeAttr('disabled').html('Save');
                console.log(error);
            });
    };

// get all the select options
export const getCustomers =
    (pageOrigin = null) =>
    async (dispatch) => {
        QuotationService.getCustomers('')
            .then((res) => {
                let customers_arr = [];
                let customers = res.data.customers.data;

                customers.map((val, i) => {
                    let select = {
                        value: val._id,
                        label: val.name,
                        name: 'customer_id',
                    };
                    customers_arr[i] = select;
                });

                if (pageOrigin == 'pos') {
                    dispatch({
                        type: POS_GET_CUSTOMERS,
                        payload: customers_arr,
                    });
                } else {
                    dispatch({
                        type: GET_QU_CUSTOMERS,
                        payload: customers_arr,
                    });
                }
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    error.data.errors.map((err) => ToastDanger(err));
                } else {
                    ToastDanger('Network error, please reload the page.');
                }
                console.log(error);
            });
    };

// get all the select options
export const searchCustomers = (search) => async (dispatch) =>
    new Promise((resolve, reject) => {
        QuotationService.getCustomers(search)
            .then((res) => {
                let customers_arr = [];
                let customers = res.data.customers.data;

                customers.map((val, i) => {
                    let select = {
                        value: val._id,
                        label: val.name,
                        name: 'customer_id',
                    };
                    customers_arr[i] = select;
                });
                resolve(customers_arr);
                // dispatch({ type: GET_SI_CUSTOMERS, payload: customers_arr });
            })
            .catch((error) => {
                // ToastDanger("Network error, please reload the page.");
                console.log(error);
                reject(error);
            });
    });

// get the products
export const getProducts =
    (search = '') =>
    async (dispatch) => {
        const formdata = {
            search: search,
        };

        AwesomeDebouncePromise(
            QuotationService.getProducts(formdata)
                .then((res) => {
                    let items_arr = [];
                    let items = res.data.products.data;
                    items.map((item, i) => {
                        // console.log("item", item);
                        let attribute = '';
                        if (
                            item.attribute_string == '()' ||
                            item.attribute_string === null
                        ) {
                            attribute = '';
                        } else {
                            try {
                                let attr_replace_char = item.attribute_string
                                    .replace('(', '')
                                    .replace(')', '');
                                attribute =
                                    attr_replace_char.slice(0, 40) +
                                    (attr_replace_char.length > 40
                                        ? '...'
                                        : '');
                            } catch (err) {
                                attribute = '';
                            }
                        }

                        let select = {
                            _id: item._id,
                            value: item._id,
                            label: item.product_name,
                            sub_label: attribute,
                            name: 'item',
                            barcode: item.barcode,
                            brand: item.brand,
                            product_name: item.product_name,
                            sku: item.sku,
                            qty: 1,
                            instock: item.instock,
                            product_unit_id: item.product_unit_id
                                ? item.product_unit_id
                                : '',
                            product_unit_name: item.product_unit
                                ? item.product_unit.name
                                : '',
                            attribute_string: item.attribute_string,
                            srp: item.srp,
                            amount: item.srp,
                            description: item.description,
                            lot_no:
                                item.single_lot != null &&
                                item.single_lot != undefined
                                    ? item.single_lot.lot_no
                                    : '',
                            status_code: item.status_code,
                        };

                        items_arr[i] = select;
                    });
                    // console.log("items_arr", items_arr);

                    dispatch({ type: GET_QU_PRODUCTS, payload: items_arr });
                })
                .catch((error) => {
                    if (error.data.errors.length > 0) {
                        error.data.errors.map((err) => ToastDanger(err));
                    } else {
                        ToastDanger('Network error, please reload the page.');
                    }
                    console.log(error);
                }),
            500
        );
    };

export const searchProducst =
    (search, index, new_item_template) => (dispatch, getState) =>
        new Promise((resolve, reject) => {
            const formdata = {
                search: search,
            };
            QuotationService.getProducts(formdata)
                .then((res) => {
                    let items_arr = [];
                    let items = res.data.products.data;
                    items.forEach((item, i) => {
                        let attribute = '';
                        try {
                            let attr_replace_char = item.attribute_string
                                .replace('(', '')
                                .replace(')', '');
                            attribute =
                                attr_replace_char.slice(0, 40) +
                                (attr_replace_char.length > 40 ? '...' : '');
                        } catch (err) {
                            attribute = '';
                        }

                        let select = {
                            _id: item._id,
                            value: item._id,
                            label: item.product_name,
                            sub_label: attribute,
                            name: 'item',
                            barcode: item.barcode,
                            brand: item.brand,
                            brand_id: item.brand_id,
                            product_name: item.product_name,
                            sku: item.sku,
                            qty: 1,
                            instock: item.instock,
                            product_unit_name: item
                                ? item.product_unit_name
                                : '',
                            product_unit_id: item.product_unit_id,
                            category: item.category,
                            category_id: item.category_id,
                            attribute_string: item.attribute_string,
                            srp: item.srp,
                            amount: item.srp,
                            status_code: item.status_code,
                            description: item.description,
                        };

                        // console.log("items_arr", items_arr);
                        items_arr.push(select);
                    });
                    resolve(items_arr);
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });
        });

export const handleOnInputChange = (search) => async (dispatch) => {
    const formdata = {
        search: search,
    };

    AwesomeDebouncePromise(
        QuotationService.getProducts(formdata)
            .then((res) => {
                let items_arr = [];
                let items = res.data.products.data;
                items.map((item, i) => {
                    //console.log("item", item);
                    let attribute = '';
                    if (
                        item.attribute_string == '()' ||
                        item.attribute_string === null
                    ) {
                        attribute = '';
                    } else {
                        let attr_replace_char = item.attribute_string
                            .replace('(', '')
                            .replace(')', '');
                        attribute =
                            attr_replace_char.slice(0, 40) +
                            (attr_replace_char.length > 40 ? '...' : '');
                    }

                    let select = {
                        _id: item._id,
                        value: item._id,
                        label: item.product_name,
                        sub_label: attribute,
                        name: 'item',
                        barcode: item.barcode,
                        brand: item.brand,
                        product_name: item.product_name,
                        sku: item.sku,
                        qty: 1,
                        product_unit_id: item.product_unit_id
                            ? item.product_unit_id
                            : '',
                        product_unit_name: item.product_unit
                            ? item.product_unit.name
                            : '',
                        attribute_string: item.attribute_string,
                        srp: item.srp,
                        amount: item.srp,
                    };

                    items_arr[i] = select;
                });
                // console.log("items_arr", items_arr);

                dispatch({ type: GET_QU_PRODUCTS, payload: items_arr });
                // callback(items_arr);

                return items_arr;
            })
            .catch((error) => {
                // ToastDanger("Network error, please reload the page.");
                console.log(error);
            }),
        500
    );
};

//  fetch
export const getQuotations =
    (pageNumber, rows_per_page = null, pageOrigin = null, date_filter = null) =>
    async (dispatch, getState) => {
        dispatch(setLoading(true));

        let {
            search,
            pagination,
            pagination_list,
            status_view,
            sort_order_name,
            sort_order_direction,
        } = getState().quotation;
        // let rows_per_page_val =
        //   rows_per_page != null ? rows_per_page : getNumRows("quotation");
        let rows_per_page_val =
            (rows_per_page != null ? rows_per_page : getNumRows('quotation')) ||
            10;

        // let params = {
        //     search: search,
        //     activePage: pagination.activePage,
        //     page: pageNumber,
        //     rows_per_page: rows_per_page_val,
        //     sort_order_name: sort_order_name,
        //     sort_order_direction: sort_order_direction
        // }

        //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
        let params = {
            search,
            options: {
                page: pageNumber,
                limit: rows_per_page_val,
                sort_by: sort_order_name,
                sort_order: sort_order_direction,
            },
        };

        if (pageOrigin) {
            switch (pageOrigin['origin']) {
                case 'inventory_view':
                    params.product_id = pageOrigin['product_id'];
                    break;
                case 'customer_history':
                    params.customer_id = pageOrigin['customer_id'];
                default:
                    break;
            }
        }

        // Custom History Date Filtering
        if (date_filter !== null) {
            params.date_from = date_filter['date_from'];
            params.date_to = date_filter['date_to'];
        }

        QuotationService.paginateWithSearchQuotations(params, status_view)
            .then((res) => {
                let data_list = [];
                res.data.docs.map((data) => {
                    data_list.push(data);
                });

                pagination_list[status_view] = data_list;

                // let pagination = {
                //     totalCount: res.data.quotations.total,
                //     activePage: res.data.quotations.current_page,
                //     itemsCountPerPage: res.data.quotations.per_page,
                //     totalItemsCount: res.data.quotations.total
                // }

                //** MERN PAGINATE WITH OPTIMIZE SEARCH QUERY */
                let pagination = {
                    totalCount: res.data.total_docs,
                    activePage: res.data.page,
                    itemsCountPerPage: res.data.limit,
                    totalItemsCount: res.data.total_docs,
                };

                dispatch({
                    type: GET_QUOTATION_STATUS,
                    payload: {
                        pagination_list: pagination_list,
                        pagination: pagination,
                        quotations: res.data.docs,
                    },
                });
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch(setLoading(false));
                // ToastDanger("Network error, please reload the page.");
                console.log(error);
            });
    };

// handle submit form
export const handleOnSubmit =
    (method, status) => async (dispatch, getState) => {
        const { single_quotation, inv_default_template } = getState().quotation;
        let errors = [];
        single_quotation.quotation_items.map((item) => {
            if (item.qty <= 0) {
                errors.push(
                    'Quantity cannot be zero value, please add at least 1'
                );
            }
            if (item.srp <= 0) {
                errors.push(
                    'Unit Price cannot be zero value, please include at least the amount'
                );
            }
        });

        if (single_quotation.quotation_items.length == 0) {
            ToastDanger('Please add Product item at least one.');
        } else if (errors.length > 0) {
            ToastDanger(errors);
        } else {
            let stat_arr = status.split('-');
            let stat = stat_arr[0];
            let actions = stat_arr[1] !== undefined ? stat_arr[1] : null;

            // console.log('single_quotation', single_quotation);
            const data = {
                _id: single_quotation._id,
                quotation_no: single_quotation.quotation_no,
                quotation_id: single_quotation._id, // for create-si
                customer_id: single_quotation.customer_id,
                quotation_items: JSON.stringify(
                    single_quotation.quotation_items
                ),
                discount: single_quotation.discount,
                discount_type: single_quotation.discount_type,
                tax: single_quotation.tax,
                tax_type: single_quotation.tax_type,
                vat_amount: single_quotation.vat_amount,
                other: single_quotation.other,
                sub_total: single_quotation.sub_total,
                total: single_quotation.total,
                remarks: single_quotation.remarks,
                terms_and_condition: single_quotation.terms_and_condition,
                payment_details: single_quotation.payment_details,
                internal_notes: single_quotation.internal_notes,
                quotation_validation: single_quotation.quotation_validation,
                payment_terms: single_quotation.payment_terms,
                is_invoiced: single_quotation.isInvoice,
                sales_invoice_items: JSON.stringify(
                    single_quotation.quotation_items
                ), // for create-si
                receipt_type: single_quotation.receipt_type,
                payment: single_quotation.payment,
                status: stat,
                invoice_template_id: single_quotation.invoice_template_id, // for create-si
                quotation_template_id: single_quotation.quotation_template_id,
                payment_mode: single_quotation.payment_mode,
                quotation_date: single_quotation.quotation_date,
                sales_invoice_date: single_quotation.sales_invoice_date, // for create-si
            };

            dispatch(setMiniLoading(true));
            // console.log(data);
            if (method == 'create') {
                if (status == 'draft' || status == 'draft-print')
                    return dispatch(quotationPost(data, actions));
                if (status == 'draft-email') {
                    data['send_to_email'] = true;
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to draft and email this Quotation. Continue?',
                        () => dispatch(quotationPost(data, actions))
                    );
                }
                if (status == 'saved' || status == 'saved-print')
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save Quotation, saving this will stop you from editing any other changes. Continue?',
                        () => dispatch(quotationPost(data, actions))
                    );
                if (status == 'saved-email') {
                    data['send_to_email'] = true;
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save and email this Quotation, saving this will stop you from editing any other changes. Continue?',
                        () => dispatch(quotationPost(data, actions))
                    );
                }
            } else if (method == 'create-si') {
                data['invoice_template_id'] = inv_default_template.value; // setting an invoice template in sales invoice
                if (status == 'saved')
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save Sales Invoice, saving this will stop you from editing any other changes. Continue?',
                        () => dispatch(salesInvoicePost(data, actions))
                    );
            } else {
                // update
                if (status == 'draft' || status == 'draft-print')
                    return dispatch(
                        updateSingleQuotation(data, data._id, actions)
                    );
                if (status == 'draft-email') {
                    data['send_to_email'] = true;
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to draft and email this Quotation. Continue?',
                        () =>
                            dispatch(
                                updateSingleQuotation(data, data._id, actions)
                            )
                    );
                }
                // if(status == 'draft') return dispatch(updateSingleQuotation(data, data._id));
                if (status == 'saved' || status == 'saved-print')
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save Quotation, saving this will stop you from editing any other changes. Continue?',
                        () =>
                            dispatch(
                                updateSingleQuotation(data, data._id, actions)
                            )
                    );
                if (status == 'saved-email') {
                    data['send_to_email'] = true;
                    return SwalWarning(
                        'Are You Sure?',
                        'You are about to save and send email this Quotation, saving this will stop you from editing any other changes. Continue?',
                        () =>
                            dispatch(
                                updateSingleQuotation(data, data._id, actions)
                            )
                    );
                }
            }
        }
    };

// store
export const quotationPost = (data, actions) => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setMUIPage(1));
    QuotationService.quotationPost(data)
        .then((res) => {
            // switch(res.data.status){
            //     case 0:
            //         ToastDanger(res.data.errors);
            //         break;
            //     case 1:
            //         dispatch(setPage('main_page'));
            //         ToastSuccess(res.data.message);
            //         dispatch(clearQuotation());
            //         // dispatch(getQuotations())
            //         break;
            //     default:
            //         break;
            // }

            if (res.data.status == 0) {
                ToastDanger(res.data.errors);
            } else if (res.data.status == 1) {
                dispatch(setPage('main_page'));
                ToastSuccess(res.data.message);
                dispatch(clearQuotation());
                if (actions == 'print') {
                    dispatch(setPage('print_modal_qu', res.data.id));
                }
            }

            dispatch(setLoading(false));
        })
        .catch((error) => {
            if (error.data.errors.length > 0) {
                dispatch(setMiniLoading(false));
                error.data.errors.map((err) => ToastDanger(err));
            } else {
                ToastDanger('Network error, please reload the page.');
            }
            $('.btn-save').removeAttr('disabled').html('Save');
            console.log(error);
        });
};

// update
export const updateSingleQuotation =
    (data, id, actions) => async (dispatch) => {
        dispatch(setLoading(true));
        QuotationService.updateSingleQuotation(data, id)
            .then((res) => {
                // switch(res.data.status){
                //     case 0:
                //         ToastDanger(res.data.errors);
                //         break;
                //     case 1:
                //         dispatch(setPage('main_page'));
                //         ToastSuccess(res.data.message);
                //         dispatch(clearQuotation());
                //         break;
                //     default:
                //         break;
                // }

                if (res.data.status == 0) {
                    ToastDanger(res.data.errors);
                } else if (res.data.status == 1) {
                    dispatch(setPage('main_page'));
                    ToastSuccess(res.data.message);
                    dispatch(clearQuotation());
                    if (actions == 'print') {
                        dispatch(setPage('print_modal_qu', res.data.id));
                    }
                }

                dispatch(setLoading(false));
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    error.data.errors.map((err) => ToastDanger(err));
                } else {
                    ToastDanger('Network error, please reload the page.');
                }
                console.log(error);
            });
    };

// get the quotation base on id
export const getSingleQuotation =
    (id, status = null) =>
    async (dispatch) => {
        dispatch(setMiniLoading(true));
        QuotationService.getSingleQuotation(id)
            .then((res) => {
                if (res.data.status == 1) {
                    let single_quotation = res.data.quotation;
                    let qu_default_template = [];
                    let subs_setting = res.data.subs_setting;

                    // // dispatch(getSettingApp());
                    // // const subsetting = getSubSetting();
                    // const subsetting = single_quotation.subs_setting;
                    // single_quotation["default_tax"] = subsetting
                    //   ? subsetting.tax_rate
                    //   : 0;

                    dispatch(getSettingApp());
                    const subsetting = getSubSetting();
                    single_quotation['default_tax'] = subsetting.tax_rate;

                    //console.log("tax", single_quotation);

                    if (single_quotation['payment_terms'] == null) {
                        single_quotation['payment_terms'] = subsetting
                            ? subsetting.payment_payment_terms
                                ? subsetting.payment_payment_terms
                                : { value: 'none', label: 'None' }
                            : { value: 'none', label: 'None' };
                    }

                    let edit_attr = {
                        discount: res.data.quotation.discount
                            ? res.data.quotation.discount
                            : 0,
                        vat: res.data.quotation.vat
                            ? res.data.quotation.vat
                            : 0,
                        other: res.data.quotation.other
                            ? res.data.quotation.other
                            : 0,
                    };

                    // create si state
                    single_quotation['receipt_type'] = {
                        value: 'sales invoice',
                        label: 'Sales Invoice',
                    };
                    single_quotation['payment'] = {
                        value: 'Unpaid',
                        label: 'Unpaid',
                    };

                    let default_customer_value = {
                        value: res.data.quotation.customer._id,
                        label: res.data.quotation.customer.name,
                    };

                    single_quotation.quotation_date =
                        single_quotation.quotation_date
                            ? new Date(single_quotation.quotation_date)
                            : new Date();

                    if (single_quotation.template_setting) {
                        qu_default_template = {
                            value: single_quotation.template_setting._id,
                            label: single_quotation.template_setting
                                .template_name
                                ? single_quotation.template_setting
                                      .template_name
                                : single_quotation.template.template_name,
                        };
                    }

                    if (single_quotation['payment_mode'] == null) {
                        single_quotation['payment_mode'] = {
                            value: '',
                            label: 'None',
                        };
                    }

                    single_quotation.subs_setting = subs_setting;
                    single_quotation.quotation_items.map((item, index) => {
                        single_quotation.quotation_items[index].status_code =
                            item.product.status_code;
                    });

                    let payload = {
                        single_quotation: single_quotation,
                        edit_attr: edit_attr,
                        default_customer_value: default_customer_value,
                        qu_default_template,
                    };

                    dispatch({ type: GET_SINGLE_QUOTATION, payload });

                    if (status == 'delete') {
                        dispatch(archive());
                    }
                }
                dispatch(setMiniLoading(false));
            })
            .catch((error) => {
                dispatch(setMiniLoading(false));
                // ToastDanger("Network error, please reload the page.");
                console.log(error);
            });
    };

// post [create sales invoice from quotation]
export const salesInvoicePost = (data) => async (dispatch) => {
    QuotationService.salesInvoicePost(data)
        .then((res) => {
            switch (res.data.status) {
                case 0:
                    ToastDanger(res.data.errors);
                    break;
                case 1:
                    dispatch(setPage('main_page'));
                    ToastSuccess(res.data.message);
                    // dispatch(getQuotations())
                    break;
                default:
                    break;
            }
        })
        .catch((error) => {
            // ToastDanger("Network error, please reload the page.");
            console.log(error);
        });
};

// archieve
export const moveToArchive =
    (pageOrigin = null) =>
    async (dispatch, getState) => {
        let { rows_to_delete } = getState().quotation;
        let id_arr = [];

        rows_to_delete.map((row) => {
            id_arr.push(row.id);
        });
        let params = { ids: JSON.stringify(id_arr) };

        QuotationService.ArchiveQuotation(params)
            .then((res) => {
                dispatch(setRowChecked());
                switch (res.data.status) {
                    case 0:
                        ToastWarning(res.data.message);
                        if (pageOrigin != null) {
                            if (pageOrigin['origin'] == 'customer_history') {
                                dispatch(
                                    getQuotations(
                                        1,
                                        null,
                                        pageOrigin['customer_id']
                                    )
                                );
                            }
                        } else {
                            dispatch(getQuotations());
                        }
                        break;
                    case 1:
                        ToastSuccess(res.data.message);
                        if (pageOrigin != null) {
                            if (pageOrigin['origin'] == 'customer_history') {
                                dispatch(
                                    getQuotations(
                                        1,
                                        null,
                                        pageOrigin['customer_id']
                                    )
                                );
                            }
                        } else {
                            dispatch(getQuotations());
                            dispatch(clearQuotation());
                        }
                    default:
                        break;
                }
            })
            .catch((error) => {
                if (error.data.errors.length > 0) {
                    ToastDanger(error.data.errors);
                } else {
                    ToastDanger('Network error, please reload the page.');
                }
                console.log(error);
            });
    };

export const archive = () => async (dispatch, getState) => {
    let { single_quotation } = getState().quotation;

    SwalWarning(
        'Warning!',
        `Do you want to remove the Quotation No: ${single_quotation.quotation_no}?`,
        () => dispatch(moveToArchive())
    );
};

export const setModal = (modal, status) => async (dispatch) => {
    dispatch({ type: SET_MODAL, payload: { modal: modal, status: status } });
};

// handle select quotation templates
export const handleSelectSalesInvoiceTemplate =
    (selectedOption) => async (dispatch) => {
        if (selectedOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        let quotation_template_id = selectedOption.value;
        let qu_default_template = {
            value: selectedOption.value,
            label: selectedOption.label,
        };

        dispatch({
            type: HANDLE_SELECT_QU_TEMPLATE,
            payload: {
                qu_default_template: qu_default_template,
                quotation_template_id: quotation_template_id,
            },
        });
    };

// handle select quotation templates
export const handleSelectSalesOrderTemplate =
    (selectedOption) => async (dispatch) => {
        if (selectedOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        let quotation_template_id = selectedOption.value;
        let qu_default_template = {
            value: selectedOption.value,
            label: selectedOption.label,
        };

        dispatch({
            type: HANDLE_SELECT_QU_TEMPLATE,
            payload: {
                qu_default_template: qu_default_template,
                quotation_template_id: quotation_template_id,
            },
        });
    };

// handle input datepicker
export const QUDatePicker = (date, page_origin) => async (dispatch) => {
    if (date) {
        dispatch(blockRoute(true));
    } else {
        dispatch(blockRoute(false));
    }

    /** page_origin = quotation_date or sales_invoice_date */
    let dateParams = {
        page_origin: page_origin,
        date: date,
    };

    dispatch({ type: SET_DATE_PICKER, payload: dateParams });
};

// fetch sales invoice templates
export const getInvoiceTemplates = () => async (dispatch) => {
    QuotationService.getInvTemplates()
        .then((res) => {
            let inv_templates = res.data.sales_invoice_templates;
            let inv_default_template = {
                value: res.data.selected_template._id,
                label: res.data.selected_template.template_name,
            };
            let invoice_template_id = res.data.sales_invoice_template_id;

            let inv_template_arr = [];
            inv_templates.map((val, i) => {
                let select = {
                    value: val._id,
                    label: val.template_name,
                    name: 'invoice_template_id',
                };

                inv_template_arr[i] = select;
            });

            dispatch({
                type: GET_QU_SI_TEMPLATES,
                payload: {
                    inv_templates: inv_template_arr,
                    inv_default_template: inv_default_template,
                    invoice_template_id: invoice_template_id,
                },
            });
        })
        .catch((error) => {
            // ToastDanger("Network error, please reload the page.");
            $('.btn-remove').removeAttr('disabled').html('Remove');
            console.log(error);
        });
};

// fetch sales order templates
export const getOrderTemplates = () => async (dispatch) => {
    QuotationService.getInvTemplates()
        .then((res) => {
            let inv_templates = res.data.sales_order_templates;
            let inv_default_template = {
                value: res.data.selected_template._id,
                label: res.data.selected_template.template_name,
            };
            let order_template_id = res.data.sales_order_template_id;

            let inv_template_arr = [];
            inv_templates.map((val, i) => {
                let select = {
                    value: val._id,
                    label: val.template_name,
                    name: 'order_template_id',
                };

                inv_template_arr[i] = select;
            });

            dispatch({
                type: GET_QU_SO_TEMPLATES,
                payload: {
                    inv_templates: inv_template_arr,
                    inv_default_template: inv_default_template,
                    order_template_id: order_template_id,
                },
            });
        })
        .catch((error) => {
            // ToastDanger("Network error, please reload the page.");
            $('.btn-remove').removeAttr('disabled').html('Remove');
            console.log(error);
        });
};

// Sort by column name and direction
export const sortTableByColumn =
    (sort_order_name, sort_order_direction, pageOrigin = null) =>
    async (dispatch) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: SORT_BY_COLUMN, payload: sortingParams });
        dispatch(getQuotations(1, null, pageOrigin));
    };

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: SEARCH_INPUT, payload: e.target.value });
    // dispatch(getQuotations(1));
};

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });
        // console.log(newArray);
        dispatch({ type: GET_ROWS_TO_DELETE, payload: newArray });
    };

export const confirmDeleteDialogBox =
    (pageOrigin) => async (dispatch, getState) => {
        let { rows_to_delete } = getState().quotation;
        let quotation_no = '';
        let quote = [];
        rows_to_delete.map((rows, i) => {
            // quotation_no += rows.name + ", ";
            quote.push('<b>' + rows.name + '</b>');
        });
        SwalWarningHtml(
            'Warning!',
            `Do you want to remove the Quotation No: ${quote.join()}?`,
            () => dispatch(moveToArchive(pageOrigin))
        );
    };

// set the rows unchecked
export const setRowChecked = () => async (dispatch) =>
    dispatch({ type: SET_ROW_CHECKED });

// handle prev, next page of modals
export const goPage = (direction) => async (dispatch, getState) => {
    dispatch(setMiniLoading(true));

    let { single_quotation } = getState().quotation;

    let params = {
        direction: direction,
        _id: single_quotation._id,
    };

    try {
        const go_to = await QuotationService.goPage(params);
        dispatch(getSingleQuotation(go_to.data._id));
        // dispatch(setMiniLoading(false))
    } catch (e) {
        console.log(e);
        // ToastDanger("Server Error");
        dispatch(setMiniLoading(false));
    }
};

export const add_items = () => async (dispatch, getState) => {
    let { single_quotation, item_template } = getState().quotation;
    let items_data = single_quotation.quotation_items;
    item_template.new = true;

    let errors = false;
    items_data.map((item) => {
        if (item.qty <= 0) {
            ToastDanger('Quantity cannot be zero value, please add at least 1');
            errors = true;
        }
        if (item.srp <= 0) {
            ToastDanger(
                'Unit Price cannot be zero value, please include at least the amount'
            );
            errors = true;
        }
    });

    if (!errors) {
        items_data.push(item_template);
        dispatch({ type: ADD_ITEM, payload: items_data });
    }
};

export const dynamicInputChange = (e, index) => async (dispatch, getState) => {
    let { name, value } = e.target;

    let { single_quotation } = getState().quotation;
    let items = { ...single_quotation.quotation_items[index] };
    items[name] = parseFloat(value);
    items.amount = parseFloat(items.qty) * parseFloat(items.srp);

    if (items.discount_type && items.discount_type.value !== 'none') {
        let discountAmount = 0;
        if (items.discount_type.value === 'percent') {
            discountAmount = (items.discount / 100) * items.amount;
        } else if (items.discount_type.value === 'amount') {
            discountAmount = items.discount;
        }
        items.amount = items.amount - discountAmount;
        items.discount_amount = discountAmount ? discountAmount : 0;
    }

    single_quotation.quotation_items[index] = items;
    dispatch({ type: ADD_ITEM, payload: single_quotation.quotation_items });
};

export const handleTaxType = (selectOption) => async (dispatch, getState) => {
    let { tax, default_tax } = getState().quotation.single_quotation;

    tax = selectOption.value === 'none' ? 0 : default_tax;

    dispatch({
        type: QU_SELECT_TAX_TYPE,
        payload: { tax, tax_type: selectOption },
    });
};

// COMPUTATION HANDLER
export const computationHandler = () => async (dispatch, getState) => {
    let {
        single_quotation: {
            quotation_items,
            discount,
            discount_type,
            tax,
            tax_type,
            other,
        },
    } = getState().quotation;

    // Subtotal and discount
    const sub_total = quotation_items.reduce(
        (sub, item) => sub + parseFloat(item.amount ? item.amount : item.srp),
        0
    );
    const discounted =
        discount_type.value === 'percent'
            ? (parseFloat(sub_total) * discount) / 100
            : discount;

    const sub_total_discounted = sub_total - discounted + other;

    // Exclusive Vat     // Formula: price * (vat / 100)
    const exclusive_vat = (parseFloat(sub_total_discounted) * tax) / 100;
    //const total_w_exclusive = sub_total_discounted + ((parseFloat(sub_total_discounted) * tax) / 100) + other; // total amount
    const total_w_exclusive =
        sub_total_discounted + (parseFloat(sub_total_discounted) * tax) / 100; // total amount

    // Inclusive Vat   // Formula: price / ( (vat / 100) + 1)
    const inclusive_amount = sub_total_discounted / (tax / 100 + 1);
    const inclusive_vat = sub_total_discounted - inclusive_amount;

    let payload = {};

    switch (tax_type.value) {
        case 'exclusive':
            payload = {
                sub_total: sub_total,
                vat_amount: exclusive_vat,
                total: total_w_exclusive,
            };
            break;
        case 'inclusive':
            payload = {
                vat_amount: inclusive_vat,
                sub_total: sub_total,
                total: sub_total_discounted,
            };
            break;
        default:
            payload = {
                vat_amount: 0.0,
                sub_total: sub_total,
                total: sub_total_discounted,
            };
            break;
    }

    // console.log(payload);
    dispatch({ type: QU_COMPUTATION_HANDLER, payload });
};

export const sendEmail = (e, id) => async (dispatch, getState) => {
    let { email_config } = getState().quotation;
    if (email_config.require_password) {
        verfiyPass(function (result) {
            dispatch(sendSingleEmail(id));
        });
    } else {
        dispatch(sendSingleEmail(id));
    }
};

const sendSingleEmail = (id) => async (dispatch, getState) => {
    dispatch({ type: SEND_EMAIL, payload: true });
    let { quotations } = getState().quotation;
    QuotationService.sendEmail(id)
        .then((result) => {
            ToastSuccess(result.data.message);
            dispatch({ type: SEND_EMAIL, payload: false });
            dispatch({ type: RESEND_EMAIL, payload: true });
        })
        .catch((err) => {
            console.log('err', err);
            if (err.data == undefined) {
                ToastDanger('Failed to send email.');
            } else {
                ToastDanger(err.data.message);
            }
            dispatch({ type: SEND_EMAIL, payload: false });
            dispatch({ type: RESEND_EMAIL, payload: quotations.email_sent });
        });
};

export const reSendEmail = (e, id) => async (dispatch, getState) => {
    SwalWarning(
        'Are you sure?',
        'Do you really want to resend this email?',
        () => dispatch(sendEmail(e, id))
    );
};

export const sendEmailDyanmic =
    (e, id, index) => async (dispatch, getState) => {
        let { quotations } = getState().quotation;
        let po = quotations[index];
        if (po.email_sent) {
            SwalWarning(
                'Are you sure?',
                'Do you really want to resend this email?',
                () => dispatch(sendByIndex(id, index))
            );
        } else {
            dispatch(sendByIndex(id, index));
        }
    };

const sendByIndex = (id, index) => async (dispatch, getState) => {
    let { email_config } = getState().quotation;
    if (email_config.require_password) {
        verfiyPass(function (result) {
            dispatch(sendEmailIndex(id, index));
        });
    } else {
        dispatch(sendEmailIndex(id, index));
    }
};

const sendEmailIndex = (id, index) => async (dispatch, getState) => {
    let { quotations } = getState().quotation;
    let quote = quotations[index];
    quote.sending_email = true;
    quotations[index] = quote;
    dispatch({ type: DYNAMIC_SEND_EMAIL, payload: quotations });
    QuotationService.sendEmail(id)
        .then((result) => {
            ToastSuccess(result.data.message);
            quote.sending_email = false;
            quote.email_sent = true;
            quotations[index] = quote;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: quotations });
        })
        .catch((err) => {
            // ToastDanger('Failed to send email.');
            if (err.data == undefined) {
                ToastDanger('Failed to send email.');
            } else {
                ToastDanger(err.data.message);
            }
            quote.sending_email = false;
            quotations[index] = quote;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: quotations });
        });
};

export const getPdf = (e, id, quotation_no, customer) => async (dispatch) => {
    dispatch({ type: DOWNLOAD_BTN, payload: true });
    QuotationService.getPdf(id)
        .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = `QU (${quotation_no}) ${customer}.pdf`;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            dispatch({ type: DOWNLOAD_BTN, payload: false });
        })

        .catch((err) => {
            dispatch({ type: DOWNLOAD_BTN, payload: false });
            try {
                ToastDanger(err.data.message);
            } catch (error) {
                ToastDanger('Failed to generate PDF.');
            }
        });
};

export const emailConfig = () => async (dispatch) => {
    let x = 0;
    while (x < 3) {
        try {
            const result = await QuotationService.getEmailConfig();
            dispatch({ type: EMAIL_SETTING_DATA, payload: result.data });
            x = 4;
        } catch (err) {
            // Temporary comment coz connection is most likely to timeout
            // ToastDanger('Failed to load email configuration.');
            console.log('error', err);
            x++;
        }
    }
};

const verfiyPass = (callback) => {
    SwallverfiyPass(function (password) {
        let formdata = {
            password: password,
            admin_email: getUserData().admin_email,
        };
        QuotationService.verifyPassword(formdata)
            .then((result) => {
                SwalSuccessAutoRemove(result.data.message, callback('success'));
            })
            .catch((err) => {
                console.log('err', err);
                try {
                    SwalError(err.data.message);
                } catch (error) {
                    console.log('error', error);
                    SwalError('Unknown error occured');
                }
                // reject(err)
            });
    });
};

export const dynamiPdf = (e, id, index) => async (dispatch, getState) => {
    let { quotations } = getState().quotation;
    let quote = quotations[index];
    quote.download_pdf = true;
    quotations[index] = quote;
    dispatch({ type: DYNAMIC_SEND_EMAIL, payload: quotations });
    QuotationService.getPdf(id)
        .then((result) => {
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = `QU (${quote.quotation_no}) ${quote.customer}.pdf`;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            quote.download_pdf = false;
            quotations[index] = quote;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: quotations });
        })

        .catch((err) => {
            quote.download_pdf = false;
            quotations[index] = quote;
            dispatch({ type: DYNAMIC_SEND_EMAIL, payload: quotations });
            try {
                ToastDanger(err.data.message);
            } catch (error) {
                ToastDanger('Failed to generate PDF.');
            }
        });
};

export const clear_data = () => async (dispatch) => {
    dispatch({ type: CLEAR_DATA });
};

export const cancelQuote = () => async (dispatch, getState) => {
    let { single_quotation } = getState().quotation;
    SwalWarningHtml(
        'Are You Sure?',
        'You are about to cancel Quotation <b>' +
            single_quotation.quotation_no +
            '</b>. Continue?',
        () => dispatch(passwordCancel())
    );
};

const passwordCancel = () => async (dispatch) => {
    SwallverfiyPass(function (password) {
        let formdata = {
            password: password,
            admin_email: getUserData().admin_email,
            role_id: getUserData().role_id,
            subscriber_id: getUserData().subscriber_id,
            id: getUserData()._id,
        };
        QuotationService.verifyPassword(formdata)
            .then((result) => {
                SwalSuccessAutoRemove(
                    result.data.message,
                    dispatch(submitCancel())
                );
            })
            .catch((err) => {
                console.log('err', err);
                try {
                    SwalError(err.data.message);
                } catch (error) {
                    console.log('error', error);
                    SwalError('Unknown error occured');
                }
                // reject(err)
            });
    });
};

export const submitCancel = () => async (dispatch, getState) => {
    let { single_quotation, btn_form } = getState().quotation;
    btn_form.cancel = true;
    dispatch({ type: BTN_FORM, payload: btn_form });
    QuotationService.cancelQuotation(single_quotation._id)
        .then((result) => {
            btn_form.cancel = false;
            dispatch({ type: BTN_FORM, payload: btn_form });
            ToastSuccess(
                'Quotation: ' + single_quotation.quotation_no + ' cancelled'
            );
            dispatch(setPage('main_page'));
        })
        .catch((err) => {
            btn_form.cancel = false;
            dispatch({ type: BTN_FORM, payload: btn_form });
            try {
                ToastDanger(err.data.message);
            } catch (err) {
                ToastDanger('Failed to cancel Sales Invoice');
            }
        });
};
