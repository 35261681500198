import http from '../../../Services/api.laravel.http';


export const UserService = {
 	getPermissions: () => http.post('/settings/roles/get/permissions'),
 	savePermission: (formParams) => http.post('/settings/roles', formParams),
 	searchPermissions: (formParams) => http.post('/settings/roles/search', formParams),
 	ArchiveBrand: (formParams) => http.post('/settings/roles/archive/multiple', formParams),
 	getRole: (id) => http.get('/settings/roles/' + id),
 	updatePermission: (id, formParams) => http.put('/settings/roles/' +id, formParams),

 	getRoles: () => http.get('/settings/roles/get/'),
 	saveAccount: (formParams) => http.post('/settings/accounts', formParams),
 	getAccounts: (formParams) => http.post('/settings/accounts/search', formParams),
 	ArchiveAccounts: (formParams) => http.post('/settings/accounts/archive/multiple', formParams),
 	getAccount: (id) => http.get('/settings/accounts/' + id),
 	updateAccount: (id, formParams) => http.put('/settings/accounts/' + id, formParams),
}
