import http from '../../../Services/api.laravel.http';
import httpWithFile from '../../../Services/api.laravel.httpWithFile';
import { search } from '../../../Services/api.laravel.search';

export const ProfileService = {
    getUser: () => http.get('/user/details'),
    updateCred: (formdata) =>
        http.post('/settings/accounts/update-credentials', formdata),
    uploadProfile: (formdata) =>
        http.post('/settings/accounts/update-profile', formdata),
    updateInfo: (formdata) =>
        http.post('/settings/accounts/update-info', formdata),
};
