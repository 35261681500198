import React, { Fragment, PropTypes, useEffect, useState } from 'react';
import Spinner from '../layouts/Spinner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { connect, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { numberFormat, stockClass } from '../../Utils/Common';
import { customAlert } from '../../Utils/Confirm';
import {
    getPO,
    getPoData,
    handleInputChange,
    submitReceiving,
    checkBoxManual,
    load_supplier,
    SearchSupplier,
    handleSelectSupplier,
    getSupplier,
    setModal,
    add_items,
    searchProduct,
    dynamicSelect,
    removeitems,
    expiryDate,
    dynamicInputChange,
    editCost,
    editSrp,
    searchItem,
    getReceivingTemplates,
    ShowComboBox,
    handleSelectTemplate,
    clear_data,
    getSingleProduct,
    IRDatePicker,
} from '../../redux/actions/receiving/receiving.actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactTooltip from 'react-tooltip';
import { ToastDanger } from '../../Services/_toast.service';
import { SwalWarning } from '../../Services/_swal.service';
import ReceivingSupplierModal from './modals/Receiving.supplier.modal';
import { Link, Redirect } from 'react-router-dom';
import { Show, useAccess } from 'react-redux-permission';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';
import DynamicFont from 'react-dynamic-font';
import debounce from 'debounce-promise';
import moment from 'moment';

function Receiving_Create({
    receiving: {
        loading,
        ir_default_template,
        po_data,
        po_items,
        supplier_name,
        PO,
        single_receiving,
        single_boolean,
        suppliers,
        suppliers_default,
        supp_items,
        receivingData,
        payment_type_options,
        terms_conditions,
        supplier_modal,
        btn_submit,
        editable_cost,
        editable_srp,
        redirect,
        item_receipt_templates,
    },
    getPO,
    getPoData,
    handleInputChange,
    submitReceiving,
    checkBoxManual,
    load_supplier,
    SearchSupplier,
    handleSelectSupplier,
    getSupplier,
    handleSelectPaymentType,
    getTermsConditions,
    setModal,
    dynamicSelect,
    add_items,
    searchProduct,
    removeitems,
    expiryDate,
    dynamicInputChange,
    editCost,
    editSrp,
    searchItem,
    block_route,
    blockRoute,
    getReceivingTemplates,
    handleSelectTemplate,
    clear_data,
    ShowComboBox,
    IRDatePicker,
}) {
    const { hasPermission } = useAccess();
    const canViewCost = hasPermission('inv.receiving.itemReceipts.cost');

    const getQueryParams = () =>
        window.location.search
            .replace('?', '')
            .split('&')
            .reduce(
                (r, e) => (
                    (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1])),
                    r
                ),
                {}
            );
    let params = getQueryParams();
    let default_po = {};
    let sup_id = null;
    if (params) {
        if (params.po_id) {
            default_po = { value: params.po_id, label: params.po_no };
        }
    }

    let history = useHistory();

    useEffect(() => {
        getPO();
        load_supplier();
        getSupplier();
        clear_data();
        let params = getQueryParams();
        if (params) {
            if (params.po_id) {
                getPoData(params.po_id);
                setOrderId(params.po_id);
                setSubmit(false);
            }
        }

        blockRoute(false);
        getReceivingTemplates();
    }, []);

    const [showArrowBox, setShowArrowBox] = useState({
        show: false,
        key: null,
        position: { x: 0, y: 0 },
    });

    // console.log(showArrowBox)

    const dispatch = useDispatch();
    // Add the handleHoverProduct function
    const handleHoverProduct = (productId, key, e) => {
        dispatch(getSingleProduct(productId));

        // Update the position of the hover box based on mouse coordinates
        setShowArrowBox({
            show: true,
            key,
            position: { x: e.clientX, y: e.clientY },
        });
    };

    const handleMouseMove = (e) => {
        // Update the position of the hover box based on mouse coordinates
        setShowArrowBox((prev) => ({
            ...prev,
            position: { x: e.clientX, y: e.clientY },
        }));
    };

    const handleScroll = () => {
        // Update the position of the hover box based on scroll
        setShowArrowBox((prev) => ({
            ...prev,
            position: { ...prev.position, y: prev.position.y + window.scrollY },
        }));
    };

    useEffect(() => {
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
        return () => {
            // Remove scroll event listener on component unmount
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // When onClick navbar prevent another dialog box
        window.addEventListener('beforeunload', function (e) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
            blockRoute(false);
        });

        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () =>
        window.history.pushState(null, null, window.location.pathname);

    // const [delivery_date, setdatePick] = useState(new Date());
    const delivery_date = receivingData.delivery_date;

    const [status, setStatus] = useState('draft');
    const [purchase_order_id, setOrderId] = useState();
    const [enable_submit, setSubmit] = useState(true);
    const selectChange = (selectOption) => {
        getPoData(selectOption.value);
        setOrderId(selectOption.value);
        // enable_submit = false;
        setSubmit(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();
    };

    const submitClick = (status) => {
        if (purchase_order_id == undefined && !single_boolean) {
            ToastDanger('P.O number required.');
        } else if (delivery_date == null) {
            ToastDanger('Delivery date required.');
        } else if (single_boolean) {
            // if(single_receiving.reference_no == '')
            // {
            //     ToastDanger("Reference number required.");
            // }
            // else{
            //     finalConfirmation(status);
            // }
            finalConfirmation(status);
        } else {
            setStatus(status);
            finalConfirmation(status);
        }
    };

    const finalConfirmation = (status) => {
        if (status == 'saved' || status == 'saved-print') {
            SwalWarning(
                'Are you sure?',
                'You are about to save Item Receipt, saving this will stop you from editing any other changes. Continue?',
                function () {
                    formsubmit(status);
                }
            );
        } else if (status == 'saved-email') {
            SwalWarning(
                'Are you sure?',
                'You are about to save and email Item Receipt, saving this will stop you from editing any other changes. Continue?',
                function () {
                    formsubmit(status);
                }
            );
        } else if (status == 'draft-email') {
            SwalWarning(
                'Are you sure?',
                'You are about to draft and email this Item Receipt. Continue?',
                function () {
                    formsubmit(status);
                }
            );
        } else {
            formsubmit(status);
        }
    };

    const formsubmit = (stat) => {
        let formdata = single_receiving;
        let stat_arr = stat.split('-');
        let stats = stat_arr[0];
        let actions = stat_arr[1] !== undefined ? stat_arr[1] : null;
        if (actions == 'email') {
            formdata.send_to_email = true;
        }
        formdata.purchase_order_items = JSON.stringify(po_items);
        formdata.purchase_order_id = purchase_order_id;
        formdata.delivery_date = delivery_date;
        formdata.supplier_id = PO.supplier_id;
        formdata.status = stats;
        formdata.manual = single_boolean;
        formdata.actions = actions;
        if (single_boolean) {
            formdata.purchase_order_id = '';
        }
        submitReceiving(formdata);
    };

    const supplierChange = (val) => {
        // console.log("supplier", val);
        PO.supplier_id = val.value;
        sup_id = val.value;
        handleSelectSupplier(val, function (result) {
            setSubmit(false);
        });
    };

    const Option = (props) => {
        return (
            <components.Option {...props}>
                <div>
                    {props.data.label} [{props.data.brand.name}]
                </div>
                <div style={{ fontSize: 11 }}>{props.data.sku}</div>
                <div style={{ fontSize: 11 }}>{props.data.description}</div>
                {/* <div style={{ fontSize: 11 }}>{props.data.lot_no}</div>
        <div style={{ fontSize: 11 }}>{props.data.brand}</div> */}
            </components.Option>
        );
    };

    // const customFilter = (option, searchText) => {
    //   try {
    //     if (
    //       option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
    //       option.data.barcode.toLowerCase().includes(searchText.toLowerCase()) ||
    //       option.data.attribute_string
    //         .toLowerCase()
    //         .includes(searchText.toLowerCase()) ||
    //       option.data.sku.toLowerCase().includes(searchText.toLowerCase()) ||
    //       option.data.product_unit_name
    //         .toLowerCase()
    //         .includes(searchText.toLowerCase()) ||
    //       option.data.description
    //         .toLowerCase()
    //         .includes(searchText.toLowerCase()) ||
    //       option.data.lot_no.toLowerCase().includes(searchText.toLowerCase())
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   } catch (err) {
    //     console.log(err);
    //     // console.log("option", option);
    //     return false;
    //   }
    // };

    const customFilter = (option, searchText) => {
        try {
            // Split the searchText into individual words
            const searchWords = searchText.toLowerCase().split(' ');

            // Loop through each search word and check if it exists in any of the fields
            return searchWords.every((word) =>
                [
                    option.data.label,
                    option.data.barcode,
                    option.data.attribute_string,
                    option.data.sku,
                    option.data.product_unit_name,
                    option.data.description,
                    option.data.lot_no,
                    option.data.brand.name,
                ].some((field) => field?.toLowerCase().includes(word))
            );
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    const popmod = [
        {
            flip: {
                behavior: ['bottom'], // don't allow it to flip to be above
            },
            preventOverflow: {
                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            },
            hide: {
                enabled: false, // turn off since needs preventOverflow to be enabled
            },
        },
    ];

    // console.log("po_items", po_items);
    // console.log("supp_items", supp_items);
    console.log('supplier_name', supplier_name);

    if (redirect) {
        return <Redirect to={'/receivings'} />;
    }

    return (
        <BeforeUnloadComponent
            blockRoute={block_route}
            ignoreBeforeunloadDocument={true}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <ReactTooltip effect="solid" event="click" />
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Receiving
                            </h3>

                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb f-regular">
                                        <li className="breadcrumb-item ">
                                            <a href="#" className="text-white">
                                                Create
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section className="row f-regular">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title"></h4>
                                        <Link to="/receivings">
                                            <i className="ft ft-arrow-left"></i>{' '}
                                            Back
                                        </Link>
                                        <div className="heading-elements">
                                            {' '}
                                            <ul className="list-inline mb-0"></ul>
                                        </div>
                                    </div>
                                    <form
                                        className="card-content f-regular"
                                        onSubmit={onSubmit}
                                    >
                                        <div className="card-body pb-10m">
                                            <div className="row f-regular">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-8"></div>
                                                        <div className="col-md-4">
                                                            <div className="form-group pb-0 mb-0 button_container">
                                                                {/* <input
                                  type='checkbox'
                                  className='switchery'
                                  id='manual-input'
                                  checked={single_boolean}
                                  onChange={(e) => checkBoxManual(e)}
                                /> */}
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            single_boolean
                                                                        }
                                                                        onChange={
                                                                            checkBoxManual
                                                                        }
                                                                    />
                                                                    <span className="slider round"></span>
                                                                </label>
                                                                <label
                                                                    htmlFor="manual-input"
                                                                    className="font-medium-2 text-bold-600 ml-1"
                                                                >
                                                                    {single_boolean
                                                                        ? 'Manual'
                                                                        : 'Automated'}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <label>
                                                                Template{' '}
                                                                <span className="text-danger">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <div className="form-group row">
                                                                <div className="col-md-10 col-10 pr-0">
                                                                    <Select
                                                                        name="receiving_template"
                                                                        value={
                                                                            ir_default_template
                                                                        }
                                                                        onChange={
                                                                            handleSelectTemplate
                                                                        }
                                                                        options={
                                                                            item_receipt_templates
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            href="#"
                                                                            className="tool-tip text-primary"
                                                                            data-tip="this is a tool tip"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4"></div>
                                                        <div className="col-sm-4">
                                                            {single_boolean ? (
                                                                <section>
                                                                    <label>
                                                                        Reference
                                                                        No.
                                                                    </label>
                                                                    <div className="form-group row">
                                                                        <div className="input-group input-group-clear">
                                                                            <div className="col-md-10 col-10 pr-0">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control f-regular"
                                                                                    name="reference_no"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        handleInputChange(
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                    value={
                                                                                        single_receiving.reference_no
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="float-right pl-0 col-md-2 col-2">
                                                                                <div className="input-group-prepend">
                                                                                    <span
                                                                                        href="#"
                                                                                        className="tool-tip text-primary"
                                                                                        data-tip="this is a tool tip"
                                                                                    >
                                                                                        <i className="la la-question-circle"></i>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            ) : (
                                                                <section>
                                                                    <label>
                                                                        Select
                                                                        PO No.
                                                                    </label>
                                                                    <div className="form-group row">
                                                                        <div className="col-md-10 col-10 pr-0">
                                                                            {default_po ? (
                                                                                <Select
                                                                                    name="po_select"
                                                                                    onChange={
                                                                                        selectChange
                                                                                    }
                                                                                    defaultValue={
                                                                                        default_po
                                                                                    }
                                                                                    options={
                                                                                        po_data
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <Select
                                                                                    name="po_select"
                                                                                    onChange={
                                                                                        selectChange
                                                                                    }
                                                                                    options={
                                                                                        po_data
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <div className="float-right pl-0 col-md-2 col-2">
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <label>
                                                                Supplier{' '}
                                                                <span className="red">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <div className="form-group row">
                                                                {single_boolean ? (
                                                                    <Fragment>
                                                                        <div className="col-md-10 col-10 pr-0">
                                                                            <AsyncSelect
                                                                                isLoading={
                                                                                    loading
                                                                                }
                                                                                loadingMessage={() =>
                                                                                    'searching...'
                                                                                }
                                                                                noOptionsMessage={({
                                                                                    inputValue,
                                                                                }) =>
                                                                                    inputValue
                                                                                        ? 'No Supplier'
                                                                                        : 'Search Supplier'
                                                                                }
                                                                                defaultOptions={
                                                                                    suppliers_default
                                                                                }
                                                                                loadOptions={
                                                                                    SearchSupplier
                                                                                }
                                                                                isSearchable={
                                                                                    true
                                                                                }
                                                                                cacheOptions
                                                                                onChange={
                                                                                    handleSelectSupplier
                                                                                }
                                                                                placeholder="Select Supplier..."
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="float-right pl-0 col-md-2 col-2">
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                                <div
                                                                                    className="a-tagicon icon-s"
                                                                                    onClick={() =>
                                                                                        setModal(
                                                                                            'supplier_modal',
                                                                                            null,
                                                                                            true
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <i className="ft ft-plus-circle text-primary"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        <div className="col-md-10 col-10 pr-0">
                                                                            <div className="form-control f-regular">
                                                                                {
                                                                                    supplier_name
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="float-right pl-0 col-md-2 col-2">
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4"></div>
                                                        <div className="col-sm-4">
                                                            <label>
                                                                Delivery Date:{' '}
                                                                <span className="red">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <div className="form-group row">
                                                                <div className="col-md-10 col-10 pr-0">
                                                                    <div className="date-picker-100">
                                                                        <DatePicker
                                                                            onChange={(
                                                                                date
                                                                            ) =>
                                                                                IRDatePicker(
                                                                                    date
                                                                                )
                                                                            }
                                                                            selected={
                                                                                delivery_date
                                                                            }
                                                                            name="delivery_date"
                                                                            className="form-control f-regular"
                                                                            autoComplete="off"
                                                                            peekNextMonth
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            href="#"
                                                                            className="tool-tip text-primary"
                                                                            data-tip="this is a tool tip"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ReactTooltip
                                                effect="solid"
                                                event="click"
                                            />
                                            <div className="row">
                                                <div className="col-md-12 table-responsive">
                                                    <table className="table table-condensed table-bordered f-regular table-responsive-sm">
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    className="text-center min-wdth-300 max-wdth-300"
                                                                    rowSpan="2"
                                                                    width="150px"
                                                                >
                                                                    LOT #{' '}
                                                                    <a
                                                                        href="#"
                                                                        className="tool-tip"
                                                                        data-place="right"
                                                                        data-tip="leaving this column blank will auto generate LOT #"
                                                                    >
                                                                        <i className="la la-question-circle"></i>
                                                                    </a>
                                                                </th>
                                                                <th
                                                                    rowSpan="2"
                                                                    width="30%"
                                                                >
                                                                    Product
                                                                </th>
                                                                <th
                                                                    className="text-center"
                                                                    colSpan="2"
                                                                    width="120px"
                                                                >
                                                                    Ordered
                                                                </th>
                                                                <th
                                                                    className="text-center"
                                                                    colSpan="2"
                                                                    width="120px"
                                                                >
                                                                    Received
                                                                </th>
                                                                <th
                                                                    rowSpan="2"
                                                                    className="text-center"
                                                                    width="120px"
                                                                >
                                                                    SRP
                                                                    <Show when="inv.receiving.itemReceipts.srp">
                                                                        <a
                                                                            className="ml-10"
                                                                            onClick={() =>
                                                                                editSrp(
                                                                                    editable_srp
                                                                                )
                                                                            }
                                                                        >
                                                                            {editable_srp ? (
                                                                                <i className="icon-unlock text-success"></i>
                                                                            ) : (
                                                                                <i className="icon-lock"></i>
                                                                            )}
                                                                        </a>
                                                                    </Show>
                                                                </th>
                                                                <th
                                                                    rowSpan="2"
                                                                    className="text-center"
                                                                >
                                                                    Expiry Date{' '}
                                                                    <a
                                                                        href="#"
                                                                        className="tool-tip"
                                                                        data-place="left"
                                                                        data-tip="leaving this column blank means product has no expiration date"
                                                                    >
                                                                        <i className="la la-question-circle"></i>
                                                                    </a>
                                                                </th>
                                                                {single_boolean ? (
                                                                    <th
                                                                        rowSpan="2"
                                                                        width="5%"
                                                                    ></th>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    className="text-center"
                                                                    width="80px"
                                                                >
                                                                    Qty
                                                                </th>
                                                                <th
                                                                    className="text-center"
                                                                    width="80px"
                                                                >
                                                                    Unit
                                                                </th>
                                                                <th
                                                                    className="text-center"
                                                                    width="80px"
                                                                >
                                                                    {' '}
                                                                    Cost
                                                                    <Show when="inv.receiving.itemReceipts.cost">
                                                                        <a
                                                                            className="ml-10"
                                                                            onClick={() =>
                                                                                editCost(
                                                                                    editable_cost
                                                                                )
                                                                            }
                                                                        >
                                                                            {editable_cost ? (
                                                                                <i className="icon-unlock text-success"></i>
                                                                            ) : (
                                                                                <i className="icon-lock"></i>
                                                                            )}
                                                                        </a>
                                                                    </Show>
                                                                </th>
                                                                <th
                                                                    className="text-center"
                                                                    width="80px"
                                                                >
                                                                    Qty
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {!supplier_name &&
                                                                single_boolean &&
                                                                po_items.length ===
                                                                    0 && (
                                                                    <tr
                                                                        align="center"
                                                                        className="bg-warning bg-accent-2"
                                                                    >
                                                                        <td
                                                                            colSpan={
                                                                                9
                                                                            }
                                                                        >
                                                                            <span>
                                                                                --
                                                                                Please
                                                                                select
                                                                                Supplier
                                                                                first
                                                                                --
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )}

                                                            {loading ? (
                                                                <tr>
                                                                    <td
                                                                        colSpan={
                                                                            single_boolean
                                                                                ? 9
                                                                                : 8
                                                                        }
                                                                        className="text-center"
                                                                    >
                                                                        <Spinner />
                                                                    </td>
                                                                </tr>
                                                            ) : single_boolean &&
                                                              po_items.length >
                                                                  0 ? (
                                                                po_items.map(
                                                                    (
                                                                        data,
                                                                        key
                                                                    ) => {
                                                                        return (
                                                                            <Fragment
                                                                                key={
                                                                                    key
                                                                                }
                                                                            >
                                                                                {/* Manually */}
                                                                                <tr
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    className={stockClass(
                                                                                        data.status_code
                                                                                    )}
                                                                                >
                                                                                    <td className="min-wdth-300 max-wdth-300">
                                                                                        <input
                                                                                            type="text"
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                dynamicInputChange(
                                                                                                    e,
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                            name="lot_no"
                                                                                            value={
                                                                                                data.lot_no
                                                                                            }
                                                                                            className="form-control"
                                                                                        />
                                                                                    </td>

                                                                                    <td className="min-wdth-300 max-wdth-300">
                                                                                        {data.selected ? (
                                                                                            <div>
                                                                                                {/* <div className='f-right' 
                                              onClick={() => ShowComboBox(key)}>   
                                              <i className='ft ft-chevron-down c-pointer'></i>                                                
                                              </div> */}
                                                                                                <div className="mw-90">
                                                                                                    <Link
                                                                                                        to={`/inventory/view/${data.item_id}`}
                                                                                                        target="_blank"
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            e.stopPropagation()
                                                                                                        }
                                                                                                        onMouseEnter={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            handleHoverProduct(
                                                                                                                data.item_id,
                                                                                                                key,
                                                                                                                e
                                                                                                            )
                                                                                                        }
                                                                                                        onMouseMove={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            handleMouseMove(
                                                                                                                e
                                                                                                            )
                                                                                                        }
                                                                                                        onMouseLeave={() =>
                                                                                                            setShowArrowBox(
                                                                                                                {
                                                                                                                    show: false,
                                                                                                                    key: null,
                                                                                                                    position:
                                                                                                                        {
                                                                                                                            x: 0,
                                                                                                                            y: 0,
                                                                                                                        },
                                                                                                                }
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            data.product_name
                                                                                                        }
                                                                                                    </Link>

                                                                                                    {showArrowBox.show &&
                                                                                                        showArrowBox.key ===
                                                                                                            key && (
                                                                                                            <div
                                                                                                                className="arrow-box"
                                                                                                                style={{
                                                                                                                    top: `${showArrowBox.position.y}px`,
                                                                                                                    left: `${showArrowBox.position.x}px`,
                                                                                                                    position:
                                                                                                                        'fixed',
                                                                                                                }}
                                                                                                            >
                                                                                                                <p>
                                                                                                                    Incoming:{' '}
                                                                                                                    <span className="font-weight-bold">
                                                                                                                        {
                                                                                                                            data
                                                                                                                                .productDetails
                                                                                                                                .incoming_orders
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                </p>
                                                                                                                <p>
                                                                                                                    Current
                                                                                                                    stock:{' '}
                                                                                                                    <span className="font-weight-bold">
                                                                                                                        {
                                                                                                                            data
                                                                                                                                .productDetails
                                                                                                                                .total_stocks
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                </p>
                                                                                                                <p>
                                                                                                                    Last
                                                                                                                    arrival:{' '}
                                                                                                                    <span className="font-weight-bold">
                                                                                                                        {moment(
                                                                                                                            data
                                                                                                                                .productDetails
                                                                                                                                .last_arrival
                                                                                                                        ).isValid()
                                                                                                                            ? moment(
                                                                                                                                  data
                                                                                                                                      .productDetails
                                                                                                                                      .last_arrival
                                                                                                                              ).format(
                                                                                                                                  'YYYY-MM-DD'
                                                                                                                              )
                                                                                                                            : 'N/A'}
                                                                                                                    </span>
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div>
                                                                                                {/* <div className='f-12 text-dark'>
                                                {data.sku}
                                              </div> */}
                                                                                                <div className="f-12 text-dark">
                                                                                                    {
                                                                                                        data.barcode
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="f-12 text-dark">
                                                                                                    {
                                                                                                        data.description
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="f-12 text-dark">
                                                                                                    {
                                                                                                        data
                                                                                                            .brand
                                                                                                            .name
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <AsyncSelect
                                                                                                isLoading={
                                                                                                    loading
                                                                                                }
                                                                                                value={{
                                                                                                    value: data.item_id,
                                                                                                    label: data.product_name,
                                                                                                }}
                                                                                                // filterOption={customFilter}
                                                                                                // options={data.items}
                                                                                                loadingMessage={() =>
                                                                                                    'searching...'
                                                                                                }
                                                                                                noOptionsMessage={({
                                                                                                    inputValue,
                                                                                                }) =>
                                                                                                    inputValue
                                                                                                        ? 'No Products'
                                                                                                        : 'Search product'
                                                                                                }
                                                                                                defaultOptions={
                                                                                                    data.items
                                                                                                }
                                                                                                components={{
                                                                                                    Option,
                                                                                                }}
                                                                                                loadOptions={debounce(
                                                                                                    (
                                                                                                        value
                                                                                                    ) =>
                                                                                                        searchProduct(
                                                                                                            value,
                                                                                                            key
                                                                                                        ),
                                                                                                    1000
                                                                                                )}
                                                                                                isSearchable={
                                                                                                    true
                                                                                                }
                                                                                                onChange={(
                                                                                                    val
                                                                                                ) =>
                                                                                                    dynamicSelect(
                                                                                                        val,
                                                                                                        key
                                                                                                    )
                                                                                                }
                                                                                                placeholder="Select Product..."
                                                                                                required
                                                                                            />
                                                                                        )}

                                                                                        {/* <AsyncSelect loadingMessage={() => 'searching...'} defaultOptions={data.items} cacheOptions loadOptions={(val) => searchItem(val, key)} isSearchable={true} onChange={(val) => dynamicSelect(val, key)} cacheOptions placeholder="Select Items" required/> */}
                                                                                    </td>
                                                                                    <td className="text-center min-wdth-120 max-wdth-100">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control text-right"
                                                                                            step="any"
                                                                                            value={
                                                                                                data.order_qty
                                                                                            }
                                                                                            name="order_qty"
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                dynamicInputChange(
                                                                                                    e,
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                            required
                                                                                        />
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        {
                                                                                            data.product_unit
                                                                                        }
                                                                                    </td>
                                                                                    <td className="text-right">
                                                                                        {editable_cost ? (
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control text-right"
                                                                                                name="cost"
                                                                                                value={
                                                                                                    data.cost
                                                                                                }
                                                                                                step="any"
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    dynamicInputChange(
                                                                                                        e,
                                                                                                        key
                                                                                                    )
                                                                                                }
                                                                                                required
                                                                                            />
                                                                                        ) : (
                                                                                            <label
                                                                                                style={{
                                                                                                    filter: !canViewCost
                                                                                                        ? 'blur(4px)'
                                                                                                        : 'none',
                                                                                                    userSelect:
                                                                                                        !canViewCost
                                                                                                            ? 'none'
                                                                                                            : 'auto',
                                                                                                }}
                                                                                            >
                                                                                                {numberFormat(
                                                                                                    data.cost,
                                                                                                    2,
                                                                                                    true
                                                                                                )}
                                                                                            </label>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="min-wdth-120 max-wdth-100">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control text-right"
                                                                                            value={
                                                                                                data.received_qty
                                                                                            }
                                                                                            name="received_qty"
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                dynamicInputChange(
                                                                                                    e,
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                            step="any"
                                                                                            required
                                                                                        />
                                                                                    </td>

                                                                                    <td className="text-right">
                                                                                        {editable_srp ? (
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control text-right"
                                                                                                value={
                                                                                                    data.srp ||
                                                                                                    data
                                                                                                        .product
                                                                                                        .srp
                                                                                                }
                                                                                                name="srp"
                                                                                                step="any"
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    dynamicInputChange(
                                                                                                        e,
                                                                                                        key
                                                                                                    )
                                                                                                }
                                                                                                required
                                                                                            />
                                                                                        ) : (
                                                                                            <label>
                                                                                                {numberFormat(
                                                                                                    data.srp,
                                                                                                    2,
                                                                                                    true
                                                                                                )}
                                                                                            </label>
                                                                                        )}
                                                                                    </td>

                                                                                    <td className="text-center">
                                                                                        <DatePicker
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                expiryDate(
                                                                                                    e,
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                            popperPlacement="bottom"
                                                                                            popperModifiers={
                                                                                                popmod
                                                                                            }
                                                                                            selected={
                                                                                                data.expiry_date
                                                                                            }
                                                                                            name="expiry_date"
                                                                                            className="form-control f-regular"
                                                                                            autoComplete="off"
                                                                                            peekNextMonth
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            dropdownMode="select"
                                                                                        />
                                                                                    </td>
                                                                                    <td width="100px">
                                                                                        <button
                                                                                            className="btn btn-danger btn-sm"
                                                                                            onClick={() =>
                                                                                                removeitems(
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="ft-trash-2"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </Fragment>
                                                                        );
                                                                    }
                                                                )
                                                            ) : !single_boolean &&
                                                              Object.keys(PO)
                                                                  .length ==
                                                                  0 ? (
                                                                <tr
                                                                    align="center"
                                                                    className="bg-warning bg-accent-2"
                                                                >
                                                                    <td
                                                                        colSpan={
                                                                            8
                                                                        }
                                                                    >
                                                                        <span>
                                                                            --
                                                                            Please
                                                                            select
                                                                            PO
                                                                            No.
                                                                            first
                                                                            --
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                // populating data

                                                                po_items.map(
                                                                    (
                                                                        items,
                                                                        key
                                                                    ) => {
                                                                        return (
                                                                            <Fragment>
                                                                                <tr
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                    className={stockClass(
                                                                                        items.status_code
                                                                                    )}
                                                                                >
                                                                                    <td>
                                                                                        <input
                                                                                            type="text"
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                dynamicInputChange(
                                                                                                    e,
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                            name="lot_no"
                                                                                            value={
                                                                                                items.lot_no
                                                                                            }
                                                                                            className="form-control"
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div>
                                                                                            <div className="mw-90">
                                                                                                <Link
                                                                                                    to={`/inventory/view/${items.item_id}`}
                                                                                                    target="_blank"
                                                                                                    onClick={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        e.stopPropagation()
                                                                                                    }
                                                                                                    onMouseEnter={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        handleHoverProduct(
                                                                                                            items.item_id,
                                                                                                            key,
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                    onMouseMove={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        handleMouseMove(
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                    onMouseLeave={() =>
                                                                                                        setShowArrowBox(
                                                                                                            {
                                                                                                                show: false,
                                                                                                                key: null,
                                                                                                                position:
                                                                                                                    {
                                                                                                                        x: 0,
                                                                                                                        y: 0,
                                                                                                                    },
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        items.product_name
                                                                                                    }
                                                                                                </Link>

                                                                                                {showArrowBox.show &&
                                                                                                    showArrowBox.key ===
                                                                                                        key && (
                                                                                                        <div
                                                                                                            className="arrow-box"
                                                                                                            style={{
                                                                                                                top: `${showArrowBox.position.y}px`,
                                                                                                                left: `${showArrowBox.position.x}px`,
                                                                                                                position:
                                                                                                                    'fixed',
                                                                                                            }}
                                                                                                        >
                                                                                                            <p>
                                                                                                                Incoming:{' '}
                                                                                                                <span className="font-weight-bold">
                                                                                                                    {
                                                                                                                        items
                                                                                                                            .productDetails
                                                                                                                            .incoming_orders
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                Current
                                                                                                                stock:{' '}
                                                                                                                <span className="font-weight-bold">
                                                                                                                    {
                                                                                                                        items
                                                                                                                            .productDetails
                                                                                                                            .total_stocks
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                Last
                                                                                                                arrival:{' '}
                                                                                                                <span className="font-weight-bold">
                                                                                                                    {moment(
                                                                                                                        items
                                                                                                                            .productDetails
                                                                                                                            .last_arrival
                                                                                                                    ).isValid()
                                                                                                                        ? moment(
                                                                                                                              items
                                                                                                                                  .productDetails
                                                                                                                                  .last_arrival
                                                                                                                          ).format(
                                                                                                                              'YYYY-MM-DD'
                                                                                                                          )
                                                                                                                        : 'N/A'}
                                                                                                                </span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    )}
                                                                                            </div>
                                                                                            {/* <div className='f-12 text-dark'>
                                              {items.sku}
                                            </div> */}
                                                                                            <div className="f-12 text-dark">
                                                                                                {
                                                                                                    items.barcode
                                                                                                }
                                                                                            </div>
                                                                                            <div className="f-12 text-dark">
                                                                                                {
                                                                                                    items.description
                                                                                                }
                                                                                            </div>
                                                                                            <div className="f-12 text-dark">
                                                                                                {items.brand
                                                                                                    ? items
                                                                                                          .brand
                                                                                                          .name
                                                                                                    : ''}
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td className="text-center min-wdth-120 max-wdth-100">
                                                                                        {numberFormat(
                                                                                            items.order_qty,
                                                                                            0
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        {
                                                                                            items.product_unit
                                                                                        }
                                                                                    </td>
                                                                                    <td className="text-right min-wdth-120 max-wdth-100">
                                                                                        {editable_cost ? (
                                                                                            <input
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    dynamicInputChange(
                                                                                                        e,
                                                                                                        key
                                                                                                    )
                                                                                                }
                                                                                                name="cost"
                                                                                                value={numberFormat(
                                                                                                    items.cost,
                                                                                                    2,
                                                                                                    false
                                                                                                )}
                                                                                                type="number"
                                                                                                step="any"
                                                                                                min="0"
                                                                                                className="form-control text-right"
                                                                                                required
                                                                                            />
                                                                                        ) : (
                                                                                            <label>
                                                                                                {numberFormat(
                                                                                                    items.cost,
                                                                                                    2,
                                                                                                    true
                                                                                                )}
                                                                                            </label>
                                                                                        )}
                                                                                    </td>

                                                                                    <td className="min-wdth-120 max-wdth-100">
                                                                                        <input
                                                                                            type="number"
                                                                                            step="any"
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                dynamicInputChange(
                                                                                                    e,
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                            name="received_qty"
                                                                                            value={
                                                                                                items.received_qty
                                                                                            }
                                                                                            min="0"
                                                                                            className="form-control text-right"
                                                                                        />
                                                                                    </td>
                                                                                    <td className="text-right min-wdth-120 max-wdth-100">
                                                                                        {editable_srp ? (
                                                                                            <input
                                                                                                type="number"
                                                                                                step="any"
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    dynamicInputChange(
                                                                                                        e,
                                                                                                        key
                                                                                                    )
                                                                                                }
                                                                                                name="srp"
                                                                                                min="0"
                                                                                                className="form-control text-right"
                                                                                                value={numberFormat(
                                                                                                    items.srp,
                                                                                                    2,
                                                                                                    false
                                                                                                )}
                                                                                            />
                                                                                        ) : (
                                                                                            <label>
                                                                                                {numberFormat(
                                                                                                    items.srp,
                                                                                                    2,
                                                                                                    true
                                                                                                )}
                                                                                            </label>
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        <DatePicker
                                                                                            onChange={(
                                                                                                e
                                                                                            ) =>
                                                                                                expiryDate(
                                                                                                    e,
                                                                                                    key
                                                                                                )
                                                                                            }
                                                                                            popperPlacement="bottom"
                                                                                            popperModifiers={
                                                                                                popmod
                                                                                            }
                                                                                            selected={
                                                                                                items.expiry_date
                                                                                            }
                                                                                            name="expiry_date"
                                                                                            className="form-control f-regular"
                                                                                            autoComplete="off"
                                                                                            peekNextMonth
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            dropdownMode="select"
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </Fragment>
                                                                        );
                                                                    }
                                                                )
                                                            )}
                                                        </tbody>

                                                        {po_items.length !==
                                                        0 ? (
                                                            // supp_items.length != 0 ? (
                                                            <tfoot>
                                                                <tr>
                                                                    <td
                                                                        colSpan="9"
                                                                        className="text-left"
                                                                    >
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            onClick={
                                                                                add_items
                                                                            }
                                                                        >
                                                                            <i className="la la-plus"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        ) : supplier_name ? (
                                                            <tfoot>
                                                                <tr>
                                                                    <td
                                                                        colSpan="9"
                                                                        className="text-left"
                                                                    >
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            onClick={
                                                                                add_items
                                                                            }
                                                                        >
                                                                            <i className="la la-plus"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        ) : (
                                                            <tfoot></tfoot>
                                                        )}
                                                    </table>

                                                    <div className="row mt-3">
                                                        <div className="col-md-4">
                                                            <label>
                                                                Remarks{' '}
                                                            </label>
                                                            <div className="form-group row">
                                                                <div className="col-md-10 col-10 pr-0">
                                                                    <textarea
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                        value={
                                                                            single_receiving.remarks
                                                                        }
                                                                        name="remarks"
                                                                        className="form-control f-regular"
                                                                        rows="2"
                                                                    ></textarea>
                                                                </div>
                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                    <div className="input-group-prepend">
                                                                        <span
                                                                            href="#"
                                                                            className="tool-tip text-primary"
                                                                            data-tip="this is a tool tip"
                                                                        >
                                                                            <i className="la la-question-circle"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="row justify-content-end mt-3">
                                                <div className="mr-2">
                                                    <div className="form-group">
                                                        {/* <Show when="inv.receiving.itemReceipts.draft">
                                                        <button className="btn btn-outline-light text-dark " type="submit" onClick={() => submitClick('draft')}>Draft</button>
                                                    </Show>
                                                    <button className="btn btn-primary text-white margin-l-5" type="submit" onClick={() => submitClick('saved')} disabled={btn_submit}>Save</button> */}
                                                        {/* <a className="btn btn-danger text-white margin-l-5" href="/receivings">Cancel</a> */}
                                                        <Show when="inv.receiving.itemReceipts.draft">
                                                            <div className="btn-group btn-dpd">
                                                                <button
                                                                    className="btn btn-default btn-outline-light text-dark btn-main"
                                                                    onClick={() =>
                                                                        submitClick(
                                                                            'draft'
                                                                        )
                                                                    }
                                                                    type="button"
                                                                    disabled={
                                                                        loading
                                                                    }
                                                                >
                                                                    Save as
                                                                    Draft
                                                                </button>
                                                                <button
                                                                    data-toggle="dropdown"
                                                                    className="btn btn-default btn-outline-light text-dark dropdown-toggle btn-carret"
                                                                    type="button"
                                                                    disabled={
                                                                        loading
                                                                    }
                                                                >
                                                                    <span className="caret"></span>
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    {/* <li><a className="dpd-menu" onClick={() => submitClick('draft')}>Daft</a></li> */}
                                                                    <li>
                                                                        <a
                                                                            className="dpd-menu"
                                                                            onClick={() =>
                                                                                submitClick(
                                                                                    'draft-email'
                                                                                )
                                                                            }
                                                                        >
                                                                            Draft
                                                                            &
                                                                            Email
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="dpd-menu"
                                                                            onClick={() =>
                                                                                submitClick(
                                                                                    'draft-print'
                                                                                )
                                                                            }
                                                                        >
                                                                            Draft
                                                                            &
                                                                            Print
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </Show>{' '}
                                                        <div className="btn-group btn-dpd">
                                                            <button
                                                                className="btn btn-primary  btn-main"
                                                                onClick={() =>
                                                                    submitClick(
                                                                        'saved'
                                                                    )
                                                                }
                                                                disabled={
                                                                    loading
                                                                }
                                                                type="button"
                                                            >
                                                                Save Receiving
                                                            </button>
                                                            <button
                                                                data-toggle="dropdown"
                                                                className="btn btn-primary dropdown-toggle btn-carret"
                                                                type="button"
                                                                disabled={
                                                                    loading
                                                                }
                                                            >
                                                                <span className="caret"></span>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                {/* <li><a className="dpd-menu" href="#" onClick={() => submitClick('saved')} disabled={btn_submit}>Save</a></li> */}
                                                                <li>
                                                                    <a
                                                                        className="dpd-menu"
                                                                        onClick={() =>
                                                                            submitClick(
                                                                                'saved-email'
                                                                            )
                                                                        }
                                                                    >
                                                                        Save &
                                                                        Email
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a
                                                                        className="dpd-menu"
                                                                        onClick={() =>
                                                                            submitClick(
                                                                                'saved-print'
                                                                            )
                                                                        }
                                                                    >
                                                                        Save &
                                                                        Print
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <ReceivingSupplierModal
                    show={supplier_modal}
                    onHide={() => setModal('supplier_modal', null, false)}
                />
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    receiving: state.receiving,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    getPO,
    getPoData,
    handleInputChange,
    submitReceiving,
    checkBoxManual,
    load_supplier,
    SearchSupplier,
    dynamicSelect,
    getSupplier,
    setModal,
    add_items,
    searchProduct,
    handleSelectSupplier,
    removeitems,
    expiryDate,
    dynamicInputChange,
    editCost,
    editSrp,
    searchItem,
    blockRoute,
    getReceivingTemplates,
    handleSelectTemplate,
    clear_data,
    ShowComboBox,
    getSingleProduct,
    IRDatePicker,
})(Receiving_Create);
