import React, { Fragment, useEffect } from 'react';
import { defaultFormat } from '../../../Utils/DateFormat';
import { numberFormat, capitalize, formatPrice } from '../../../Utils/Common';
import Pagination from 'react-js-pagination';
import DynamicFont from 'react-dynamic-font';
import { Link } from 'react-router-dom';

export class PurchaseOrderPrintContent extends React.PureComponent {
    // function PurchaseOrderPrintContent({ po }) {

    constructor(props) {
        super(props);
        this.divScrollRef = React.createRef();

        let img_url = '/app-assets/images/logo/sample-logo.png';
        const tableRows = this.renderExtrRow(this.props.print_rows);
        let totalItemsCount = this.props.po.purchase_order_items.length;
        var remainder = totalItemsCount % 25;
        var remainderCheck = 0;

        if (remainder == 0 || remainder >= 21) {
            totalItemsCount += 5;
            remainderCheck = 1;
        }
        // console.log("this.props.po", this.props.po)
        this.state = {
            po: this.props.po,
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: totalItemsCount,
            pageRangeDisplayed: 5,
            img_url: img_url,
            tableRows: tableRows,
            print: this.props.print,
            remainderCheck: remainderCheck,
            subs_setting: this.props.subs_setting,
        };
    }

    renderExtrRow(numberOfRows) {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(
                <tr key={i}>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }

        return tableRows;
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.divScrollRef.current.scrollIntoView();
    }

    renderPrint(page, totalPage) {
        const { po } = this.state;
        const totalItemsCount = po.purchase_order_items.length;
        var showItemsTo = page * this.state.itemsCountPerPage;
        var showItemsFrom = showItemsTo - this.state.itemsCountPerPage;
        showItemsTo = showItemsTo - 1;
        let extraRowsCount = this.state.itemsCountPerPage;
        if (this.state.print == 0) {
            // extraRowsCount -= 15;
        }

        const { canViewCost } = this.props;

        po.purchase_order_items.map((item, i) => {
            if (showItemsFrom <= i && showItemsTo >= i) {
                extraRowsCount--;
            }
        });

        const DynamicStringStyle = {
            maxWidth: '150px',
            fontSize: '1rem',
            lineHeight: '1.45',
            overflow: 'hidden',
        };

        const supplierAddress = [
            po.supplier_details.street1,
            po.supplier_details.street2 && ', ',
            po.supplier_details.street2,
            (po.supplier_details.street1 || po.supplier_details.street2) &&
                ', ',
            po.supplier_details.city,
            po.supplier_details.city && po.supplier_details.state && ', ',
            po.supplier_details.state,
        ]
            .filter(Boolean)
            .join('');

        const template_setting = this.state.po.template_setting;

        // get currency display from subscriber settings
        const subs_setting = this.state.subs_setting;
        const currencyDisplay =
            'currency' in subs_setting &&
            'code' &&
            subs_setting.currency &&
            subs_setting.currency.code &&
            subs_setting.currency.code.checked
                ? 'code'
                : 'symbol';

        return (
            <div className="margin-1p5em px-1 print-page mt-1">
                <div className="print-logo-container print-header-container">
                    {/* {template_setting && template_setting.company_logo && (
            <img src={template_setting.company_logo} className='print-logo' />
          )}
        {template_setting.company_logo == "" || null ? (
            template_setting ? (
              <div className='c-name'>{template_setting.company_name}</div>
            ) : (
              <div className='print-logo-empty'></div>
            )
          ) : null} */}
                    {/* {template_setting && template_setting.company_logo ? (
              <img src={template_setting.company_logo} className='print-logo' />
          ) : (
              template_setting ? (
                  <div className='c-name'>{template_setting.company_name}</div>
              ) : (
                  <div className='print-logo-empty'></div>
              )
          )} */}
                    <img
                        src={
                            template_setting &&
                            'company_logo' in template_setting
                                ? template_setting.company_logo
                                : '/BlitzLogo.png'
                        }
                        className="print-logo"
                    />

                    <div className="print-header text-right">
                        <span className="print-header-text">
                            PURCHASE ORDER
                        </span>
                    </div>
                </div>
                <div className="print-body row print-content-block">
                    <div className="col-md-12">
                        <span className="f-20b">Invoice to:</span>
                    </div>
                    <div className="col-md-4 col-print-4">
                        <span className="f-16b">
                            <Link
                                to={`/purchases/suppliers/history/${po.supplier_details._id}`}
                                className="txt-underline"
                                target="_blank"
                            >
                                {po.supplier_details.name}
                            </Link>
                        </span>
                        <p className="f-12">{supplierAddress}</p>
                    </div>
                    <div className="col-md-4 col-print-4"></div>
                    <div className="col-md-4 col-print-4 pr-0">
                        <span className="f-16b">PO No</span>{' '}
                        <span className="f-right">{po.purchase_order_no}</span>
                        <br />
                        <span className="f-16b">Date</span>{' '}
                        <span className="f-right">
                            {defaultFormat(po.purchase_order_date)}
                        </span>
                    </div>
                    <div className="col-md-12 pr-0">
                        {po.status == 'draft' ? (
                            <div className="watermark">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <table className="table table-striped table-print">
                            <thead>
                                <tr key={-1} className="print-table-header">
                                    <th>#</th>
                                    <th>Item Description</th>
                                    <th className="text-center">Price</th>
                                    <th className="text-center">Unit</th>
                                    <th className="text-center">Qty</th>
                                    <th className="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {po.purchase_order_items.map((item, i) =>
                                    showItemsFrom <= i ? (
                                        showItemsTo >= i ? (
                                            <>
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td
                                                        style={
                                                            DynamicStringStyle
                                                        }
                                                    >
                                                        <Link
                                                            to={`/inventory/view/${item._id}`}
                                                            className="txt-underline"
                                                            target="_blank"
                                                        >
                                                            <DynamicFont
                                                                content={
                                                                    item.product_name
                                                                }
                                                            />
                                                        </Link>
                                                        {item.product !=
                                                            null && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {
                                                                        item
                                                                            .product
                                                                            .barcode
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                        {item.product !=
                                                            null && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {
                                                                        item
                                                                            .product
                                                                            .description
                                                                    }
                                                                </span>
                                                            </>
                                                        )}

                                                        {item.brand && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {
                                                                        item
                                                                            .brand
                                                                            .name
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        <label
                                                            style={{
                                                                filter: !canViewCost
                                                                    ? 'blur(4px)'
                                                                    : 'none',
                                                                userSelect:
                                                                    !canViewCost
                                                                        ? 'none'
                                                                        : 'auto',
                                                            }}
                                                        >
                                                            {formatPrice(
                                                                item.cost,
                                                                currencyDisplay
                                                            )}
                                                        </label>
                                                    </td>
                                                    <td className="text-center">
                                                        {item.product_unit
                                                            ? item.product_unit
                                                                  .name
                                                            : ''}
                                                    </td>
                                                    <td className="text-center">
                                                        {numberFormat(
                                                            item.qty,
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        <label
                                                            style={{
                                                                filter: !canViewCost
                                                                    ? 'blur(4px)'
                                                                    : 'none',
                                                                userSelect:
                                                                    !canViewCost
                                                                        ? 'none'
                                                                        : 'auto',
                                                            }}
                                                        >
                                                            {formatPrice(
                                                                item.amount,
                                                                currencyDisplay
                                                            )}
                                                        </label>
                                                    </td>
                                                </tr>

                                                {item.discount_type &&
                                                    item.discount_type.value !==
                                                        'none' && (
                                                        <tr>
                                                            <td></td>
                                                            <td>
                                                                <span>
                                                                    <span>
                                                                        Discount:{' '}
                                                                    </span>
                                                                    <span className="product-discount">
                                                                        {
                                                                            item.discount
                                                                        }
                                                                        {item
                                                                            .discount_type
                                                                            .label ===
                                                                        'Percent'
                                                                            ? ' %'
                                                                            : ''}
                                                                    </span>
                                                                </span>

                                                                <span
                                                                    style={{
                                                                        margin: '0 0 0 10px',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        Discount
                                                                        type:{' '}
                                                                    </span>
                                                                    <span className="product-discount">
                                                                        {
                                                                            item
                                                                                .discount_type
                                                                                .label
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className="text-center text-danger">
                                                                -{' '}
                                                                {numberFormat(
                                                                    Number(
                                                                        item.discount_amount
                                                                    ).toFixed(2)
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                            </>
                                        ) : (
                                            void 0
                                        )
                                    ) : (
                                        void 0
                                    )
                                )}
                                {this.renderExtrRow(extraRowsCount)}
                            </tbody>
                        </table>
                    </div>
                    {page == totalPage ? (
                        <Fragment>
                            <div className="col-md-6 col-print-6">
                                <span className="f-14b">&nbsp;</span>
                                <br />
                                <br />
                                <span className="f-14b">Remarks</span>
                                <p className="f-12">
                                    {po.remarks != null ? po.remarks : ''}&nbsp;
                                </p>

                                <span className="f-14b">Payment Terms</span>
                                <p className="f-12">
                                    {po.payment_terms != ''
                                        ? po.payment_terms.label
                                        : ''}
                                    &nbsp;
                                </p>

                                <span className="f-14b">Payment Mode:</span>
                                <p className="f-12">
                                    {po.payment_mode != null
                                        ? capitalize(po.payment_mode.label)
                                        : ''}
                                    &nbsp;
                                </p>
                            </div>
                            <div className="col-md-2 col-print-2 "></div>
                            <div className="col-md-4 col-print-4 pr-0">
                                <span className="f-14b">Sub Total:</span>
                                <span className="f-right f-14b">
                                    <label
                                        style={{
                                            filter: !canViewCost
                                                ? 'blur(4px)'
                                                : 'none',
                                            userSelect: !canViewCost
                                                ? 'none'
                                                : 'auto',
                                        }}
                                    >
                                        {formatPrice(
                                            po.sub_total,
                                            currencyDisplay
                                        )}
                                    </label>
                                </span>
                                <br />
                                <span className="f-14b">
                                    Discount{' '}
                                    {po.discount &&
                                    po.discount_type.value === 'percent'
                                        ? `(${numberFormat(po.discount, 0)}%)`
                                        : null}
                                    :
                                </span>
                                <span className="f-right f-14b">
                                    {po.discount &&
                                    po.discount_type.value === 'percent'
                                        ? formatPrice(
                                              po.sub_total *
                                                  (po.discount / 100),
                                              currencyDisplay
                                          )
                                        : formatPrice(
                                              po.discount,
                                              currencyDisplay
                                          )}{' '}
                                </span>
                                <br />
                                <span className="f-14b">
                                    VAT{' '}
                                    {po.tax
                                        ? `(${numberFormat(
                                              po.tax,
                                              0
                                          )}% ${po.tax_type.label.slice(
                                              0,
                                              4
                                          )}.)`
                                        : null}
                                </span>
                                <span className="f-right f-14b">
                                    {formatPrice(
                                        po.vat_amount,
                                        currencyDisplay
                                    )}
                                </span>
                                <br />
                                <section className="print-total">
                                    <span className="f-16b">Total:</span>
                                    <span className="f-right f-16b">
                                        <label
                                            style={{
                                                filter: !canViewCost
                                                    ? 'blur(4px)'
                                                    : 'none',
                                                userSelect: !canViewCost
                                                    ? 'none'
                                                    : 'auto',
                                            }}
                                        >
                                            {formatPrice(
                                                po.total,
                                                currencyDisplay
                                            )}
                                        </label>
                                    </span>
                                    <br />
                                </section>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4">
                                        <span className="f-14b">
                                            Prepared by:
                                        </span>
                                        {/* <p className="f-12">{po.user_prepared_by}&nbsp;</p> */}
                                        <p className="f-12">
                                            {po.users.prepared_by.name}&nbsp;
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <span className="f-14b">
                                            Checked by:
                                        </span>
                                        {/* <p className="f-12">{po.user_checked_by}&nbsp;</p> */}
                                        <p className="f-12">
                                            {po.users.checked_by.name}&nbsp;
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        {/* <span className="f-14b">Approved by:</span> */}
                                        <p className="f-12">&nbsp;</p>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        void 0
                    )}
                </div>
                <div className="print-footer h-p25em">
                    <div className="row">
                        <div className="col-md-10 col-print-10 pb-0 mb-0">
                            <ul className="f-12b ul-print-paw">
                                {template_setting && (
                                    <li>{template_setting.company_name}</li>
                                )}
                                {template_setting && (
                                    <li>{template_setting.company_landline}</li>
                                )}
                                {template_setting && (
                                    <li>{template_setting.company_email}</li>
                                )}
                                {template_setting && (
                                    <li className="mb-2">
                                        {template_setting.company_address}
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="col-md-2 col-print-2 pb-0 mb-0">
                            <span className="f-right mt-25-f">
                                Page {page}/{totalPage}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="page-break" />
            </div>
        );
    }

    renderMultiplePage() {
        const tableRows = [];
        const tableRowsTry = [];
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        for (let i = 1; i <= numberOfPage; i++) {
            tableRows.push(this.renderPrint(i, Math.floor(numberOfPage)));
        }
        return tableRows;
    }

    render() {
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );

        return (
            <Fragment>
                <div ref={this.divScrollRef} className="main-print-div">
                    {this.state.print == 0 ? (
                        this.renderPrint(this.state.activePage, numberOfPage)
                    ) : (
                        <Fragment>{this.renderMultiplePage()}</Fragment>
                    )}{' '}
                </div>

                <div className="hidden-print d-flex justify-content-center pagination-print">
                    <Pagination
                        className="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

export default PurchaseOrderPrintContent;
