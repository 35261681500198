// Report.js
import React from 'react';

/**
 * Displays a progress bar and optional status text while the report is being generated.
 */
function Report({ statusMessage, progress, handleCheckStatus }) {
    return (
        <div className="text-center" style={{ height: '200px' }}>
            <div className="m-auto">
                <p>{statusMessage}</p>

                {/* Small progress bar */}
                <div
                    className="progress"
                    style={{
                        width: '200px',
                        height: '20px',
                        backgroundColor: '#e9ecef',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        margin: 'auto',
                    }}
                >
                    <div
                        className="progress-bar d-flex justify-content-center align-items-center"
                        role="progressbar"
                        style={{
                            width: `${progress}%`,
                            transition: 'width 0.5s',
                            backgroundColor: '#007bff',
                        }}
                    >
                        <div style={{ marginLeft: '20px' }}>{progress}%</div>
                    </div>
                </div>

                {/* Optionally re-activate the manual check button if you like */}
                {/* <button onClick={handleCheckStatus}>Check Status</button> */}
            </div>
        </div>
    );
}

/**
 * Displays a simple “download CSV” button and message once report is ready.
 */
export function DownloadCsv({ statusMessage, handleDownloadCSV }) {
    return (
        <div className="text-center" style={{ height: '200px' }}>
            <div className="m-auto">
                <p>{statusMessage}</p>
                <button onClick={handleDownloadCSV}>Download CSV</button>
            </div>
        </div>
    );
}

export default Report;
