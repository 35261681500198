import * as TYPES from '../../types';
import { AuthService } from './auth.service.js';
import { ToastSuccess, ToastDanger } from '../../../Services/_toast.service';
import $ from 'jquery';
import history from '../../../history';
import { SwalWarning } from '../../../Services/_swal.service';
import {
    setUserPermissions,
    setUser,
    setUserAccess,
    removeUserSession,
    setUserSession,
} from '../../../Utils/Common';

export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPES.AUTH_LOADING, payload: status });
export const setRedirect = (status) => async (dispatch) =>
    dispatch({ type: TYPES.SET_REDIRECT, payload: status });

// getUserDetails
export const getUserDetails = (id) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    AuthService.getUserDetails()
        .then((res) => {
          console.log(res.data.defaultRedirect)
            dispatch({ type: TYPES.AUTH_USER, payload: res.data.user });
            dispatch({
                type: TYPES.AUTH_ROLE,
                payload: res.data.admin_role_access,
            });
            dispatch({ type: TYPES.AUTH_ACCESS, payload: res.data.Access });
            dispatch({
                type: TYPES.AUTH_PERMISSIONS,
                payload: res.data.accessArray,
            });
            dispatch({
                type: TYPES.AUTH_REDIRECT,
                payload: res.data.defaultRedirect,
            });
            dispatch(setLoading(false));
        })
        .catch((err) => {
            dispatch(setLoading(false));
            removeUserSession();
            // window.location.href  = '/login';
            // ToastDanger('Network error, please reload the page.');
        });
};

export const sendResetReqeust = (e) => async (dispatch, getState) => {
    e.preventDefault();
    dispatch({ type: TYPES.SET_LOADING, payload: true });
    let { inputs, status } = getState().auth;
    AuthService.sendResetPass(inputs)
        .then((result) => {
            dispatch({ type: TYPES.SET_LOADING, payload: false });
            status.result = true;
            status.success = true;
            status.message = result.data.message;
            dispatch({ type: TYPES.STATUS, payload: status });
        })
        .catch((err) => {
            dispatch({ type: TYPES.SET_LOADING, payload: false });
            status.result = true;
            status.success = false;
            status.message = err.data.message;
            dispatch({ type: TYPES.STATUS, payload: status });
        });
};

export const handleInputChange = (e) => async (dispatch, getState) => {
    let { name, value } = e.target;
    let { inputs } = getState().auth;
    inputs[name] = value;
    dispatch({ type: TYPES.AUTH_INPUTS, payload: inputs });
};

export const handleReset = (e) => async (dispatch, getState) => {
    e.preventDefault();
    let { inputs, status } = getState().auth;
    status.result = false;
    status.success = false;
    status.message = '';
    dispatch({ type: TYPES.STATUS, payload: status });
    if (inputs.password != inputs.confirm_password) {
        status.result = true;
        status.success = false;
        status.message = 'Password does not match';
        dispatch({ type: TYPES.STATUS, payload: status });
    } else {
        dispatch({ type: TYPES.SET_LOADING, payload: true });
        AuthService.resetPass(inputs)
            .then((result) => {
                status.result = true;
                status.success = true;
                status.message = result.data.message;
                dispatch({ type: TYPES.STATUS, payload: status });
                dispatch({ type: TYPES.SET_LOADING, payload: false });
            })
            .catch((err) => {
                status.result = true;
                status.success = false;
                status.message = err.data.errors[0];
                dispatch({ type: TYPES.STATUS, payload: status });
                dispatch({ type: TYPES.SET_LOADING, payload: false });
            });
    }
};
export const setToken = (token) => async (dispatch, getState) => {
    let { inputs } = getState().auth;
    inputs.token = token;
    dispatch({ type: TYPES.AUTH_INPUTS, payload: inputs });
};

export const setDefaultRedirect = (url) => async (dispatch) => {
  dispatch({type: TYPES.AUTH_REDIRECT, payload: url},)
}

export const submitRegister = (e) => async (dispatch, getState) => {
    e.preventDefault();
    let { inputs, status } = getState().auth;

    // console.log("getState().auth", getState().auth)

    status.result = false;
    status.success = false;
    status.message_arr = [];
    dispatch({ type: TYPES.STATUS, payload: status });
    if (inputs.password != inputs.confirm_password) {
        status.result = true;
        status.success = false;
        status.message_arr[0] = 'Password does not match';
        dispatch({ type: TYPES.STATUS, payload: status });
    } else {
        dispatch({ type: TYPES.SET_LOADING, payload: true });
        status.result = false;
        status.success = false;
        status.message_arr = [];
        dispatch({ type: TYPES.STATUS, payload: status });
        AuthService.register(inputs)
            .then((result) => {
                dispatch({ type: TYPES.SET_LOADING, payload: false });
                status.result = true;
                status.success = true;
                status.message_arr[0] = result.data.message;
                dispatch({ type: TYPES.STATUS, payload: status });
                setUserSession(result.data.token, result.data.user);
                setUser(result.data.user);
                // setUserAccess(result.data.data.original.access);
                // setUserPermissions(result.data.data.original.access);
                // window.location.pathname = '/settings/app';

                // ** Uncomment this code if node api is using
                setUserAccess(result.data.access);
                setUserPermissions(result.data.access);
                window.location.pathname = result.data.url_redirect;
            })
            .catch((err) => {
                console.log('err', err);
                dispatch({ type: TYPES.SET_LOADING, payload: false });
                status.result = true;
                status.success = false;
                status.message_arr = [];
                if (err.data != undefined) {
                    // err.data.message.map((data, index) => {
                    //   // console.log("data", data);
                    //   status.message_arr[index] = data;
                    // });
                    status.message_arr.push(err.data.message);
                }

                dispatch({ type: TYPES.STATUS, payload: status });
            });
    }
};

export const validateToken = () => async (dispatch) => {
    AuthService.validate()
        .then((result) => {})
        .catch((err) => {
            console.log(err);
            window.location.href = '/';
        });
};
