import React, { Fragment, useEffect } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { NumberFormat } from '../../Services/_numberformat.service';
import { reactSelectStyle, stockClass } from '../../Utils/Common';
import {
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleInputAttr,
    handleInputChange,
    handleInputSrp,
    handleCheckVat,
    handleInputQty,
    handleSelectItem,
    handleSelectTermsConditions,
    handleOnSubmit,
    handleEditInputRow,
    handleToggleEditRow,
    handleUpdateRow,
    handleAttrSubmit,
    getProducts,
    getTermsConditions,
    QUDatePicker,
    getCustomers,
    handleSelectDiscountType,
    handleSelectDiscountTypeProduct,
    handleInputChangeDiscount,
    handleInputDiscountProduct,
    handleDiscountItem,
    setModal,
    getQuotationTemplates,
    handleSelectSalesInvoiceTemplate,
    handleSelectPaymentType,
    clearQuotation,
    add_items,
    dynamicInputChange,
    handleOnInputChange,
    searchProducst,
    searchCustomers,
    ShowComboBox,
    computationHandler,
    handleTaxType,
    clear_data,
    getQuotationValidations,
} from '../../redux/actions/quotation/quotation.actions';
import QuotationBlankTable from '../layouts/table_template/QuotationBlankTable';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import QuotationCustomerModal from './modals/Quotation.customer.modal';
import PaymentTermsModal from './modals/PaymentTerms.modal';
import DatePicker from 'react-datepicker';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';
import debounce from 'debounce-promise';
import DynamicFont from 'react-dynamic-font';
import { subscriberSetting } from '../../redux/actions/settings_app/setting_app.actions';
import { MiniSpinner } from '../layouts/Spinner';
import FormTab from './component/FormTab';
import QuotationValidationModal from './modals/QuotationValidation.modal';
import CustomerSelect from './component/CustomerSelect';

function QuotationCreate({
    quotation: {
        default_customer_value,
        single_quotation,
        mini_loading,
        showDiscountRow,
        loading,
        customers,
        terms_conditions,
        isSearchable,
        itemSelected,
        items,
        isDiscount,
        default_product_value,
        payment_terms_modal,
        quote_validation_modal,
        isOther,
        edit_quotation_items,
        edit_row_index,
        edit_attr,
        main_url,
        main_page,
        customer_modal,
        qu_templates,
        qu_default_template,
        payment_mode_options,
        instruction,
        item_template,
        tax_types,
    },
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleInputAttr,
    handleInputChange,
    handleInputSrp,
    handleCheckVat,
    handleInputQty,
    handleSelectItem,
    handleSelectTermsConditions,
    handleOnSubmit,
    handleEditInputRow,
    handleToggleEditRow,
    handleUpdateRow,
    getQuotationTemplates,
    QUDatePicker,
    handleAttrSubmit,
    getProducts,
    getTermsConditions,
    getCustomers,
    handleSelectDiscountType,
    handleSelectDiscountTypeProduct,
    handleInputChangeDiscount,
    handleInputDiscountProduct,
    handleDiscountItem,
    setModal,
    handleSelectSalesInvoiceTemplate,
    handleSelectPaymentType,
    clearQuotation,
    add_items,
    dynamicInputChange,
    block_route,
    blockRoute,
    handleOnInputChange,
    searchProducst,
    searchCustomers,
    subscriberSetting,
    computationHandler,
    handleTaxType,
    clear_data,
    ShowComboBox,
    getQuotationValidations,
}) {
    useEffect(() => {
        async function fetchAll() {
            Promise.all([
                clearQuotation(),
                getQuotationTemplates(),
                getProducts(),
                getTermsConditions(),
                getQuotationValidations(),
                getCustomers(),
                blockRoute(false),
                clear_data(),
                subscriberSetting('quotation'),
            ]);
        }

        fetchAll();
    }, []);

    const { quotation_items, discount, discount_type, tax, tax_type, other } =
        single_quotation;
    useEffect(() => {
        computationHandler();
    }, [
        JSON.stringify(quotation_items),
        discount,
        discount_type,
        tax,
        tax_type,
        other,
    ]);

    let history = useHistory();

    useEffect(() => {
        // When onClick navbar prevent another dialog box
        window.addEventListener('beforeunload', function (e) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
            blockRoute(false);
        });

        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () =>
        window.history.pushState(null, null, window.location.pathname);

    if (main_page) return <Redirect to={main_url} />;
    const customFilter = (option, searchText) => {
        try {
            if (
                option.data.label
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                option.data.barcode
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            ) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    };

    let percent_value = [
        { value: 'none', label: 'None' },
        { value: 'percent', label: 'Percent' },
        { value: 'amount', label: 'Amount' },
    ];

    const Option = (props) => {
        // console.log(props)
        return (
            <components.Option {...props}>
                <div>
                    {props.data.label} [{props.data.brand}]
                </div>
                <div style={{ fontSize: 11 }}>{props.data.sku}</div>
                <div style={{ fontSize: 11 }}>{props.data.description}</div>
                <div style={{ fontSize: 11 }}>{props.data.brand}</div>
            </components.Option>
        );
    };

    // console.log('customer', customers);

    return (
        <BeforeUnloadComponent
            blockRoute={block_route}
            ignoreBeforeunloadDocument={true}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <ReactTooltip effect="solid" event="click" />
                <div className="content-wrapper max-1920 mx-auto">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Sales
                            </h3>
                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb f-regular">
                                        <li className="breadcrumb-item">
                                            <Link
                                                to={main_url}
                                                className="text-white"
                                            >
                                                Quotations
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link to="#" className="text-white">
                                                Create
                                            </Link>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <div className="row f-regular">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header mb-0 pb-0">
                                            <Link to={main_url}>
                                                <i className="ft ft-arrow-left"></i>{' '}
                                                Back
                                            </Link>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body pb-10m f-regular">
                                                {mini_loading ? (
                                                    <MiniSpinner />
                                                ) : (
                                                    <form>
                                                        <section>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Template{' '}
                                                                                <span className="text-danger">
                                                                                    *
                                                                                </span>
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <Select
                                                                                        options={
                                                                                            qu_templates
                                                                                        }
                                                                                        onChange={
                                                                                            handleSelectSalesInvoiceTemplate
                                                                                        }
                                                                                        value={
                                                                                            qu_default_template
                                                                                        }
                                                                                        isSearchable={
                                                                                            isSearchable
                                                                                        }
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Customer{' '}
                                                                                <span className="text-danger">
                                                                                    *
                                                                                </span>{' '}
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <CustomerSelect
                                                                                        loading={
                                                                                            loading
                                                                                        }
                                                                                        default_customer_value={
                                                                                            default_customer_value
                                                                                        }
                                                                                        customers={
                                                                                            customers
                                                                                        }
                                                                                        searchCustomers={
                                                                                            searchCustomers
                                                                                        }
                                                                                        handleSelectCustomer={
                                                                                            handleSelectCustomer
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                        <Show when="inv.sales.customers.create">
                                                                                            <div
                                                                                                className="a-tagicon icon-s"
                                                                                                onClick={() =>
                                                                                                    setModal(
                                                                                                        'customer_modal',
                                                                                                        true
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i className="ft ft-plus-circle text-primary"></i>
                                                                                            </div>
                                                                                        </Show>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4"></div>
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Quotation
                                                                                Date
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <div className="date-picker-100">
                                                                                        <DatePicker
                                                                                            onChange={(
                                                                                                date
                                                                                            ) =>
                                                                                                QUDatePicker(
                                                                                                    date,
                                                                                                    'quotation_date'
                                                                                                )
                                                                                            }
                                                                                            selected={
                                                                                                single_quotation.quotation_date
                                                                                            }
                                                                                            name="sales_invoice_date"
                                                                                            className="form-control f-regular"
                                                                                            peekNextMonth
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            dropdownMode="select"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {!single_quotation.customer ? (
                                                                        <div className="table-responsive">
                                                                            <QuotationBlankTable
                                                                                instruction={
                                                                                    instruction
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <Fragment>
                                                                            <table className="table table-hover table-striped table-bordered table-responsive f-regular">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-left td-16m ">
                                                                                            SKU
                                                                                        </th>
                                                                                        <th className="text-center">
                                                                                            Product
                                                                                        </th>
                                                                                        <th className="text-center mw-6m">
                                                                                            Qty
                                                                                        </th>
                                                                                        <th className="text-center">
                                                                                            Unit
                                                                                        </th>
                                                                                        <th className="text-center">
                                                                                            Unit/Price
                                                                                        </th>
                                                                                        <th className="text-center">
                                                                                            Amount
                                                                                        </th>
                                                                                        <th className="text-center">
                                                                                            Action
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {single_quotation.quotation_items.map(
                                                                                        (
                                                                                            item,
                                                                                            index
                                                                                        ) => (
                                                                                            <Fragment
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                {' '}
                                                                                                {/* This is the key part */}
                                                                                                <tr
                                                                                                    className={stockClass(
                                                                                                        item.status_code
                                                                                                    )}
                                                                                                >
                                                                                                    <td className="table-data__sku ">
                                                                                                        {
                                                                                                            item.sku
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td className="min-wdth-300 max-wdth-200 ">
                                                                                                        {item.selected ? (
                                                                                                            <div>
                                                                                                                {/* <div className='f-right' onClick={() => ShowComboBox(index)}>
                                                        <i className='ft ft-chevron-down c-pointer'></i>
                                                      </div> */}
                                                                                                                <div className="mw-90">
                                                                                                                    <Link
                                                                                                                        to={`/inventory/view/${item._id}`}
                                                                                                                        target="_blank"
                                                                                                                        onClick={(
                                                                                                                            e
                                                                                                                        ) =>
                                                                                                                            e.stopPropagation()
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {
                                                                                                                            item.product_name
                                                                                                                        }
                                                                                                                    </Link>
                                                                                                                </div>
                                                                                                                {/* <div className='f-10'>
                                                        {item.barcode}
                                                      </div> */}
                                                                                                                <div className="f-10">
                                                                                                                    {
                                                                                                                        item.description
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div className="f-10">
                                                                                                                    {
                                                                                                                        item.brand
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <AsyncSelect
                                                                                                                    value={{
                                                                                                                        label: item.product_name,
                                                                                                                        value: item._id,
                                                                                                                    }}
                                                                                                                    components={{
                                                                                                                        Option,
                                                                                                                    }}
                                                                                                                    classNamePrefix="lp-copy-sel"
                                                                                                                    isLoading={
                                                                                                                        loading
                                                                                                                    }
                                                                                                                    loadingMessage={() =>
                                                                                                                        'searching...'
                                                                                                                    }
                                                                                                                    noOptionsMessage={({
                                                                                                                        inputValue,
                                                                                                                    }) =>
                                                                                                                        inputValue
                                                                                                                            ? 'No Products'
                                                                                                                            : 'Search product'
                                                                                                                    }
                                                                                                                    defaultOptions={
                                                                                                                        item.items
                                                                                                                    }
                                                                                                                    loadOptions={debounce(
                                                                                                                        (
                                                                                                                            value
                                                                                                                        ) =>
                                                                                                                            searchProducst(
                                                                                                                                value,
                                                                                                                                index
                                                                                                                            ),
                                                                                                                        1000
                                                                                                                    )}
                                                                                                                    isSearchable={
                                                                                                                        true
                                                                                                                    }
                                                                                                                    cacheOptions
                                                                                                                    onChange={(
                                                                                                                        val
                                                                                                                    ) =>
                                                                                                                        handleSelectItem(
                                                                                                                            val,
                                                                                                                            index
                                                                                                                        )
                                                                                                                    }
                                                                                                                    placeholder="Select Products..."
                                                                                                                    required
                                                                                                                />
                                                                                                            </>
                                                                                                        )}
                                                                                                    </td>
                                                                                                    <td className="mw-6m">
                                                                                                        <input
                                                                                                            type="number"
                                                                                                            className="form-control text-center"
                                                                                                            value={
                                                                                                                item.qty
                                                                                                            }
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                dynamicInputChange(
                                                                                                                    e,
                                                                                                                    index
                                                                                                                )
                                                                                                            }
                                                                                                            name="qty"
                                                                                                            min={
                                                                                                                1
                                                                                                            }
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td className="min-wdth-100 max-wdth-100 text-center">
                                                                                                        {
                                                                                                            item.product_unit_name
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td
                                                                                                        className="mw-8m"
                                                                                                        align="right"
                                                                                                    >
                                                                                                        <input
                                                                                                            type="number"
                                                                                                            className="form-control text-right"
                                                                                                            value={
                                                                                                                item.srp
                                                                                                            }
                                                                                                            onChange={(
                                                                                                                e
                                                                                                            ) =>
                                                                                                                dynamicInputChange(
                                                                                                                    e,
                                                                                                                    index
                                                                                                                )
                                                                                                            }
                                                                                                            name="srp"
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td align="right">
                                                                                                        {item.amount
                                                                                                            ? NumberFormat(
                                                                                                                  item.amount.toFixed(
                                                                                                                      2
                                                                                                                  )
                                                                                                              )
                                                                                                            : NumberFormat(
                                                                                                                  item.srp.toFixed(
                                                                                                                      2
                                                                                                                  )
                                                                                                              )}
                                                                                                    </td>

                                                                                                    <td
                                                                                                        align="center"
                                                                                                        className="mw-2m"
                                                                                                    >
                                                                                                        <span className="m-1">
                                                                                                            <button
                                                                                                                className="btn btn-sm btn-primary"
                                                                                                                onClick={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    handleDiscountItem(
                                                                                                                        index,
                                                                                                                        false
                                                                                                                    )
                                                                                                                }
                                                                                                                type="button"
                                                                                                            >
                                                                                                                <i className="ft ft-tag"></i>
                                                                                                            </button>
                                                                                                        </span>

                                                                                                        <span>
                                                                                                            <button
                                                                                                                className="btn btn-sm btn-danger"
                                                                                                                onClick={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    handleRemoveItem(
                                                                                                                        index
                                                                                                                    )
                                                                                                                }
                                                                                                                type="button"
                                                                                                            >
                                                                                                                <i className="ft ft-trash"></i>
                                                                                                            </button>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {item.showDiscountRow && (
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            colSpan={
                                                                                                                1
                                                                                                            }
                                                                                                        ></td>
                                                                                                        <td
                                                                                                            className="d-flex ali justify-content-center align-items-center"
                                                                                                            style={{
                                                                                                                gap: '5%',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span>
                                                                                                                Discount:
                                                                                                            </span>

                                                                                                            <span className="w-20 table-data__discount">
                                                                                                                {(() => {
                                                                                                                    switch (
                                                                                                                        item
                                                                                                                            .discount_type
                                                                                                                            .value
                                                                                                                    ) {
                                                                                                                        case 'percent':
                                                                                                                            return (
                                                                                                                                <div class="input-group">
                                                                                                                                    <input
                                                                                                                                        onChange={(
                                                                                                                                            e
                                                                                                                                        ) =>
                                                                                                                                            handleInputDiscountProduct(
                                                                                                                                                e,
                                                                                                                                                index
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                        value={
                                                                                                                                            item.discount
                                                                                                                                        }
                                                                                                                                        name="discount"
                                                                                                                                        className="form-control text-right f-regular "
                                                                                                                                        type="number"
                                                                                                                                    />
                                                                                                                                    <div class="input-group-append">
                                                                                                                                        <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                                            %
                                                                                                                                        </span>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            );
                                                                                                                        case 'amount':
                                                                                                                            return (
                                                                                                                                <input
                                                                                                                                    onChange={(
                                                                                                                                        e
                                                                                                                                    ) =>
                                                                                                                                        handleInputDiscountProduct(
                                                                                                                                            e,
                                                                                                                                            index
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    value={
                                                                                                                                        item.discount
                                                                                                                                    }
                                                                                                                                    name="discount"
                                                                                                                                    className="form-control text-right f-regular"
                                                                                                                                    type="number"
                                                                                                                                />
                                                                                                                            );
                                                                                                                        default: // 0 disabled
                                                                                                                            return (
                                                                                                                                <input
                                                                                                                                    value={
                                                                                                                                        item.discount
                                                                                                                                    }
                                                                                                                                    disabled
                                                                                                                                    name="discount"
                                                                                                                                    className="form-control text-right f-regular"
                                                                                                                                    type="number"
                                                                                                                                />
                                                                                                                            );
                                                                                                                    }
                                                                                                                })()}
                                                                                                            </span>

                                                                                                            <span className="table-data__discount-dropdown">
                                                                                                                <Select
                                                                                                                    name="discount_type"
                                                                                                                    onChange={(
                                                                                                                        selectOption
                                                                                                                    ) =>
                                                                                                                        handleSelectDiscountTypeProduct(
                                                                                                                            selectOption,
                                                                                                                            index
                                                                                                                        )
                                                                                                                    }
                                                                                                                    menuPortalTarget={
                                                                                                                        document.body
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        item.discount_type
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        item.discount_type
                                                                                                                    }
                                                                                                                    label="Single select"
                                                                                                                    options={
                                                                                                                        percent_value
                                                                                                                    }
                                                                                                                    isSearchable={
                                                                                                                        false
                                                                                                                    }
                                                                                                                />
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td className="min-wdth-100 max-wdth-100 text-right text-danger">
                                                                                                            -{' '}
                                                                                                            {NumberFormat(
                                                                                                                Number(
                                                                                                                    item.discount_amount
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )
                                                                                                            )}
                                                                                                        </td>

                                                                                                        <td
                                                                                                            align="center"
                                                                                                            className="mw-2m"
                                                                                                        >
                                                                                                            <button
                                                                                                                className="btn btn-sm btn-danger"
                                                                                                                onClick={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    handleDiscountItem(
                                                                                                                        index,
                                                                                                                        true
                                                                                                                    )
                                                                                                                }
                                                                                                                type="button"
                                                                                                            >
                                                                                                                <i className="ft ft-trash"></i>
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </Fragment>
                                                                                        )
                                                                                    )}

                                                                                    {/* <tr className="bg-primary bg-accent-2">
                                                                                <td className="min-wdth-100 max-wdth-100"><div className="td-padding">{itemSelected.sku} </div></td>
                                                                                <td className="min-wdth-300 max-wdth-200">
                                                                                    <Select filterOption={customFilter} components={{Option}} classNamePrefix="lp-copy-sel" options={items} onChange={handleSelectItem} isSearchable={isSearchable} value={default_product_value}/>
                                                                                    <AsyncSelect isLoading={loading} loadingMessage={() => 'searching...'} defaultOptions={items} loadOptions={getProducts} isSearchable={true} cacheOptions onChange={(val) => handleSelectItem(val)} placeholder="Select Products..." required/>
                                                                                </td>
                                                                                <td className="min-wdth-120 max-wdth-150"><input onChange={handleInputQty} value={itemSelected.qty} name="qty" min={1} className="form-control" type="text" step="any"/></td>
                                                                                <td className="min-wdth-100 max-wdth-100 text-center">{itemSelected.product_unit_name}</td>
                                                                                <td className="min-wdth-150 max-wdth-150"><input onChange={handleInputSrp} value={itemSelected.srp} type="number" name="cost" className="form-control" min={1}/></td>
                                                                                <td className='min-wdth-150 max-wdth-150' align="right"><div className="td-padding">{itemSelectedAmount ?  NumberFormat(itemSelectedAmount.toFixed(2)) : 0}</div></td>
                                                                                <td className="text-center" width="5%"><a className="btn btn-sm btn-primary text-white" onClick={handleAddItem}>add</a></td>
                                                                            </tr> */}

                                                                                    <tr>
                                                                                        <td
                                                                                            colSpan="7"
                                                                                            className="text-left"
                                                                                        >
                                                                                            <button
                                                                                                className="btn btn-primary"
                                                                                                type="button"
                                                                                                onClick={
                                                                                                    add_items
                                                                                                }
                                                                                            >
                                                                                                <i className="la la-plus"></i>
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                        <td className="p-2">
                                                                                            &nbsp;
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            colSpan={
                                                                                                4
                                                                                            }
                                                                                        ></td>
                                                                                        <td>
                                                                                            <div align="right">
                                                                                                Sub
                                                                                                Total
                                                                                            </div>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            {NumberFormat(
                                                                                                single_quotation.sub_total.toFixed(
                                                                                                    2
                                                                                                )
                                                                                            )}
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            colSpan={
                                                                                                4
                                                                                            }
                                                                                        ></td>
                                                                                        <td>
                                                                                            <div align="right">
                                                                                                Discount
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            {(() => {
                                                                                                switch (
                                                                                                    single_quotation
                                                                                                        .discount_type
                                                                                                        .value
                                                                                                ) {
                                                                                                    case 'percent':
                                                                                                        return (
                                                                                                            <div class="input-group">
                                                                                                                <input
                                                                                                                    onChange={
                                                                                                                        handleInputChangeDiscount
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        single_quotation.discount
                                                                                                                    }
                                                                                                                    name="discount"
                                                                                                                    className="form-control text-right f-regular"
                                                                                                                    type="number"
                                                                                                                />
                                                                                                                <div class="input-group-append">
                                                                                                                    <span className="input-group-text py-0 f-regular discount-1">
                                                                                                                        %
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        );
                                                                                                    case 'amount':
                                                                                                        return (
                                                                                                            <input
                                                                                                                onChange={
                                                                                                                    handleInputChangeDiscount
                                                                                                                }
                                                                                                                value={
                                                                                                                    single_quotation.discount
                                                                                                                }
                                                                                                                name="discount"
                                                                                                                className="form-control text-right f-regular"
                                                                                                                type="number"
                                                                                                            />
                                                                                                        );
                                                                                                    default: // 0 disabled
                                                                                                        return (
                                                                                                            <input
                                                                                                                value={
                                                                                                                    single_quotation.discount
                                                                                                                }
                                                                                                                disabled
                                                                                                                name="discount"
                                                                                                                className="form-control text-right f-regular"
                                                                                                                type="number"
                                                                                                            />
                                                                                                        );
                                                                                                }
                                                                                            })()}
                                                                                        </td>
                                                                                        <td
                                                                                            width="10%"
                                                                                            className="text-center padding-td-5"
                                                                                        >
                                                                                            <Select
                                                                                                name="discount_type"
                                                                                                onChange={
                                                                                                    handleSelectDiscountType
                                                                                                }
                                                                                                menuPortalTarget={
                                                                                                    document.body
                                                                                                }
                                                                                                value={
                                                                                                    single_quotation.discount_type
                                                                                                }
                                                                                                defaultValue={
                                                                                                    single_quotation.discount_type
                                                                                                }
                                                                                                label="Single select"
                                                                                                options={
                                                                                                    percent_value
                                                                                                }
                                                                                                isSearchable={
                                                                                                    false
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            colSpan={
                                                                                                4
                                                                                            }
                                                                                        ></td>
                                                                                        <td>
                                                                                            <div align="right">
                                                                                                VAT
                                                                                                (
                                                                                                {
                                                                                                    single_quotation.tax
                                                                                                }{' '}
                                                                                                %)
                                                                                            </div>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            {NumberFormat(
                                                                                                single_quotation.vat_amount.toFixed(
                                                                                                    2
                                                                                                )
                                                                                            )}
                                                                                        </td>
                                                                                        <td className="padding-td-5 min-width-150">
                                                                                            <Select
                                                                                                name="tax_type"
                                                                                                onChange={
                                                                                                    handleTaxType
                                                                                                }
                                                                                                value={
                                                                                                    single_quotation.tax_type
                                                                                                }
                                                                                                menuPortalTarget={
                                                                                                    document.body
                                                                                                }
                                                                                                options={
                                                                                                    tax_types
                                                                                                }
                                                                                                isSearchable={
                                                                                                    false
                                                                                                }
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            colSpan={
                                                                                                4
                                                                                            }
                                                                                        ></td>
                                                                                        <td>
                                                                                            <div align="right">
                                                                                                Delivery
                                                                                                Fee
                                                                                            </div>
                                                                                        </td>
                                                                                        <td width="12%">
                                                                                            {isOther ? (
                                                                                                <input
                                                                                                    onChange={
                                                                                                        handleInputAttr
                                                                                                    }
                                                                                                    value={
                                                                                                        edit_attr.other
                                                                                                    }
                                                                                                    name="other"
                                                                                                    className="form-control"
                                                                                                    type="number"
                                                                                                />
                                                                                            ) : (
                                                                                                <div align="right">
                                                                                                    {' '}
                                                                                                    {
                                                                                                        single_quotation.other
                                                                                                    }{' '}
                                                                                                </div>
                                                                                            )}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            {isOther ? (
                                                                                                <Fragment>
                                                                                                    <div className="btn-group">
                                                                                                        <a
                                                                                                            className="btn btn-primary btn-sm"
                                                                                                            onClick={() =>
                                                                                                                handleAttrSubmit(
                                                                                                                    'other'
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <i className="ft ft-check text-white"></i>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </Fragment>
                                                                                            ) : (
                                                                                                <a
                                                                                                    className="btn btn-info btn-sm"
                                                                                                    onClick={() =>
                                                                                                        toggleEditBtn(
                                                                                                            true,
                                                                                                            'isOther'
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <i className="ft ft-edit text-white"></i>
                                                                                                </a>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan="4"></td>
                                                                                        <td>
                                                                                            <div align="right">
                                                                                                <strong>
                                                                                                    Total
                                                                                                </strong>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            {NumberFormat(
                                                                                                single_quotation.total.toFixed(
                                                                                                    2
                                                                                                )
                                                                                            )}
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </Fragment>
                                                                    )}
                                                                    <div className="col-12 mb-2"></div>

                                                                    {/* FORM TABS */}
                                                                    <FormTab />

                                                                    {/* <div className="row">
                                                                        <div className="col-md-4">
                                                                            <label>
                                                                                Remarks{' '}
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <textarea
                                                                                        onChange={
                                                                                            handleInputChange
                                                                                        }
                                                                                        value={
                                                                                            single_quotation.remarks
                                                                                        }
                                                                                        rows="2"
                                                                                        placeholder="Remarks"
                                                                                        name="remarks"
                                                                                        className="form-control f-regular"
                                                                                    ></textarea>
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Payment
                                                                                Mode:
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <Select
                                                                                        options={
                                                                                            payment_mode_options
                                                                                        }
                                                                                        onChange={
                                                                                            handleSelectPaymentType
                                                                                        }
                                                                                        value={
                                                                                            single_quotation.payment_mode
                                                                                        }
                                                                                        isSearchable={
                                                                                            isSearchable
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-4">
                                                                            <label>
                                                                                Payment
                                                                                Terms:{' '}
                                                                            </label>
                                                                            <div className="form-group row">
                                                                                <div className="col-md-10 col-10 pr-0">
                                                                                    <Select
                                                                                        options={
                                                                                            terms_conditions
                                                                                        }
                                                                                        onChange={
                                                                                            handleSelectTermsConditions
                                                                                        }
                                                                                        value={
                                                                                            single_quotation.payment_terms
                                                                                        }
                                                                                        isSearchable={
                                                                                            isSearchable
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="float-right pl-0 col-md-2 col-2">
                                                                                    <div className="input-group-prepend">
                                                                                        <span
                                                                                            href="#"
                                                                                            className="tool-tip text-primary"
                                                                                            data-tip="this is a tool tip"
                                                                                        >
                                                                                            <i className="la la-question-circle"></i>
                                                                                        </span>
                                                                                        <div
                                                                                            className="a-tagicon icon-s"
                                                                                            onClick={() =>
                                                                                                setModal(
                                                                                                    'payment_terms_modal',
                                                                                                    true
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <i className="ft ft-plus-circle text-primary"></i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </form>
                                                )}
                                                <div className="row justify-content-end">
                                                    <div className="mr-2">
                                                        <div className="form-group">
                                                            {/* <Show when="inv.sales.quotations.draft"><button onClick={() => handleOnSubmit('create','draft')} type="button" className="btn btn-outline-light text-dark">Draft</button></Show>{" "}
                                                        {
                                                            (edit_row_index != null || isOther || isDiscount) ? <button type="button" className="btn btn-primary btn-save" disabled>Save</button>
                                                            : <button onClick={() => handleOnSubmit('create','saved')} type="submit" className="btn btn-primary btn-save">Save</button>
                                                        } */}
                                                            <Show when="inv.sales.quotations.draft">
                                                                <div className="btn-group btn-dpd">
                                                                    <button
                                                                        className="btn btn-default btn-outline-light text-dark btn-main"
                                                                        onClick={() =>
                                                                            handleOnSubmit(
                                                                                'create',
                                                                                'draft'
                                                                            )
                                                                        }
                                                                        type="button"
                                                                        disabled={
                                                                            mini_loading
                                                                        }
                                                                    >
                                                                        Save as
                                                                        Draft
                                                                    </button>
                                                                    <button
                                                                        data-toggle="dropdown"
                                                                        className="btn btn-default btn-outline-light text-dark dropdown-toggle btn-carret"
                                                                        type="button"
                                                                        disabled={
                                                                            mini_loading
                                                                        }
                                                                    >
                                                                        <span className="caret"></span>
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        {/* <li><a className="dpd-menu" onClick={() => handleOnSubmit('create', 'draft')}>Daft</a></li> */}
                                                                        <li>
                                                                            <a
                                                                                className="dpd-menu"
                                                                                onClick={() =>
                                                                                    handleOnSubmit(
                                                                                        'create',
                                                                                        'draft-email'
                                                                                    )
                                                                                }
                                                                            >
                                                                                Draft
                                                                                &
                                                                                Email
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                className="dpd-menu"
                                                                                onClick={() =>
                                                                                    handleOnSubmit(
                                                                                        'create',
                                                                                        'draft-print'
                                                                                    )
                                                                                }
                                                                            >
                                                                                Draft
                                                                                &
                                                                                Print
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </Show>{' '}
                                                            <div className="btn-group btn-dpd">
                                                                <button
                                                                    className="btn btn-primary  btn-main"
                                                                    onClick={() =>
                                                                        handleOnSubmit(
                                                                            'create',
                                                                            'saved'
                                                                        )
                                                                    }
                                                                    type="button"
                                                                    disabled={
                                                                        mini_loading
                                                                    }
                                                                >
                                                                    Save
                                                                    Quotation
                                                                </button>
                                                                <button
                                                                    data-toggle="dropdown"
                                                                    className="btn btn-primary dropdown-toggle btn-carret"
                                                                    type="button"
                                                                    disabled={
                                                                        mini_loading
                                                                    }
                                                                >
                                                                    <span className="caret"></span>
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                    {/* {
                                                                    (edit_row_index != null || isOther || isDiscount) &&
                                                                    <li><a className="dpd-menu" href="#" onClick={() => handleOnSubmit('create', 'saved')}>Save</a></li>
                                                                } */}
                                                                    <li>
                                                                        <a
                                                                            className="dpd-menu"
                                                                            onClick={() =>
                                                                                handleOnSubmit(
                                                                                    'create',
                                                                                    'saved-email'
                                                                                )
                                                                            }
                                                                        >
                                                                            Save
                                                                            &
                                                                            Email
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className="dpd-menu"
                                                                            onClick={() =>
                                                                                handleOnSubmit(
                                                                                    'create',
                                                                                    'saved-print'
                                                                                )
                                                                            }
                                                                        >
                                                                            Save
                                                                            &
                                                                            Print
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <QuotationCustomerModal
                    show={customer_modal}
                    onHide={() => setModal('customer_modal', false)}
                />
                <PaymentTermsModal
                    show={payment_terms_modal}
                    onHide={() => setModal('payment_terms_modal', false)}
                />
                <QuotationValidationModal
                    show={quote_validation_modal}
                    onHide={() => setModal('quote_validation_modal', false)}
                />
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    quotation: state.quotation,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    handleOnSubmit,
    handleSelectCustomer,
    toggleEditBtn,
    handleAddItem,
    handleRemoveItem,
    handleInputAttr,
    handleInputChange,
    handleInputSrp,
    handleCheckVat,
    handleInputQty,
    handleSelectItem,
    handleSelectTermsConditions,
    handleEditInputRow,
    handleToggleEditRow,
    handleUpdateRow,
    handleAttrSubmit,
    getProducts,
    getTermsConditions,
    getCustomers,
    handleSelectDiscountType,
    handleSelectDiscountTypeProduct,
    handleInputChangeDiscount,
    handleInputDiscountProduct,
    handleDiscountItem,
    setModal,
    getQuotationTemplates,
    handleSelectSalesInvoiceTemplate,
    QUDatePicker,
    handleSelectPaymentType,
    handleSelectTermsConditions,
    clearQuotation,
    add_items,
    dynamicInputChange,
    blockRoute,
    handleOnInputChange,
    searchProducst,
    searchCustomers,
    subscriberSetting,
    computationHandler,
    handleTaxType,
    clear_data,
    ShowComboBox,
    getQuotationValidations,
})(QuotationCreate);
