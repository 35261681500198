import {
    SET_PAGE,
    SET_LOADING,
    SET_MINI_LOADING,
    GET_SINGLE_QUOTATION,
    GET_QUOTATIONS,
    GET_QUOTATION_STATUS,
    GET_QU_TERMS_CONDITIONS,
    GET_QU_PRODUCTS,
    GET_QU_CUSTOMERS,
    SEARCH_INPUT,
    CLEAR_QUOTATION,
    HANDLE_QU_SELECT_TERMS_CONDITIONS,
    HANDLE_QU_QTY,
    HANDLE_QU_INPUT_ATTR,
    HANDLE_QU_TOGGLE_EDIT_BTN_ATTR,
    HANDLE_QU_SRP,
    HANDLE_QU_CHECK_VAT,
    HANDLE_QU_SELECT_CUSTOMER,
    HANDLE_QU_SELECT_ITEM,
    HANDLE_QU_ADD_ITEM,
    HANDLE_QU_REMOVE_ITEM,
    QUOTATION_INPUT_CHANGE,
    GET_QU_SI_TEMPLATES,
    GET_QU_SO_TEMPLATES,
    HANDLE_SELECT_QU_SI_TEMPLATE,
    HANDLE_QU_TOGGLE_EDIT_ROW,
    HANDLE_QU_EDIT_INPUT_ROW,
    HANDLE_QU_EDIT_UPDATE_ROW,
    HANDLE_QU_SI_PAYMENT,
    HANDLE_QU_SELECT_RECEIPT_TYPE,
    HANDLE_QU_SUBMIT_ATTR,
    HANDLE_QU_SELECT_PAYMENT_TYPE,
    SET_PO_ITEM_ID,
    SET_MODAL,
    HANDLE_SELECT_QU_TEMPLATE,
    SET_DATE_PICKER,
    GET_QU_TEMPLATES,
    SET_QU_ITEM_ID,
    GET_ROWS_TO_DELETE,
    SET_ROW_CHECKED,
    SORT_BY_COLUMN,
    QUOTATION_UPDATE_FILE,
    QUOTATION_REMOVE_FILE,
    QUOTATION_UPLOADS_TO_REMOVE,
    QUOTATION_GET_UPLOADED_FILES,
    ITEMS_TEMPLATE,
    ADD_ITEM,
    TOGGLE_DISCOUNT_ROW,
    SET_LAST_ADDED_DROPDOWN_INDEX,
    SET_ITEM,
    QUOTATION_CLEAR_UPLOAD,
    GET_QU_SUB_SETTING,
    QU_SELECT_TAX_TYPE,
    QU_COMPUTATION_HANDLER,
    SEND_EMAIL,
    RESEND_EMAIL,
    DYNAMIC_SEND_EMAIL,
    DOWNLOAD_BTN,
    EMAIL_SETTING_DATA,
    CLEAR_DATA,
    BTN_FORM,
    GET_STATUS,
    GET_QU_VALIDATION,
    HANDLE_QU_SELECT_VALIDATION,
    CLEAR_QU_VALIDATION,
    QU_VALIDATION_INPUT_CHANGE,
} from '../types';

const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8,
    },
    quotations: [],
    status_view: 'all',
    pagination_list: { 0: [], 1: [], 2: [], all: [] },
    single_quotation: {
        sales_invoice: null,
        lastAddedDropdownIndex: null,
        quotation_no: '',
        showDiscountRow: false,
        quotation_items: [],
        discount: 0,
        discount_type: { value: 'none', label: 'None' },
        vat_amount: 0,
        tax: 0,
        default_tax: 0,
        tax_type: { value: 'none', label: 'None' },
        other: 0,
        customer_id: '',
        product_unit_id: '',
        sub_total: 0,
        total: 0,
        customer: null,
        remarks: '',
        terms_and_condition: '',
        payment_details: '',
        internal_notes: '',
        payment_terms: { value: 'none', label: 'None' },
        quotation_validation: { value: 'none', label: 'None' },
        payment: { value: 'Unpaid', label: 'Unpaid' },
        receipt_type: { value: 'sales invoice', label: 'Sales Invoice' },
        payment_mode: { value: '', label: 'None' },
        status: null,
        quotation_template_id: null,
        invoice_template_id: null,
        quotation_date: new Date(),
        sales_invoice_date: new Date(),
        subs_setting: [],
        uploads: [],
        email_sent: false,
        download_pdf: false,
    },
    subs_setting: null,
    new_uploads: [],
    files_to_remove: [],
    product_units: [],
    terms_conditions: [],
    quote_validation: [],
    customers: [],
    items: [],
    edit_quotation_items: [],
    tax_types: [
        { value: 'none', label: 'None' },
        { value: 'exclusive', label: 'Exclusive' },
        { value: 'inclusive', label: 'Inclusive' },
    ],
    default_product_value: { value: '', label: 'Choose Product' },
    payment_mode_options: [
        { value: '', label: 'None' },
        { value: 'cash', label: 'Cash' },
        { value: 'credit card', label: 'Credit Card' },
        { value: 'cod', label: 'COD' },
        { value: 'check', label: 'Check' },
        { value: 'bank transfer', label: 'Bank Transfer' },
    ],
    receipt_type_options: [
        { value: 'sales invoice', label: 'Sales Invoice' },
        { value: 'delivery receipt', label: 'Delivery Receipt' },
    ],
    itemSelected: {
        _id: '',
        sku: '',
        product_name: '',
        product_unit_id: '',
        product_unit_name: '',
        amount: 0.0,
        srp: 0.0,
        qty: 0,
    },
    edit_attr: {
        discount: 0,
        vat: 0,
        other: 0.0,
    },
    qu_templates: [],
    qu_default_template: [],
    inv_templates: [],
    inv_default_template: [],
    default_select_type_invoice: { value: '', label: 'Choose S.I. or D.R.' },
    default_customer_value: { value: '', label: 'Choose Customer' },
    type_invoice_options: [
        { value: 'S.I.', label: 'Sales Invoice' },
        { value: 'D.R.', label: 'Delivery Receipt' },
    ],
    isSearchable: true,
    isDiscount: false,
    isVat: false,
    isOther: false,
    isQuotationNo: false,
    search: '',
    loading: false,
    mini_loading: false,
    delete_modal: false,
    edit_row_index: null,
    preview_template: false,
    main_page: false,
    edit_page: false,
    print_modal_qu: false,
    print_modal_si: false,
    main_url: '/sales/quotations',
    create_url: '/sales/quotations/create',
    edit_url: '/sales/quotations/edit',
    create_si_url: '/sales/quotations/create-sales-invoice',
    edit_url_so: '/sales/sales-orders/edit',
    item_id: null,
    customer_modal: false,
    payment_terms_modal: false,
    quote_validation_modal: false,
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    diselect_row: false,
    set_row_checked: false,
    id_obj: null,
    item_template: {
        _id: '',
        sku: '',
        product_name: '',
        product_unit_id: '',
        product_id: '',
        product_unit_name: '',
        amount: 0.0,
        srp: 0.0,
        qty: 0,
        items: [],
        new: true,
        description: '',
        barcode: '',
        selected: false,
    },
    download_pdf: false,
    email_config: [],
    btn_form: {
        save: false,
        draft: false,
        email_save: false,
        cancel: false,
        approve: false,
    },
    single_quote_validation: [],
};

const quotationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_QUOTATIONS:
            return {
                ...state,
                quotations: action.payload.quotations,
                pagination: action.payload.pagination,
                set_row_checked: false,
            };
        case GET_QUOTATION_STATUS:
            return {
                ...state,
                quotations: action.payload.quotations,
                pagination: action.payload.pagination,
                pagination_list: action.payload.pagination_list,
            };
        case GET_SINGLE_QUOTATION:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    ...action.payload.single_quotation,
                },
                edit_quotation_items:
                    action.payload.single_quotation.quotation_items,
                edit_attr: action.payload.edit_attr,
                default_customer_value: action.payload.default_customer_value,
                qu_default_template: action.payload.qu_default_template,
                subs_setting: action.payload.subs_setting,
            };
        case GET_QU_TERMS_CONDITIONS:
            return {
                ...state,
                terms_conditions: action.payload,
            };
        case GET_QU_VALIDATION:
            return {
                ...state,
                quote_validation: action.payload,
            };
        case GET_QU_PRODUCTS:
            return {
                ...state,
                items: action.payload,
                item_template: {
                    ...state.item_template,
                    items: action.payload,
                },
            };
        case GET_QU_CUSTOMERS:
            return {
                ...state,
                customers: action.payload,
            };
        case HANDLE_QU_SELECT_CUSTOMER:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    customer_id: action.payload.customer_id,
                    customer: action.payload.customer,
                },
                default_customer_value: action.payload.default_customer_value,
            };
        case HANDLE_QU_SELECT_ITEM:
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    ...action.payload.item_selected,
                },
                default_product_value: action.payload.default_product_value,
            };
        case HANDLE_QU_ADD_ITEM:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    quotation_items: action.payload.quotation_items,
                },
                edit_quotation_items: action.payload.quotation_items,
                edit_row_index: null,
                default_product_value: { value: '', label: 'Choose Product' },
                itemSelected: {
                    _id: '',
                    sku: '',
                    product_name: '',
                    product_unit_id: '',
                    product_unit_name: '',
                    amount: 0.0,
                    srp: 0.0,
                    qty: 0,
                },
            };
        case HANDLE_QU_REMOVE_ITEM:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    quotation_items: action.payload.quotation_items,
                },
                edit_quotation_items: action.payload.quotation_items,
                edit_row_index: null,
            };
        case HANDLE_QU_SELECT_TERMS_CONDITIONS:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    payment_terms: action.payload,
                },
            };
        case HANDLE_QU_SELECT_VALIDATION:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    quotation_validation: action.payload,
                },
            };
        case HANDLE_QU_SRP:
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    srp: action.payload.srp,
                    amount: action.payload.amount,
                },
            };
        case HANDLE_QU_QTY:
            return {
                ...state,
                itemSelected: {
                    ...state.itemSelected,
                    qty: action.payload.qty,
                    amount: action.payload.amount,
                },
            };
        case HANDLE_QU_INPUT_ATTR:
            return {
                ...state,
                edit_attr: {
                    ...state.edit_attr,
                    [action.payload.key]: action.payload.value,
                },
            };
        case HANDLE_QU_SUBMIT_ATTR:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    [action.payload.key]: action.payload.value,
                },
                isDiscount: false,
                isOther: false,
            };
        case HANDLE_QU_TOGGLE_EDIT_BTN_ATTR:
            return {
                ...state,
                [action.payload.attr]: action.payload.status,
            };
        case HANDLE_QU_TOGGLE_EDIT_ROW:
            return {
                ...state,
                edit_row_index: action.payload,
            };
        case HANDLE_QU_EDIT_INPUT_ROW:
            return {
                ...state,
                edit_quotation_items: action.payload,
            };
        case HANDLE_QU_EDIT_UPDATE_ROW:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    quotation_items: action.payload,
                },
                edit_row_index: null,
            };
        case HANDLE_QU_CHECK_VAT:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    vat: action.payload,
                },
            };
        case QUOTATION_INPUT_CHANGE:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    [action.payload.key]: action.payload.value,
                },
            };
        case GET_QU_TEMPLATES:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    quotation_template_id: action.payload.quotation_template_id,
                },
                qu_templates: action.payload.qu_templates,
                qu_default_template: action.payload.qu_default_template,
            };
        case GET_QU_SI_TEMPLATES:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    invoice_template_id: action.payload.invoice_template_id,
                },
                inv_templates: action.payload.inv_templates,
                inv_default_template: action.payload.inv_default_template,
            };
        case GET_QU_SO_TEMPLATES:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    order_template_id: action.payload.order_template_id,
                },
                inv_templates: action.payload.inv_templates,
                inv_default_template: action.payload.inv_default_template,
            };
        case HANDLE_QU_SELECT_RECEIPT_TYPE:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    receipt_type: action.payload,
                },
            };
        case HANDLE_QU_SI_PAYMENT:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    payment: action.payload,
                },
            };
        case HANDLE_QU_SELECT_PAYMENT_TYPE:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    payment_mode: action.payload,
                },
            };
        case SET_PAGE:
            if (action.payload.page == 'main_page') {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                    edit_page: false,
                };
            } else if (action.payload.page == 'edit_page') {
                return {
                    ...state,
                    main_page: false,
                    edit_url: action.payload.edit_url,
                    [action.payload.page]: action.payload.status,
                };
            } else {
                return {
                    ...state,
                    [action.payload.page]: action.payload.status,
                };
            }
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case SET_MINI_LOADING:
            return {
                ...state,
                mini_loading: action.payload,
            };
        case SEARCH_INPUT:
            return {
                ...state,
                search: action.payload,
            };
        case GET_STATUS:
            return {
                ...state,
                status_view: action.payload,
            };
        case SET_MODAL: {
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
            };
        }
        case CLEAR_QUOTATION:
            return {
                ...state,
                single_quotation: {
                    sales_invoice: null,
                    lastAddedDropdownIndex: null,
                    quotation_no: '',
                    showDiscountRow: false,
                    quotation_items: [],
                    discount: 0,
                    discount_type: { value: 'none', label: 'None' },
                    vat_amount: 0,
                    tax: 0,
                    default_tax: 0,
                    tax_type: { value: 'none', label: 'None' },
                    other: 0,
                    customer_id: '',
                    product_unit_id: '',
                    sub_total: 0,
                    total: 0,
                    customer: null,
                    remarks: '',
                    terms_and_condition: '',
                    payment_details: '',
                    internal_notes: '',
                    payment_terms: { value: 'none', label: 'None' },
                    quotation_validation: { value: 'none', label: 'None' },
                    payment: { value: 'Unpaid', label: 'Unpaid' },
                    receipt_type: {
                        value: 'sales invoice',
                        label: 'Sales Invoice',
                    },
                    payment_mode: { value: '', label: 'None' },
                    status: null,
                    quotation_template_id: null,
                    invoice_template_id: null,
                    quotation_date: new Date(),
                    sales_invoice_date: new Date(),
                    subs_setting: [],
                    uploads: [],
                    email_sent: false,
                },
                edit_attr: {
                    discount: 0,
                    vat: 0,
                    other: 0.0,
                },
                edit_quotation_items: [],
                edit_row_index: null,
                isDiscount: false,
                isOther: false,
                isQuotationNo: false,
                loading: false,
                mini_loading: false,
                delete_modal: false,
                create_si_modal: false,
                main_page: false,
                edit_page: false,
                print_modal_qu: false,
                print_modal_si: false,
                main_url: '/sales/quotations',
                create_url: '/sales/quotations/create',
                edit_url: '/sales/quotations/edit',
                create_si_url: '/sales/quotations/create-sales-invoice',
                item_id: null,
                customer_modal: false,
                payment_terms_modal: false,
                quote_validation_modal: false,
                sort_order_name: null,
                sort_order_direction: null,
                rows_to_delete: null,
                diselect_row: false,
                set_row_checked: false,
                id_obj: null,
            };
        case SET_PO_ITEM_ID:
            return {
                ...state,
                item_id: action.payload,
            };
        case HANDLE_SELECT_QU_TEMPLATE:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    quotation_template_id: action.payload.quotation_template_id,
                },
                qu_default_template: action.payload.qu_default_template,
            };
        case SET_DATE_PICKER:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    [action.payload.page_origin]: action.payload.date,
                },
            };
        case SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction,
            };
        case SET_QU_ITEM_ID:
            return {
                ...state,
                id_obj: action.payload,
            };
        case GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload,
            };
        case SET_ROW_CHECKED:
            return {
                ...state,
                set_row_checked: true,
            };
        case QUOTATION_UPDATE_FILE:
            return {
                ...state,
                new_uploads: [...state.new_uploads, ...action.payload],
            };
        case QUOTATION_REMOVE_FILE:
            if (action.payload.status == 'old') {
                return {
                    ...state,
                    single_quotation: {
                        ...state.single_quotation,
                        uploads: [
                            ...state.single_quotation.uploads.filter(
                                (file) => file.name !== action.payload.name
                            ),
                        ],
                    },
                };
            } else {
                return {
                    ...state,
                    new_uploads: [
                        ...state.new_uploads.filter(
                            (file) => file.name !== action.payload.name
                        ),
                    ],
                };
            }
        case QUOTATION_UPLOADS_TO_REMOVE:
            return {
                ...state,
                files_to_remove: [...state.files_to_remove, action.payload],
            };
        case QUOTATION_GET_UPLOADED_FILES:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    uploads: action.payload,
                },
                new_uploads: [],
                files_to_remove: [],
            };
        case ITEMS_TEMPLATE:
            return {
                ...state,
                item_template: action.payload,
            };
        case ADD_ITEM:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    quotation_items: action.payload,
                },
            };
        case TOGGLE_DISCOUNT_ROW:
            const updatedItems = state.single_quotation.quotation_items.map(
                (item, index) => {
                    if (index === action.payload.index) {
                        return {
                            ...item,
                            showDiscountRow: !action.payload.remove,
                        };
                    } else {
                        return item;
                    }
                }
            );

            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    quotation_items: updatedItems,
                },
            };
        case SET_LAST_ADDED_DROPDOWN_INDEX:
            return {
                ...state,
                single_quotation: action.payload,
            };

        case SET_ITEM:
            return {
                ...state,
                single_quotation: action.payload,
            };
        case QUOTATION_CLEAR_UPLOAD:
            return {
                ...state,
                new_uploads: [],
            };
        case QU_SELECT_TAX_TYPE:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    tax: action.payload.tax,
                    tax_type: action.payload.tax_type,
                },
            };
        case GET_QU_SUB_SETTING:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    ...action.payload,
                },
            };
        case QU_COMPUTATION_HANDLER:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    ...action.payload,
                },
            };
        case SEND_EMAIL:
            return {
                ...state,
                sending_email: action.payload,
            };
        case RESEND_EMAIL:
            return {
                ...state,
                single_quotation: {
                    ...state.single_quotation,
                    email_sent: action.payload,
                },
            };
        case DYNAMIC_SEND_EMAIL:
            return {
                ...state,
                quotations: action.payload,
            };
        case DOWNLOAD_BTN:
            return {
                ...state,
                download_pdf: action.payload,
            };
        case EMAIL_SETTING_DATA:
            return {
                ...state,
                email_config: action.payload,
            };
        case CLEAR_DATA:
            return {
                ...state,
                qu_templates: [],
                qu_default_template: [],
                inv_templates: [],
                inv_default_template: [],
                default_select_type_invoice: {
                    value: '',
                    label: 'Choose S.I. or D.R.',
                },
                default_customer_value: { value: '', label: 'Choose Customer' },
            };
        case BTN_FORM:
            return {
                ...state,
                btn_form: action.payload,
            };
        case CLEAR_QU_VALIDATION:
            return {
                ...state,
                single_quote_validation: [],
                search: '',
                loading: false,
                main_page: false,
                edit_page: false,
                delete_modal: false,
                main_url: '/settings/sales',
                create_url: '/settings/terms-conditions/create',
                edit_url: '/settings/terms-conditions/edit',
            };
        case QU_VALIDATION_INPUT_CHANGE:
            return {
                ...state,
                single_quote_validation: {
                    ...state.single_quote_validation,
                    [action.payload.key]: action.payload.value,
                },
            };
        default:
            return state;
    }
};

export default quotationReducer;
