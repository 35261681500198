import * as TYPES from './../../types';

// state
const initialState = {
    roles: [],
    user_form: { },
    historyRedirect: null,
    pagination: {totalCount: 0, activePage: 1, itemsCountPerPage: 1, totalItemsCount: 10, pageRangeDisplayed: 8 },
    main_url: '/settings/users',
    create_url: '/settings/users/account/create',
    edit_url: '/settings/users/account/edit',
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    loading: false,
    accounts: []
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case TYPES.SET_ROLES_DROPDOWN:
            return {
                ...state,
                roles: action.payload
            }
        case TYPES.SET_USER_FORM:
            return {
                ...state,
                user_form: action.payload
            }
        case TYPES.SET_REDIRECT:
            return {
                ...state,
                historyRedirect: action.payload
            } 
        case TYPES.SET_ACCOUNTs:
            return {
                ...state,
                accounts: action.payload.accounts,
                pagination: action.payload.pagination
            }
        case TYPES.SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction
            }  
        case TYPES.SET_ROLES_SEARCH: 
            return {
                ...state,
                search: action.payload
            }
        case TYPES.GET_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload
            }
        case TYPES.RESET_USER_FORM:
            return {
                ...state,
                user_form: {}
            }                 
        default:
            return state;
            break;
    }

}

export default userReducer;

