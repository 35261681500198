import { getNumRows } from '../../../Utils/Common.js';
import * as TYPES from '../../types.js';
import {
    ToastSuccess,
    ToastDanger,
    ToastWarning,
} from '../../../Services/_toast.service';
import { dailySalesService } from './_service.dailySales.js';
import { blockRoute, setMUIPage } from '../helper/helper.actions';

// Set Loading
export const setLoading = (status) => async (dispatch) =>
    dispatch({ type: TYPES.SET_LOADING, payload: status });

// Set Modal
export const setModal =
    (modal, products = null) =>
    async (dispatch) => {
        dispatch({
            type: TYPES.SET_MODAL,
            payload: { modal, status: products !== null },
        });
        dispatch({
            type: TYPES.PRODUCTS_DAILY_SALES,
            payload: products,
        });
    };

export const handleSearchInputMUI = (e) => async (dispatch) => {
    dispatch({ type: TYPES.SEARCH_INPUT, payload: e.target.value });
    // dispatch(getReceiving(1));
};

// returns selected rows only
export const getSelectedRows =
    (selectedRows, currentRows) => async (dispatch) => {
        // console.log(selectedRows, currentRows)
        let newArray = [];

        currentRows.map((current, i) => {
            selectedRows.map((selected, _i) => {
                if (selected.index == current.index) {
                    let selected = {
                        id: current.data[0],
                        name: current.data[1],
                    };
                    newArray.push(selected);
                }
            });
        });
        // console.log(newArray);
        dispatch({ type: TYPES.GET_ROWS_TO_DELETE, payload: newArray });
    };

// POS Transaction Checker
export const dailySalesChecker = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await dailySalesService.dailySalesChecker();

        // console.log('res', res);
        let sales = res.data.sales;
        let payload = {
            sales,
        };

        dispatch({ type: TYPES.DAILY_SALES_CHECKER, payload });
    } catch (err) {
        console.log(err);
        dispatch(setLoading(false));
        ToastDanger(`Server Error`);
    }
};

export const getDailySales =
    (pageNumber, rows_per_page = null, pageOrigin, date_filter = null) =>
    async (dispatch, getState) => {
        try {
            dispatch(setLoading(true));

            const {
                search,
                pagination,
                sort_order_name,
                sort_order_direction,
                sales_option,
            } = getState().daily_sales;

            let params = {
                search: search,
                sales_option: sales_option.value,
                options: {
                    page: pageNumber || pagination.activePage,
                    sort_by: sort_order_name,
                    sort_order: sort_order_direction,
                },
            };

            // Date filters
            if (date_filter) {
                params.date_from = date_filter.date_from;
                params.date_to = date_filter.date_to;
            }

            dailySalesService
                .getDailySales(params)
                .then((result) => {
                    // If the backend returns a status=2, that means large data queued
                    if (result.data.status === 2) {
                        // console.log('result', result);
                        // Dispatch action to store the job info in Redux
                        dispatch({
                            type: TYPES.LARGE_DATA_QUEUED,
                            payload: {
                                jobId: result.data.result.jobId,
                                total_docs: result.data.result.total_docs,
                            },
                        });
                    } else if (result.data.docs) {
                        // Normal flow: we got the docs
                        let payload = {
                            daily_sales: result.data.docs,
                            pagination: {
                                totalCount: result.data.total_docs,
                                activePage: result.data.page,
                                totalItemsCount: result.data.total_docs,
                            },
                        };
                        dispatch({ type: TYPES.GET_DAILY_SALES_DATA, payload });
                    } else {
                        // Maybe handle error or no data scenario
                    }

                    dispatch(setLoading(false));
                })
                .catch((err) => {
                    console.log(err);
                    dispatch(setLoading(false));
                });
        } catch (err) {
            console.log(err);
            dispatch(setLoading(false));
        }
    };

export const checkReport = (jobId) => async (dispatch) => {
    try {
        const res = await dailySalesService.checkReport(jobId);
        // if jobData found => status=1 => means doc is found => "completed"
        dispatch({
            type: TYPES.LARGE_DATA_STATUS_UPDATE,
            payload: {
                status: res.data.data.status, // 'completed'
                fileUrl: res.data.data.filePath, // ...
            },
        });
    } catch (err) {
        // If the API returns 0 => means doc is not found => "File is not ready"
        // => that implies still "in-progress" or "queued"
        // So you can do something like:
        dispatch({
            type: TYPES.LARGE_DATA_STATUS_UPDATE,
            payload: {
                status: 'in-progress',
                fileUrl: null,
            },
        });
    }
};

export const sortTableByColumn =
    (sort_order_name, sort_order_direction, pageOrigin = null) =>
    async (dispatch, getState) => {
        let sortingParams = {
            sort_order_name: sort_order_name,
            sort_order_direction: sort_order_direction,
        };

        dispatch({ type: TYPES.SORT_BY_COLUMN, payload: sortingParams });

        // Get the current daily sales from the state
        const { daily_sales } = getState().daily_sales;

        // Sort the existing data in the frontend
        const sortedData = daily_sales.sort((a, b) => {
            if (sort_order_direction === 'asc') {
                return a[sort_order_name] > b[sort_order_name] ? 1 : -1;
            } else {
                return a[sort_order_name] < b[sort_order_name] ? 1 : -1;
            }
        });

        // Dispatch the sorted data
        dispatch({
            type: TYPES.GET_DAILY_SALES_DATA,
            payload: {
                daily_sales: sortedData,
                pagination: getState().daily_sales.pagination,
            },
        });
    };

// date input onchange
export const dateFilterInput = (e) => async (dispatch) => {
    dispatch({
        type: TYPES.DATE_FILTER_INPUT,
        payload: { key: [e.target.name], value: e.target.value },
    });
};

// DATE INPUT CLEAR
export const dateFilterClear =
    (type, pageOrigin = null) =>
    async (dispatch) => {
        dispatch({ type: TYPES.DAILY_SALES_DATE_INPUT_CLEAR, payload: type });

        switch (type) {
            case 'daily_sales':
                dispatch(getDailySales(1, null, pageOrigin));
                break;
            default:
                break;
        }
    };

// HANDLE SUBMIT DATE FILTER FOR [ QUOTATIONS, SALES_INVOICES .... ]
export const handleDateFilterSubmit =
    (e, filterBy, pageOrigin = null) =>
    async (dispatch, getState) => {
        e.preventDefault();

        let { date_to, date_from } = getState().daily_sales;
        const today = new Date();
        try {
            if (
                new Date(date_from).getTime() >= today.getTime() ||
                new Date(date_to).getTime() >= today.getTime()
            ) {
                ToastDanger('Your Date value can not be greater than today.');
            } else {
                let date_filter = { date_from: date_from, date_to: date_to };
                // CHECK TYPE OF PAGE TO FILTER
                switch (filterBy) {
                    case 'daily_sales':
                        dispatch(
                            getDailySales(1, null, pageOrigin, date_filter)
                        );
                        break;
                    default:
                        break;
                }

                // console.log(pageOrigin);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
            ToastDanger('Something went wrong please contact your admin...');
        }
    };

// handle select sales options
export const handleSelectSalesType =
    (selectOption) => async (dispatch, getState) => {
        if (selectOption) {
            dispatch(blockRoute(true));
        } else {
            dispatch(blockRoute(false));
        }

        const { date_from, date_to } = getState().daily_sales; // Get current date range
        let date_filter = null;

        if (date_from && date_to) {
            date_filter = { date_from, date_to }; // Apply date filter if present
        }

        dispatch({ type: TYPES.HANDLE_SALES_OPTIONS, payload: selectOption });

        // Fetch data with updated sales option and date filter
        dispatch(getDailySales(1, null, null, date_filter));
    };
