import React, { Fragment } from 'react';
import { defaultFormat } from '../../../Utils/DateFormat';
import { numberFormat, capitalize, formatPrice } from '../../../Utils/Common';
import Pagination from 'react-js-pagination';
import DynamicFont from 'react-dynamic-font';
import { Link } from 'react-router-dom';

export class QuotationPrintContent extends React.PureComponent {
    // function QuotationPrintContent({ qu }) {
    constructor(props) {
        super(props);
        this.divScrollRef = React.createRef();

        let img_url = '/app-assets/images/logo/sample-logo.png';
        const tableRows = this.renderExtrRow(this.props.print_rows);
        let totalItemsCount = this.props.qu.quotation_items.length;

        // Set itemsCountPerPage to 5 if the totalItemsCount is 7, 15, 23, or 31
        let itemsCountPerPage = [7, 15, 23, 31].includes(totalItemsCount)
            ? 6
            : 8;

        this.state = {
            qu: this.props.qu,
            activePage: 1,
            itemsCountPerPage: itemsCountPerPage,
            totalItemsCount: totalItemsCount,
            pageRangeDisplayed: 5,
            img_url: img_url,
            tableRows: tableRows,
            print: this.props.print,
            subs_setting: this.props.subs_setting,
        };
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.divScrollRef.current.scrollIntoView();
    }

    renderExtrRow(numberOfRows) {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }

        return tableRows;
    }

    renderPrint(page, totalPage) {
        const { qu } = this.state;
        const totalItemsCount = qu.quotation_items.length;
        var showItemsTo = page * this.state.itemsCountPerPage;
        var showItemsFrom = showItemsTo - this.state.itemsCountPerPage;
        showItemsTo = showItemsTo - 1;
        let extraRowsCount = this.state.itemsCountPerPage;
        if (this.state.print == 0) {
            // extraRowsCount -= 15;
        }

        qu.quotation_items.map((item, i) => {
            if (showItemsFrom <= i && showItemsTo >= i) {
                extraRowsCount--;
            }
        });

        const DynamicStringStyle = {
            'max-width': '150px',
            fontSize: '1rem',
            lineHeight: '1.45',
            overflow: 'hidden',
            'text-decoration': 'underline',
        };

        const customerAddress = [
            qu.customer.street1,
            qu.customer.street2 && ', ',
            qu.customer.street2,
            (qu.customer.street1 || qu.customer.street2) && ', ',
            qu.customer.city,
            qu.customer.city && qu.customer.state && ', ',
            qu.customer.state,
        ]
            .filter(Boolean)
            .join('');

        const template_setting = this.state.qu.template_setting;

        const subs_setting = this.state.subs_setting;
        // const currencyDisplay = "currency" in subs_setting && "code" && subs_setting.currency && subs_setting.currency.code && subs_setting.currency.code.checked ? "code" : "symbol"
        const currencyDisplay =
            'currency' in subs_setting &&
            'code' &&
            subs_setting.currency &&
            subs_setting.currency.code &&
            subs_setting.currency.code.checked
                ? 'code'
                : 'symbol';

        // console.log('qu', qu);

        return (
            <div className="margin-1p5em px-1 print-page mt-1">
                <div className="print-logo-container print-header-container">
                    {/* {template_setting && template_setting.company_logo && (
            <img src={template_setting.company_logo} className='print-logo' />
          )}
          {template_setting.company_logo === "" ? (
            template_setting ? (
              <div className='c-name'>{template_setting.company_name}</div>
            ) : (
              <div className='print-logo-empty'></div>
            )
          ) : null} */}

                    {/* {template_setting && template_setting.company_logo ? (
            <img src={template_setting.company_logo} className='print-logo' />
          ) : template_setting ? (
            <div className='c-name'>{template_setting.company_name}</div>
          ) : (
            <div className='print-logo-empty'></div>
          )} */}

                    <div className="d-flex col-md-12 p-0 mb-2">
                        <div className="col-md-3 p-0">
                            <img
                                src={
                                    template_setting &&
                                    'company_logo' in template_setting
                                        ? template_setting.company_logo
                                        : '/BlitzLogo.png'
                                }
                                alt="logo"
                                className="print-logo"
                            />
                        </div>

                        <div className="col-md-9 text-center">
                            <div className="f-14b ul-print-paw">
                                {template_setting && (
                                    <span>{template_setting.company_name}</span>
                                )}{' '}
                                {template_setting && (
                                    <span>
                                        {`| ${template_setting.company_address}`}
                                    </span>
                                )}
                            </div>
                            <div className="f-14b ul-print-paw">
                                {template_setting && (
                                    <span>
                                        {template_setting.company_email}
                                    </span>
                                )}{' '}
                                {template_setting && (
                                    <span>
                                        {`| ${template_setting.company_mobile}`}
                                    </span>
                                )}{' '}
                                {template_setting && (
                                    <span>
                                        {`| ${template_setting.company_landline}`}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="print-header text-right">
                        <span className="print-header-text">QUOTATION</span>
                    </div>
                </div>
                <div className="print-body row print-content-block">
                    <div className="col-md-12 pr-0">
                        <span className="f-20b">Customer:</span>
                    </div>
                    <div className="col-md-4 col-print-4">
                        <span className="f-16b">
                            <Link
                                to={`/sales/customers/history/${qu.customer._id}`}
                                className="txt-underline"
                                target="_blank"
                            >
                                {qu.customer.name}
                            </Link>
                        </span>
                        {/* <p className='f-12'>{qu.customer.street1}</p> */}
                        <p className="f-12">{customerAddress}</p>
                    </div>
                    <div className="col-md-4 col-print-4"></div>
                    <div className="col-md-4 col-print-4 pr-0">
                        <span className="f-16b">QU No.</span>{' '}
                        <span className="f-right">{qu.quotation_no}</span>
                        <br />
                        <span className="f-16b">Date</span>{' '}
                        <span className="f-right">
                            {qu.quotation_date
                                ? defaultFormat(qu.quotation_date)
                                : null}
                        </span>
                    </div>
                    <div className="col-md-12 pr-0 mt-1">
                        {qu.status == 'draft' ? (
                            <div class="watermark">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <table className="table table-striped table-print">
                            <thead>
                                <tr className="print-table-header">
                                    <th>#</th>
                                    <th>Item Description</th>
                                    <th className="text-center">Price</th>
                                    <th className="text-center">Unit</th>
                                    <th className="text-center">Qty</th>
                                    <th className="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {qu.quotation_items.map((item, i) =>
                                    showItemsFrom <= i ? (
                                        showItemsTo >= i ? (
                                            <>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td
                                                        style={
                                                            DynamicStringStyle
                                                        }
                                                    >
                                                        <Link
                                                            to={`/inventory/view/${item.product_id}`}
                                                            className="txt-underline"
                                                            target="_blank"
                                                        >
                                                            <DynamicFont
                                                                content={
                                                                    item.product_name
                                                                }
                                                            />
                                                        </Link>
                                                        {item.product !=
                                                            null && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {
                                                                        item
                                                                            .product
                                                                            .barcode
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                        {item.product !=
                                                            null && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {
                                                                        item
                                                                            .product
                                                                            .description
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                        {item.brand != null && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {item.brand}
                                                                </span>
                                                            </>
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {formatPrice(
                                                            item.srp,
                                                            currencyDisplay
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.product_unit
                                                            ? item.product_unit
                                                                  .name
                                                            : ''}
                                                    </td>
                                                    <td className="text-center">
                                                        {numberFormat(
                                                            item.qty,
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {formatPrice(
                                                            item.amount,
                                                            currencyDisplay
                                                        )}
                                                    </td>
                                                </tr>

                                                {item.discount_type &&
                                                    item.discount_type.value !==
                                                        'none' && (
                                                        <tr>
                                                            <td></td>
                                                            <td>
                                                                <span>
                                                                    <span>
                                                                        Discount:{' '}
                                                                    </span>
                                                                    <span className="product-discount">
                                                                        {item
                                                                            .discount_type
                                                                            .label ===
                                                                        'Percent'
                                                                            ? item.discount
                                                                            : formatPrice(
                                                                                  item.discount,
                                                                                  currencyDisplay
                                                                              )}
                                                                        {item
                                                                            .discount_type
                                                                            .label ===
                                                                        'Percent'
                                                                            ? ' %'
                                                                            : ''}
                                                                    </span>
                                                                </span>

                                                                <span
                                                                    style={{
                                                                        margin: '0 0 0 10px',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        Discount
                                                                        type:{' '}
                                                                    </span>
                                                                    <span className="product-discount">
                                                                        {
                                                                            item
                                                                                .discount_type
                                                                                .label
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className="text-center text-danger">
                                                                -{' '}
                                                                {formatPrice(
                                                                    Number(
                                                                        item.discount_amount
                                                                    ).toFixed(
                                                                        2
                                                                    ),
                                                                    currencyDisplay
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                            </>
                                        ) : (
                                            void 0
                                        )
                                    ) : (
                                        void 0
                                    )
                                )}
                                {this.renderExtrRow(extraRowsCount)}
                            </tbody>
                        </table>
                    </div>

                    {page == totalPage ? (
                        <>
                            <div
                                className="d-flex col-md-12 p-0 m-0"
                                style={{ height: 'auto' }}
                            >
                                <div className="col-md-8 col-print-8 ">
                                    <div className="f-14b">
                                        Quotation Validity:
                                        <span className="f-12 font-weight-light">
                                            {' '}
                                            {qu.quotation_validation.label}
                                        </span>
                                    </div>
                                    <div
                                        className="f-14b"
                                        style={{ height: '50px' }}
                                    >
                                        Terms and Condition:{' '}
                                        <span className="f-12 font-weight-light">
                                            {qu.terms_and_condition}
                                        </span>
                                    </div>
                                    <div className="f-14b">
                                        Payment Terms:{' '}
                                        <span className="f-12 font-weight-light">
                                            {qu.payment_terms.label}
                                        </span>
                                    </div>
                                    <div className="f-14b">
                                        Payment Details:{' '}
                                        <span className="f-12 font-weight-light">
                                            {qu.payment_mode.label}
                                        </span>
                                    </div>
                                    <div
                                        className="f-12 font-weight-light"
                                        style={{
                                            height: 'auto',
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {qu.payment_details}
                                    </div>
                                    <div
                                        className="f-14b"
                                        style={{ height: '50px' }}
                                    >
                                        Additional Remarks:{' '}
                                        <span className="f-12 font-weight-light">
                                            {qu.remarks}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-md-4 col-print-4 pr-0">
                                    <span className="f-14b">Sub Total:</span>
                                    <span className="f-right f-14b">
                                        {formatPrice(
                                            qu.sub_total,
                                            currencyDisplay
                                        )}
                                    </span>
                                    {qu.discount_type.value === 'none' ? (
                                        ''
                                    ) : (
                                        <>
                                            <br />
                                            <span className="f-14b">
                                                Discount{' '}
                                                {qu.discount &&
                                                qu.discount_type.value ===
                                                    'percent'
                                                    ? `(${numberFormat(
                                                          qu.discount,
                                                          0
                                                      )}%)`
                                                    : null}
                                                :
                                            </span>
                                            <span className="f-right f-14b">
                                                {qu.discount &&
                                                qu.discount_type.value ===
                                                    'percent'
                                                    ? formatPrice(
                                                          qu.sub_total *
                                                              (qu.discount /
                                                                  100),
                                                          currencyDisplay
                                                      )
                                                    : formatPrice(
                                                          qu.discount,
                                                          currencyDisplay
                                                      )}{' '}
                                            </span>
                                        </>
                                    )}

                                    {qu.tax_type.value === 'none' ? (
                                        ''
                                    ) : (
                                        <>
                                            <br />{' '}
                                            <span className="f-14b">
                                                {' '}
                                                VAT{' '}
                                                {qu.tax
                                                    ? `${numberFormat(
                                                          qu.tax,
                                                          0
                                                      )}%`
                                                    : null}
                                                :
                                            </span>
                                            <span className="f-right f-14b">
                                                {qu.tax
                                                    ? formatPrice(
                                                          qu.vat_amount,
                                                          currencyDisplay
                                                      )
                                                    : ''}
                                            </span>
                                        </>
                                    )}

                                    {qu.other === 0 ? (
                                        ''
                                    ) : (
                                        <>
                                            <br />
                                            <span className="f-14b">
                                                Delivery Fee:
                                            </span>
                                            <span className="f-right f-14b">
                                                {formatPrice(
                                                    qu.other,
                                                    currencyDisplay
                                                )}{' '}
                                            </span>
                                        </>
                                    )}

                                    <br />
                                    <section class="print-total">
                                        <span className="f-16b">Total:</span>
                                        <span className="f-right f-16b">
                                            {formatPrice(
                                                qu.total,
                                                currencyDisplay
                                            )}
                                        </span>
                                        <br />
                                    </section>
                                </div>
                            </div>

                            <div className="d-flex col-md-12 p-0 m-0">
                                <div className="col-4">
                                    <span className="f-14b">Prepared by:</span>
                                    <p className="f-12">
                                        {qu.user_prepared_by}&nbsp;
                                    </p>
                                </div>
                                <div className="col-4">
                                    <span className="f-14b">Checked by:</span>
                                    <p className="f-12">
                                        {qu.user_checked_by}&nbsp;
                                    </p>
                                </div>
                                <div className="col-4">
                                    <span className="f-14b">Approved by:</span>
                                    <p className="f-12">
                                        {qu.user_approved_by}&nbsp;
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : (
                        void 0
                    )}
                </div>
                <div className="print-footer h-p25em">
                    <div className="col-12 pr-0 pt-1">
                        <div className="row">
                            <div className="col-4 "></div>
                            <div className="col-4 "></div>
                            <div className="col-4 ">
                                <span className="f-right mt-25-f mt-0">
                                    Page {page}/{totalPage}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderMultiplePage() {
        const tableRows = [];
        const tableRowsTry = [];
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        for (let i = 1; i <= numberOfPage; i++) {
            tableRows.push(this.renderPrint(i, Math.floor(numberOfPage)));
        }
        return tableRows;
    }

    render() {
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );

        const tableRows = [];
        for (var i = 0; i < 5; i++) {
            tableRows.push(
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }

        // let qu = this.state.qu;

        return (
            <Fragment>
                <div ref={this.divScrollRef} className="main-print-div">
                    {this.state.print == 0 ? (
                        this.renderPrint(this.state.activePage, numberOfPage)
                    ) : (
                        <Fragment>{this.renderMultiplePage()}</Fragment>
                    )}{' '}
                </div>

                <div className="hidden-print d-flex justify-content-center pagination-print">
                    <Pagination
                        className="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

export default QuotationPrintContent;
