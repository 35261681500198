import {
    SET_LOADING,
    SEARCH_INPUT,
    GET_RECEIVINGS,
    GET_PO,
    GET_PO_DATA,
    RECEIVING_INPUT_CHANGE,
    RECEIVING_DATA,
    GET_UNRECEIVE_DATA,
    LOAD_UNRECEIVE_DATA,
    SET_PO_ITEM_ID,
    BOOLEAN_VAR,
    GET_SUPPLIERS,
    GET_PO_SUPPLIERS_DEFAULT,
    RECEIVING_LOAD_ITEM_SUP,
    MANUAL_ITEM,
    SORT_BY_COLUMN,
    SET_MODAL,
    MANUAL,
    SET_PO,
    SUPPLIER_NAME,
    BTN_SUB,
    RECEIVING_UPDATE_FILE,
    RECEIVING_REMOVE_FILE,
    RECEIVING_UPLOADS_TO_REMOVE,
    RECEIVING_GET_UPLOADED_FILES,
    EDIT_COST,
    EDIT_SRP,
    RECEIVING_CLEAR_UPLOAD,
    SEND_EMAIL,
    RESEND_EMAIL,
    DYNAMIC_SEND_EMAIL,
    DOWNLOAD_BTN,
    EMAIL_SETTING_DATA,
    RECEIVING_REDIRECT,
    GET_RECEIVING_TEMPLATES,
    SELECT_RECEIVING_TEMPLATE,
    GET_IR_ROWS_TO_DELETE,
    CLEAR_DATA,
    BTN_FORM,
    RECEIVING_PRINT,
    HOVER_PRODUCT,
    GET_STATUS,
    GET_IR_STATUS,
    SET_DATE_PICKER,
} from './../types';

// state
const initialState = {
    pagination: {
        totalCount: 0,
        activePage: 1,
        itemsCountPerPage: 1,
        totalItemsCount: 10,
        pageRangeDisplayed: 8,
    },
    receivings: [],
    status_view: 'all',
    pagination_list: { 0: [], 1: [], 2: [], all: [] },
    po_data: [],
    po_items: [],
    receiving_print: { print: false, id: null },
    PO: {},
    supplier_name: '',
    search: '',
    loading: false,
    get_unreceive: false,
    unreceive_items: [],
    single_receiving: { reference_no: '', remarks: '' },
    deliveryDate: new Date(),
    receivingData: {
        _id: '',
        lot_no: '',
        supplier_id: '',
        supplier_name: '',
        purchase_order_id: '',
        item_receipt_template_id: '',
        reference_no: '',
        remarks: '',
        status: 'draft',
        // po_no: "",
        purchase_order_no: '',
        date: '2020-01-01',
        user: '',
        delivery_status: '',
        user_received_by: '',
        user_checked_by: '',
        uploads: [],
        email_sent: false,
        item_receipt_template_id: '',
        item_receipt_no: '',
        latest_ir_no: '',
        oldest_ir_no: '',
        delivery_date: new Date(),
    },
    item_receipt_templates: [],
    ir_default_template: [],
    new_uploads: [],
    files_to_remove: [],
    item_id: null,
    single_boolean: false,
    suppliers: [],
    suppliers_default: [],
    supp_items: [],
    sort_order_name: null,
    sort_order_direction: null,
    rows_to_delete: null,
    edit_url: '/receivings/edit',
    supplier_modal: false,
    print_modal: false,
    barcode_modal: false,
    btn_submit: true,
    editable_cost: false,
    editable_srp: false,
    sending_email: false,
    email_config: [],
    redirect: false,
    rows_to_delete: null,
    btn_form: {
        save: false,
        draft: false,
        email_save: false,
        cancel: false,
        approve: false,
    },
};

const receivingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RECEIVINGS:
            return {
                ...state,
                receivings: action.payload.receivings,
                pagination: action.payload.pagination,
            };
        case GET_IR_STATUS:
            return {
                ...state,
                receivings: action.payload.receivings,
                pagination: action.payload.pagination,
                pagination_list: action.payload.pagination_list,
            };
        case GET_PO:
            return {
                ...state,
                po_data: action.payload.po,
            };

        case SEARCH_INPUT:
            return {
                ...state,
                search: action.payload,
            };
        case GET_STATUS:
            return {
                ...state,
                status_view: action.payload,
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case BTN_SUB:
            return {
                ...state,
                btn_submit: action.payload,
            };
        case GET_PO_DATA:
            return {
                ...state,
                po_items: action.payload.po_items,
                supplier_name: action.payload.supplier_name,
                PO: action.payload.PO,
                single_receiving: {
                    ...state.single_receiving,
                    remarks: action.payload.remarks,
                },
            };
        case RECEIVING_INPUT_CHANGE:
            return {
                ...state,
                single_receiving: {
                    ...state.single_receiving,
                    [action.payload.key]: action.payload.value,
                },
            };
        case SET_PO_ITEM_ID:
            return {
                ...state,
                item_id: action.payload,
            };
        case RECEIVING_DATA:
            return {
                ...state,
                receivingData: action.payload.receivingData,
                po_items: action.payload.po_items,
                single_receiving: action.payload.single_receiving,
                subs_setting: action.payload.subs_setting,
                ir_default_template: action.payload.ir_default_template,
            };

        case GET_UNRECEIVE_DATA:
            return {
                ...state,
                get_unreceive: action.payload,
            };

        case LOAD_UNRECEIVE_DATA:
            return {
                ...state,
                unreceive_items: action.payload.unreceive_items,
            };
        case BOOLEAN_VAR:
            return {
                ...state,
                single_boolean: action.payload,
            };
        case GET_SUPPLIERS:
            return {
                ...state,
                suppliers: action.payload,
            };
        case GET_PO_SUPPLIERS_DEFAULT:
            return {
                ...state,
                suppliers_default: action.payload,
            };
        case RECEIVING_LOAD_ITEM_SUP:
            return {
                ...state,
                supp_items: action.payload,
            };

        case MANUAL_ITEM:
            return {
                ...state,
                po_items: action.payload.items,
                supplier_name: action.payload.supplier_name,
            };
        case SORT_BY_COLUMN:
            return {
                ...state,
                sort_order_name: action.payload.sort_order_name,
                sort_order_direction: action.payload.sort_order_direction,
            };
        case SET_MODAL:
            return {
                ...state,
                [action.payload.modal]: action.payload.status,
            };
        case MANUAL:
            return {
                ...state,
                po_items: action.payload,
            };
        case SET_PO:
            return {
                ...state,
                PO: action.payload,
            };
        case SUPPLIER_NAME:
            return {
                ...state,
                supplier_name: action.payload,
            };
        case RECEIVING_UPDATE_FILE:
            return {
                ...state,
                new_uploads: [...state.new_uploads, ...action.payload],
            };
        case RECEIVING_REMOVE_FILE:
            if (action.payload.status == 'old') {
                return {
                    ...state,
                    receivingData: {
                        ...state.receivingData,
                        uploads: [
                            ...state.receivingData.uploads.filter(
                                (file) => file.name !== action.payload.name
                            ),
                        ],
                    },
                };
            } else {
                return {
                    ...state,
                    new_uploads: [
                        ...state.new_uploads.filter(
                            (file) => file.name !== action.payload.name
                        ),
                    ],
                };
            }
        case RECEIVING_UPLOADS_TO_REMOVE:
            return {
                ...state,
                files_to_remove: [...state.files_to_remove, action.payload],
            };
        case RECEIVING_GET_UPLOADED_FILES:
            return {
                ...state,
                receivingData: {
                    ...state.receivingData,
                    uploads: action.payload,
                },
                new_uploads: [],
                files_to_remove: [],
            };
        case EDIT_COST:
            return {
                ...state,
                editable_cost: action.payload,
            };
        case EDIT_SRP:
            return {
                ...state,
                editable_srp: action.payload,
            };
        case RECEIVING_CLEAR_UPLOAD:
            return {
                ...state,
                new_uploads: [],
            };
        case SEND_EMAIL:
            return {
                ...state,
                sending_email: action.payload,
            };
        case RESEND_EMAIL:
            return {
                ...state,
                receivingData: {
                    ...state.receivingData,
                    email_sent: action.payload,
                },
            };
        case DYNAMIC_SEND_EMAIL:
            return {
                ...state,
                receivings: action.payload,
            };
        case DOWNLOAD_BTN:
            return {
                ...state,
                download_pdf: action.payload,
            };
        case EMAIL_SETTING_DATA:
            return {
                ...state,
                email_config: action.payload,
            };
        case RECEIVING_REDIRECT:
            return {
                ...state,
                redirect: action.payload,
            };
        case GET_RECEIVING_TEMPLATES:
            return {
                ...state,
                receivingData: {
                    ...state.receivingData,
                    item_receipt_template_id:
                        action.payload.item_receipt_template_id,
                },
                item_receipt_templates: action.payload.item_receipt_templates,
                ir_default_template: action.payload.ir_default_template,
            };
        case SELECT_RECEIVING_TEMPLATE:
            return {
                ...state,
                receivingData: {
                    ...state.receivingData,
                    item_receipt_template_id:
                        action.payload.item_receipt_template_id,
                },
                ir_default_template: action.payload.ir_default_template,
            };
        case GET_IR_ROWS_TO_DELETE:
            return {
                ...state,
                rows_to_delete: action.payload,
            };
        case CLEAR_DATA:
            return {
                ...state,
                single_receiving: { reference_no: '', remarks: '' },
                item_receipt_templates: [],
                PO: {},
                po_items: [],
                supplier_name: '',
            };
        case BTN_FORM:
            return {
                ...state,
                btn_form: action.payload,
            };
        case RECEIVING_PRINT:
            return {
                ...state,
                receiving_print: action.payload,
            };
        case HOVER_PRODUCT:
            const { productId, productDetails } = action.payload;

            return {
                ...state,
                po_items: state.po_items.map((item) => {
                    if (item.item_id === productId) {
                        return {
                            ...item,
                            productDetails,
                        };
                    }
                    return item;
                }),
            };

        case SET_DATE_PICKER:
            return {
                ...state,
                receivingData: {
                    ...state.receivingData,
                    delivery_date: action.payload,
                },
            };
        default:
            return state;
            break;
    }
};

export default receivingReducer;
