import React from 'react';
import { connect } from 'react-redux';
import BlitzDefaultContent from '../modals/ReceivingPrintContent';
import MotoTruck from './MototruckTemplate';
import { getSubSetting } from '../../../Utils/Common';
import { useAccess } from 'react-redux-permission';

function ReceivingCustomTemplate({
    receiving: {
        receivingData,
        auth,
        componentRef,
        po_items,
        single_receiving,
    },
}) {
    const subs_setting = getSubSetting();
    const template_label = receivingData.template_setting
        ? receivingData.template_setting.template_label
        : 'none';

    // Set up useAccess
    const { hasPermission } = useAccess();
    const canViewCost = hasPermission('inv.receiving.itemReceipts.cost');

    switch (template_label) {
        case 'blitz_default':
            return (
                <>
                    <BlitzDefaultContent
                        subs_setting={subs_setting}
                        receivingData={receivingData}
                        po_items={po_items}
                        single_receiving={single_receiving}
                        auth={auth}
                        print="0"
                        print_rows="5"
                        canViewCost={canViewCost}
                    />
                    <div style={{ display: 'none' }}>
                        <BlitzDefaultContent
                            subs_setting={subs_setting}
                            receivingData={receivingData}
                            po_items={po_items}
                            single_receiving={single_receiving}
                            auth={auth}
                            ref={componentRef}
                            print="1"
                            print_rows="19"
                            canViewCost={canViewCost}
                        />
                    </div>
                </>
            );
        case 'mototruck':
            return (
                <>
                    <MotoTruck
                        subs_setting={subs_setting}
                        receivingData={receivingData}
                        po_items={po_items}
                        single_receiving={single_receiving}
                        auth={auth}
                        print="0"
                        print_rows="5"
                        canViewCost={canViewCost}
                    />
                    <div style={{ display: 'none' }}>
                        <MotoTruck
                            subs_setting={subs_setting}
                            receivingData={receivingData}
                            po_items={po_items}
                            single_receiving={single_receiving}
                            auth={auth}
                            ref={componentRef}
                            print="1"
                            print_rows="19"
                            canViewCost={canViewCost}
                        />
                    </div>
                </>
            );
        default:
            return (
                <>
                    <h4 className="text-center my-5">
                        -- There's No Data To Show--
                    </h4>
                </>
            );
    }
}

const mapStateToProps = (state) => ({
    receiving: state.receiving,
});

export default connect(mapStateToProps)(ReceivingCustomTemplate);
