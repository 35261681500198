import React, { Fragment } from 'react';
import { defaultFormat } from '../../../Utils/DateFormat';
import { numberFormat, capitalize, formatPrice } from '../../../Utils/Common';
import Pagination from 'react-js-pagination';
import DynamicFont from 'react-dynamic-font';
import { Link } from 'react-router-dom';

export class BlitzDefaultContent extends React.PureComponent {
    // function SalesOrderPrintContent({ so, auth }) {
    // let subId = auth.user.subscriber_id;
    constructor(props) {
        super(props);
        this.divScrollRef = React.createRef();

        let img_url = '/app-assets/images/logo/sample-logo.png';
        const tableRows = this.renderExtrRow(this.props.print_rows);
        let totalItemsCount = this.props.so.sales_order_items.length;

        // Set itemsCountPerPage to 5 if the totalItemsCount is 7, 15, 23, or 31
        let itemsCountPerPage = [7, 15, 23, 31].includes(totalItemsCount)
            ? 6
            : 8;

        this.state = {
            so: this.props.so,
            auth: this.props.auth,
            activePage: 1,
            itemsCountPerPage: itemsCountPerPage,
            totalItemsCount: totalItemsCount,
            pageRangeDisplayed: 5,
            img_url: img_url,
            tableRows: tableRows,
            print: this.props.print,
            subs_setting: this.props.subs_setting,
        };
    }

    renderExtrRow(numberOfRows) {
        const tableRows = [];
        for (var i = 0; i < numberOfRows; i++) {
            tableRows.push(
                <tr key={'tr-' + i}>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
            );
        }

        return tableRows;
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        this.divScrollRef.current.scrollIntoView();
    }

    renderPrint(page, totalPage) {
        // console.log(page, totalPage);
        const { so } = this.state;
        const totalItemsCount = so.sales_order_items.length;
        var showItemsTo = page * this.state.itemsCountPerPage;
        var showItemsFrom = showItemsTo - this.state.itemsCountPerPage;
        showItemsTo = showItemsTo - 1;
        let extraRowsCount = this.state.itemsCountPerPage;
        if (this.state.print == 0) {
            // extraRowsCount -= 15;
        }

        so.sales_order_items.map((item, i) => {
            if (showItemsFrom <= i && showItemsTo >= i) {
                extraRowsCount--;
                // console.log(extraRowsCount);
            }
        });

        // console.log(showItemsFrom, showItemsTo);
        // console.log(extraRowsCount);

        const DynamicStringStyle = {
            'max-width': '150px',
            fontSize: '1rem',
            lineHeight: '1.45',
            overflow: 'hidden',
            'text-decoration': 'underline',
        };

        let auth = this.state.auth;

        let subId = '5fbce7782151301e660fa0d4';

        let img_url =
            auth.user.subscriber_id == subId
                ? '/monaki.png'
                : '/app-assets/images/logo/sample-logo.png';
        let company =
            auth.user.subscriber_id == subId ? 'Monikki Enterprises' : '';
        let address =
            auth.user.subscriber_id == subId
                ? '7i Bellagio 3 Forbestown Rd cor Burgos Circle BGC Taguig'
                : '';
        let contact = auth.user.subscriber_id == subId ? '02-7368-2071' : '';
        let email =
            auth.user.subscriber_id == subId
                ? 'mysecret.ph23@gmail.com.com'
                : '';
        let img_class =
            auth.user.subscriber_id == subId
                ? 'print-logo temp-logo'
                : 'print-logo';
        let temp_footer =
            auth.user.subscriber_id == subId ? (
                <div className="col-md-12">
                    <div className="f-14b temp-div pt-1 pb-2">
                        <div className="f-12">
                            {company} | {address} | {contact} | {email}{' '}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-md-6 col-print-6">
                    <ul className="f-16b ul-print-paw">
                        <li>Phone #</li>
                        <li>Address</li>
                        <li>Website</li>
                    </ul>
                </div>
            );

        const customerAddress = [
            so.customer.street1,
            so.customer.street2 && ', ',
            so.customer.street2,
            (so.customer.street1 || so.customer.street2) && ', ',
            so.customer.city,
            so.customer.city && so.customer.state && ', ',
            so.customer.state,
        ]
            .filter(Boolean)
            .join('');

        // const { company_name, company_address, company_landline, company_email } = this.state.subs_setting;
        const subs_setting = this.state.subs_setting;
        const currencyDisplay =
            subs_setting &&
            subs_setting.currency &&
            subs_setting.currency.code &&
            subs_setting.currency.code.checked
                ? 'code'
                : 'symbol';
        const template_setting = this.state.so.template_setting;

        // console.log('so', so);
        return (
            <div className="margin-1p5em px-1 print-page mt-1">
                <div className="print-logo-container print-header-container">
                    {/* {template_setting && template_setting.company_logo && (
            <img src={template_setting.company_logo} className='print-logo' />
          )}
          {template_setting && template_setting.company_logo === "" ? (
            template_setting ? (
              <div className='c-name'>{template_setting.company_name}</div>
            ) : (
              <div className='print-logo-empty'></div>
            )
          ) : null} */}
                    <div className="d-flex col-md-12 p-0 mb-2">
                        <div className="col-md-3 p-0">
                            <img
                                src={
                                    template_setting &&
                                    'company_logo' in template_setting
                                        ? template_setting.company_logo
                                        : '/BlitzLogo.png'
                                }
                                alt="logo"
                                className="print-logo"
                            />
                        </div>

                        <div className="col-md-9 text-center">
                            <div className="f-14b ul-print-paw">
                                {template_setting && (
                                    <span>{template_setting.company_name}</span>
                                )}{' '}
                                {template_setting && (
                                    <span>
                                        {`| ${template_setting.company_address}`}
                                    </span>
                                )}
                            </div>
                            <div className="f-14b ul-print-paw">
                                {template_setting && (
                                    <span>
                                        {template_setting.company_email}
                                    </span>
                                )}{' '}
                                {template_setting && (
                                    <span>
                                        {`| ${template_setting.company_mobile}`}
                                    </span>
                                )}{' '}
                                {template_setting && (
                                    <span>
                                        {`| ${template_setting.company_landline}`}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="print-header text-right">
                        <span className="print-header-text">SALES ORDER</span>
                    </div>
                </div>

                <div className="print-body row print-content-block">
                    <div className="col-md-12 pr-0">
                        <span className="f-20b">Customer:</span>
                    </div>
                    <div className="col-md-4 col-print-4 ">
                        <span className="f-16b">
                            <Link
                                to={`/sales/customers/history/${
                                    so.customer ? so.customer._id : null
                                }`}
                                className="txt-underline"
                                target="_blank"
                            >
                                {so.customer ? so.customer.name : null}
                            </Link>
                        </span>
                        <p className="f-12">{customerAddress}</p>
                    </div>
                    <div className="col-md-4 col-print-4"></div>
                    <div className="col-md-4 col-print-4 pr-0">
                        <span className="f-16b">SO No</span>{' '}
                        <span className="f-right">{so.sales_order_no}</span>
                        <br />
                        <span className="f-16b">Date</span>{' '}
                        <span className="f-right">
                            {defaultFormat(so.sales_order_date)}
                        </span>
                    </div>
                    <div className="col-md-12 pr-0 mt-1">
                        {so.status == 'draft' ? (
                            <div class="watermark">DRAFT</div>
                        ) : (
                            ''
                        )}
                        <table className="table table-striped table-print">
                            <thead>
                                <tr className="print-table-header" key={-1}>
                                    <th>#</th>
                                    <th>Item Description</th>
                                    <th className="text-center">Price</th>
                                    <th className="text-center">Unit</th>
                                    <th className="text-center">Qty</th>
                                    <th className="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {so.sales_order_items.map((item, i) =>
                                    showItemsFrom <= i ? (
                                        showItemsTo >= i ? (
                                            <>
                                                <tr key={i}>
                                                    <td>{i + 1}</td>
                                                    <td
                                                        style={
                                                            DynamicStringStyle
                                                        }
                                                    >
                                                        <Link
                                                            to={`/inventory/view/${item.product_id}`}
                                                            className="txt-underline"
                                                            target="_blank"
                                                        >
                                                            <DynamicFont
                                                                content={
                                                                    item.product_name
                                                                }
                                                            />
                                                        </Link>
                                                        {item.sku != null && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {item.sku}
                                                                </span>
                                                            </>
                                                        )}
                                                        {item.description !=
                                                            null && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {
                                                                        item.description
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                        {item.brand != null && (
                                                            <>
                                                                <br />
                                                                <span className="f-12 text-dark">
                                                                    {item.brand}
                                                                </span>
                                                            </>
                                                        )}
                                                        {/* {
                                                            item.products.brand != null &&  <><br/><span className="f-10">{item.products.brand.name}</span></>
                                                        } */}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.unit_price
                                                            ? formatPrice(
                                                                  item.unit_price,
                                                                  currencyDisplay
                                                              )
                                                            : formatPrice(
                                                                  item.srp,
                                                                  currencyDisplay
                                                              )}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.product_unit_name
                                                            ? item.product_unit_name
                                                            : ''}
                                                    </td>
                                                    <td className="text-center">
                                                        {numberFormat(
                                                            item.qty,
                                                            0
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        {formatPrice(
                                                            item.amount,
                                                            currencyDisplay
                                                        )}
                                                    </td>
                                                </tr>

                                                {item.discount_type &&
                                                    item.discount_type.value !==
                                                        'none' && (
                                                        <tr>
                                                            <td></td>
                                                            <td>
                                                                <span>
                                                                    <span>
                                                                        Discount:{' '}
                                                                    </span>
                                                                    <span className="product-discount">
                                                                        {
                                                                            item.discount
                                                                        }
                                                                        {item
                                                                            .discount_type
                                                                            .label ===
                                                                        'Percent'
                                                                            ? ' %'
                                                                            : ''}
                                                                    </span>
                                                                </span>

                                                                <span
                                                                    style={{
                                                                        margin: '0 0 0 10px',
                                                                    }}
                                                                >
                                                                    <span>
                                                                        Discount
                                                                        type:{' '}
                                                                    </span>
                                                                    <span className="product-discount">
                                                                        {
                                                                            item
                                                                                .discount_type
                                                                                .label
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td className="text-center text-danger">
                                                                -{' '}
                                                                {numberFormat(
                                                                    Number(
                                                                        item.discount_amount
                                                                    ).toFixed(2)
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                            </>
                                        ) : (
                                            void 0
                                        )
                                    ) : (
                                        void 0
                                    )
                                )}
                                {this.renderExtrRow(extraRowsCount)}
                            </tbody>
                        </table>
                    </div>
                    {page == totalPage ? (
                        <>
                            <div
                                className="d-flex col-md-12 p-0 m-0"
                                style={{ height: '300px' }}
                            >
                                <div className="col-md-8 col-print-8 ">
                                    <div
                                        className="f-14b"
                                        style={{ height: '50px' }}
                                    >
                                        Terms and Condition:{' '}
                                        <span className="f-12 font-weight-light">
                                            {so.terms_and_condition}
                                        </span>
                                    </div>
                                    <div className="f-14b">
                                        Payment Terms:{' '}
                                        <span className="f-12 font-weight-light">
                                            {so.payment_mode.label}
                                        </span>
                                    </div>
                                    <div className="f-14b">
                                        Payment Details:{' '}
                                        <span className="f-12 font-weight-light">
                                            {so.payment_mode.label}
                                        </span>
                                    </div>
                                    <div
                                        className="f-12 font-weight-light"
                                        style={{
                                            height: 'auto',
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {so.payment_details}
                                    </div>
                                    <div
                                        className="f-14b"
                                        style={{ height: '50px' }}
                                    >
                                        Additional Remarks:{' '}
                                        <span className="f-12 font-weight-light">
                                            {so.remarks}
                                        </span>
                                    </div>
                                </div>

                                <div className="col-md-4 col-print-4 pr-0">
                                    <span className="f-14b">Sub Total:</span>
                                    <span className="f-right f-14b">
                                        {formatPrice(
                                            so.sub_total,
                                            currencyDisplay
                                        )}
                                    </span>

                                    {so.discount_type.value === '' ? (
                                        ''
                                    ) : (
                                        <>
                                            <br />
                                            <span className="f-14b">
                                                Discount{' '}
                                                {so.discount &&
                                                so.discount_type.value ===
                                                    'percent'
                                                    ? `(${numberFormat(
                                                          so.discount,
                                                          0
                                                      )}%)`
                                                    : null}
                                                :
                                            </span>
                                            <span className="f-right f-14b">
                                                {so.discount &&
                                                so.discount_type.value ===
                                                    'percent'
                                                    ? formatPrice(
                                                          so.sub_total *
                                                              (so.discount /
                                                                  100),
                                                          currencyDisplay
                                                      )
                                                    : formatPrice(
                                                          so.discount,
                                                          currencyDisplay
                                                      )}{' '}
                                            </span>
                                        </>
                                    )}
                                    {so.tax_type.value === 'none' ? (
                                        ''
                                    ) : (
                                        <>
                                            {' '}
                                            <br />
                                            <span className="f-14b">
                                                {' '}
                                                VAT{' '}
                                                {so.tax
                                                    ? `(${numberFormat(
                                                          so.tax,
                                                          0
                                                      )}%`
                                                    : null}
                                                :
                                            </span>
                                            <span className="f-right f-14b">
                                                {so.tax
                                                    ? formatPrice(
                                                          so.vat_amount,
                                                          currencyDisplay
                                                      )
                                                    : formatPrice(
                                                          so.vat_amount,
                                                          currencyDisplay
                                                      )}
                                            </span>
                                        </>
                                    )}

                                    {so.other === 0 ? (
                                        ''
                                    ) : (
                                        <>
                                            <br />
                                            <span className="f-14b">
                                                Delivery Fee:
                                            </span>
                                            <span className="f-right f-14b">
                                                {formatPrice(
                                                    so.other,
                                                    currencyDisplay
                                                )}{' '}
                                            </span>
                                        </>
                                    )}

                                    <br />
                                    <section class="print-total">
                                        <span className="f-16b">Total:</span>
                                        <span className="f-right f-16b">
                                            {formatPrice(
                                                so.total,
                                                currencyDisplay
                                            )}
                                        </span>
                                        <br />
                                    </section>
                                </div>
                            </div>

                            <div className="d-flex col-md-12 p-0 m-0">
                                <div className="col-4">
                                    <span className="f-14b">Prepared by:</span>
                                    <p className="f-12">
                                        {so.user_prepared_by}&nbsp;
                                    </p>
                                </div>
                                <div className="col-4">
                                    <span className="f-14b">Checked by:</span>
                                    <p className="f-12">
                                        {so.user_checked_by}&nbsp;
                                    </p>
                                </div>{' '}
                                <div className="col-4">
                                    <span className="f-14b">Approved by:</span>
                                    <p className="f-12">
                                        {so.user_approved_by}&nbsp;
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : (
                        void 0
                    )}
                </div>
                <div className="print-footer h-p25em">
                    <div className="col-12 pr-0 pt-1">
                        <div className="row">
                            <div className="col-4 "></div>
                            <div className="col-4 "></div>
                            <div className="col-4 ">
                                <span className="f-right mt-25-f mt-0">
                                    Page {page}/{totalPage}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-break" />
            </div>
        );
    }

    renderMultiplePage() {
        const tableRows = [];
        const tableRowsTry = [];
        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );
        for (let i = 1; i <= numberOfPage; i++) {
            tableRows.push(this.renderPrint(i, Math.floor(numberOfPage)));
        }
        return tableRows;
    }

    render() {
        let so = this.state.so;

        let numberOfPage = Math.ceil(
            this.state.totalItemsCount / this.state.itemsCountPerPage
        );

        return (
            <Fragment>
                {/*
                <div className="temp-watermark">
                    <h1>{so.status == 'draft' ? so.status : ''}</h1>
                </div>
            */}
                <div ref={this.divScrollRef} className="main-print-div">
                    {this.state.print == 0 ? (
                        this.renderPrint(this.state.activePage, numberOfPage)
                    ) : (
                        <Fragment>{this.renderMultiplePage()}</Fragment>
                    )}{' '}
                </div>

                <div className="hidden-print d-flex justify-content-center pagination-print">
                    <Pagination
                        className="pagination"
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                </div>
            </Fragment>
        );
    }
}

// const mapStateToProps = state => ({
// 	single_sales_order: state.sales_order.single_sales_order,
// 	auth: state.auth
// })

export default BlitzDefaultContent;
