import React from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import debounce from 'debounce-promise';

// 1) Define your custom SingleValue to show both label and business_name:
const CustomSingleValue = (props) => (
    <components.SingleValue {...props}>
        <div>
            {props.data.label}
            {props.data.data && props.data.data.business_name && (
                <div style={{ fontSize: '0.6em', color: '#777' }}>
                    {props.data.data.business_name &&
                        `${props.data.data.business_name} | `}
                    {props.data.data.street1} {props.data.data.street2},{' '}
                    {props.data.data.city}
                </div>
            )}
        </div>
    </components.SingleValue>
);

// 2) Define a styles object to override React Select's defaults:
const customStyles = {
    // Make the selected value container taller and allow multiline
    valueContainer: (provided) => ({
        ...provided,
        minHeight: '48px', // Increase this if you need more space
        height: 'auto',
        display: 'flex',
        alignItems: 'flex-start', // so the text starts at the top
        padding: '2px 8px', // optional: adjust padding to taste
    }),
    singleValue: (provided) => ({
        ...provided,
        whiteSpace: 'normal', // allow wrapping
        lineHeight: '1.2',
    }),
};

export default function CustomerSelect({
    loading,
    default_customer_value,
    customers,
    searchCustomers,
    handleSelectCustomer,
}) {
    return (
        <AsyncSelect
            isLoading={loading}
            classNamePrefix="lp-copy-sel"
            value={default_customer_value}
            loadingMessage={() => 'searching...'}
            noOptionsMessage={({ inputValue }) =>
                inputValue ? 'No Customer' : 'Search Customer'
            }
            defaultOptions={customers}
            loadOptions={debounce((value) => searchCustomers(value), 500)}
            isSearchable
            cacheOptions
            onChange={handleSelectCustomer}
            placeholder="Select Customer..."
            required
            components={{
                SingleValue: CustomSingleValue,
            }}
            // 3) Apply your custom styles here:
            styles={customStyles}
        />
    );
}
