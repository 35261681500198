import React, { Fragment, useEffect, useState } from 'react';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import {
    getReceiving,
    handleSearchInput,
    redirectTo,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    setModal,
    emailConfig,
    sendEmailDyanmic,
    dynamiPdf,
    confirmDeleteDialogBox,
    autoPrint,
} from '../../redux/actions/receiving/receiving.actions';
import ReactTooltip from 'react-tooltip';
import {
    formattedDate,
    truncateString,
    numberFormat,
    gettoPrint,
} from '../../Utils/Common';
import { customAlert } from '../../Utils/Confirm';
import { Redirect, useHistory, Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Show, useAccess } from 'react-redux-permission';
import ReceivingPrintModal from './modals/Receiving.modal.print';
import ReceivingBarcodeModal from './modals/Receiving.modal.barcode';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import { defaultFormat } from '../../Utils/DateFormat';

function ReceivingTable({
    receiving: {
        receivings,
        set_row_checked,
        pagination,
        search,
        loading,
        sort_order_name,
        sort_order_direction,
        edit_url,
        print_modal,
        barcode_modal,
        receiving_print,
    },
    getReceiving,
    handleSearchInput,
    redirectTo,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    setModal,
    handleHover,
    hoverIndex,
    updateMUISetting,
    pageOrigin,
    emailConfig,
    muiPage,
    setMUIPage,
    sendEmailDyanmic,
    dynamiPdf,
    confirmDeleteDialogBox,
    autoPrint,
}) {
    const { hasPermission } = useAccess();
    const canCreate = hasPermission('inv.receiving.itemReceipts.create');
    const canDelete = hasPermission('inv.receiving.itemReceipts.delete');
    const canEdit = hasPermission('inv.receiving.itemReceipts.edit');
    const canView = hasPermission('inv.receiving.itemReceipts.view');
    const canPrint = hasPermission('inv.receiving.itemReceipts.print');

    useEffect(() => {
        getReceiving(muiPage, null, pageOrigin);
        emailConfig();
        autoPrint();
    }, []);

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        if (debouncedSearch) {
            if (oldDebounced !== debouncedSearch[0]) {
                if (debouncedSearch[0].length !== 1) {
                    setOldDebounced(debouncedSearch[0]);
                    getReceiving(1, null, pageOrigin);
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    const history = useHistory();
    const btnDelete = (id, lot_no) => {
        var message = 'You are about to remove ' + lot_no + '. Continue?';
        customAlert('Are you sure?', message, function () {
            // /(id);
        });
    };

    // THIS WILL SET THE HEADER ALIGN STYLE
    const useStyles = makeStyles((theme) => ({
        centeredHeader: {
            '& span': {
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
            },
        },
    }));
    const classes = useStyles();

    const changePage = (link) => {
        history.push(link);
    };

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    root: {
                        '&:nth-child(5)': {
                            height: '61px',
                        },
                        '&:nth-child(11)': {
                            width: '350px',
                        },
                    },
                },
                MUIDataTableHeadCell: {},
            },
        });
    // console.log("receiving_print", receiving_print);
    const columns = [
        {
            name: '_id',
            label: '',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: 'item_receipt_no',
            label: 'Item Receipt No.',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = receivings[tableMeta['rowIndex']]._id;
                    //  setModal('print_modal', receivings[cellMeta.dataIndex]._id, true);
                    return (
                        <Link
                            className="txt-underline"
                            onClick={() => setModal('print_modal', id, true)}
                        >
                            {value}
                        </Link>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
            },
        },
        {
            name: 'created_at',
            label: 'Date Created',
            options: {
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span> {defaultFormat(value)} </span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
            },
        },
        {
            name: 'reference_no',
            label: 'Reference No.',
            options: {
                display: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let reference_id =
                        receivings[tableMeta['rowIndex']].reference_id;
                    if (reference_id) {
                        return (
                            <Link
                                className="txt-underline"
                                to={`/purchases/purchase-orders/edit/${reference_id}`}
                                target="_blank"
                            >
                                {value}
                            </Link>
                        );
                    } else {
                        return <span>{value}</span>;
                    }
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-left clm-10' };
                },
            },
        },
        {
            name: 'supplier_name',
            label: 'Supplier',
            options: {
                display: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = receivings[tableMeta['rowIndex']].supplier_id;
                    return (
                        <Link
                            className="txt-underline"
                            to={`/purchases/suppliers/history/${id}`}
                            target="_blank"
                        >
                            {value}
                        </Link>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'text-left cursor-pointer clm-10' };
                },
            },
        },
        {
            name: 'delivery_date',
            label: 'Delivery Date',
            options: {
                display: true,
                print: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div>{defaultFormat(value)}</div>;
                },
                setCellProps: (value) => {
                    return { className: 'text-left' };
                },
            },
        },
        {
            name: 'delivery_status',
            label: 'Delivery Status',
            options: {
                display: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <label
                            className={'parent-center lbl-status lbl-' + value}
                        >
                            {value}
                        </label>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ml-2' };
                },
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                display: true,
                sort: true,
                print: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <label
                            className={'parent-center lbl-status lbl-' + value}
                        >
                            {value}
                        </label>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer text-left' };
                },
            },
        },
        {
            name: '_total_items',
            label: 'Items',
            options: {
                display: true,
                sort: false,
                setCellProps: (value) => {
                    return { className: '' };
                },
                customBodyRender: (value) => {
                    return (
                        <div className="text-center ">
                            {numberFormat(value, 0)}{' '}
                        </div>
                    );
                },
            },
        },
        {
            name: '_total_qty',
            label: 'Requested',
            options: {
                display: true,
                sort: false,
                setCellProps: (value) => {
                    return { className: '' };
                },
                customBodyRender: (value) => {
                    return (
                        <div className="text-center ">
                            {numberFormat(value, 0)}{' '}
                        </div>
                    );
                },
            },
        },
        {
            name: 'remarks',
            label: 'Remarks',
            options: {
                display: true,
                sort: false,
                print: false,
                setCellProps: (value) => {
                    return { className: 'text-left' };
                },
            },
        },
        {
            name: '',
            label: 'Actions',
            options: {
                display: true,
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let id = receivings[tableMeta['rowIndex']]._id;
                    return (
                        <div style={{ width: '180px' }}>
                            {(hoverIndex == tableMeta.rowIndex ||
                                receivings[tableMeta['rowIndex']]
                                    .sending_email ||
                                receivings[tableMeta['rowIndex']]
                                    .download_pdf) && (
                                <div className="btn-group">
                                    <Show when="inv.receiving.itemReceipts.edit">
                                        <Link
                                            className="btn btn-sm btn-grey btn-sm"
                                            to={'/receivings/edit/' + id}
                                        >
                                            {' '}
                                            <i className="ft ft-edit"></i>{' '}
                                        </Link>
                                    </Show>
                                    <button
                                        className="btn btn-sm btn-blue"
                                        onClick={(e) =>
                                            sendEmailDyanmic(
                                                e,
                                                id,
                                                tableMeta.rowIndex
                                            )
                                        }
                                    >
                                        <i
                                            className={
                                                receivings[
                                                    tableMeta['rowIndex']
                                                ].sending_email
                                                    ? 'icon-loading-custom icon-15'
                                                    : receivings[
                                                          tableMeta['rowIndex']
                                                      ].email_sent
                                                    ? 'custom-resend'
                                                    : 'ft ft-navigation'
                                            }
                                        ></i>
                                    </button>
                                    <button
                                        className="btn btn-sm btn-danger"
                                        onClick={(e) =>
                                            dynamiPdf(e, id, tableMeta.rowIndex)
                                        }
                                    >
                                        <i
                                            className={
                                                receivings[
                                                    tableMeta['rowIndex']
                                                ].download_pdf
                                                    ? 'icon-loading-custom icon-15'
                                                    : 'ft-custom-pdf'
                                            }
                                        ></i>
                                    </button>
                                    <Show when="inv.receiving.itemReceipts.print-barcode">
                                        <button
                                            className="btn btn-sm btn-grey btn-barcode"
                                            onClick={() =>
                                                setModal(
                                                    'barcode_modal',
                                                    id,
                                                    true
                                                )
                                            }
                                        >
                                            {' '}
                                            <i className="icon-barcode"></i>
                                        </button>
                                    </Show>
                                </div>
                            )}
                        </div>
                    );
                },
                setCellProps: (value) => {
                    return { className: 'text-left' };
                },
            },
        },
    ];

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        // selectableRows: false,
        filter: true,
        search: false,
        searchOpen: true,
        filter: false,
        // responsive: 'stacked',
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `receiving_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            getReceiving(null, numberOfRows, pageOrigin);
            updateMUISetting('rows', 'receiving', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getReceiving(
                        tableState.page + 1,
                        pagination.itemsCountPerPage,
                        pageOrigin
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction, pageOrigin);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'receiving',
                        null,
                        tableState.columns
                    );
                    break;
                default:
                // console.log('action not handled.');
            }
        },
        onRowsDelete: () => {
            // Confirm Delete Modal
            if (canDelete) {
                confirmDeleteDialogBox(pageOrigin);
            }
            return false;
        },
        onRowClick: (rowData, rowMeta) => {
            // redirect to edit page
            // changePage(`${edit_url}/${rowData[0]}`)
        },
        onCellClick: (colData, cellMeta) => {
            if (cellMeta.colIndex != 6) {
                if (!canPrint) {
                    return false;
                }
                // changePage(`${edit_url}/${receivings[cellMeta.dataIndex]._id}`);
                //  setModal('print_modal', receivings[cellMeta.dataIndex]._id, true);
            }
        },
        onFilterChange: (column, filterList, type) => {
            // console.log(column, filterList, type);
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        onChange={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
        setRowProps: (data, dataIndex, rowIndex) => {
            return {
                onMouseEnter: () => handleHover('in', rowIndex),
                onMouseLeave: () => handleHover('out', rowIndex),
            };
        },
    };

    if (!canDelete) {
        options.selectableRows = false;
    }

    const _columns = updateMUISetting(
        'view-columns',
        'receiving',
        null,
        columns
    );

    return (
        <>
            <Show when="inv.receiving.itemReceipts.view">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={''}
                        data={receivings}
                        columns={_columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </Show>
            <ReceivingPrintModal
                show={print_modal}
                onHide={() => setModal('print_modal', null, false)}
            />
            <ReceivingBarcodeModal
                show={barcode_modal}
                onHide={() => setModal('barcode_modal', null, false)}
            />
        </>
    );
}

const mapStateToProps = (state) => ({
    receiving: state.receiving,
    hoverIndex: state.helper.hoverIndex,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    getReceiving,
    handleSearchInput,
    redirectTo,
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
    setModal,
    handleHover,
    updateMUISetting,
    emailConfig,
    setMUIPage,
    sendEmailDyanmic,
    dynamiPdf,
    confirmDeleteDialogBox,
    autoPrint,
})(ReceivingTable);
