import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Common';
import { connect } from 'react-redux';
 
// handle the public routes
function PublicRoute({ component: Component, auth: {  defaultRedirect }, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => !getToken() ? <Component {...props} /> : <Redirect to={{ pathname: defaultRedirect }} />} // should not immediately redirect to dashboard, should redirect to where user has access 
    />
  )
}

const mapStateToProps = state => ({
  auth: state.auth
})

 
export default connect(mapStateToProps)(PublicRoute)