import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Spinner, { MiniSpinner } from '../../components/layouts/Spinner';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import countryList from 'react-select-country-list';
import {
    handleInputChange,
    customerUpdate,
    handleSelectCountry,
    handleMobile,
    removeFileEdit,
    updateFile,
    getSingleCustomer,
} from '../../redux/actions/customer/customer.actions';
import { Redirect } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import { fileExtensionIcon } from '../../Utils/Common';
import { Link } from 'react-router-dom';
import { SwalFormWarning } from '../../Services/_swal.service';
import BeforeUnloadComponent from 'react-beforeunload-component';
import { useHistory, useLocation } from 'react-router-dom';
import { blockRoute } from '../../redux/actions/helper/helper.actions';

function CustomerEdit({
    customer: { single_customer, main_page, main_url, mini_loading },
    new_uploads,
    handleInputChange,
    customerUpdate,
    handleSelectCountry,
    handleMobile,
    isSearchable,
    removeFileEdit,
    updateFile,
    getSingleCustomer,
    match,
    block_route,
    blockRoute,
}) {
    const [countryOptions, setCountryOptions] = useState('');
    const [countryValue, setCountryValue] = useState(
        single_customer.country || { value: 'PH', label: 'Philippines' }
    );
    const [mobileCountry, setMobileCountry] = useState(
        single_customer.country && single_customer.country.value
            ? single_customer.country.value.toLowerCase()
            : 'ph'
    );
    // console.log('single_customer', single_customer);

    useEffect(() => {
        setCountryValue(single_customer.country);
        if (single_customer.country && single_customer.country.value) {
            setMobileCountry(single_customer.country.value.toLowerCase());
        }
    }, [single_customer.country]);

    /**
     * @param {"country" | "mobile"} origin - origin of change - "country" if from country dropdown or "mobile" if from mobile input
     * @param {string | null} - country code in lowercase e.g. "ph"
     * @param {{value: string, label: string} | null} countryObject - country dropdown option e.g. {value: "PH", label: "Philippines"}
     */
    const syncMobileAndCountry = (origin, country, countryObject) => {
        if (
            !origin ||
            !['country', 'mobile'].find((item) => item === origin.toLowerCase())
        )
            return;

        if (origin === 'country') {
            if (!countryObject) {
                setMobileCountry('ph');
            } else {
                setMobileCountry(countryObject.value.toLowerCase());
            }
        } else if (origin === 'mobile') {
            if (!country) return;

            const countryValue = countryOptions.find(
                (countryOption) => countryOption.value === country.toUpperCase()
            );
            setCountryValue(countryValue);
            handleSelectCountry(countryValue); // need to do change country in state as well
        }

        // reset mobile
        handleMobile('');
    };

    const onDrop = useCallback((acceptedFiles) => {
        updateFile(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    const history = useHistory();

    useEffect(() => {
        blockRoute(false);
    }, []);

    useEffect(() => {
        if (countryOptions == '') {
            setCountryOptions(countryList().getData());
        }

        getSingleCustomer(match.params.id);
    }, [countryOptions]);

    useEffect(() => {
        // When onClick navbar prevent another dialog box
        window.addEventListener('beforeunload', function (e) {
            // the absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
            blockRoute(false);
            console.log('01');
        });

        if (block_route) {
            window.history.pushState(null, null, window.location.pathname);
            window.addEventListener('popstate', onBackButtonEvent);
            console.log('02');
        }

        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [block_route]);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (block_route) {
            SwalFormWarning(
                'Warning',
                'Do you want to leave without saving',
                () => goLastLocation(),
                () => blockAgain()
            );
        }
    };

    const goLastLocation = () => {
        history.goBack();
        blockRoute(false);
    };

    const blockAgain = () => {
        window.history.pushState(null, null, window.location.pathname);
        console.log('03');
    };

    if (main_page) return <Redirect to={main_url} />;

    // const ext = fileName.substr(fileName.lastIndexOf('.') + 1);

    return (
        <BeforeUnloadComponent
            ignoreBeforeunloadDocument={true}
            blockRoute={block_route}
            modalComponentHandler={({
                handleModalLeave,
                handleModalCancel,
            }) => {
                SwalFormWarning(
                    'Warning',
                    'Do you want to leave without saving',
                    () => handleModalLeave(),
                    () => handleModalCancel()
                );
            }}
        >
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-wrapper-before"></div>
                    <div className="content-header row">
                        <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                            <h3 className="content-header-title mb-0 d-inline-block">
                                Sales
                            </h3>
                            <div className="breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper mr-1">
                                    <ol className="breadcrumb f-regular">
                                        <li className="breadcrumb-item">
                                            <a
                                                href={main_url}
                                                className="text-white"
                                            >
                                                Customers
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="#" className="text-white">
                                                Edit
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-body margin-top-5">
                        <section id="basic-examples">
                            <div className="row f-regular">
                                <div className="col-md-12 col-sm-12 col-lg-12 col-xl-9">
                                    <div className="card">
                                        <div className="card-header mb-0 pb-0">
                                            <Link to={main_url}>
                                                <i className="ft ft-arrow-left"></i>{' '}
                                                Back
                                            </Link>
                                        </div>
                                        <div className="card-content collapse show">
                                            <div className="card-body f-regular">
                                                {mini_loading ? (
                                                    <MiniSpinner />
                                                ) : (
                                                    <form
                                                        onSubmit={
                                                            customerUpdate
                                                        }
                                                    >
                                                        <ReactTooltip
                                                            effect="solid"
                                                            event="click"
                                                        />
                                                        <div className="row">
                                                            <div className="col-12 col-md-6 col-sm-12">
                                                                <label className="label-control">
                                                                    Name:{' '}
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            type="text"
                                                                            value={
                                                                                single_customer.name
                                                                            }
                                                                            name="name"
                                                                            className="form-control f-regular"
                                                                            placeholder="Name"
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    Business
                                                                    Name:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_customer.business_name
                                                                            }
                                                                            type="text"
                                                                            name="business_name"
                                                                            className="form-control f-regular"
                                                                            placeholder="Business Name"
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    Street 1:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <textarea
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_customer.street1
                                                                            }
                                                                            name="street1"
                                                                            rows="3"
                                                                            className="form-control f-regular"
                                                                            placeholder="Street 1"
                                                                        ></textarea>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    Street 2:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <textarea
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_customer.street2
                                                                            }
                                                                            name="street2"
                                                                            rows="3"
                                                                            className="form-control f-regular"
                                                                            placeholder="Street 2"
                                                                        ></textarea>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    City:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_customer.city
                                                                            }
                                                                            type="text"
                                                                            name="city"
                                                                            className="form-control f-regular"
                                                                            placeholder="City"
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    State/Region:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_customer.state
                                                                            }
                                                                            type="text"
                                                                            name="state"
                                                                            className="form-control f-regular"
                                                                            placeholder="State/Region"
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    Country:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <Select
                                                                            options={
                                                                                countryOptions
                                                                            }
                                                                            value={
                                                                                countryValue
                                                                            }
                                                                            onChange={(
                                                                                option
                                                                            ) => {
                                                                                setCountryValue(
                                                                                    option
                                                                                );
                                                                                syncMobileAndCountry(
                                                                                    'country',
                                                                                    null,
                                                                                    option
                                                                                );
                                                                                handleSelectCountry(
                                                                                    option
                                                                                );
                                                                            }}
                                                                            isSearchable={
                                                                                isSearchable
                                                                            }
                                                                            name="country"
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    Landline:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_customer.landline
                                                                            }
                                                                            type="number"
                                                                            name="landline"
                                                                            className="form-control f-regular"
                                                                            placeholder="Landline"
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    Mobile:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <PhoneInput
                                                                            countryCodeEditable={
                                                                                false
                                                                            }
                                                                            enableSearch="true"
                                                                            value={
                                                                                single_customer.mobile
                                                                                    ? single_customer.mobile
                                                                                    : ''
                                                                            }
                                                                            country={
                                                                                mobileCountry
                                                                            }
                                                                            inputProps={{
                                                                                name: 'mobile',
                                                                                required: false,
                                                                                autoFormat: true,
                                                                                enableSearch: true,
                                                                            }}
                                                                            inputClass="react-mobile-input w-100"
                                                                            placeholder=""
                                                                            masks={{
                                                                                ph: '... ... ....',
                                                                            }}
                                                                            onChange={(
                                                                                mobile,
                                                                                country
                                                                            ) => {
                                                                                setMobileCountry(
                                                                                    country.countryCode
                                                                                );
                                                                                syncMobileAndCountry(
                                                                                    'mobile',
                                                                                    country.countryCode
                                                                                );
                                                                                handleMobile(
                                                                                    mobile ===
                                                                                        country.dialCode
                                                                                        ? ''
                                                                                        : mobile
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    Fax:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_customer.fax
                                                                            }
                                                                            type="number"
                                                                            name="fax"
                                                                            className="form-control f-regular"
                                                                            placeholder="Fax"
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    TIN:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_customer.tin
                                                                            }
                                                                            type="number"
                                                                            name="tin"
                                                                            className="form-control f-regular"
                                                                            placeholder="TIN"
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    Email:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_customer.email
                                                                            }
                                                                            type="email"
                                                                            name="email"
                                                                            className="form-control f-regular"
                                                                            placeholder="Email"
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <label className="label-control">
                                                                    Contact
                                                                    Person:{' '}
                                                                </label>
                                                                <div className="form-group row">
                                                                    <div className="col-md-11 col-10">
                                                                        <input
                                                                            onChange={
                                                                                handleInputChange
                                                                            }
                                                                            value={
                                                                                single_customer.contact_person
                                                                            }
                                                                            type="text"
                                                                            name="contact_person"
                                                                            className="form-control f-regular"
                                                                            placeholder="Contact Person"
                                                                        />
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1">
                                                                        <div className="input-group-prepend">
                                                                            <span
                                                                                href="#"
                                                                                className="tool-tip text-primary"
                                                                                data-tip="this is a tool tip"
                                                                            >
                                                                                <i className="la la-question-circle"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* <div className="col-12 col-md-6 mt-2">
                                                            <div className="form-group row">
                                                                <div className="col-md-12 col-10">
                                                                    <center>
                                                                        <div {...getRootProps()} className="file-dropzone-line">
                                                                            <input {...getInputProps()}/>
                                                                                <i className="ft ft-share file-upload-icon"></i>
                                                                                <div className="file-upload-message">Drag & Drop <br/>Files Here</div>
                                                                                <div className="file-upload-message-or">or</div>
                                                                                <div className="btn btn-grey btn">Browse Files</div>
                                                                        </div>
                                                                    </center>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6 mt-2">
                                                            <div className="form-group row justify-content-center">
                                                                <div className="col-md-10 col-12">
                                                                    {
                                                                        single_customer.uploads.length > 0 && single_customer.uploads.map(upload => (
                                                                            <>
                                                                                <div class="d-flex justify-content-between align-items-center">
                                                                                    <div className="min-wdth-300">
                                                                                        <span className="file-icon-print"><img src={fileExtensionIcon(upload.name)} alt="file-types"/></span>
                                                                                        <div className="text-secondary file-icon-text">{upload.name.slice(0, 20) + (upload.name.length > 20 ? "..." : "")}</div>
                                                                                    </div>
                                                                                    
                                                                                    <div className="btn-group">
                                                                                        <a href={upload.file_path} download target="_blank" className="btn btn-blue btn-sm"><i className="ft ft-download"></i></a>
                                                                                        <button onClick={() => removeFileEdit(upload.name, 'old', upload._id)} className="btn btn-danger btn-sm"><i className="ft ft-x"></i></button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="dropdown-divider"></div>
                                                                            </>
                                                                        ))
                                                                    }
                                                                    {
                                                                        new_uploads.length > 0 && new_uploads.map(_upload => (
                                                                            <>
                                                                                <div class="d-flex justify-content-between align-items-center">
                                                                                    <div className="min-wdth-300">
                                                                                        <span className="file-icon-print"><img src={fileExtensionIcon(_upload.name)} alt="file-types"/></span>
                                                                                        <div className="text-secondary file-icon-text">{_upload.name.slice(0, 20) + (_upload.name.length > 20 ? "..." : "")}</div>
                                                                                    </div>
                                                                                    
                                                                                    <div className="btn-group">
                                                                                        <button onClick={() => removeFileEdit(_upload.name, 'new')}className="btn btn-danger btn-sm"><i className="ft ft-x"></i></button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="dropdown-divider"></div>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                            <div className="col-6 offset-6">
                                                                <div className="form-group row">
                                                                    <div className="col-md-12 col-8 text-right">
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary btn-save mr-4 mt-3"
                                                                        >
                                                                            Save
                                                                            Customer
                                                                        </button>
                                                                    </div>
                                                                    <div className="float-right pl-0 col-1 col-md-1"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </BeforeUnloadComponent>
    );
}

const mapStateToProps = (state) => ({
    customer: state.customer,
    new_uploads: state.customer.new_uploads,
    block_route: state.helper.block_route,
});

export default connect(mapStateToProps, {
    blockRoute,
    customerUpdate,
    handleInputChange,
    handleSelectCountry,
    handleMobile,
    removeFileEdit,
    updateFile,
    getSingleCustomer,
})(CustomerEdit);
