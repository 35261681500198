import React, { useEffect, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { MiniSpinner } from '../../../layouts/Spinner';
import { Modal, Button } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faCheckSquare,
    faSquare,
    faChevronRight,
    faChevronDown,
    faPlusSquare,
    faMinusSquare,
    faFolder,
    faFolderOpen,
    faFile,
} from '@fortawesome/free-solid-svg-icons';
import {
    getPemissions,
    setChecked,
    setExpanded,
    saveRole,
} from '../../../../redux/actions/settings/roles.actions';
import { useHistory } from 'react-router-dom';

function RoleCreate({
    rolesReducer: {
        cbtNodes,
        cbtChecked,
        cbtExpanded,
        historyRedirect,
        loading,
    },
    getPemissions,
    setChecked,
    setExpanded,
    saveRole,
}) {
    let history = useHistory();

    useEffect(() => {
        getPemissions();
    }, []);

    useEffect(() => {
        console.log(cbtChecked);
    }, [cbtChecked]);

    if (historyRedirect) {
        history.push(historyRedirect);
    }

    return (
        <div className="app-content content">
            <ReactTooltip effect="solid" event="click" />
            <div className="content-wrapper">
                <div className="content-wrapper-before"></div>
                <div className="content-header row">
                    <div className="content-header-left col-md-12 col-12 mb-2 breadcrumb-new fix-header">
                        <h3 className="content-header-title mb-0 d-inline-block">
                            Settings
                        </h3>
                        <div className="breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper mr-1">
                                <ol className="breadcrumb f-regular">
                                    <li className="breadcrumb-item">
                                        <Link
                                            to="/settings/users"
                                            className="text-white"
                                        ></Link>
                                        <a href="#">Role</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#" className="text-white">
                                            Create
                                        </a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body margin-top-5">
                    <section id="basic-examples">
                        <div className="row f-regular">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header mb-0 pb-0">
                                        <Link to="/settings/users">
                                            <i className="ft ft-arrow-left"></i>{' '}
                                            Back
                                        </Link>
                                    </div>
                                    <div className="card-content collapse show">
                                        <div className="card-body pb-4">
                                            {loading ? (
                                                <MiniSpinner />
                                            ) : (
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        saveRole(e);
                                                    }}
                                                    class="form-role-create"
                                                >
                                                    <div className="row">
                                                        <div className="col-md-4 col-12">
                                                            <section className="row f-regular">
                                                                <div className="col-12">
                                                                    <label className="label-control">
                                                                        Role
                                                                        Name:{' '}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <div className="form-group row">
                                                                        <div className="col-md-11 col-10">
                                                                            <input
                                                                                type="text"
                                                                                name="name"
                                                                                className="form-control"
                                                                                placeholder="Name.."
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="pl-0 col-1 col-md-1">
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <label className="label-control">
                                                                        Role
                                                                        Description:{' '}
                                                                    </label>
                                                                    <div className="form-group row">
                                                                        <div className="col-md-11 col-10">
                                                                            <textarea
                                                                                class="form-control"
                                                                                name="description"
                                                                                placeholder="Description.."
                                                                            ></textarea>
                                                                        </div>
                                                                        <div className="pl-0 col-1 col-md-1">
                                                                            <div className="input-group-prepend">
                                                                                <span
                                                                                    href="#"
                                                                                    className="tool-tip text-primary"
                                                                                    data-tip="this is a tool tip"
                                                                                >
                                                                                    <i className="la la-question-circle"></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div>
                                                        <div class="col-md-1">
                                                            {' '}
                                                            &nbsp;
                                                        </div>
                                                        <div class="col-md-6">
                                                            <section className="row f-regular">
                                                                <label className="label-control">
                                                                    Access:{' '}
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <div className="col-12 g-bg ">
                                                                    {cbtNodes.length ==
                                                                    0 ? (
                                                                        <MiniSpinner />
                                                                    ) : (
                                                                        <CheckboxTree
                                                                            checkModel="all"
                                                                            nodes={
                                                                                cbtNodes
                                                                            }
                                                                            checked={
                                                                                cbtChecked
                                                                            }
                                                                            expanded={
                                                                                cbtExpanded
                                                                            }
                                                                            onClick={(
                                                                                item
                                                                            ) =>
                                                                                console.log(
                                                                                    item
                                                                                )
                                                                            }
                                                                            onCheck={(
                                                                                checked
                                                                            ) => {
                                                                                let newChecked =
                                                                                    checked;
                                                                                for (const node of cbtNodes) {
                                                                                    // add parent if some child nodes are deselected
                                                                                    if (
                                                                                        node.children.some(
                                                                                            (
                                                                                                child
                                                                                            ) =>
                                                                                                checked.includes(
                                                                                                    child.value
                                                                                                ) &&
                                                                                                !checked.includes(
                                                                                                    node.value
                                                                                                )
                                                                                        )
                                                                                    ) {
                                                                                        newChecked.push(
                                                                                            node.value
                                                                                        );
                                                                                    }
                                                                                }
                                                                                setChecked(
                                                                                    newChecked
                                                                                );
                                                                            }}
                                                                            onExpand={(
                                                                                expanded
                                                                            ) =>
                                                                                setExpanded(
                                                                                    expanded
                                                                                )
                                                                            }
                                                                            showExpandAll="true"
                                                                            icons={{
                                                                                check: (
                                                                                    <FontAwesomeIcon
                                                                                        className="rct-icon rct-icon-check"
                                                                                        icon={
                                                                                            faCheckSquare
                                                                                        }
                                                                                    />
                                                                                ),
                                                                                uncheck:
                                                                                    (
                                                                                        <FontAwesomeIcon
                                                                                            className="rct-icon rct-icon-uncheck"
                                                                                            icon={
                                                                                                faSquare
                                                                                            }
                                                                                        />
                                                                                    ),
                                                                                halfCheck:
                                                                                    (
                                                                                        <FontAwesomeIcon
                                                                                            className="rct-icon rct-icon-half-check"
                                                                                            icon={
                                                                                                faCheckSquare
                                                                                            }
                                                                                        />
                                                                                    ),
                                                                                expandClose:
                                                                                    (
                                                                                        <FontAwesomeIcon
                                                                                            className="rct-icon rct-icon-expand-close"
                                                                                            icon={
                                                                                                faChevronRight
                                                                                            }
                                                                                        />
                                                                                    ),
                                                                                expandOpen:
                                                                                    (
                                                                                        <FontAwesomeIcon
                                                                                            className="rct-icon rct-icon-expand-open"
                                                                                            icon={
                                                                                                faChevronDown
                                                                                            }
                                                                                        />
                                                                                    ),
                                                                                expandAll:
                                                                                    (
                                                                                        <FontAwesomeIcon
                                                                                            className="rct-icon rct-icon-expand-all"
                                                                                            icon={
                                                                                                faPlusSquare
                                                                                            }
                                                                                        />
                                                                                    ),
                                                                                collapseAll:
                                                                                    (
                                                                                        <FontAwesomeIcon
                                                                                            className="rct-icon rct-icon-collapse-all"
                                                                                            icon={
                                                                                                faMinusSquare
                                                                                            }
                                                                                        />
                                                                                    ),
                                                                                parentClose:
                                                                                    (
                                                                                        <FontAwesomeIcon
                                                                                            className="rct-icon rct-icon-parent-close"
                                                                                            icon={
                                                                                                faFolder
                                                                                            }
                                                                                        />
                                                                                    ),
                                                                                parentOpen:
                                                                                    (
                                                                                        <FontAwesomeIcon
                                                                                            className="rct-icon rct-icon-parent-open"
                                                                                            icon={
                                                                                                faFolderOpen
                                                                                            }
                                                                                        />
                                                                                    ),
                                                                                leaf: (
                                                                                    <FontAwesomeIcon
                                                                                        className="rct-icon rct-icon-leaf-close"
                                                                                        icon={
                                                                                            faFile
                                                                                        }
                                                                                    />
                                                                                ),
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="col-12">
                                                                    <br />
                                                                    {cbtNodes.length >
                                                                    1 ? (
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary btn-save pull-right"
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    ) : (
                                                                        <span></span>
                                                                    )}
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    rolesReducer: state.rolesReducer,
});

export default connect(mapStateToProps, {
    getPemissions,
    setChecked,
    setExpanded,
    saveRole,
})(RoleCreate);
