import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Spinner from '../layouts/Spinner';
import { connect } from 'react-redux';
import {
    sortTableByColumn,
    handleSearchInputMUI,
    getSelectedRows,
} from '../../redux/actions/inventory/inventory.actions';
import { getStockLogs } from '../../redux/actions/inventory/stockLogs.action';
import { Redirect } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles,
} from '@material-ui/core/styles';
import { formattedDate, numberFormat } from '../../Utils/Common';
import { Show, useAccess } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    handleHover,
    setMUIPage,
    updateMUISetting,
} from '../../redux/actions/helper/helper.actions';
import StockLogDateFilter from './StockLog.datefilter.global';

function StockLogsTable({
    stock_logs: {
        stock_logs,
        sort_order_direction,
        pagination,
        sortTableByColumn,
        loading,
        sort_order_name,
        set_row_checked,
        search,
    },
    getStockLogs,
    setMUIPage,
    updateMUISetting,
    getSelectedRows,
    handleSearchInputMUI,
    pageOrigin,
    muiPage,
}) {
    const { hasPermission } = useAccess();
    const canview = hasPermission('inv.setting.inventory.stocks');

    // START DEVOUNCED SEARCH
    const debouncedSearch = useDebounce(search, 800);
    const [oldDebounced, setOldDebounced] = useState('');
    useEffect(() => {
        if (debouncedSearch) {
            if (oldDebounced !== debouncedSearch[0]) {
                if (debouncedSearch[0].length !== 1) {
                    setOldDebounced(debouncedSearch[0]);
                    getStockLogs(1, null, pageOrigin);
                }
            }
        }
    }, [debouncedSearch]);
    // END DEVOUNCE SEARCH

    useEffect(() => {
        getStockLogs(muiPage, null, pageOrigin);
    }, []);

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTable: {
                    root: {},
                    paper: {
                        boxShadow: 'none',
                    },
                },
                MUIDataTableBodyRow: {
                    root: {
                        '&:nth-child(odd)': {
                            backgroundColor: 'rgba(0,0,0,.05)',
                        },
                    },
                },
                MuiTableRow: {
                    hover: {
                        '&:hover': {
                            backgroundColor: '#bfcfe4 !important',
                        },
                    },
                },
            },
        });

    const columns = [
        {
            name: '_id',
            label: '',
            options: {
                display: false,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'quantity',
            label: 'Quantity',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'movement',
            label: 'Movement',
            options: {
                display: true,
                viewColumns: false,
                filter: false,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'supplier',
            label: 'Supplier',
            options: {
                display: true,

                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'lot_no',
            label: 'Lot No',
            options: {
                display: true,

                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'reference_type',
            label: 'Reference',
            options: {
                display: true,
                print: false,
                download: false,
                setCellProps: (value) => {
                    return { className: 'cursor-pointer ' };
                },
            },
        },
        {
            name: 'created_at',
            label: 'Date',
            options: {
                display: true,
                print: false,
                download: false,
                customBodyRender: (value) => {
                    return <span> {value ? value.substr(0, 10) : ''} </span>;
                },
                setCellProps: (value) => {
                    return { className: 'cursor-pointer' };
                },
            },
        },
    ];

    const _columns = updateMUISetting(
        'view-columns',
        'stock_logs',
        null,
        columns
    );

    const options = {
        serverSide: true,
        jumpToPage: true,
        filterType: 'dropdown',
        responsive: 'standard',
        search: false,
        filter: false,
        selectableRows: false,
        searchOpen: true,
        searchProps: {
            onBlur: (e) => {
                console.log('onBlur!');
            },
            onKeyUp: (e) => {
                console.log('onKeyUp!');
            },
        },
        rowsPerPageOptions: [10, 20],
        rowsPerPage: pagination.itemsCountPerPage,
        count: pagination.totalItemsCount,
        page: pagination.activePage - 1,
        sortOrder: {
            name: sort_order_name,
            direction: sort_order_direction,
        },
        downloadOptions: {
            filename: `stocklogs_${formattedDate()}.csv`,
        },
        textLabels: {
            body: {
                noMatch: loading ? (
                    <Spinner />
                ) : (
                    'Sorry, there is no matching data to display'
                ),
            },
        },
        onChangeRowsPerPage: (numberOfRows) => {
            // console.log("numberOfRows", numberOfRows);
            getStockLogs(null, numberOfRows, pageOrigin);
            // updateMUISetting('rows', 'purchase_order', numberOfRows);
        },
        onTableChange: (action, tableState) => {
            // console.log(action, tableState);
            switch (action) {
                case 'changePage':
                    getStockLogs(
                        tableState.page + 1,
                        pagination.itemsCountPerPage,
                        pageOrigin
                    );
                    setMUIPage(tableState.page + 1);
                    break;
                case 'sort':
                    // console.log(tableState.sortOrder.name, tableState.sortOrder.direction);
                    let column_name = tableState.sortOrder.name;
                    let direction = tableState.sortOrder.direction;
                    sortTableByColumn(column_name, direction, pageOrigin);
                    break;
                case 'search':
                    // handleSearchInputMUI(tableState.searchText);
                    break;
                case 'rowSelectionChange':
                    let selectedRows = tableState.selectedRows.data;
                    let currentRows = tableState.data;
                    getSelectedRows(selectedRows, currentRows);
                    break;
                case 'propsUpdate':
                    // let update_checked = tableState.selectedRows = { data: [], lookup: {}, showResponsive: false };
                    if (set_row_checked) {
                        tableState.selectedRows['data'] = [];
                        tableState.selectedRows['lookup'] = {};
                        tableState.selectedRows['showResponsive'] = false;
                    } else {
                        return tableState;
                    }
                    break;
                case 'viewColumnsChange':
                    updateMUISetting(
                        'columns',
                        'stock_logs',
                        null,
                        tableState.columns
                    );
                    break;
                case 'changeRowsPerPage':
                    // updateMUISetting('rows', 'purchase_order', tableState.rowsPerPage);
                    break;
                case 'onFilterDialogOpen':
                    break;
                case 'onSearchClose':
                    break;
                default:
                    // console.log(tableState);
                    console.log('action not handled.');
            }
        },
        customSearchRender: (searchText, handleSearch, hideSearch, options) => {
            return (
                <div className="custom-search">
                    <i className="ft ft-search"></i>{' '}
                    <input
                        type="text"
                        name="search"
                        onKeyUp={handleSearchInputMUI}
                        className="custom-search-input"
                    />
                </div>
            );
        },
    };

    return (
        <>
            <Show when="inv.setting.inventory.stocks">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <StockLogDateFilter
                        filterBy="stock_logs"
                        pageOrigin={pageOrigin}
                    />
                    <MUIDataTable
                        title={''}
                        data={stock_logs}
                        columns={_columns}
                        options={options}
                    />
                </MuiThemeProvider>
            </Show>
        </>
    );
}

const mapStateToProps = (state) => ({
    stock_logs: state.stock_logs,
    muiPage: state.helper.muiPage,
});

export default connect(mapStateToProps, {
    getStockLogs,
    handleHover,
    setMUIPage,
    updateMUISetting,
    getSelectedRows,
    handleSearchInputMUI,
})(StockLogsTable);
